import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

// Basic utils, constants and configs
import IconButton from '@material-ui/core/IconButton';
import $ from 'jquery';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class MuiDatatableSaveResultListBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchName: '',
      listName: '',
      isDisabled: true,
      savedLists: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.initialState = JSON.parse(JSON.stringify(this.state));
  }

  handleClose = () => {
    // reset state except savedLists
    const initialState = this.initialState;
    initialState.savedLists = this.state.savedLists;
    this.setState({
      ...initialState
    });
    this.props.handleClose();
  };

  loadMarkListsAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.loadMarkListsURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify({ markListType: this.props.markListPopupType }),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /loadMarkLists response', responseBody, status);
        this.setState({
          savedLists: responseBody.allMarkListsOfUser
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  generateSnackBarMessage(itemListCount, listName) {
    if (itemListCount > 0) {
      return `${itemListCount} Suchergebnisse wurden zur Liste "${listName}" hinzugefügt`;
    }
    return `Alle ${
      this.props.data.length
    } Suchergebnisse sind in der Liste "${listName}" vorhanden`;
  }

  addUserItemToMarkList(listName = '', markListId = '') {
    this.setState({
      isDisabled: false
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestPackage = {
      markListId,
      itemList: {
        listType: this.props.markListPopupType,
        list: this.props.data
      },
      listName: 'New List'
    };

    // check listname availability
    if (this.state.listName !== '' && listName === '') {
      requestPackage.listName = this.state.listName.trim();
    } else {
      requestPackage.listName = listName.trim();
    }

    const url = CONSTANTS.saveArchiveSearchResultToMarkListURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestPackage),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(
          'API /saveArchiveSearchResultToMarkList success response',
          responseBody,
          status
        );

        // TODO check if a stateChange is required
        this.setState({
          savedLists: responseBody.allMarkListsOfUser
        });

        let infoMessage = `Die Liste "${requestPackage.listName}" mit ${
          this.props.data.length
        } Einträge wurde erfolgreich gespeichert`;
        if (markListId.length > 0) {
          const count = this.props.data.length - responseBody.duplicates;
          if (count > 0) {
            this.handleClose();
          }
          infoMessage = this.generateSnackBarMessage(
            count,
            requestPackage.listName
          );
        } else {
          this.handleClose();
        }

        this.props.handleSnackbarOpen(infoMessage);
      })
      .fail(err => {
        console.log('error while deletion of search', JSON.stringify(err));
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
    this.setState({
      isDisabled: true
    });
  }

  // TODO refactoring is required
  markListLengthByContent(savedList) {
    if (this.props.markListPopupType === 'company') {
      return `${savedList.name} (${savedList.companyMarks.length})`;
    }
    if (this.props.markListPopupType === 'project') {
      return `${savedList.name} (${savedList.projectMarks.length})`;
    }
    return `${savedList.name} (${savedList.candidateMarks.length})`;
  }

  generateMarkListAttribute() {
    switch (this.props.markListPopupType) {
      case 'candidate':
        return 'candidateMarks';
      case 'project':
        return 'projectMarks';
      case 'company':
        return 'companyMarks';
      default:
        return '';
    }
  }

  countDuplicates(compareToList) {
    const attribute = this.props.markListPopupType;
    let duplicates = 0;
    this.props.data.forEach(entry => {
      if (compareToList.find(item => entry[attribute] === item[attribute])) {
        duplicates++;
      }
    });
    if (duplicates > 0) {
      if (duplicates === this.props.data.length) {
        return 'Alle Suchergebnisse sind in dieser Liste vorhanden';
      }
      const percent = ((duplicates / this.props.data.length) * 100).toFixed(1);
      return `${percent}% der Suchergebnisse sind in dieser Liste vorhanden`;
    }
    return 'Ergebnisse zur Liste hinzfügen';
  }

  renderListItems() {
    let originList = [];
    if (this.state.listName === '') {
      originList = this.state.savedLists;
    } else {
      if (this.state.savedLists) {
        originList = this.state.savedLists.filter(item =>
          item.name
            .toLowerCase()
            .includes(this.state.listName.trim().toLowerCase())
        );
      }
      if (originList.length === 0) {
        return TEXT.markListPopup.noExistingList;
      }
    }

    const resultArray = [];

    if (originList) {
      originList.forEach(savedList => {
        const { _id: listId } = savedList;
        let markListLengthByType = 0;
        // TODO refactor
        if (this.props.markListPopupType === 'candidate') {
          markListLengthByType = savedList.candidateMarks.length;
          // TODO refactor
        } else if (this.props.markListPopupType === 'company') {
          markListLengthByType = savedList.companyMarks.length;
          // TODO refactor
        } else if (this.props.markListPopupType === 'project') {
          markListLengthByType = savedList.projectMarks.length;
        }
        if (markListLengthByType !== 0) {
          resultArray.push(
            <ListItem
              button
              color="primary"
              key={listId}
              onClick={() => this.addUserItemToMarkList(savedList.name, listId)}
            >
              <ListItemText
                primary={this.markListLengthByContent(savedList)}
                secondary={this.countDuplicates(
                  savedList[this.generateMarkListAttribute()]
                )}
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="AddToList"
                  onClick={() =>
                    this.addUserItemToMarkList(savedList.name, listId)
                  }
                >
                  <i className="material-icons">add_circle</i>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
      });
    }
    // show latest mark lists first
    return resultArray.reverse();
  }

  render() {
    return (
      // Max dialog height if list of Items to Large ?
      <Dialog
        open={this.props.open}
        aria-labelledby="markCandidatePopUp-dialog-title"
        onEnter={this.loadMarkListsAPICall}
      >
        <DialogTitle>
          {this.props.data.length} Suchergebnisse zur Liste hinzufügen
        </DialogTitle>
        <DialogContent>
          {/* TODO is texfield input limit required ? */}
          <TextField
            value={this.state.listName}
            name="listName"
            label={TEXT.markListPopup.placeholder}
            fullWidth
            autoFocus
            margin="normal"
            inputProps={{
              maxLength: 35
            }}
            onChange={e => {
              this.setState({
                listName: e.target.value,
                isDisabled: e.target.value.length === 0
              });
            }}
          />
          <div className="float-right mb-4">
            <Button
              className="button-more-info"
              variant="contained"
              disabled={this.state.isDisabled}
              color="secondary"
              onClick={() => this.addUserItemToMarkList()}
            >
              {TEXT.markListPopup.button}
              <i className="material-icons pl-2">playlist_add</i>
            </Button>
          </div>
          <div className="container mt-5 pt-5">
            {this.renderListItems().length > 0 && (
              <DialogContentText>
                {TEXT.markListPopup.subheadline}
              </DialogContentText>
            )}
            <List>{this.renderListItems()}</List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {TEXT.markListPopup.cancelButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

MuiDatatableSaveResultListBox.defaultProps = {};

MuiDatatableSaveResultListBox.propTypes = {
  data: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  markListPopupType: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default MuiDatatableSaveResultListBox;
