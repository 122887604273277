import React from 'react';
import PropTypes from 'prop-types';

import './DetailField.css';
import * as UTILS from '../utils/utilFunctions';

const DetailField = ({ value, label, type, customOnClickHandler }) => {
  switch (type) {
    case 'phoneNumber':
      return (
        <div>
          <p className="detailLabel">{label}</p>
          <a
            className="detailField"
            href={'tel:'.concat(value)}
            onClick={customOnClickHandler}
          >
            {value}
          </a>
        </div>
      );
    case 'url': {
      let httpsUrl = '';
      if (value) {
        httpsUrl = value;
        if (
          !httpsUrl.startsWith('http://') &&
          !httpsUrl.startsWith('https://')
        ) {
          httpsUrl = `https://${value}`;
        }
      }
      return (
        <div>
          <p className="detailLabel">{label}</p>
          <a
            className="detailField"
            href={httpsUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            {value}
          </a>
        </div>
      );
    }
    case 'mail':
      return (
        <div>
          <p className="detailLabel">{label}</p>
          {/* <a className="detailField" href={UTILS.parserMailLink(value)}> */}
          <a className="detailField" href={UTILS.parserMailLink(value)}>
            {value}
          </a>
        </div>
      );
    default:
      return (
        <div>
          <p className="detailLabel">{label}</p>
          <p className="detailField">{value}</p>
        </div>
      );
  }
};
DetailField.defaultProps = {
  type: 'text',
  value: '',
  customOnClickHandler: () =>
    console.log('WARNING: customOnClickHandler not found')
};

DetailField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  customOnClickHandler: PropTypes.func
};
export default DetailField;
