import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// import PropTypes from 'prop-types';
// react context
// material UI
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import StarIcon from '@material-ui/icons/Star';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import HistoryDrawer from '../generic_components/HistoryDrawer';
import DetailField from '../generic_components/DetailField';
import MarkItemPopUp from '../components/MarkItemPopUp';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import withUser from '../utils/withUser';

import TEXT from './staticContent/text';
import TOOLTIPS from '../textTooltips';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';

class ProjectDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyChangeHistoryPopUp: false,
      isMarkProjectPopUpOpen: false,
      showDeleteProjectModal: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  renderButtonSection() {
    return (
      <div className="col-12 text-center">
        {this.renderShowHistoryButton()}
        {this.renderEditButton()}
        {this.renderMarkButton()}
        {this.renderDeleteButton()}
      </div>
    );
  }

  renderMarkButton() {
    return (
      <Tooltip title={TOOLTIPS.projectArchive.markButton}>
        <IconButton
          // disabled // TODO implement Marklist for Projects
          className="company-mark-button"
          color="primary"
          onClick={() => this.setState({ isMarkProjectPopUpOpen: true })}
        >
          <StarIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderEditButton() {
    return (
      <Tooltip title={TOOLTIPS.projectArchive.editButton}>
        <IconButton
          className="saveEditButton editButton"
          disabled={!this.props.isEditable}
          aria-label="Edit"
          color="primary"
          onClick={() =>
            this.props.liftUpStateToProjectArchive({
              isProjectDetailsUpdatePopUpOpen: true
            })
          }
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderDeleteButton() {
    const isAdminUser = this.props.user.role === 'admin';
    if (isAdminUser)
      return (
        <Tooltip title={TOOLTIPS.projectArchive.deleteButton}>
          <IconButton
            className="saveEditButton editButton"
            aria-label="Edit"
            color="primary"
            onClick={() => this.setState({ showDeleteProjectModal: true })}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      );
    return '';
  }

  renderDeleteCompanyConfirmationModal() {
    return (
      <ConfirmationModal
        key="confirmation-deleteCandidateData"
        handleClose={() => this.setState({ showDeleteProjectModal: false })}
        open={this.state.showDeleteProjectModal}
        buttonPrimaryAction={this.handleDeleteProjectAPICall}
        headlineText="Auftrag vollständig löschen?"
        icon="delete_forever"
        descriptionText="Soll der Auftrag wirklich vollständig gelöscht werden?"
      />
    );
  }

  handleDeleteProjectAPICall = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const url = CONSTANTS.deleteProjectURL;
    const projectId = this.props.projectId;
    const request = { projectId };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(request)
    })
      .done(responseBody => {
        if (
          responseBody &&
          responseBody.status === 'project_has_potential_candidates'
        )
          this.props.handleSnackbarOpen(
            'Auftrag konnte nicht gelöscht werden: Es existieren Kandiaten im Status/Beworben'
          );
        if (
          responseBody &&
          responseBody.status === 'project_jobposting_is_online'
        )
          this.props.handleSnackbarOpen(
            'Auftrag konnte nicht gelöscht werden: Eine Job Anzeige ist online'
          );
        if (
          responseBody &&
          responseBody.status === 'project_has_action_protocol_entries'
        )
          this.props.handleSnackbarOpen(
            'Auftrag konnte nicht gelöscht werden: Es existieren Einträge im Auftragsstatus'
          );
        if (responseBody && responseBody.status === 'success') {
          this.setState({ showDeleteProjectModal: false });
          this.props.handleSnackbarOpen('Auftrag wurde erfolgreich gelöscht');
          this.props.liftUpStateToProjectArchive({
            showResultList: true,
            showProjectDetails: false
          });
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url, null);
      });
  };

  renderShowHistoryButton() {
    return (
      <Tooltip title={TOOLTIPS.projectArchive.showHistoryButton}>
        <IconButton
          className="company-changeHistory-button"
          aria-label="changeHistory"
          color="primary"
          onClick={() => this.setState({ showCompanyChangeHistoryPopUp: true })}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderHistoryDrawer() {
    return (
      <HistoryDrawer
        open={this.state.showCompanyChangeHistoryPopUp}
        handleClose={() => {
          this.setState({ showCompanyChangeHistoryPopUp: false });
        }}
        payload={{ projectChangeHistoryId: this.props.projectChangeHistoryId }}
        url={CONSTANTS.getProjectChangeHistoryURL}
      />
    );
  }

  render() {
    console.log(
      'logger for unused state in ProjectDetails',
      this.state.dummyPopup
    ); // ESLINT placeholder
    return (
      <div>
        <MarkItemPopUp
          open={this.state.isMarkProjectPopUpOpen}
          handleClose={() => this.setState({ isMarkProjectPopUpOpen: false })}
          item={{
            project: this.props.projectId
          }}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          markListPopupType="project"
        />
        <Paper>
          <div className="row">
            <div className="col-12 mt-3">
              <div className="projectCompanyName mb-3">
                <div className="projectArchive-companyName">
                  {this.props.company && this.props.company.name}
                </div>
              </div>
              {this.renderButtonSection()}
              {this.state.showDeleteProjectModal &&
                this.renderDeleteCompanyConfirmationModal()}
              <Divider className="mb-3" />
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.jobTitle1}
                  value={this.props.jobTitle1}
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.projectId}
                  value={this.props.projectNumber}
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.creationDate}
                  value={UTILS.formatDateToDELocale(
                    new Date(this.props.creationDate)
                  )}
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.industrySector}
                  value={
                    this.props.company && this.props.company.industrySector
                  }
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.status}
                  value={
                    this.props.status !== 'undefined' ? this.props.status : ''
                  }
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.statusDetails}
                  value={this.props.statusDetails}
                />
              </div>
              <Divider className="mb-3" />
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.serviceType}
                  value={this.props.serviceType}
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.consultant}
                  value={
                    this.props.consultingUser &&
                    `${this.props.consultingUser.firstName} ${
                      this.props.consultingUser.lastName
                    }`
                  }
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.editor}
                  value={
                    this.props.editingUser &&
                    `${this.props.editingUser.firstName} ${
                      this.props.editingUser.lastName
                    }`
                  }
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.partner}
                  value={this.props.partner && this.props.partner.partnerName}
                />
              </div>
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.numberOfPositions}
                  value={this.props.numberOfPositions}
                />
              </div>
              <Divider className="mb-3" />

              {/* <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.jobTitle2}
                  value={this.props.jobTitle2}
                /> */}
              {/* </div> */}
              {!CONSTANTS.isLienert && (
                <div className="col-12">
                  <DetailField
                    label={TEXT.projectDetails.supervisor}
                    value={this.props.supervisor}
                  />
                </div>
              )}
              {!CONSTANTS.isLienert && (
                <div className="col-12">
                  <DetailField
                    label={TEXT.projectDetails.salary}
                    value={this.props.salary}
                  />
                </div>
              )}
              {CONSTANTS.isLienert && (
                <div className="col-12">
                  <DetailField
                    label={TEXT.projectDetails.confidential}
                    value={this.props.confidential}
                  />
                </div>
              )}
              {CONSTANTS.isLienert && (
                <div className="col-12">
                  <DetailField
                    label={TEXT.projectDetails.partTime}
                    value={this.props.partTime}
                  />
                </div>
              )}
              {CONSTANTS.isLienert && (
                <div className="col-12">
                  <DetailField
                    label={TEXT.projectDetails.castingType}
                    value={this.props.castingType}
                  />
                </div>
              )}
              <Divider className="mb-3" />
              <div className="col-12">
                <DetailField
                  label={TEXT.projectDetails.comment}
                  value={this.props.comment}
                />
              </div>
            </div>
          </div>
        </Paper>
        {this.state.showCompanyChangeHistoryPopUp && this.renderHistoryDrawer()}
      </div>
    );
  }
}

ProjectDetails.defaultProps = {
  status: '',
  statusDetails: '',
  serviceType: '',
  consultingUser: undefined,
  editingUser: undefined,
  numberOfPositions: '',
  jobTitle1: '',
  // jobTitle2: '',
  supervisor: '',
  partTime: '',
  salary: '',
  comment: '',
  confidential: '',
  castingType: ''
};
ProjectDetails.propTypes = {
  company: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  projectChangeHistoryId: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  status: PropTypes.string,
  statusDetails: PropTypes.string,
  serviceType: PropTypes.string,
  consultingUser: PropTypes.object,
  editingUser: PropTypes.object,
  partner: PropTypes.object.isRequired,
  numberOfPositions: PropTypes.number,
  jobTitle1: PropTypes.string,
  // jobTitle2: PropTypes.string,
  supervisor: PropTypes.string,
  partTime: PropTypes.string,
  salary: PropTypes.string,
  comment: PropTypes.string,
  confidential: PropTypes.string,
  castingType: PropTypes.string,
  liftUpStateToProjectArchive: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};
export default withUser(ProjectDetails);
