import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';

const baseStyle = {};
const activeStyle = {
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
  borderRadius: 5
};
const rejectStyle = {
  borderWidth: 2,
  borderRadius: 5,
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};
class DropContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Dropzone
        onDrop={this.props.onDrop}
        disableClick={this.props.disableClick}
        disabled={this.props.disabled}
        multiple={this.props.multiple}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          // isDragAccept,
          isDragReject
          // acceptedFiles,
          // rejectedFiles
        }) => {
          let styles = { ...baseStyle };
          styles = isDragActive ? { ...styles, ...activeStyle } : styles;
          styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

          return (
            <div
              {...getRootProps()}
              className={classNames('dropzone', {
                'dropzone--isActive': isDragActive
              })}
              style={styles}
            >
              <input {...getInputProps()} />
              {!this.props.disabled && (
                <div className="text-center">
                  <Tooltip title={this.props.tooltipText}>
                    <div>
                      {this.props.cloudIcon && (
                        <CloudUploadIcon
                          color="primary"
                          fontSize="large"
                          className="mt-2"
                        />
                      )}
                      <p>{this.props.actionDescription}</p>
                    </div>
                  </Tooltip>
                </div>
              )}

              <div className="text-center">
                {this.props.dropzoneErrorMessage.length > 0 && (
                  <p>{this.props.dropzoneErrorMessage}</p>
                )}
              </div>
              {this.props.showLoadingIndicator ? (
                <CircularProgress
                  size={110}
                  thickness={3}
                  style={{ left: 'calc(50% - 64px)', position: 'relative' }}
                />
              ) : (
                this.props.children
              )}
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
DropContainer.defaultProps = {
  dropzoneErrorMessage: '',
  showLoadingIndicator: false,
  disableClick: true,
  actionDescription: 'Dateien hier ablegen',
  disabled: false,
  multiple: true,
  cloudIcon: true,
  tooltipText: "Legen Sie die gewünschte Datei einfach hier via Drag'n'Drop ab"
};
DropContainer.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  dropzoneErrorMessage: PropTypes.string,
  showLoadingIndicator: PropTypes.bool,
  disableClick: PropTypes.bool,
  actionDescription: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  cloudIcon: PropTypes.bool,
  tooltipText: PropTypes.string
};
export default DropContainer;
