import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';
// react context
import withUser from '../utils/withUser';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

import TEXT from './staticContent/text';
import { staticResources } from './staticContent/ActionProtocolCreationPopUpDropDowns';

// custom: for key value dropDowns
// TODO refactor to parent component
function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    employees.forEach(employee => {
      if (employee.lastname) {
        const value = employee.id;
        const label = `${employee.firstname || ''} ${employee.lastname}`;
        const mappedEmployee = { value, label };
        mappedDropDownEntries.push(mappedEmployee);
      }
    });
  }
  return mappedDropDownEntries;
}

class ActionProtocolCreationPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitCount: 0
    };
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  createActionProtocolAPICall = actionProtocolData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    // custom
    // TODO refactor parent component
    const url = CONSTANTS.createActionProtocolURL;

    const payload = {
      projectId: this.props.projectId,
      actionProtocolData
    };
    if (Object.keys(actionProtocolData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(
            'Der Eintrag wurde erfolgreich gespeichert!'
          );
          this.props.refreshProject();
          this.setState(prevState => {
            let newSubmitCount = prevState.submitCount;
            newSubmitCount++;
            return {
              submitCount: newSubmitCount
            };
          });
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    const d = new Date();
    const dateString = `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(
      -2
    )}-${`0${d.getDate() + 1}`.slice(-2)}`;
    const forms = {
      formFields: {
        type: {
          type: 'dropDown',
          value: 'Anzeigen',
          content: staticResources.dropDowns.type.content,
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.type,
          additionalProps: { autofocus: true }
          // required: false
        },
        date: {
          type: 'date',
          value: dateString,
          validator: 'isText',
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.date
        },
        doneByUser: {
          // value: '',
          value: this.props.user.id || '',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees),
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.doneByUser,
          customClass: 'col-12 mb-1 mt-1'
        },
        description: {
          value: '',
          // validator: 'isText',
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.description,
          customClass: 'col-12 mb-1 mt-1'
        }
      }
    };
    return forms;
  }

  handleClose = () => {
    this.props.handleClose();
    this.setState({ submitCount: 0 });
  };

  render() {
    console.log('DEBUG user id', this.props.user);
    this.form = this.generateFormFields();
    const headlineText =
      this.state.submitCount > 0
        ? 'Weiteren Eintrag hinzufügen'
        : TEXT.actionProtocolCreationPopUp.headline;
    const buttonCancelText =
      this.state.submitCount > 0
        ? 'Schließen'
        : TEXT.actionProtocolCreationPopUp.buttons.cancel;
    const buttonSaveText =
      this.state.submitCount > 0
        ? 'Weiteren Eintrag speichern'
        : TEXT.actionProtocolCreationPopUp.buttons.save;
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText={headlineText}
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: false,
          handleSubmit: this.createActionProtocolAPICall,
          // initialData: this.props.data,
          handleClose: this.handleClose,
          key: this.state.submitCount,
          buttonCancelText,
          buttonSaveText,
          alwaysContinue: true
        }}
      />
    );
  }
}
ActionProtocolCreationPopUp.defaultProps = {
  // isUpdate: false,
};

ActionProtocolCreationPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // isUpdate: PropTypes.bool,
  // data
  projectId: PropTypes.string.isRequired,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  // dropDown key values
  employees: PropTypes.array.isRequired,

  // custom: only required for subSchema update
  // dataSetId: PropTypes.string,
  refreshProject: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

export default withUser(ActionProtocolCreationPopUp);
