import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import $ from 'jquery';
import { Document, Page } from 'react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import './CandidateDocuments.css';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FolderIcon from '@material-ui/icons/Folder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import IconButton from '@material-ui/core/IconButton';

import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class CandidateDossier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      currentDocName: '',
      currentDoc: '',
      currentDocFileType: '',
      taggedDocuments: [],
      isLoadingDocument: false,
      selectedEmployeeId: '',
      selectedProjectId: '',
      includeCandidatePage: false
    };
  }

  componentDidMount() {
    this.prepareDocumentPanel();
  }

  handleToggle = value => {
    console.log('debug handleToggle value', value);
    this.setState(prevState => {
      const taggedDocuments = prevState.taggedDocuments;
      console.log('debug handleToggle taggedDocuments', taggedDocuments);
      taggedDocuments.forEach((taggedDocument, index) => {
        if (taggedDocument.documentName === value.documentName) {
          console.log('debug handleToggle taggedDocument', taggedDocument);
          taggedDocuments[index].checked = !value.checked;
        }
      });
      return { taggedDocuments };
    });
  };

  handleListItemClick = (fileName, isTaggedDocument) => {
    if (this.state.currentDocName !== fileName) {
      this.setState({ isLoadingDocument: true });
      this.getCandidateDocumentById(fileName, isTaggedDocument);
    }
  };

  swapPosition(isUp, index) {
    console.log('index', index);
    const tempArray = this.state.taggedDocuments;
    if (isUp && index > 0) {
      // Reihenfolge nach oben

      [tempArray[index], tempArray[index - 1]] = [
        tempArray[index - 1],
        tempArray[index]
      ];
      this.setState({
        taggedDocuments: tempArray
      });
    }
    if (!isUp && index < tempArray.length - 1) {
      // Reihenfolge nach oben

      [tempArray[index], tempArray[index + 1]] = [
        tempArray[index + 1],
        tempArray[index]
      ];
      this.setState({
        taggedDocuments: tempArray
      });
    }
  }

  renderAvailableFiles() {
    return (
      <div className="text-center">
        <List dense>
          {this.state.taggedDocuments.map((taggedDocument, index) => (
            <ListItem
              button
              key={taggedDocument.documentName}
              selected={
                taggedDocument.documentName === this.state.currentDocName
              }
              onClick={() =>
                this.handleListItemClick(taggedDocument.documentName, true)
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {taggedDocument.documentType ? (
                    taggedDocument.documentType
                  ) : (
                    <FolderIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={taggedDocument.documentName}
                secondary={
                  taggedDocument.documentType
                    ? taggedDocument.documentType
                    : 'kein typ vorhanden'
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="down"
                  onClick={() => this.swapPosition(false, index)}
                >
                  <ExpandMoreIcon />
                </IconButton>
                <IconButton
                  aria-label="up"
                  onClick={() => this.swapPosition(true, index)}
                >
                  <ExpandLessIcon />
                </IconButton>
                <Checkbox
                  onChange={() => this.handleToggle(taggedDocument)}
                  checked={taggedDocument.checked}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  documentLoadingInfo() {
    return <CircularProgress size={110} thickness={3} />;
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoadingDocument: false });
  };

  documentPreview() {
    if (
      this.state.currentDocFileType &&
      this.state.currentDocFileType.toLowerCase() === 'pdf'
    ) {
      return (
        <Document
          file={this.state.currentDoc}
          loading={this.documentLoadingInfo()}
          noData={this.documentLoadingInfo()}
          onLoadSuccess={this.onDocumentLoad}
          error={this.documentLoadingInfo()}
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      );
    }
    if (
      this.state.currentDocFileType &&
      (this.state.currentDocFileType.toLowerCase() === 'jpeg' ||
        this.state.currentDocFileType.toLowerCase() === 'jpg' ||
        this.state.currentDocFileType.toLowerCase() === 'png')
    ) {
      this.setState({ isLoadingDocument: false });
      return (
        <div className="text-center">
          <img
            src={this.state.currentDoc}
            alt=""
            className="img-fluid rounded"
          />
        </div>
      );
    }
    // this.setState({ isLoadingDocument: false });
    if (!this.state.currentDoc) {
      return (
        <h4 className="m-3">
          {TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}
        </h4>
      );
    }
    return (
      <h4 className="m-3">
        {TEXT.resultPage.sideBar.documentsView.fileOnlyAsDownload}
      </h4>
    );
  }

  noDocumentAvailableInfo() {
    this.setState({ isLoadingDocument: false });
    if (this.state.currentDoc === '') {
      return (
        <h4 className="m-3">
          {TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}
        </h4>
      );
    }
    return (
      <h4 className="m-3">
        {TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}
      </h4>
    );
  }

  /**
   * Sets the href for the button with the viewed file
   */
  downloadFile = dossier => {
    const element = document.createElement('a');
    const blob = UTILS.dataURIToBlob(dossier);
    element.setAttribute('href', URL.createObjectURL(blob));

    element.setAttribute(
      'download',

      `Kandidatendossier_${this.props.selectedCandidate.lastName}.pdf`
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  handleDropDownChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSwitchChange = () => {
    this.setState(prevState => {
      const includeCandidatePage = !prevState.includeCandidatePage;

      return { includeCandidatePage };
    });
  };

  showDocContent() {
    if (this.state.taggedDocuments.length !== 0) {
      return (
        <div className="row col-12">
          <Paper
            style={{ width: '100%', maxHeight: '1000px', overflow: 'auto' }}
          >
            <div className="col-12">
              <div className="text-right p-2">
                {this.renderAvailableFiles()}
              </div>
            </div>
            <div className="row m-0 pt-3 pb-3">
              <div className="text-center col-3 mb-3">
                <FormControl>
                  <InputLabel shrink htmlFor="berater-dropdown">
                    Berater
                  </InputLabel>
                  <Select
                    style={{ minWidth: '200px' }}
                    value={this.state.selectedEmployeeId}
                    onChange={this.handleDropDownChange}
                    inputProps={{
                      name: 'selectedEmployeeId',
                      id: 'berater-dropdown'
                    }}
                  >
                    {this.props.employees &&
                      this.props.employees.map(employee => (
                        <MenuItem value={employee.id}>
                          {employee.firstname} {employee.lastname}{' '}
                          {employee.email}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="text-center col-3 mb-3">
                <FormControl>
                  <InputLabel shrink htmlFor="project-dropdown">
                    Auftrag
                  </InputLabel>
                  <Select
                    style={{ minWidth: '200px' }}
                    value={this.state.selectedProjectId}
                    onChange={this.handleDropDownChange}
                    inputProps={{
                      name: 'selectedProjectId',
                      id: 'project-dropdown'
                    }}
                  >
                    {this.props.selectedCandidate.projects &&
                      this.props.selectedCandidate.projects.map(project => {
                        if (project.proposed) {
                          return (
                            <MenuItem value={project.id}>
                              {project.projectNumber} | {project.jobTitle1}
                            </MenuItem>
                          );
                        }
                        return '';
                      })}
                  </Select>
                </FormControl>
              </div>
              <div className="text-center align-self-center col-3">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.onSwitchChange}
                      value={this.state.includeCandidatePage}
                      checked={this.state.includeCandidatePage}
                      color="primary"
                    />
                  }
                  label="Kandidatensteckbrief"
                />
              </div>
              <div className="text-center align-self-center col-3">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    this.state.selectedEmployeeId === '' ||
                    this.state.selectedProjectId === ''
                  }
                  onClick={this.getDossier}
                  download
                >
                  Dossier erstellen
                </Button>
              </div>
            </div>
            <div className="text-center col-12">
              {this.state.isLoadingDocument && (
                <CircularProgress size={110} thickness={3} />
              )}
              {this.documentPreview()}
            </div>
          </Paper>
        </div>
      );
    }
    return (
      <div>
        <div className="sideBarWrapper m-2 p-2">
          <div className="candidateArchive-emptyPage">
            {this.props.disabled
              ? 'Dokumente können erst nach Erstellung des Kandidaten hochgeladen werden.'
              : TEXT.resultPage.sideBar.documentsView.candidateHasNoTaggedDocs}
          </div>
        </div>
      </div>
    );
  }

  displayPdfDocumentPages(numOfPages) {
    const pageList = [];
    for (let i = 1; i <= numOfPages; i++) {
      pageList.push(
        <Page
          width={800}
          className="mx-auto"
          pageNumber={i}
          renderAnnotations={false}
          renderTextLayer={false}
        />
      );
    }
    return pageList;
  }

  getCandidateDocumentById = (fileName, isTaggedDocument) => {
    const url = CONSTANTS.getCandidateDocumentByIdURL;
    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      fileName,
      isTaggedDocument
    };
    console.log('tagged doc', fileName, documentRequest);

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        let result = '';
        if (response.contentType === 'pdf') {
          result = UTILS.convertBufferToPdf(response.content);
        } else if (
          response.contentType === 'jpeg' ||
          response.contentType === 'jpg' ||
          response.contentType === 'png'
        ) {
          result = UTILS.convertBufferToImageV2(
            response.content,
            response.contentType
          );
        } else {
          result = UTILS.convertBufferToDocx(
            response.content,
            response.contentType
          );
        }
        this.setState({
          currentDoc: result,
          currentDocName: fileName,
          currentDocFileType: response.contentType
        });

        return result;
      })
      .fail(err => {
        this.props.handleSnackbarOpen(
          'Das Dokument konnte nicht geladen werden!'
        );
        this.setState({ isLoadingDocument: false });
        console.log(err);
      });
  };

  sortTagDocuments(a, b) {
    const sortArray = ['IB', 'ED', 'CV', 'AZ', 'DZ', 'BB', 'ALL'];
    const aType = sortArray.indexOf(a.documentType);
    const bType = sortArray.indexOf(b.documentType);
    if (aType > bType) {
      return 1;
    }
    if (aType < bType) {
      return -1;
    }
    return 0;
  }

  getDossier = () => {
    const tempArray = this.state.taggedDocuments.filter(
      tagdocument => tagdocument.checked === true
    );
    this.props.liftUpStateToApp({
      showLoadingIndicatorGlobal: true,
      showLoadingIndicatorGlobalText: 'Dossier wird erstellt'
    });

    const url = CONSTANTS.getCandidateDossierURL;
    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      selectedProjectId: this.state.selectedProjectId,
      selectedEmployeeId: this.state.selectedEmployeeId,
      arrayOfDocuments: tempArray,
      includeCandidatePage: this.state.includeCandidatePage
    };

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        console.log('response', response);
        const result = UTILS.convertBufferToPdf(response.dossier);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false,
          showLoadingIndicatorGlobalText: ''
        });
        this.downloadFile(result);
      })
      .fail(err => {
        console.log(err);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false,
          showLoadingIndicatorGlobalText: ''
        });
      });
  };

  prepareDocumentPanel = () => {
    if (
      this.props.selectedCandidate.taggedDocuments &&
      this.props.selectedCandidate.taggedDocuments.length > 0
    ) {
      const taggedDocuments = JSON.parse(
        JSON.stringify(this.props.selectedCandidate.taggedDocuments)
      );
      taggedDocuments.sort(this.sortTagDocuments);
      taggedDocuments.forEach(taggedDocument => {
        taggedDocument.checked = true; //eslint-disable-line
      });
      this.setState({
        currentDocName: '',
        currentDoc: '',
        taggedDocuments
      });
    }
  };

  render() {
    return this.showDocContent();
  }
}
CandidateDossier.defaultProps = {
  selectedCandidate: undefined,

  disabled: false
};
CandidateDossier.propTypes = {
  selectedCandidate: PropTypes.object,
  handleSnackbarOpen: PropTypes.func.isRequired,

  employees: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default withUser(CandidateDossier);
