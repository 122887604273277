const text = {
  search: {
    searchFieldErrorMessage: 'Bitte geben Sie einen gültigen Suchbegriff ein.' // duplicate in text.js company
  },
  projectDetails: {
    projectId: 'Auftragsnummer:',
    creationDate: 'Erstellungsdatum:',
    industrySector: 'Branche:',
    status: 'Status:',
    statusDetails: 'Statusgrund:',
    serviceType: 'Leistungsart:',
    consultant: 'Berater:',
    editor: 'Bearbeitung:',
    partner: 'Systempartner:',
    numberOfPositions: 'Anzahl an Positionen:',
    jobTitle1: 'Anzeigentitel (intern):',
    // jobTitle2: 'Jobbezeichnung 2:',
    supervisor: 'Vorgesetzer:',
    salary: 'Gehaltswunsch:',
    partTime: 'Teilzeit:',
    comment: 'Kommentar:',
    confidential: 'Vertraulich:',
    castingType: 'Besetzungsart:'
  },
  projectDetailsUpdatePopUp: {
    headline: 'Auftragsdaten ändern',
    buttons: {
      cancel: 'Abbrechen',
      save: 'Änderung speichern'
    },
    labels: {
      jobTitle1: 'Auftragstitle (Jobbezeichnung 1)',
      projectNumber: ' Auftragsnummer',
      status: 'Status:',
      statusDetails: 'Statusgrund',
      serviceType: 'Leistungsart',
      consultingUser: 'Berater',
      editingUser: 'Bearbeitung',
      // jobTitle2: 'Jobbezeichnung 2',
      numberOfPositions: 'Anzahl an Positionen',
      supervisor: 'Vorgesetzer',
      partTime: 'Teilzeit',
      salary: 'Gehaltswunsch',
      comment: 'Interner Kommentar',
      confidential: 'Vertraulich',
      castingType: 'Besetzungsart'
    }
  },
  actionProtocolCreationPopUp: {
    headline: 'Eintrag hinzufügen',
    buttons: {
      cancel: 'Abbrechen',
      save: 'Speichern'
    },
    labels: {
      type: 'Art des Eintrags',
      description: 'Beschreibung',
      date: 'Datum',
      doneByUser: 'Bearbeiter'
    }
  },
  projectStatusReport: {
    download: 'Download Status Report'
  }
};
module.exports = text;
