import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { urlify, stringToShortenHTMLCode } from '../utils/utilFunctions';
import ViewHtmlPopUp from '../generic_components/ViewHtmlPopUp';

export default class HTMLNotes extends PureComponent {
  state = {
    popupOpen: false
  };

  handleOpenPopUp = ev => {
    this.setState({ popupOpen: true });
    ev.stopPropagation();
  };

  handleClosePopUp = ev => {
    this.setState({ popupOpen: false });
    ev.stopPropagation();
  };

  render() {
    const urlifiedValue = urlify(this.props.html);
    return (
      // eslint-disable-next-line
      <div style={{ cursor: 'pointer' }} onClick={this.handleOpenPopUp}>
        {stringToShortenHTMLCode(urlifiedValue, this.props.maxLength)}
        <ViewHtmlPopUp
          open={this.state.popupOpen}
          html={this.props.html}
          handleClose={this.handleClosePopUp}
          onBackdropClick={this.handleClosePopUp}
        />
      </div>
    );
  }
}

HTMLNotes.defaultProps = {
  maxLength: 250
};

HTMLNotes.propTypes = {
  html: PropTypes.string.isRequired,
  maxLength: PropTypes.number
};
