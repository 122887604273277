import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router-dom';

import ProjectDetails from './ProjectDetails';
import JobPosting from './JobPosting';
import ProjectControlling from './ProjectControlling';
import ProjectMatchingTab from './ProjectMatchingTab';
import ProjectStatus from './ProjectStatus';
import ProjectApplications from './ProjectApplications';
import ProjectStatusReport from './ProjectStatusReport';
import DocumentPanel from '../generic_components/DocumentPanel';

import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';

class SingleProjectResult extends PureComponent {
  render() {
    // console.log('singlePorjectResult', this.props.selectedProject);
    const mainColumnClass = this.props.showProjectDetailsColumn
      ? 'col-10 pl-0'
      : 'col-12';
    return (
      <div>
        <div className="row navidation">
          <div className="col-12">
            <Tabs
              value={this.props.currentTab}
              onChange={(event, value) => {
                UTILS.setURLParam(this.props.history, 't', value);

                this.props.liftUpStateToProjectArchive({
                  currentTab: value,
                  statusFilterCandidateId: null
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Auftragserfassung" />
              <Tab label="Auftragsstatus" />
              <Tab label="Eingegangene Bewerbungen" />
              <Tab label="Auftragssteuerung" />
              <Tab label="Statusreport" />
              <Tab label="Dokumente" />

              {CONSTANTS.partner === 'lienert' && <Tab label="Matching" />}
            </Tabs>
          </div>
        </div>
        <div className="SingleProjectResult row">
          {this.props.showProjectDetailsColumn && this.props.selectedProject && (
            <div className="col-2 pr-0">
              <div className="col-12 mt-3 pr-0">
                <ProjectDetails
                  key={this.props.selectedProject.id}
                  company={this.props.selectedProject.company}
                  projectId={this.props.selectedProject.id}
                  isEditable={this.props.selectedProject.isEditable}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  projectChangeHistoryId={
                    this.props.selectedProject.projectChangeHistoryId
                  }
                  projectNumber={this.props.selectedProject.projectNumber}
                  creationDate={this.props.selectedProject.creationDate}
                  status={this.props.selectedProject.status}
                  statusDetails={this.props.selectedProject.statusDetails}
                  serviceType={this.props.selectedProject.serviceType}
                  consultingUser={this.props.selectedProject.consultingUser}
                  editingUser={this.props.selectedProject.editingUser}
                  partner={this.props.selectedProject.partner}
                  numberOfPositions={
                    this.props.selectedProject.numberOfPositions
                  }
                  jobTitle1={this.props.selectedProject.jobTitle1}
                  // jobTitle2={this.props.selectedProject.jobTitle2}
                  supervisor={this.props.selectedProject.supervisor}
                  partTime={this.props.selectedProject.partTime}
                  salary={this.props.selectedProject.salary}
                  comment={this.props.selectedProject.comment}
                  castingType={this.props.selectedProject.castingType}
                  confidential={this.props.selectedProject.confidential}
                  liftUpStateToProjectArchive={
                    this.props.liftUpStateToProjectArchive
                  }
                  liftUpStateToApp={this.props.liftUpStateToApp}
                />
              </div>
            </div>
          )}

          <div className={mainColumnClass}>
            <div className="col-12 mt-3 plr-3">
              {/* <SwipeableViews index={this.props.currentTab}> */}
              {this.props.currentTab === 0 && (
                <div>
                  {this.props.selectedProject &&
                    this.props.selectedProject.jobPosting && (
                      <JobPosting
                        key={this.props.selectedProject.id}
                        selectedProject={this.props.selectedProject}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToProjectArchive={
                          this.props.liftUpStateToProjectArchive
                        }
                      />
                    )}
                </div>
              )}
              {this.props.currentTab === 1 && (
                <div>
                  <ProjectStatus
                    key={this.props.selectedProject.id}
                    selectedProjectId={this.props.selectedProject.id}
                    projectNumber={this.props.selectedProject.projectNumber}
                    jobTitle1={this.props.selectedProject.jobTitle1}
                    filterCandidateId={this.props.statusFilterCandidateId}
                    data={this.props.selectedProject.potentialCandidates}
                    isEditable={this.props.selectedProject.isEditable}
                    handleSnackbarOpen={this.props.handleSnackbarOpen}
                    handleOpenPotentialCandidateUpdatePopUp={
                      this.props.handleOpenPotentialCandidateUpdatePopUp
                    }
                    liftUpStateToApp={this.props.liftUpStateToApp}
                    liftUpUpdatedPotentialCandidates={
                      this.props.liftUpUpdatedPotentialCandidates
                    }
                    liftUpAllPotentialCandidates={
                      this.props.liftUpAllPotentialCandidates
                    }
                  />
                </div>
              )}
              {this.props.currentTab === 2 && (
                <div>
                  <ProjectApplications
                    key={this.props.selectedProject.id}
                    data={this.props.selectedProject.potentialCandidates}
                    selectedProjectId={this.props.selectedProject.id}
                    filterCandidateId={this.props.statusFilterCandidateId}
                    isEditable={this.props.selectedProject.isEditable}
                    handleSnackbarOpen={this.props.handleSnackbarOpen}
                    handleOpenPotentialCandidateUpdatePopUp={
                      this.props.handleOpenPotentialCandidateUpdatePopUp
                    }
                    refreshProject={this.props.refreshProject}
                    liftUpStateToApp={this.props.liftUpStateToApp}
                    liftUpUpdatedPotentialCandidates={
                      this.props.liftUpUpdatedPotentialCandidates
                    }
                  />
                </div>
              )}
              {this.props.currentTab === 3 && (
                <div>
                  <ProjectControlling
                    key={this.props.selectedProject.id}
                    isEditable={this.props.selectedProject.isEditable}
                    data={this.props.selectedProject.actionProtocolEntries}
                    selectedProjectId={this.props.selectedProject.id}
                    handleSnackbarOpen={this.props.handleSnackbarOpen}
                    refreshProject={this.props.refreshProject}
                    employees={this.props.employees}
                  />
                </div>
              )}

              {this.props.currentTab === 4 && (
                <div>
                  <ProjectStatusReport
                    key={this.props.selectedProject.id}
                    selectedProject={this.props.selectedProject}
                    liftUpStateToApp={this.props.liftUpStateToApp}
                  />
                </div>
              )}
              {this.props.currentTab === 5 && (
                <DocumentPanel
                  key={`${this.props.selectedProject.id}documents`}
                  documentList={this.props.selectedProject.documents}
                  id={this.props.selectedProject.id}
                  deleteURL={CONSTANTS.deleteNewProjectDocumentURL}
                  getDocumentURL={CONSTANTS.getProjectDocumentByIdURL}
                  uploadDocumentURL={CONSTANTS.uploadNewProjectDocumentsURL}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  archive="project"
                />
              )}
              {this.props.currentTab === 6 && (
                <div>
                  <ProjectMatchingTab
                    key={this.props.selectedProject.id}
                    selectedProject={this.props.selectedProject}
                    handleSnackbarOpen={this.props.handleSnackbarOpen}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SingleProjectResult.defaultProps = {
  showProjectDetailsColumn: true,
  currentTab: 0,
  statusFilterCandidateId: undefined
};
SingleProjectResult.propTypes = {
  showProjectDetailsColumn: PropTypes.bool,
  selectedProject: PropTypes.object.isRequired,
  refreshProject: PropTypes.func.isRequired,
  liftUpStateToProjectArchive: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  liftUpAllPotentialCandidates: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
  handleOpenPotentialCandidateUpdatePopUp: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  statusFilterCandidateId: PropTypes.string
};
export default withRouter(SingleProjectResult);
