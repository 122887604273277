import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import CreateNewIcon from '@material-ui/icons/PersonAdd';
import MergeIcon from '@material-ui/icons/Group';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import StarIcon from '@material-ui/icons/Star';
import InfoIcon from '@material-ui/icons/Info';
import ProjectIcon from '@material-ui/icons/QuestionAnswer';
import NotesIcon from '@material-ui/icons/Notes';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Menu, MenuItem } from '@material-ui/core';

import * as CONSTANTS from '../constants';

import HistoryDrawer from '../generic_components/HistoryDrawer';
import AddCandidateToProjectPopUp from './popUps/AddCandidateToProjectPopUp';
import AddCandidateToProjectApplicationPopUp from './popUps/AddCandidateToProjectApplicationPopUp';
import DetailField from '../generic_components/DetailField';
import withUser from '../utils/withUser';
import DropContainer from '../generic_components/DropContainer';
import TEXT from './staticContent/text';
import MarkItemPopUp from '../components/MarkItemPopUp';

import TOOLTIPS from '../textTooltips';
import * as UTILS from '../utils/utilFunctions';
import HelperFunctions from './utils/helperFunctions';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import * as API from './api/candidateArchiveAPI';
import {
  STATUS_TYPES,
  mapCandidateStatus,
  getAvailableStatusTypes
} from './utils/statusTypes';

class CandidateDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCandidateHistoryDrawer: false,
      showEditCandidateStatusPopUp: false,
      pictureErrorMessage: '',
      pictureLoading: false,
      markCandidatePopUpOpen: false,
      showAddCandidateToProjectPopUp: false,
      showAddCandidateToProjectApplicationPopUp: false,
      isDownloadButtonActive: false,
      showDeleteCandidateDataModal: false,
      showDeleteCandidateModal: false,
      showDeleteCandidateImageModal: false,
      deleteCandidateAskForce: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleCloseUpdateCandidateStatusPopUp = (reload, companyId) => {
    this.setState({
      showEditCandidateStatusPopUp: false
    });
    if (reload) {
      this.props.refreshCandidate(companyId);
    }
  };

  handleResetCandidateStatusAPICall = () => {
    API.resetCandidateStatus(this.props.selectedCandidate.id)
      .then(responseBody => {
        if (responseBody.success === true) {
          this.props.handleSnackbarOpen(
            'Der Status wurde erfolgreich zurückgesetzt'
          );
          this.handleCloseUpdateCandidateStatusPopUp();
          this.props.refreshCandidate();
        } else {
          this.props.handleSnackbarOpen(
            'Sie sind nicht berechtigt, den Status des Kandidaten zurückzusetzen.'
          );
        }
      })
      .catch(err => {
        this.errorHandling(err);
      });
  };

  addCandidateToProjectApplicationAPICall = projectId => {
    API.addCandidateToProjectApplication(
      projectId,
      this.props.selectedCandidate.id
    )
      .then(responseBody => {
        if (responseBody.success) {
          this.props.handleSnackbarOpen(
            'Der Kandidat wurde erfolgreich zum Auftrag hinzugefügt'
          );
          this.props.refreshCandidate();
        } else if (
          !responseBody.success &&
          responseBody.error === 'candidateIsAlreadyPotentialCandidate'
        )
          this.props.handleSnackbarOpen(
            'Der Kandidat befindet sich bereits im Status "beworben" dem ausgewählten Auftrag zugeordnet'
          );
        else
          this.props.handleSnackbarOpen(
            'Es ist ein technischer Fehler aufgetreten'
          );
        this.setState({ showAddCandidateToProjectApplicationPopUp: false });
      })
      .catch(err => {
        this.errorHandling(err);
      });
  };

  addCandidateToProjectAPICall = projectId => {
    API.addCandidateToProject(projectId, this.props.selectedCandidate.id)
      .then(responseBody => {
        if (responseBody.success) {
          this.props.handleSnackbarOpen(
            'Der Kandidat wurde erfolgreich zum Auftrag hinzugefügt'
          );
          if (CONSTANTS.isLienert)
            this.handleEditCandidateStatusAPICall('locked');
          else this.props.refreshCandidate();
        } else if (
          !responseBody.success &&
          responseBody.error === 'candidateIsAlreadyPotentialCandidate'
        )
          this.props.handleSnackbarOpen(
            'Der Kandidat befindet sich bereits im Status "vorgeschlagen" dem ausgewählten Auftrag zugeordnet'
          );
        else
          this.props.handleSnackbarOpen(
            'Es ist ein technischer Fehler aufgetreten'
          );
        this.setState({ showAddCandidateToProjectPopUp: false });
      })
      .catch(err => {
        this.errorHandling(err);
      });
  };

  handleEditCandidateStatusAPICall = newStatus => {
    const validMonths = STATUS_TYPES[newStatus].validMonths;
    API.editCandidateStatus(
      this.props.selectedCandidate.id,
      newStatus,
      new Date().setMonth(new Date().getMonth() + (validMonths || 1200))
    )
      .then(responseBody => {
        this.handleCloseUpdateCandidateStatusPopUp();
        if (responseBody.success === true) {
          this.props.handleSnackbarOpen('Der Status wurde erfolgreich gesetzt');
          this.props.refreshCandidate();
        } else {
          // this.props.handleSnackbarOpen('SFehler');
          this.props.handleSnackbarOpen(
            `Sie haben bereits ${
              responseBody.errorDetails.lockedByUserCount
            } Kandidaten auf ${mapCandidateStatus(
              newStatus
            )} gestellt. Bitte entsperren Sie zunächst andere Kandidaten.`
          );
        }
      })
      .catch(err => {
        this.errorHandling(err);
      });
  };

  handleDeleteCandidateDocumentsAPICall = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    API.deleteCandidateDocuments(this.props.selectedCandidate.id)
      .then(() => {
        this.props.handleSnackbarOpen(
          'Die Dokumente des Kandidaten wurden erfolgreich gelöscht'
        );
        this.setState({ showDeleteCandidateDataModal: false });
        this.props.refreshCandidate();
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .catch(err => {
        this.errorHandling(err);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  handleDeleteCandidateAPICall = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    API.deleteCandidate(
      this.props.selectedCandidate.id,
      this.state.deleteCandidateAskForce
    )
      .then(responseBody => {
        if (responseBody && responseBody.status === 'success') {
          this.props.handleSnackbarOpen(
            'Der Kandidat wurde erfolgreich gelöscht'
          );
          this.setState({ showDeleteCandidateModal: false });
          this.props.liftUpStateToCandidateArchive({
            selectedCandidate: {},
            showResultList: true,
            candidateDetails: false
          });
        } else if (
          responseBody &&
          responseBody.status === 'projectlist_not_empty'
        ) {
          this.setState({
            deleteCandidateAskForce: true
          });
        } else {
          this.props.handleSnackbarOpen(
            'Es ist ein technischer Fehler aufgetreten'
          );
        }

        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .catch(err => {
        this.errorHandling(err);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  handleDeleteCandidateImageAPICall = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    API.deleteCandidateImage(this.props.selectedCandidate.id)
      .then(() => {
        this.props.liftUpStateToCandidateArchive({
          selectedCandidate: {
            ...this.props.selectedCandidate,
            picture: undefined
          }
        });
        this.setState({ showDeleteCandidateImageModal: false });
        this.props.handleSnackbarOpen(
          'Das Profilbild wurde erfolgreich gelöscht'
        );
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .catch(err => {
        this.errorHandling(err);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  onImageDrop = acceptedFiles => {
    this.setState({ pictureErrorMessage: '', pictureLoading: true });
    API.updateCandidatePicture(this.props.selectedCandidate.id, acceptedFiles)
      .then(responseBody => {
        this.props.liftUpStateToCandidateArchive({
          selectedCandidate: {
            ...this.props.selectedCandidate,
            picture: responseBody.picture
          }
        });
        this.setState({
          pictureLoading: false
        });
        console.log(responseBody);
      })
      .catch(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pictureErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            pictureLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pictureErrorMessage: 'Nicht unterstütztes Dateiformat',
            pictureLoading: false
          });
        }

        this.errorHandling(err, null, null, ignoreErrorCodes);
      });
  };

  renderCandidateStatusPopUp(candidateStatus) {
    if (candidateStatus.statusType !== 'available')
      return (
        <ConfirmationModal
          key="confirmation-renewStatus"
          handleClose={this.handleCloseUpdateCandidateStatusPopUp}
          open={this.state.showEditCandidateStatusPopUp}
          buttonPrimaryAction={this.handleResetCandidateStatusAPICall}
          headlineText="Status zurücksetzen?"
          descriptionText='Soll der Status des Kandidaten wieder auf "verfügbar" gesetzt werden?'
        />
      );
    const validMonths = STATUS_TYPES[this.state.newCandidateStatus].validMonths;
    return (
      <ConfirmationModal
        key="confirmation-setStatus"
        handleClose={this.handleCloseUpdateCandidateStatusPopUp}
        open={this.state.showEditCandidateStatusPopUp}
        buttonPrimaryAction={() =>
          this.handleEditCandidateStatusAPICall(this.state.newCandidateStatus)
        }
        headlineText={`Kandidat auf "${mapCandidateStatus(
          this.state.newCandidateStatus
        )}" setzen?`}
        descriptionText={`Soll der Kandidat für Ihnen Standort auf "${mapCandidateStatus(
          this.state.newCandidateStatus
        )}" gesetzt werden? Der Status ist ${
          validMonths ? `für ${validMonths} Monate` : 'unbegrenzt'
        } gültig.`}
      />
    );
  }

  renderDeleteCandidateDataConfirmationModal() {
    return (
      <ConfirmationModal
        key="confirmation-deleteCandidateData"
        handleClose={() =>
          this.setState({ showDeleteCandidateDataModal: false })
        }
        open={this.state.showDeleteCandidateDataModal}
        buttonPrimaryAction={this.handleDeleteCandidateDocumentsAPICall}
        headlineText="Alle Dokumente des Kandidaten löschen?"
        icon="delete"
        descriptionText="Der Löschvorgang löscht alle Dokumente des Kandidaten, welche nicht mit IB getagged sind."
      />
    );
  }

  renderDeleteCandidateConfirmationModal() {
    return (
      <ConfirmationModal
        key="confirmation-deleteCandidateData"
        handleClose={() => this.setState({ showDeleteCandidateModal: false })}
        open={this.state.showDeleteCandidateModal}
        buttonPrimaryAction={this.handleDeleteCandidateAPICall}
        headlineText={
          this.state.deleteCandidateAskForce
            ? 'Existierende Aufträge gefunden'
            : 'Kandidat vollständig löschen?'
        }
        icon="delete_forever"
        descriptionText={
          this.state.deleteCandidateAskForce
            ? 'Für diesen Kandidaten existieren Aufträge. Trotzdem fortfahren und Zuordnung zu Aufträgen entfernen?'
            : 'Soll der Kandidat wirklich vollständig gelöscht werden?'
        }
      />
    );
  }

  renderDeleteCandidateImageConfirmationModal() {
    return (
      <ConfirmationModal
        key="confirmation-deleteCandidateImage"
        handleClose={() =>
          this.setState({ showDeleteCandidateImageModal: false })
        }
        open={this.state.showDeleteCandidateImageModal}
        buttonPrimaryAction={this.handleDeleteCandidateImageAPICall}
        headlineText="Profilbild des Kandidaten vollständig löschen?"
        icon="delete"
        descriptionText=""
      />
    );
  }

  renderButtonSection(candidateStatus) {
    return (
      <div className="col-12 text-center">
        {this.renderEditCandidateStatusButton(candidateStatus)}
        {this.renderShowHistoryButton()}
        {this.renderEditButton()}
        {!CONSTANTS.isLienert && this.renderSendDataPrivacyMailButton()}
        {this.renderMarkButton()}
        {this.renderAddCandidateToProjectButton()}
        {this.renderAddCandidateToProjectApplicationButton()}
        {CONSTANTS.isLienert && this.renderDownloadPersonalDataSheetButton()}
        {this.renderDeletePictureButton()}
        {this.props.user.role === 'admin' &&
          this.renderDeleteCandidateDataButton()}
        {this.props.user.role === 'admin' && this.renderDeleteCandidateButton()}
      </div>
    );
  }

  renderSendDataPrivacyMailButton() {
    return (
      <Tooltip title="Datenschutz Email Senden">
        <IconButton
          className="send-data-privacy-mail-button"
          color="primary"
          onClick={() => {
            this.props.handleOpenPrivacyMailConfirmationModal();
          }}
        >
          <i className="material-icons">security</i>
        </IconButton>
      </Tooltip>
    );
  }

  renderDuplicateButtonSectionIncomingCandidate() {
    return (
      <div className="col-12 text-center">
        {this.renderDuplicateCreateNewButton()}
      </div>
    );
  }

  renderDuplicateButtonSectionDuplicateCandidate() {
    return (
      <div className="col-12 text-center">
        {this.renderResumeWithDuplicateButton()}
      </div>
    );
  }

  renderDuplicateCreateNewButton() {
    return (
      <Tooltip title="Neuen Kandidaten anlegen">
        <IconButton
          className="candidate-lock-button"
          aria-label="lock"
          color="primary"
          onClick={() => this.props.handleDuplicateCheckCreateNew()}
        >
          <CreateNewIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderResumeWithDuplicateButton() {
    return (
      <Tooltip title="Mit Duplikat fortfahren">
        <IconButton
          className="candidate-lock-button"
          aria-label="lock"
          color="primary"
          onClick={() =>
            this.props.handleDuplicateCheckResumeWithDuplicate(
              this.props.selectedCandidate
            )
          }
        >
          <MergeIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderEditCandidateStatusButton(candidateStatus) {
    const isAvailable = candidateStatus.statusType === 'available';
    return (
      <React.Fragment>
        <Tooltip
          title={
            isAvailable
              ? TOOLTIPS.candidateArchive.editCandidateStatusButton
              : TOOLTIPS.candidateArchive.resetCandidateStatusButton
          }
        >
          <IconButton
            color="primary"
            onClick={ev => {
              if (isAvailable) {
                this.setState({ statusMenuAnchorEl: ev.currentTarget });
              } else {
                this.setState({ showEditCandidateStatusPopUp: true });
              }
            }}
          >
            {isAvailable ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={this.state.statusMenuAnchorEl}
          open={!!this.state.statusMenuAnchorEl}
          onClose={() => this.setState({ statusMenuAnchorEl: null })}
        >
          {getAvailableStatusTypes().map(statusType => (
            <MenuItem onClick={() => this.setCandidateStatus(statusType)}>
              {mapCandidateStatus(statusType)}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }

  setCandidateStatus(statusType) {
    this.setState({
      statusMenuAnchorEl: null,
      showEditCandidateStatusPopUp: true,
      newCandidateStatus: statusType
    });
  }

  onDownloadButtonClick = () => {
    this.setState({
      isDownloadButtonActive: true
    });
    API.getCandidatePdfDocument(this.props.selectedCandidate.id)
      .then(responseBody => {
        const file = `data:application/pdf;base64,${responseBody.buffer}`;
        const downloadElement = document.createElement('a');
        const fileName = 'Personalienblatt.pdf';

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isDownloadButtonActive: false
        });
      })
      .catch(err => {
        this.errorHandling(err);
      });
  };

  renderDownloadPersonalDataSheetButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.downloadPersonalDataSheet}>
        <IconButton
          className="downloadDocumentButton"
          color="primary"
          // disabled
          disabled={this.state.isDownloadButtonActive}
          onClick={this.onDownloadButtonClick}
        >
          <NotesIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderAddCandidateToProjectApplicationButton() {
    return (
      <Tooltip
        title={
          TOOLTIPS.candidateArchive.showAddCandidateToProjectApplicationButton
        }
      >
        <IconButton
          className="showAddCandidateToProjectApplicationButton"
          color="primary"
          onClick={() =>
            this.setState({ showAddCandidateToProjectApplicationPopUp: true })
          }
        >
          <ProjectIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderAddCandidateToProjectButton() {
    return (
      <Tooltip
        title={TOOLTIPS.candidateArchive.showAddCandidateToProjectButton}
      >
        <IconButton
          className="company-mark-button"
          color="primary"
          onClick={() =>
            this.setState({ showAddCandidateToProjectPopUp: true })
          }
        >
          <ProjectIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderMarkButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.markButton}>
        <div style={{ display: 'inline' }}>
          <IconButton
            className="candidate-mark-button"
            color="primary"
            onClick={() => this.setState({ markCandidatePopUpOpen: true })}
          >
            <StarIcon />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  renderEditButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.editButton}>
        <IconButton
          className="saveEditButton editButton"
          aria-label="Edit"
          color="primary"
          onClick={() => this.props.handleOpenCandidateMainUpdatePopUp()}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderShowHistoryButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.showHistoryButton}>
        <IconButton
          className="company-changeHistory-button"
          aria-label="changeHistory"
          color="primary"
          onClick={() => this.setState({ showCandidateHistoryDrawer: true })}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderDeletePictureButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.deletePicture}>
        <IconButton
          className="company-deleteCandidateData-button"
          aria-label="delete candidate picture"
          color="primary"
          onClick={() => {
            this.setState({
              showDeleteCandidateImageModal: true
            });
          }}
        >
          <DeleteOutlinedIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderDeleteCandidateDataButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.deleteCandidateData}>
        <IconButton
          className="company-deleteCandidateData-button"
          aria-label="deleteCandidateData"
          color="primary"
          onClick={() => {
            this.setState({ showDeleteCandidateDataModal: true });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderDeleteCandidateButton() {
    return (
      <Tooltip title={TOOLTIPS.candidateArchive.deleteCandidate}>
        <IconButton
          className="company-deleteCandidate-button"
          aria-label="deleteCandidate"
          color="primary"
          onClick={() => {
            this.setState({
              showDeleteCandidateModal: true,
              deleteCandidateAskForce: false
            });
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
    );
  }

  mapUrl(url) {
    let httpsUrl = '';
    if (url) {
      httpsUrl = url;
      if (!httpsUrl.startsWith('http://') && !httpsUrl.startsWith('https://')) {
        httpsUrl = `https://${url}`;
      }
    }
    return httpsUrl;
  }

  renderSocialIcons() {
    return (
      this.props.selectedCandidate.links && (
        <div className="container">
          <div className="row">
            {this.props.selectedCandidate.links.linkedIn && (
              <div className="col-3 mb-3">
                <a
                  href={this.mapUrl(
                    this.props.selectedCandidate.links.linkedIn
                  )}
                >
                  <img
                    className="img-fluid socialIcon"
                    src="./img/social/linkedin.svg"
                    alt="twitter logo"
                  />
                </a>
              </div>
            )}
            {this.props.selectedCandidate.links.xing && (
              <div className="col-3 mb-3">
                <a href={this.mapUrl(this.props.selectedCandidate.links.xing)}>
                  <img
                    className="img-fluid socialIcon"
                    src="./img/social/xing.svg"
                    alt="xing logo"
                  />
                </a>
              </div>
            )}
            {this.props.selectedCandidate.links.facebook && (
              <div className="col-3 mb-3">
                <a
                  href={this.mapUrl(
                    this.props.selectedCandidate.links.facebook
                  )}
                >
                  <img
                    className="img-fluid socialIcon"
                    src="./img/social/facebook.svg"
                    alt="facebook logo"
                  />
                </a>
              </div>
            )}
            {this.props.selectedCandidate.links.twitter && (
              <div className="col-3 mb-3">
                <a
                  href={this.mapUrl(this.props.selectedCandidate.links.twitter)}
                >
                  <img
                    className="img-fluid socialIcon"
                    src="./img/social/twitter.svg"
                    alt="twitter logo"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      )
    );
  }

  renderHistoryDrawer() {
    return (
      <HistoryDrawer
        open={this.state.showCandidateHistoryDrawer}
        handleClose={() => {
          this.setState({ showCandidateHistoryDrawer: false });
        }}
        payload={{
          candidateChangeHistoryId: this.props.candidateChangeHistoryId
        }}
        url={CONSTANTS.getCandidateChangeHistoryURL}
      />
    );
  }

  renderAddCandidateToProjectApplicationPopUp() {
    return (
      <AddCandidateToProjectApplicationPopUp
        open={this.state.showAddCandidateToProjectApplicationPopUp}
        handleClose={() => {
          this.setState({ showAddCandidateToProjectApplicationPopUp: false });
        }}
        handleAddCandidateToProject={
          this.addCandidateToProjectApplicationAPICall
        }
      />
    );
  }

  renderAddCandidateToProjectPopUp() {
    return (
      <AddCandidateToProjectPopUp
        open={this.state.showAddCandidateToProjectPopUp}
        handleClose={() => {
          this.setState({ showAddCandidateToProjectPopUp: false });
        }}
        handleAddCandidateToProject={this.addCandidateToProjectAPICall}
      />
    );
  }

  renderBirthday(dateString) {
    const dateOfBirth = UTILS.formatStringToDELocale(dateString);
    if (dateOfBirth) {
      return `${dateOfBirth} (${UTILS.calcYearsFromDate(dateString, false)})`;
    }
    return dateString;
  }

  renderCandidateImage() {
    let image = './img/placeholer_logo.png';
    if (this.props.selectedCandidate.picture) {
      image = UTILS.convertBufferToImage(this.props.selectedCandidate.picture);
      return (
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={image}
              alt="logo"
              className="img-fluid mt-3 candidateDetailsImage rounded"
            />
          </div>
        </div>
      );
    }
    return '';
  }

  renderDetails() {
    return (
      <div>
        <MarkItemPopUp
          open={this.state.markCandidatePopUpOpen}
          handleClose={() => this.setState({ markCandidatePopUpOpen: false })}
          item={{
            candidate: this.props.selectedCandidate.id
          }}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          markListPopupType="candidate"
        />
        <Divider className="mb-3" />
        {!CONSTANTS.isLienert && (
          <div className="col-12">
            <DetailField
              label={TEXT.candidateDetails.currentCompany}
              value={this.props.selectedCandidate.currentCompany}
            />
          </div>
        )}
        {!CONSTANTS.isLienert && (
          <div className="col-12">
            <DetailField
              label={TEXT.candidateDetails.currentJob}
              value={this.props.selectedCandidate.currentJob}
            />
          </div>
        )}
        {!CONSTANTS.isLienert && <Divider className="mb-3" />}
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.street}
            value={this.props.selectedCandidate.street}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.postalCode}
            value={this.props.selectedCandidate.postalCode}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.city}
            value={this.props.selectedCandidate.city}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.country}
            value={this.props.selectedCandidate.country}
          />
        </div>
        <Divider className="mb-3" />
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.phoneNumber}
            value={this.props.selectedCandidate.phoneNumber}
            type="phoneNumber"
            customOnClickHandler={this.props.openContactHistoryForPhone}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.phoneNumberMobile}
            value={this.props.selectedCandidate.phoneNumberMobile}
            type="phoneNumber"
            customOnClickHandler={this.props.openContactHistoryForPhone}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.phoneNumberWork}
            value={this.props.selectedCandidate.phoneNumberWork}
            type="phoneNumber"
            customOnClickHandler={this.props.openContactHistoryForPhone}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.mailAddress}
            value={this.props.selectedCandidate.mailAddress}
            type="mail"
          />
        </div>
        <Divider className="mb-3" />
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.nationality}
            value={this.props.selectedCandidate.nationality}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.dateOfBirth}
            value={this.renderBirthday(
              this.props.selectedCandidate.dateOfBirth
            )}
          />
        </div>
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.maritalStatus}
            value={this.props.selectedCandidate.maritalStatus}
          />
        </div>
        {!CONSTANTS.isLienert && (
          <React.Fragment>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.children}
                value={this.props.selectedCandidate.children}
              />
            </div>
            <Divider className="mb-3" />
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.desiredSalary}
                value={this.props.selectedCandidate.desiredSalary}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.mobility}
                value={this.props.selectedCandidate.mobility}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.willingnessToRelocate}
                value={this.props.selectedCandidate.willingnessToRelocate}
              />
            </div>
          </React.Fragment>
        )}
        <div className="col-12">
          <DetailField
            label={TEXT.candidateDetails.comment}
            value={this.props.selectedCandidate.comment}
          />
        </div>
        {!CONSTANTS.isLienert && (
          <React.Fragment>
            <Divider className="mb-3" />
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.desiredRaiseForChange}
                value={this.props.selectedCandidate.desiredRaiseForChange}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.motivationForChange}
                value={this.props.selectedCandidate.motivationForChange}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.channelForInformation}
                value={this.props.selectedCandidate.channelForInformation}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.frequencyOfInformation}
                value={this.props.selectedCandidate.frequencyOfInformation}
              />
            </div>
          </React.Fragment>
        )}
        {this.props.selectedCandidate.dataPrivacy && !CONSTANTS.isLienert && (
          <div>
            <Divider className="mb-3" />
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.dataPrivacy.mailSentDate}
                value={UTILS.convertDateToDateAndTime(
                  this.props.selectedCandidate.dataPrivacy.mailSentDate
                )}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.dataPrivacy.mailSentBy}
                value={this.props.selectedCandidate.dataPrivacy.mailSentBy}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.dataPrivacy.mailSentTo}
                value={this.props.selectedCandidate.dataPrivacy.mailSentTo}
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.dataPrivacy.consentCurrentProject}
                value={
                  this.props.selectedCandidate.dataPrivacy.consentCurrentProject
                    ? UTILS.convertDateToDateAndTime(
                        this.props.selectedCandidate.dataPrivacy
                          .consentCurrentProject
                      )
                    : 'Nein'
                }
              />
            </div>

            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.dataPrivacy.consentAllProjects}
                value={
                  this.props.selectedCandidate.dataPrivacy.consentAllProjects
                    ? UTILS.convertDateToDateAndTime(
                        this.props.selectedCandidate.dataPrivacy
                          .consentAllProjects
                      )
                    : 'Nein'
                }
              />
            </div>
            <div className="col-12">
              <DetailField
                label={
                  TEXT.candidateDetails.dataPrivacy.consentCancellationRight
                }
                value={
                  this.props.selectedCandidate.dataPrivacy
                    .consentCancellationRight
                    ? UTILS.convertDateToDateAndTime(
                        this.props.selectedCandidate.dataPrivacy
                          .consentCancellationRight
                      )
                    : 'Nein'
                }
              />
            </div>
            <div className="col-12">
              <DetailField
                label={TEXT.candidateDetails.dataPrivacy.newsletter}
                value={
                  this.props.selectedCandidate.dataPrivacy.newsletter
                    ? 'Ja'
                    : 'Nein'
                }
              />
            </div>
          </div>
        )}{' '}
      </div>
    );
  }

  renderCandidateStatusSubText(candidateStatus) {
    let statusPartnerText = '';
    if (candidateStatus.statusSetByUser.partnerName) {
      let statusPartnerIdText = '';
      if (candidateStatus.statusSetByUser.partnerId)
        statusPartnerIdText = ` ID: ${
          candidateStatus.statusSetByUser.partnerId
        }`;
      statusPartnerText = `${
        candidateStatus.statusSetByUser.partnerName
      }${statusPartnerIdText}`;
    }
    return (
      <div>
        <div className="partner">
          {candidateStatus.statusSetByUser.partnerName && statusPartnerText}
        </div>
        <div className="statusExpirationDate">
          {candidateStatus.statusExpirationDate &&
            candidateStatus.statusType === 'locked' &&
            `(gültig bis: ${UTILS.formatStringToDELocale(
              candidateStatus.statusExpirationDate
            )})`}
        </div>
      </div>
    );
  }

  renderCandidateStatusText(candidateStatus) {
    const statusTypeText = mapCandidateStatus(candidateStatus.statusType);

    return (
      <div>
        <div>{statusTypeText}</div>
        {candidateStatus.statusSetByUser &&
          !['available', ''].includes(candidateStatus.statusType) &&
          this.renderCandidateStatusSubText(candidateStatus)}
      </div>
    );
  }

  renderCandidateStatus(candidateStatus) {
    const styling = `candidateArchive-candidateStatus-text ${HelperFunctions.determineStatusColor(
      candidateStatus.statusType
    )}`;
    return (
      <div className="candidateArchive-candidateStatus-container">
        <div className={styling}>
          {this.renderCandidateStatusText(candidateStatus)}
        </div>
      </div>
    );
  }

  renderBrandIcons() {
    if (this.props.selectedCandidate && this.props.selectedCandidate.brand) {
      const { brand } = this.props.selectedCandidate;
      console.log('DEBUG brand', brand);
      return (
        <div>
          <div className="candidateArchive_brand-logo-container">
            {brand.includes('personaltotal') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_personaltotal.png"
                alt="personaltotal"
              />
            )}
            {brand.includes('senator') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_senator.png"
                alt="senator"
              />
            )}
            {brand.includes('lienert') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_lienert.png"
                alt="lienert"
              />
            )}
            {brand.includes('matchflix') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_matchflix.png"
                alt="lienert"
              />
            )}
            {brand.includes('senator_deleted') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_senator.png"
                alt="lienert"
                style={{ filter: 'grayscale(100%)' }}
              />
            )}
            {brand.includes('personaltotal_deleted') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_personaltotal.png"
                alt="lienert"
                style={{ filter: 'grayscale(100%)' }}
              />
            )}
          </div>
        </div>
      );
    }
    return '';
  }

  render() {
    const candidateTitle = this.props.selectedCandidate.title
      ? `${this.props.selectedCandidate.title} `
      : '';
    const candidateName = `${candidateTitle}${
      this.props.selectedCandidate.firstName
    } ${this.props.selectedCandidate.lastName}`;
    const candidateStatus = HelperFunctions.verifyCandidateStatus(
      this.props.selectedCandidate
    );
    return (
      <div>
        <Paper>
          {this.state.showDeleteCandidateDataModal &&
            this.renderDeleteCandidateDataConfirmationModal()}
          {this.state.showDeleteCandidateModal &&
            this.renderDeleteCandidateConfirmationModal()}
          {this.state.showEditCandidateStatusPopUp &&
            this.renderCandidateStatusPopUp(candidateStatus)}
          {this.state.showDeleteCandidateImageModal &&
            this.renderDeleteCandidateImageConfirmationModal()}
          {!this.props.locked ? (
            <DropContainer
              dropzoneErrorMessage={this.state.pictureErrorMessage}
              showLoadingIndicator={this.state.pictureLoading}
              onDrop={this.onImageDrop}
            >
              {this.renderCandidateImage()}
            </DropContainer>
          ) : (
            this.renderCandidateImage()
          )}
          <div className="row">
            <div className="col-12 mt-3">
              {this.props.duplicateCheckMode && (
                <div className="candidateArchiveName mb-3">
                  <Typography
                    variant="h6"
                    className="candidateArchive-Name"
                    color="primary"
                  >
                    Eingehender Kandidat:
                  </Typography>
                </div>
              )}
              <div className="candidateArchiveName mb-3">
                <div className="candidateArchive-Name">{candidateName}</div>
              </div>
              {!this.props.duplicateCheckMode && (
                <div className="col-12 text-center">
                  {this.renderCandidateStatus(candidateStatus)}
                </div>
              )}
              {!this.props.duplicateCheckMode &&
                !this.props.locked &&
                this.renderButtonSection(candidateStatus)}
              {this.props.locked &&
                this.renderDuplicateButtonSectionDuplicateCandidate()}
              {this.props.duplicateCheckMode &&
                this.renderDuplicateButtonSectionIncomingCandidate()}
              {this.renderDetails()}

              <Divider className="mb-3" />
              <div className="col-12">
                <DetailField
                  label={TEXT.candidateDetails.candidateId}
                  value={this.props.selectedCandidate.id}
                />
                <DetailField
                  label={TEXT.candidateDetails.candidateDB}
                  value={UTILS.mapBoolean(
                    this.props.selectedCandidate.candidateDB
                  )}
                />
                <DetailField
                  label={TEXT.candidateDetails.advisor}
                  value={
                    this.props.selectedCandidate.advisor
                      ? this.props.selectedCandidate.advisor.name
                      : ''
                  }
                />
                {this.renderBrandIcons()}
              </div>
              <Divider className="mb-3" />
              {this.renderSocialIcons()}
            </div>
          </div>
        </Paper>
        {this.state.showCandidateHistoryDrawer && this.renderHistoryDrawer()}
        {this.state.showAddCandidateToProjectPopUp &&
          this.renderAddCandidateToProjectPopUp()}
        {this.state.showAddCandidateToProjectApplicationPopUp &&
          this.renderAddCandidateToProjectApplicationPopUp()}
      </div>
    );
  }
}

CandidateDetails.defaultProps = {
  candidateChangeHistoryId: undefined, // TODO change to CandidateHistory
  duplicateCheckMode: false,
  handleDuplicateCheckResumeWithDuplicate: undefined,
  handleDuplicateCheckCreateNew: undefined,
  locked: false
};
CandidateDetails.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  selectedCandidate: PropTypes.object.isRequired,
  candidateChangeHistoryId: PropTypes.string,
  handleOpenCandidateMainUpdatePopUp: PropTypes.func.isRequired,
  handleOpenPrivacyMailConfirmationModal: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  locked: PropTypes.bool,
  duplicateCheckMode: PropTypes.bool,
  handleDuplicateCheckCreateNew: PropTypes.func,
  handleDuplicateCheckResumeWithDuplicate: PropTypes.func,
  openContactHistoryForPhone: PropTypes.func.isRequired,
  liftUpStateToCandidateArchive: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};
export default withUser(CandidateDetails);
