import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Button from '@material-ui/core/Button';
import TEXT from './staticContent/text';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

class ProjectStatusReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDownloadButtonDisabled: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleDownloadClick = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    this.setState({
      isDownloadButtonDisabled: true
    });
    const url = CONSTANTS.getProjectStatusReportURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId: this.props.selectedProject.id
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const file = `data:application/pdf;base64,${responseBody.buffer}`;
        const downloadElement = document.createElement('a');
        const fileName = `Statusreport${
          this.props.selectedProject.projectNumber
        }.pdf`;

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isDownloadButtonDisabled: false
        });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url, null);
        this.setState({
          isDownloadButtonDisabled: false
        });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  render() {
    return (
      <Button
        onClick={this.handleDownloadClick}
        disabled={this.state.isDownloadButtonDisabled}
      >
        {TEXT.projectStatusReport.download}
      </Button>
    );
  }
}
ProjectStatusReport.defaultProps = {
  // isUpdate: false,
};

ProjectStatusReport.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default ProjectStatusReport;
