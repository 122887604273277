import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import './SearchField.css';
import debounce from 'lodash/debounce';
import $ from 'jquery';

import * as constants from '../constants';
import TEXT from '../text';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import synonyms from './synonymlist';

class SearchField extends PureComponent {
  handleSuggestionsFetchRequested = debounce(({ value }) => {
    this.getSuggestions(value);
  }, 500);

  constructor(props) {
    super(props);
    this.state = {
      // useSemantic: true,
      showIcon: false,
      tooltip: '',

      suggestions: []
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    // this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleSuggestionsSelected = this.handleSuggestionsSelected.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  onChange(e) {
    const optimizedInputValue = e.target.value.trim();
    this.props.onSearchFieldChange(
      optimizedInputValue,
      this.props.searchFieldIndex,
      this.props.columnIndex
    );
  }

  onBlur(e) {
    const optimizedInputValue = e.target.value.trim();
    this.props.onSearchFieldBlur(
      optimizedInputValue,
      this.props.searchFieldIndex,
      this.props.columnIndex
    );
    this.getTooltip(e.target.value);
  }

  onFocus() {
    // update the current focused columnIndex to Search.js
    this.props.onSearchFieldFocus(this.props.columnIndex);
  }

  onKeyDown(e) {
    this.props.onKeyDown(
      e,
      this.props.searchFieldIndex,
      this.props.columnIndex
    );
  }

  getTooltip(e) {
    let foundSynonym = false;
    let isFirstItem = true;
    const value = e.toLowerCase();
    let tooltip = TEXT.searchPage.tooltip;

    synonyms.forEach(list => {
      if (list.includes(value)) {
        list.forEach(item => {
          if (
            item !== value &&
            tooltip.indexOf(item.charAt(0).toUpperCase() + item.substr(1)) ===
              -1
          ) {
            if (isFirstItem) {
              isFirstItem = false;
            } else {
              tooltip += ', ';
            }

            tooltip += `${item.charAt(0).toUpperCase() + item.substr(1)}`;
          }
        });
        tooltip.substring(0, tooltip.length - 2);
        this.setState({ showIcon: true, tooltip });
        foundSynonym = true;
      }
    });
    if (!foundSynonym) this.setState({ showIcon: false, tooltip: '' });
  }

  getSuggestionValue(suggestion) {
    return suggestion.searchTerm;
  }

  getSuggestions(value) {
    // if ($.isEmptyObject(value)) {
    console.log(UTILS.isSearchtermValid(value));
    if (
      $.isEmptyObject(value) ||
      this.props.hasError ||
      !UTILS.isSearchtermValid(value)
    ) {
      return;
    }
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = constants.getSuggestionsURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify({ searchTerm: value }),
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        this.setState({
          suggestions: response.suggestions
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    this.props.onSearchFieldChange(
      newValue,
      this.props.searchFieldIndex,
      this.props.columnIndex
    );
    this.getTooltip(newValue);
  };

  handleSuggestionsSelected(event, { suggestionValue }) {
    this.props.onSearchFieldBlur(
      suggestionValue,
      this.props.searchFieldIndex,
      this.props.columnIndex
    );
  }

  // handleClickShowPassword() {
  //   this.setState(state => ({ useSemantic: !state.useSemantic }));
  // }

  renderInput(inputProps) {
    return (
      <TextField
        fullWidth
        InputProps={{
          inputProps
        }}
      />
    );
  }

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.searchTerm, query);
    const parts = parse(suggestion.searchTerm, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(
            (part, index) =>
              part.highlight ? (
                <span key={String(index)} style={{ fontWeight: 500 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={String(index)} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              )
          )}
        </div>
      </MenuItem>
    );
  }

  renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
      <Paper className="suggestionBOX" {...containerProps}>
        {children}
      </Paper>
    );
  }

  render() {
    const showIcon = isWidthUp('xl', this.props.width) && this.state.showIcon;
    return (
      <FormControl
        className={showIcon ? 'searchField d-flex flex-row' : 'searchField'}
        error={this.props.hasError}
      >
        <Autosuggest
          renderInputComponent={this.renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionsSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            placeholder: this.props.placeholder,
            onChange: this.handleChange,
            onBlur: this.onBlur,
            onFocus: this.onFocus,
            onKeyDown: this.onKeyDown,
            value: this.props.value,
            autoFocus: true
          }}
          // theme={autosuggestStyling}
        />
        {showIcon ? (
          <Tooltip title={this.state.tooltip}>
            <IconButton aria-label="Delete">
              <i className="material-icons">info</i>
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
        <FormHelperText id="name-error-text">
          {this.props.errorText}
        </FormHelperText>
      </FormControl>
    );
  }
}
SearchField.defaultProps = {
  placeholder: '',
  value: '',
  headline: 'Suchbegriff',
  hasError: false,
  errorText: ''
};
SearchField.propTypes = {
  onSearchFieldChange: PropTypes.func.isRequired,
  onSearchFieldBlur: PropTypes.func.isRequired,
  onSearchFieldFocus: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  searchFieldIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  headline: PropTypes.string,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  width: PropTypes.string.isRequired
};
export default withWidth()(SearchField);
