import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material UI
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import NotificationIcon from '@material-ui/icons/Notifications';
import UnreadIcon from '@material-ui/icons/Mail';
import ReadIcon from '@material-ui/icons/Drafts';
// components
import $ from 'jquery';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';

class NotificationListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  renderTitle() {
    return (
      <span>
        <span style={{ fontWeight: '500' }}>{this.props.title}: </span>
        {this.props.description}
      </span>
    );
  }

  markAsRead(id, withoutReloading) {
    const url = CONSTANTS.markNotificationsAsReadURL;
    const requestBody = { notificationIds: [id] };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    console.log('markSingleAsRead requestBody', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log('markSingleAsRead', responseBody);
        if (!withoutReloading) this.props.onIsReadButtonClick(true);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  onRowClick = () => {
    switch (this.props.type) {
      case 'counterPartReminder':
        this.markAsRead(this.props.id, true);
        break;
      case 'candidateStatusExpired':
        this.markAsRead(this.props.id, true);
        break;
      default:
        break;
    }
  };

  getRedirectToLink() {
    switch (this.props.type) {
      case 'counterPartReminder':
        if (!this.props.notificationObject.counterPartReminder.company) {
          return '';
        }
        return `/companies?id=${
          this.props.notificationObject.counterPartReminder.company.id
        }${
          this.props.notificationObject.counterPartReminder.counterPart.id
            ? `&cpid=${
                this.props.notificationObject.counterPartReminder.counterPart.id
              }`
            : ''
        }`;
      case 'candidateStatusExpired':
        if (!this.props.notificationObject.candidateStatusExpired.candidate) {
          return '';
        }
        return `/candidates?id=${
          this.props.notificationObject.candidateStatusExpired.candidate.id
        }`;
      default:
        return '';
    }
  }

  renderItem() {
    return (
      <ListItem
        button
        onClick={this.onRowClick}
        component={Link}
        to={this.getRedirectToLink()}
      >
        <ListItemAvatar>
          <Avatar>
            <NotificationIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={this.renderTitle()}
          secondary={UTILS.formatDateToDELocaleTime(
            new Date(this.props.dueDate)
          )}
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="mark_unread"
            onClick={() => this.markAsRead(this.props.id)}
          >
            {this.props.isRead ? <ReadIcon /> : <UnreadIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  render() {
    return this.renderItem();
  }
}

NotificationListItem.propTypes = {
  dueDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  onIsReadButtonClick: PropTypes.func.isRequired,
  notificationObject: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};
export default NotificationListItem;
