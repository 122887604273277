const text = {
  incomingCandidates: {
    buttonNext: 'Weiter',
    buttonBack: 'Zurück',
    buttonCompleteNow: 'Direkt zu "Kandidat anlegen"',
    details: 'Stammdaten',
    educations: 'Ausbildungen',
    jobs: 'Positionen',
    languages: 'Sprachen',
    skills: 'Weitere Kenntnisse',
    tags: 'Schlagworte',
    duplicateCheck: 'Dublettencheck',
    clickError:
      'Ein direktes Navigieren zwischen den Schritten ist nur rückwärts möglich.'
  },
  incomingCandidateDetails: {
    salutation: 'Anrede',
    firstName: 'Vorname',
    lastName: 'Nachname',
    currentCompany: 'Aktueller Arbeitgeber',
    currentJob: 'Aktuelle Position',
    street: 'Straße',
    city: 'Ort',
    postalCode: 'Postleitzahl',
    country: 'Land',
    phoneNumber: 'Telefonnummer',
    phoneNumberMobile: 'Mobil',
    mailAddress: 'Email-Adresse',
    nationality: 'Nationalität',
    dateOfBirth: 'Geburtsdatum',
    maritalStatus: 'Familienstand',
    creationDate: 'Erstellungsdatum',
    projectNumber: 'Auftragsnummer',
    upload: 'Bild hochladen',
    addCandidateToProject: 'Kandidat zu Auftrag hinzufügen (vorgeschlagen)',
    addCandidateToProjectApplied: 'Kandidat zu Auftrag hinzufügen (beworben)',
    addCandidateToProjectConfirmation:
      'Wollen Sie den Kandidaten wirklich als vorgeschlagen zum Auftrag hinzufügen?',
    addCandidateToProjectAppliedConfirmation:
      'Wollen Sie den Kandidaten wirklich als beworben zum Auftrag hinzufügen?',
    candidateDB: 'Kandidaten DB:'
  },
  incomingCandidateJobs: {
    jobText: 'Position',
    company: 'Unternehmen',
    fromDate: 'Von',
    tillDate: 'Bis',
    description: 'Beschreibung'
  },
  incomingCandidateEducations: {
    educationText: 'Ausbildung',
    instituteName: 'Institut',
    fromDate: 'Von',
    tillDate: 'Bis',
    educationType: 'Typ'
  },
  incomingCandidateSkills: {
    skillName: 'Fähigkeit',
    skillLevel: 'Grad der Beherschung'
  },
  incomingCandidateLanguages: {
    language: 'Sprache',
    skillLevel: 'Grad der Beherschung'
  },
  incomingCandidateTags: {
    tag: 'Schlagwort'
  },
  incomingCandidateDuplicateCheck: {
    noDuplicates: 'Es wurden keine Duplikate gefunden',
    createNew: 'Neuen Kandidaten anlegen'
  }
};
module.exports = text;
