import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// mdi Icon
// import Icon from '@mdi/react';
// import { mdiDomain as mdiCompanyIcon } from '@mdi/js';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import MuiDatatableSaveResultListBox from '../generic_components/MuiDatatableSaveResultListBox';
import * as UTILS from '../utils/utilFunctions';
// import HelperFunctions from './utils/helperFunctions';
// TODO direct copy of companyResult
import * as CONSTANTS from '../constants';
import HELPERFUNCTION from './utils/helperFunctions';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

const columns = [
  {
    name: 'ID',
    options: {
      display: 'exclude'
    }
  },
  { name: 'Status' },
  { name: 'Vorname' },
  { name: 'Nachname' },
  { name: 'E-Mail' },
  { name: 'Berufsbezeichnung' },
  { name: 'Unternehmen' },
  { name: 'PLZ' },
  { name: 'Stadt' },
  {
    name: 'Geburtsdatum',
    options: {
      customBodyRender: value =>
        value ? UTILS.formatDateToDELocale(new Date(value)) : ''
    }
  },
  {
    name: 'Zuletzt aktualisiert',
    options: {
      customBodyRender: value =>
        value ? UTILS.formatDateToDELocale(new Date(value)) : ''
    }
  }
];

const muiTheme = {
  palette: THEME.app.palette,
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          //    width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

class CandidateResults extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveResultListToMarklistPopUpOpen: false
    };
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data) {
      data.forEach(entry => {
        formatedData.push([
          entry.id,
          this.mapStatus(
            HELPERFUNCTION.verifyCandidateStatus({
              statusType: entry.statusType,
              statusExpirationDate: entry.statusExpirationDate
            }).statusType === 'available'
          ),
          entry.firstName,
          entry.lastName,
          entry.mailAddress,
          entry.currentJob,
          entry.currentCompany,
          entry.postalCode,
          entry.city,
          entry.dateOfBirth,
          entry.lastChange
        ]);
      });
    }
    return formatedData;
  }

  prepareDataForListSave(data) {
    const indices = this.state.listOfElementIndex || [];
    const mappedData = indices.length
      ? indices.map(index => data[index])
      : data;
    return mappedData
      .filter(entry => !!entry)
      .map(entry => ({
        candidate: entry.id,
        matchPercentage: '0',
        willingnessToChange: '0'
      }));
  }

  getMuiDataTableOptions() {
    return {
      filterType: 'checkbox',
      responsiv: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: false,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'Sort'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'Download CSV',
          print: 'Print',
          viewColumns: 'View Columns',
          filterTable: 'Filter Table'
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Show Columns',
          titleAria: 'Show/Hide Table Columns'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      },
      customToolbar: () => (
        <IconButton
          variant="contained"
          color="primary"
          disabled={this.props.candidateList.length === 0}
          onClick={() =>
            this.setState({ saveResultListToMarklistPopUpOpen: true })
          }
        >
          <StarIcon style={{ color: 'rgba(0,0,0,0.54)' }} />
        </IconButton>
      )
    };
  }

  mapStatus(isAvailable) {
    if (isAvailable) {
      return (
        <div>
          <i className="material-icons" style={{ color: 'green' }}>
            check_circle
          </i>
        </div>
      );
    }
    return (
      <div>
        <i className="material-icons " style={{ color: 'red' }}>
          remove_circle
        </i>
      </div>
    );
  }

  onTableChange = (action, tableState) => {
    console.log('debug onTableChange', action, tableState);
    const displayedPotentialCandidateIndexes = tableState.displayData
      .map(entry => entry.dataIndex)
      .slice();
    sessionStorage.setItem(
      'displayedCandidateIndexes',
      JSON.stringify(displayedPotentialCandidateIndexes)
    );
    this.setState({ listOfElementIndex: displayedPotentialCandidateIndexes });
  };

  renderTitle() {
    return (
      <div>
        <span>Kandidaten </span>

        {this.props.candidateList.length ===
          this.props.maxAmountOfSearchResults && (
          <Tooltip
            title={`Wenn Sie mehr als ${
              this.props.maxAmountOfSearchResults
            } Ergebnisse sehen wollen, verwenden Sie die Detailssuche. Dort können Sie die maximale Anzahl an Treffern einstellen.`}
          >
            <span>
              {' '}
              - auf {this.props.maxAmountOfSearchResults} Ergebnisse beschränkt
            </span>
          </Tooltip>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <SafeMuiDatatable
          title={this.renderTitle()}
          tableName="candidateResults"
          data={this.prepareDataForTable(this.props.candidateList)}
          columns={columns}
          onRowClick={this.props.handleCandidateSelected}
          muiTheme={muiTheme}
          options={this.getMuiDataTableOptions()}
          onTableChange={this.onTableChange}
        />
        <MuiDatatableSaveResultListBox
          data={this.prepareDataForListSave(this.props.candidateList)}
          open={this.state.saveResultListToMarklistPopUpOpen}
          markListPopupType="candidate"
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          handleClose={() =>
            this.setState({ saveResultListToMarklistPopUpOpen: false })
          }
        />
      </div>
    );
  }
}
CandidateResults.propTypes = {
  handleCandidateSelected: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  candidateList: PropTypes.array.isRequired,
  maxAmountOfSearchResults: PropTypes.number.isRequired
};
export default CandidateResults;
