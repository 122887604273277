import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// material UI
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import withUser from '../utils/withUser';

import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import TEXT_GENERAL from '../text';
import HTMLNotes from '../components/HTMLNotes';
// theme
const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class TemplatesTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.getColumns(),
      deletionRequest: {}
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getColumns() {
    return [
      {
        name: 'id',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'Templatetyp',
        options: {
          display: true,
          filter: true
        }
      },
      {
        name: 'Templatename',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: 'Betreff (nur Serienmails)',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: 'Nutzer (für Signatur)',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: 'zuletzt geändert',
        options: {
          display: true,
          filter: false,
          customBodyRender: value =>
            UTILS.formatDateToDELocale(value ? new Date(value) : '')
        }
      },
      {
        name: 'Template HTML',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => <HTMLNotes html={value} maxLength={500} />
        }
      },
      {
        name: 'Aktionen',
        options: {
          display: true,
          filter: false
        }
      }
    ];
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        const mappedData = {
          id: entry.id,
          templateType: entry.templateType,
          templateName: entry.templateName,
          templateHeading: entry.templateHeading,
          createdByUserName: entry.createdByUserName,
          editDate: entry.editDate,
          templateContent: entry.templateContent
        };

        const mappedEntry = JSON.parse(JSON.stringify(entry));
        mappedEntry.entryId = entry.id;
        formatedData.push([
          ...Object.values(mappedData),
          this.renderButtons(mappedEntry)
        ]);
      });
    return formatedData;
  }

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableToolbar: {
          titleRoot: {
            textAlign: 'left'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:nth-child(2)': {
              paddingLeft: '30px'
            },
            paddingRight: '10px'
            // '&:last-child': {
            //   textAlign: 'right'
            // }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:first-child': {
              paddingLeft: '30px'
            },
            paddingRight: '10px'
            // '&:last-child': {
            //   textAlign: 'right'
            // }
          }
        }
      }
    };
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      rowsPerPage: 100,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  handleDeletionRequest = (subSchemaName, entryId) => {
    this.setState({
      deletionRequest: {
        showConfirmationPopUpForEntry: subSchemaName,
        entryId
      }
    });
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      deletionRequest: { showConfirmationPopUpForEntry: null }
    });
  };

  handleRemoveEntryAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.deleteTemplateURL;

    const payload = {
      templateId: this.state.deletionRequest.entryId
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        this.props.refreshTemplates();
        this.handleCloseConfirmationPopUp();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  renderRemoveEntryConfirmationPopUp() {
    const subSchemaName = this.state.deletionRequest
      .showConfirmationPopUpForEntry;
    return (
      <ConfirmationModal
        key="confirmation-entry-deletion"
        handleClose={this.handleCloseConfirmationPopUp}
        open={!!subSchemaName}
        buttonPrimaryAction={this.handleRemoveEntryAPICall}
        headlineText="Template löschen"
        descriptionText="Soll der entsprechende Eintrag gelöscht werden?"
        // contentText={this.renderContentText()}
        // buttonPrimaryText={TEXT.adminPage.deleteUserModal.buttonPrimaryText}
      />
    );
  }

  renderButtons(entry) {
    const isConfirmationPopUpOpen = !!this.state.deletionRequest
      .showConfirmationPopUpForEntry;
    return (
      <div className="candidateTableAction">
        {isConfirmationPopUpOpen && this.renderRemoveEntryConfirmationPopUp()}
        <Tooltip title="Eintrag anzeigen">
          <IconButton
            className=""
            aria-label="Edit"
            color="primary"
            onClick={() => this.props.handleOpenViewTemplatePopUp(entry)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eintrag bearbeiten">
          <IconButton
            className=""
            aria-label="Edit"
            color="primary"
            onClick={() => this.props.handleOpenUpdateTemplatePopUp(entry)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eintrag löschen">
          <IconButton
            className=""
            aria-label="Delete"
            color="primary"
            // disabled
            onClick={() =>
              this.handleDeletionRequest('template', entry.entryId)
            }
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  // PAGE: Admin.Templates
  render() {
    return (
      <div className="container-fluid">
        {/* {this.renderDeleteConfirmationPopUp()} */}
        <div className="TemplatesTable row">
          {/* <div className="col-12"> */}
          <SafeMuiDatatable
            title="Templates"
            tableName="TemplatesTable"
            data={this.prepareDataForTable(this.props.data)}
            columns={this.state.columns}
            options={this.getOptions()}
            muiTheme={this.getTheme()}
          />
          {/* </div> */}
        </div>
      </div>
    );
  }
}

TemplatesTable.defaultProps = {};

TemplatesTable.propTypes = {
  refreshTemplates: PropTypes.func.isRequired,

  data: PropTypes.object.isRequired,
  handleOpenUpdateTemplatePopUp: PropTypes.func.isRequired,
  handleOpenViewTemplatePopUp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};
export default withUser(TemplatesTable);
