import React, { PureComponent } from 'react';
import './Sidebar.css';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
// material UI
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import $ from 'jquery';
import { Document, Page } from 'react-pdf';
import WillingnessToChangeCircle from './WillingnessToChangeCircle';
import TEXT from '../text';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import withUser from '../utils/withUser';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      numPages: null,
      currentDocName: '',
      currentDoc: '',
      currentDocType: '',
      candidateDocs: {
        documentList: [],
        candidateId: ''
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.content !== prevProps.content &&
      this.props.content &&
      this.isExtendedView()
    ) {
      // this.setState({ name: this.props.name }); // eslint-disable-line
      this.prepareDocumentPanel();
    }
  }

  /**
   * TODO ajax refactoring is planed and required
   *
   * Download the Documents (pdf/docx/jpeg/png/etc) for a candidate and store
   * it in the state of Sidebar.js after a base64 conversion
   */
  getCandidateDocumentById = fileName => {
    const url = CONSTANTS.getCandidateDocumentByIdURL;
    const documentRequest = {
      candidateId: this.props.content.ID,
      fileName
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        let result = '';
        if (response.contentType === 'pdf') {
          result = UTILS.convertBufferToPdf(response.content);
        } else if (
          response.contentType === 'jpeg' ||
          response.contentType === 'jpg' ||
          response.contentType === 'png'
        ) {
          result = UTILS.convertBufferToImageV2(
            response.content,
            response.contentType
          );
        } else {
          result = UTILS.convertBufferToDocx(
            response.content,
            response.contentType
          );
        }
        this.setState({
          currentDoc: result,
          currentDocType: response.contentType
        });

        return result;
      })
      .fail(err => {
        console.log(err);
      });
  };

  /**
   * TODO ajax refactoring is planed and required
   *
   * Create a list of available document of the given candidateId
   *
   */
  prepareDocumentPanel = () => {
    if (this.state.value === 2 && this.props.content.documents.length > 0)
      this.getCandidateDocumentById(this.props.content.documents[0]);
    this.setState({
      currentDocName: this.props.content.documents[0],
      currentDoc: '',
      candidateDocs: {
        documentList: this.props.content.documents
      }
    });
  };

  mapSkillLevel(skillLevel) {
    let skillLevelText = '';
    switch (skillLevel) {
      case 0:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel0;
        break;
      case 1:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel1;
        break;
      case 2:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel2;
        break;
      case 3:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel3;
        break;
      case 4:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel4;
        break;
      case 5:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel5;
        break;
      case 7:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel6;
        break;
      default:
        break;
    }
    return skillLevelText;
  }

  isExtendedView() {
    return (
      ['admin', 'employee'].includes(this.props.user.role) &&
      !this.props.content.anonymized
    );
  }

  getCandidateNumbers() {
    const candidateNumberDOM = [];
    if (this.isExtendedView()) {
      // console.log('debug candidateIDs', this.props.content.candidateIDs);
      const candidatesIds = this.props.content.candidateIDs;
      if (!candidatesIds.includes(this.props.content.ID))
        candidatesIds.unshift(this.props.content.ID);
      candidatesIds.forEach((element, index) => {
        const keyAddition = index; // for case of two duplicate candidateIDs
        // use original candidate id if available, otherwise use the mongoId of the candidate
        const candidateId = element.originCandidateId || element;
        candidateNumberDOM.push(
          <div
            className="col-4"
            key={candidateId + new Date().getTime() + keyAddition}
          >
            {`${candidateId} ${element.partner ? element.partner.company : ''}`}
            <IconButton
              onClick={e => {
                e.stopPropagation();
                this.props.handleSnackbarOpen(TEXT.resultPage.textCopied);
                const textField = document.createElement('textarea');
                textField.innerText = candidateId;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
              }}
            >
              <i className="material-icons">file_copy</i>
            </IconButton>
          </div>
        );
      });
    }
    return (
      this.isExtendedView() && (
        <div className="row">
          <div className="col-12">
            {`${TEXT.resultPage.candidateId}`}
            {this.props.content.candidateIDs.length > 1 ? 'n:' : ':'}{' '}
          </div>
          {candidateNumberDOM}
        </div>
      )
    );
  }

  createCandidateInfo() {
    return (
      <div className="sideBarWrapper m-2 p-2">
        <div className="col-12">
          <div className="row">
            <div className="col-6 text-center">
              <div className="portrait-wrapper mb-1 mt-3">
                <img
                  src={this.props.content.picture}
                  alt=""
                  width="150px"
                  className="img-fluid rounded"
                />
              </div>
              {/* <p>
                {this.props.content.isAvailable ? (
                  <span className="cl-green d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">
                      fiber_manual_record
                    </i>
                    <span>{TEXT.resultPage.available}</span>
                  </span>
                ) : (
                  <span className="cl-red d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">
                      fiber_manual_record
                    </i>
                    <span>{TEXT.resultPage.notAvailable}</span>
                  </span>
                )}
              </p> */}
            </div>
            <div className="col-6 text-center">
              <div className="row">
                <div className="col-12 mt-3">
                  {UTILS.calcMatchStars(this.props.content.score)}
                  <br />
                  {TEXT.resultPage.matchPercentage}
                </div>
              </div>
              <div />
              <div className="row">
                <div className="col-12  mt-3 ">
                  <WillingnessToChangeCircle
                    willingnessToChange={this.props.content.willingnessToChange}
                    textAboveCircle={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="headerInfo mb-3 d-md-flex justify-content-md-between align-items-md-center">
            <h4
              className={`candidate-name cl-blue m-md-0 ${
                !this.isExtendedView() ? ' blurry' : ''
              }`}
            >
              {this.isExtendedView() ? (
                <Link to={`candidates?id=${this.props.content.ID}`}>{`${
                  this.props.content.firstName
                } ${this.props.content.lastName}`}</Link>
              ) : (
                `${this.props.content.firstName} ${this.props.content.lastName}`
              )}
            </h4>
          </div>
          <div className="row">
            <div className="col-6">
              {this.props.content.candidateInformation}
            </div>
            <div className="col-6">{this.props.content.contactInformation}</div>
          </div>
        </div>
        <div className="col-12">
          {' '}
          {this.getCandidateNumbers()}
          {/* {this.props.content.candidateNumbers} */}
        </div>
        <div className="col-12">{this.props.content.skills}</div>
      </div>
    );
  }

  handleTabChange = (event, value) => {
    if (this.isExtendedView() && this.props.content.documents.length > 0)
      this.getCandidateDocumentById(this.props.content.documents[0]);
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  /**
   * Generate the rendered PDF Pages for the viewed PDF-Document
   * @param  {number} numOfPages total number of pages of the viewed PDF
   * @return {Array}             an array containing the JSX Pages of the PDF
   */
  displayPdfDocumentPages(numOfPages) {
    const pageList = [];
    for (let i = 1; i <= numOfPages; i++) {
      pageList.push(
        <Page
          className="mb-2"
          pageNumber={i}
          renderAnnotations={false}
          renderTextLayer={false}
        />
      );
    }
    return pageList;
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  /**
   * Handler for the file selection
   */
  handleSelectFileChange = event => {
    this.getCandidateDocumentById(event.target.value);
    this.setState({
      currentDocName: event.target.value,
      currentDoc: '',
      //  extract the extension of the file (more precise the substring
      //  after '.' for the given string)
      currentDocType: event.target.value.split('.')[1]
    });
  };

  /**
   * Return a populated Selection JSX with the names of available documents
   */
  listAvailableFiles() {
    return (
      <div className="text-center">
        <FormControl className="w-100">
          <InputLabel htmlFor="age-simple">
            {TEXT.resultPage.sideBar.documentsView.selectDoc.label}
          </InputLabel>
          <Select
            value={this.state.currentDocName}
            onChange={this.handleSelectFileChange}
          >
            {' '}
            {this.state.candidateDocs.documentList.map((file, index) => (
              <MenuItem key={file} value={file}>
                {`${
                  TEXT.resultPage.sideBar.documentsView.selectDoc.documentName
                } ${index + 1} (${file.split('.')[1]})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  /**
   * Displays a loading circle
   */
  documentLoadingInfo() {
    return (
      <div className="text-center p-4">
        <CircularProgress size={110} thickness={3} />
      </div>
    );
  }

  /**
   * Returns a JSX with a specific String in case there is no document
   * for the candidate (Reason: Error)
   */
  noDocumentAvailableInfo() {
    return (
      <div className="sideBarWrapper m-2 p-2">
        <h4 className="select-text">
          {TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}
        </h4>
      </div>
    );
  }

  /**
   * Sets the href for the button with the viewed file
   */
  downloadFile = () => {
    const element = document.createElement('a');
    const blob = UTILS.dataURIToBlob(this.state.currentDoc);
    element.setAttribute('href', URL.createObjectURL(blob));

    element.setAttribute(
      'download',
      `${this.state.currentDocName.split('.')[0]}.${this.state.currentDocType}`
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  /**
   * This function returns a JSX Component depending on currentDocType content
   * pdf    -> <Document ... />
   * jpg/pn -> <img ... />
   * for everything else an information String
   */
  documentPreview() {
    if (this.state.currentDocType.toLowerCase() === 'pdf') {
      return (
        <Document
          file={this.state.currentDoc}
          loading={this.documentLoadingInfo()}
          noData={this.documentLoadingInfo()}
          onLoadSuccess={this.onDocumentLoad}
          error={this.documentLoadingInfo()}
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      );
    }
    if (
      this.state.currentDocType.toLowerCase() === 'jpeg' ||
      this.state.currentDocType.toLowerCase() === 'jpg' ||
      this.state.currentDocType.toLowerCase() === 'png'
    ) {
      return (
        <div className="text-center">
          <img
            src={this.state.currentDoc}
            alt=""
            className="img-fluid rounded"
          />
        </div>
      );
    }
    return (
      <div className="sideBarWrapper m-2 p-2">
        <h4 className="select-text">
          {TEXT.resultPage.sideBar.documentsView.fileOnlyAsDownload}
        </h4>
      </div>
    );
  }

  loadPDFError(error) {
    console.log(error);
  }

  showDocContent() {
    if (this.state.candidateDocs.documentList.length !== 0) {
      return (
        <Typography component="div">
          <div className="sideBarWrapper p-1">
            <div className="col-12">
              <div className="text-right p-2">{this.listAvailableFiles()}</div>
            </div>
            <div className="text-center">
              <Button
                className="mb-3"
                variant="contained"
                color="primary"
                onClick={this.downloadFile}
                disabled={this.state.currentDoc === ''}
                download
              >
                {TEXT.resultPage.sideBar.documentsView.downloadButton}
              </Button>
              {this.documentPreview()}
            </div>
          </div>
        </Typography>
      );
    }
    return (
      <Typography component="div">
        <div className="sideBarWrapper m-2 p-2">
          <h4 className="select-text">
            {TEXT.resultPage.sideBar.documentsView.candidateHasNoDocs}
          </h4>
        </div>
      </Typography>
    );
  }

  render() {
    return (
      <div className="col no-padding-left no-padding-right">
        {this.props.content ? (
          <div>
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                fullWidth
              >
                <Tab label="Info" />
                <Tab label="Positionen" />
                {this.isExtendedView() ? <Tab label="Dokumente" /> : ''}
              </Tabs>
            </AppBar>
            <SwipeableViews
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
            >
              <Typography component="div">
                {this.createCandidateInfo()}
              </Typography>
              <Typography component="div">
                <div className="sideBarWrapper m-2 p-2">
                  <div className="col-12">
                    <div className="jobQualification text-left">
                      {this.props.content.jobs}
                    </div>
                  </div>
                </div>
              </Typography>
              {this.isExtendedView() ? this.showDocContent() : <div />}
            </SwipeableViews>
          </div>
        ) : (
          <div className="sideBarWrapper m-2 p-2">
            <h4 className="select-text">
              {TEXT.resultPage.selectCandidateForSidebar}
            </h4>
          </div>
        )}
      </div>
    );
  }
}
Sidebar.defaultProps = {
  content: undefined
};
Sidebar.propTypes = {
  content: PropTypes.object,
  user: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default withUser(Sidebar);
