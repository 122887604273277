import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from 'material-ui-pickers';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class AddReminderPopUp extends PureComponent {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(9);
    d.setMinutes(0);
    this.state = {
      inputFields: {
        counterPart: '', // freetext
        dueDate: d,
        comment: ''
      },
      showErrors: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  addPastContactAPICall = () => {
    // const input = { ...this.state.inputFields };
    const requestBody = {
      counterPartReminder: {
        company: this.props.companyId,
        counterPart: this.state.inputFields.counterPart,
        note: this.state.inputFields.comment
      },
      notification: {
        type: 'counterPartReminder',
        triggerDate: this.state.inputFields.dueDate
      }
    };
    const url = CONSTANTS.createCounterPartReminderURL;
    console.log(`API ${url} request`, requestBody);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(
          TEXT.companyArchive.addReminderPopup.snackBarText.success
        );
        this.props.handleClose(true);
        this.props.getCounterPartReminderListByCompanyAPICall(
          this.props.companyId
        );
      })
      .fail(err => {
        const ignoreErrorCodes = [500];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 500) {
          this.props.handleSnackbarOpen(
            TEXT.companyArchive.addReminderPopup.snackBarText.failure
          );
        }
      });
  };

  isValidForm() {
    const { counterPart } = this.state.inputFields;
    const isCounterPartSelected = !!counterPart;
    if (isCounterPartSelected) {
      this.setState({ showErrors: false });
      return true;
    }
    this.setState({ showErrors: true });
    return false;
  }

  handleSaveButtonClick = () => {
    if (this.isValidForm() === true) {
      this.addPastContactAPICall();
    }
  };

  handleCancelButtonClick = () => {
    this.props.handleClose(false);
  };

  onInputFieldChange = e => {
    console.log('EVENT: ', e.target);
    let inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id ? e.target.id : e.target.name;
    if (inputFieldKey === 'mailAddress')
      inputFieldValue = inputFieldValue.toLowerCase().trim();
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [inputFieldKey]: inputFieldValue
      }
    }));
  };

  onDueDateChange = dueDate => {
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        dueDate
      }
    }));
  };

  renderInputFields() {
    const inputFields = [
      'counterPart', // 'Ansprechpartner'
      'dueDate', // 'Unternehmensstatus'
      'comment' // 'Bemerkung'
    ];

    return (
      <div className="row">
        {/* contactPerson: { type: 'textField' }, */}
        <div className="col-12 mt-2 mb-1">
          <FormControl
            error={
              this.state.showErrors && !this.state.inputFields[inputFields[0]]
            }
            fullWidth
          >
            <InputLabel htmlFor={inputFields[0]}>Ansprechpartner</InputLabel>
            <Select
              fullWidth
              value={this.state.inputFields[inputFields[0]]}
              label="Ansprechpartner"
              placeholder="test"
              onChange={this.onInputFieldChange}
              inputProps={{
                name: inputFields[0],
                id: inputFields[0]
              }}
            >
              <MenuItem value="">
                <em>
                  {
                    TEXT.companyArchive.addReminderPopup.dropDowns.general
                      .pleaseSelect
                  }
                </em>
              </MenuItem>
              {this.props.counterParts.map(dropDowns => (
                <MenuItem value={dropDowns.id}>{`${dropDowns.firstName} ${
                  dropDowns.lastName
                }`}</MenuItem>
              ))}
            </Select>
            {this.state.showErrors &&
              !this.state.inputFields[inputFields[0]] && (
                <FormHelperText>Bitte treffen Sie eine Auswahl</FormHelperText>
              )}
          </FormControl>
        </div>
        <div className="col-12 mt-1 mb-1">
          <DateTimePicker
            fullWidth
            label="Datum / Uhrzeit"
            onChange={this.onDueDateChange}
            value={this.state.inputFields.dueDate}
            format="dd.MM.yyyy, HH:mm"
            cancelLabel="Abbrechen"
            animateYearScrolling={false}
            ampm={false}
          />
        </div>{' '}
        {/* 'comment' // 'Bemerkung' */}
        <div className="col-12 mt-1 mb-1">
          <TextField
            fullWidth
            id="comment"
            multiline
            rows="4"
            rowsMax="10"
            onChange={this.onInputFieldChange}
            value={this.state.inputFields.comment}
            label={
              TEXT.companyArchive.addReminderPopup.inputFields[inputFields[2]]
            }
          />
        </div>
      </div>
    );
  }

  renderPopUp() {
    console.log('counterParts', this.props.counterParts);
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>
          {TEXT.companyArchive.addReminderPopup.headline}
        </DialogTitle>
        <DialogContent>
          <div className="container">{this.renderInputFields()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelButtonClick} color="primary">
            {TEXT.companyArchive.addReminderPopup.buttons.cancel}
          </Button>
          <Button onClick={this.handleSaveButtonClick} color="primary">
            {TEXT.companyArchive.addReminderPopup.buttons.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return <div>{this.renderPopUp()}</div>;
  }
}

AddReminderPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  counterParts: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCounterPartReminderListByCompanyAPICall: PropTypes.func.isRequired
};
export default AddReminderPopUp;
