import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import StepButton from '@material-ui/core/StepButton';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import IncomingCandidateDetails from './IncomingCandidateDetails';
import IncomingCandidateEducations from './IncomingCandidateEducations';
import IncomingCandidateJobs from './IncomingCandidateJobs';
import IncomingCandidateSkills from './IncomingCandidateSkills';
import IncomingCandidateLanguages from './IncomingCandidateLanguages';
import IncomingCandidateTags from './IncomingCandidateTags';
import CandidateDocuments from '../../candidateArchive/CandidateDocuments';
import IncomingCandidateDuplicateCheck from './IncomingCandidateDuplicateCheck';
import IncomingCandidateBasicDetails from './IncomingCandidateBasicDetails';
import IncomingCandidateFinalStep from './IncomingCandidateFinalStep';
import TEXT from './staticContent/text';
import * as UTILS from '../../utils/utilFunctions';
import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';

class IncomingCandidateStepper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      pdfErrorMessage: '',
      documentLoading: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getSteps() {
    // TODO move to TEXT.js
    return [
      'Basisisinformationen',
      TEXT.incomingCandidates.duplicateCheck,
      TEXT.incomingCandidates.details,
      TEXT.incomingCandidates.educations,
      TEXT.incomingCandidates.jobs,
      TEXT.incomingCandidates.skills,
      TEXT.incomingCandidates.languages,
      TEXT.incomingCandidates.tags,
      'Kandidat anlegen'
    ];
  }

  handleNext = () => {
    // TODO Should we save to state here?
    // $('#root, html, body').css({ height: 'auto !important' });
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    // $('#root, html, body').css({ height: '100% !important' });

    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleStep = step => () => {
    if (this.state.activeStep < step)
      this.props.handleSnackbarOpen(TEXT.incomingCandidates.clickError);
    else
      this.setState({
        activeStep: step
      });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  onDocumentDrop = (acceptedFiles, callback) => {
    const url = CONSTANTS.uploadIncomingCandidateDocumentURL;
    const candidateId = this.props.selectedIncomingCandidate.id;
    this.setState({ pdfErrorMessage: '', documentLoading: true });

    const request = new FormData();
    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });
    request.append('candidateId', candidateId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        if (this.props.selectedIncomingCandidate.taggedDocuments) {
          callback({
            taggedDocuments: [
              ...this.props.selectedIncomingCandidate.taggedDocuments,
              ...responseBody.taggedDocuments
            ]
          });
          this.props.liftUpSelectedCandidateToIncomingCandidates({
            taggedDocuments: [
              ...this.props.selectedIncomingCandidate.taggedDocuments,
              ...responseBody.taggedDocuments
            ]
          });
        } else {
          callback({
            taggedDocuments: [...responseBody.taggedDocuments]
          });
          this.props.liftUpSelectedCandidateToIncomingCandidates({
            taggedDocuments: [...responseBody.taggedDocuments]
          });
        }

        this.setState({
          documentLoading: false
        });
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pdfErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            documentLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pdfErrorMessage: 'Nicht unterstütztes Dateiformat',
            documentLoading: false
          });
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <IncomingCandidateBasicDetails
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
            handleRemoveCandidate={this.props.handleRemoveCandidate}
          />
        );
      case 1:
        return 'Dublettencheck';
      case 2:
        return (
          <IncomingCandidateDetails
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            selectedDuplicateCandidate={this.props.selectedDuplicateCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
          />
        );
      case 3:
        return (
          <IncomingCandidateEducations
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            selectedDuplicateCandidate={this.props.selectedDuplicateCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
          />
        );
      case 4:
        return (
          <IncomingCandidateJobs
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            selectedDuplicateCandidate={this.props.selectedDuplicateCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
          />
        );
      case 5:
        return (
          <IncomingCandidateSkills
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            selectedDuplicateCandidate={this.props.selectedDuplicateCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
          />
        );
      case 6:
        return (
          <IncomingCandidateLanguages
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            selectedDuplicateCandidate={this.props.selectedDuplicateCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
          />
        );
      case 7:
        return (
          <IncomingCandidateTags
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            liftUpSelectedCandidateToIncomingCandidates={
              this.props.liftUpSelectedCandidateToIncomingCandidates
            }
          />
        );
      case 8:
        return (
          <IncomingCandidateFinalStep
            selectedIncomingCandidate={this.props.selectedIncomingCandidate}
            selectedDuplicateCandidate={this.props.selectedDuplicateCandidate}

            // handleNext={this.handleNext}
            // handleBack={this.handleBack}
            // liftUpSelectedCandidateToIncomingCandidates={
            //   this.props.liftUpSelectedCandidateToIncomingCandidates
            // }
          />
        );
      default:
        return 'Uknown stepIndex';
    }
  }

  renderCandidateImage() {
    let image = './img/placeholer_logo.png';
    if (this.props.selectedIncomingCandidate.picture) {
      image = UTILS.convertBufferToImage(
        this.props.selectedIncomingCandidate.picture
      );
      return (
        <div className="row">
          <div className="col-12 text-center">
            <img
              src={image}
              alt="logo"
              className="img-fluid mt-3 candidateDetailsImage rounded"
            />
          </div>
        </div>
      );
    }
    return '';
  }

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    return (
      <div>
        <Paper
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            top: '64px',
            left: 0
          }}
        >
          <IconButton
            className="float-left"
            onClick={this.props.handleBackButton}
          >
            <ArrowBackIcon />
          </IconButton>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={this.handleStep(index)}
                  completed={index < activeStep}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Paper>
        <div className="row mt-3" style={{ paddingTop: '150px' }}>
          <div className="col-12">
            <div className="ProjecResults col-12">
              {activeStep !== 1 ? (
                <div className="row pb-5">
                  <div className="col-6 pr-0">
                    <div className="col-12 mt-3 pr-0">
                      {this.state.activeStep === steps.length ? (
                        <div>
                          Done
                          <Button onClick={this.handleReset}>Abschl</Button>
                        </div>
                      ) : (
                        <div>{this.getStepContent(activeStep)}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-6 pr-0">
                    <div className="col-12 mt-3 pr-0">
                      <CandidateDocuments
                        isIncomingCandidates
                        selectedCandidate={this.props.selectedIncomingCandidate}
                        handleTagDocument={this.props.handleTagDocument}
                        onDrop={this.onDocumentDrop}
                        pdfErrorMessage={this.state.pdfErrorMessage}
                        documentLoading={this.state.documentLoading}
                        disabled={this.props.createNewCandidate}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <IncomingCandidateDuplicateCheck
                  handleNext={this.handleNext}
                  handleBack={this.handleBack}
                  selectedIncomingCandidate={
                    this.props.selectedIncomingCandidate
                  }
                  liftUpStateToIncomingCandidates={
                    this.props.liftUpStateToIncomingCandidates
                  }
                  liftUpStateToApp={this.props.liftUpStateToApp}
                />
              )}
            </div>{' '}
          </div>
        </div>
      </div>
    );
  }
}

IncomingCandidateStepper.defaultProps = {};
IncomingCandidateStepper.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  selectedDuplicateCandidate: PropTypes.object.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired,
  liftUpStateToIncomingCandidates: PropTypes.func.isRequired,
  handleTagDocument: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  handleRemoveCandidate: PropTypes.func.isRequired,
  createNewCandidate: PropTypes.bool.isRequired
};
export default IncomingCandidateStepper;
