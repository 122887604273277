import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import debounce from 'lodash/debounce';

import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import withUser from '../utils/withUser';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as TEXT_GENERAL from '../text';
import HTMLNotes from '../components/HTMLNotes';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

// function generateName(firstName, lastName) {
//   let name = '';

//   if (firstName) name = `${name + firstName} `;
//   if (lastName) name = `${name + lastName} `;
//   return name;
// }

function mapIsReferred(isReferred) {
  if (isReferred === false) return 'nein';
  if (isReferred === true) return 'ja';
  return '';
}

function mapEntryToPotentialCandidate(entry) {
  const currentCompany = entry.candidate ? entry.candidate.currentCompany : '';
  const currentJob = entry.candidate ? entry.candidate.currentJob : '';
  // const currentCompanyPhoneNumber = entry.candidate
  //   ? entry.candidate.currentCompanyPhoneNumber
  //   : '';
  const phoneNumber = entry.candidate ? entry.candidate.phoneNumber : '';
  const phoneNumberWork = entry.candidate
    ? entry.candidate.phoneNumberWork
    : '';
  const phoneNumberMobile = entry.candidate
    ? entry.candidate.phoneNumberMobile
    : '';
  const mailAddress = entry.candidate ? entry.candidate.mailAddress : '';
  // const candidateId = entry.candidate ? entry.candidate.id : null;

  const potentialCandidateData = {
    potentialCandidateId: entry.id,
    candidateId: entry.candidate && entry.candidate.id,
    // candidate data
    candidateFirstName: entry.candidate ? entry.candidate.firstName : '',
    candidateLastName: entry.candidate ? entry.candidate.lastName : '',
    candidateCompany: currentCompany,
    candidateJob: currentJob,
    // candidateCompanyPhoneNumber: currentCompanyPhoneNumber,
    candidatePhoneNumber: phoneNumber,
    candidatePhoneNumberWork: phoneNumberWork,
    candidatePhoneNumberMobile: phoneNumberMobile,
    candidateMailAddress: mailAddress,

    // projectStatus data
    source: entry.source,
    sourceText: entry.candidate.sourceText,
    department: entry.department,
    contactTrackingType: entry.contactTrackingType,
    contactDate: entry.contactDate,
    CVReceivedDate: entry.CVReceivedDate,
    initialInterviewDate: entry.initialInterviewDate,
    presentationDate: entry.presentationDate,
    status: entry.status,
    rejectionReason: entry.rejectionReason,
    remark: entry.remark,
    comment: entry.comment,
    firstInterviewDate: entry.firstInterviewDate,
    applicationSendDate: entry.applicationSendDate,

    reminderCompanyDate1: entry.reminderCompanyDate1,
    secondInterviewDate: entry.secondInterviewDate,
    reminderCandidateDate: entry.reminderCandidateDate,
    // reminderCompanyDate2: entry.reminderCompanyDate2,

    isReferred: mapIsReferred(entry.isReferred),
    signedDate: entry.signedDate,

    rating: entry.rating
  };
  return potentialCandidateData;
}

// PAGE: Auftragsstatus (Auftragsarchiv)
class ProjectStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // for removal of potentialCandidate
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    };
    this.onTableChange = debounce(this.onTableChange, 200);
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    sessionStorage.removeItem('displayedPotentialCandidateIndexes');
  }

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'candidateId',
        options: {
          display: 'excluded',
          filter: false
        }
      },

      {
        name: 'Vorname',
        options: {
          display: true,
          filter: false,
          customBodyRender: (value, metaData) => (
            <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
          )
        }
      },
      {
        name: 'Nachname',
        options: {
          display: true,
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value, metaData) => (
            <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
          )
        }
      },
      {
        name: 'Unternehmen',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Position',
        options: {
          display: 'true',
          filter: false
        }
      },

      // {
      //   name: 'Telefon des Unternehmens',
      //   options: {
      //     customBodyRender: value => {
      //       const hrefParam = `tel:${value}`;
      //       return <a href={hrefParam}>{value}</a>;
      //     },
      //     display: 'true',
      //     filter: false
      //   }
      // },
      {
        name: 'Telefon',
        options: {
          customBodyRender: value => {
            const hrefParam = `tel:${value}`;
            return <a href={hrefParam}>{value}</a>;
          },
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Telefon (Arbeit)',
        options: {
          customBodyRender: value => {
            const hrefParam = `tel:${value}`;
            return <a href={hrefParam}>{value}</a>;
          },
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Telefon mobil',
        options: {
          customBodyRender: value => {
            const hrefParam = `tel:${value}`;
            return <a href={hrefParam}>{value}</a>;
          },
          display: 'true',
          filter: false
        }
      },

      {
        name: 'E-Mail',
        options: {
          customBodyRender: value => {
            const hrefParam = UTILS.parserMailLink(
              value,
              `${this.props.projectNumber}${
                this.props.projectNumber && this.props.jobTitle1 ? ' - ' : ''
              }${this.props.jobTitle1}`
            );
            return <a href={hrefParam}>{value}</a>;
          },
          display: true,
          filter: false
        }
      },

      // potentialCanidateData
      {
        name: 'Herkunft', // source
        options: {
          display: 'true',
          filter: true
        }
      },
      {
        name: 'Herkunftdetails', // sourceText
        options: {
          display: 'true',
          filter: true
        }
      },
      {
        name: 'Abteilung',
        options: {
          display: 'false',
          filter: false
        }
      },
      {
        name: 'Kontaktverfolgung',
        options: {
          display: 'false',
          filter: true
        }
      },
      {
        name: 'Kontaktaufname',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value, 100),
          display: false,
          filter: true
        }
      },
      {
        name: 'CV erhalten am',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },

      {
        name: 'Tel. Vorabinterview am',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Präsentation am',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Status',
        options: {
          display: 'false',
          filter: true
        }
      },
      {
        name: 'Absagegrund',
        options: {
          display: 'false',
          filter: true
        }
      },
      {
        name: 'Bemerkung',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => <HTMLNotes html={value} maxLength={100} />
        }
      },
      {
        name: 'Kommentar',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => <HTMLNotes html={value} />
        }
      },
      {
        name: 'Interview am',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Bewerb.-hin an',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Nachgefasst Unternehmen',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Vorstellungstermin',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Nachgefasst Bewerber',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: true
        }
      },
      // {
      //   name: 'Nachgefasst Unternehmen',
      //   options: {
      //     customBodyRender: value => UTILS.formatStringToDELocale(value),
      //     display: false,
      //     filter: false
      //   }
      // },
      {
        name: 'vermittelt',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: CONSTANTS.isLienert ? 'Eintrittsdatum' : 'Vertragsunterschrift',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: false,
          filter: false
        }
      },
      {
        name: 'Bewertung',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: 'Aktionen',
        options: {
          display: true,
          filter: false
        }
      }
    ];
  }

  prepareAllPotentialCandidates = data => {
    const formatedData = [];
    data.forEach(entry => {
      if (entry.candidate && entry.proposed === true) {
        // map candidate
        const potentialCandidateData = mapEntryToPotentialCandidate(entry);

        formatedData.push(potentialCandidateData);
      }
    });
    this.props.liftUpAllPotentialCandidates(formatedData);
    // this.setState({ formatedData });
    // this.allPotentialCandidates = formatedData
    return formatedData;
  };

  prepareDataForTable = data => {
    const formatedData = [];
    data.forEach(entry => {
      if (entry.candidate && entry.proposed === true) {
        // map candidate
        const potentialCandidateData = mapEntryToPotentialCandidate(entry);

        formatedData.push([
          ...Object.values(potentialCandidateData),
          this.renderActionButtons(potentialCandidateData)
        ]);
      }
    });
    // this.setState({ formatedData });
    return formatedData;
  };

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: '500px'
          }
        },

        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:nth-child(2)': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:first-child': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        }
      }
    };
  }

  onTableChange(action, tableState) {
    // console.log('debug onTableChange', action, tableState);
    const displayedPotentialCandidateIndexes = tableState.displayData
      .map(entry => entry.dataIndex)
      .slice();
    // console.log('d2', displayedPotentialCandidateIndexes);
    sessionStorage.setItem(
      'displayedPotentialCandidateIndexes',
      JSON.stringify(displayedPotentialCandidateIndexes)
    );
    // this.setState(displayedPotentialCandidateIndexes);
  }

  getOptions() {
    return {
      onTableChange: this.onTableChange,
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: true,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      rowsPerPage: 15,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  handleEditButtonClick(potentialCandidateData) {
    this.props.handleOpenPotentialCandidateUpdatePopUp(
      potentialCandidateData,
      this.allPotentialCandidates,
      this.state.displayedPotentialCandidateIndexes
    );
  }

  renderActionButtons(potentialCandidateData) {
    return (
      <React.Fragment>
        <IconButton
          className=""
          disabled={!this.props.isEditable}
          aria-label="Edit"
          color="primary"
          onClick={() => this.handleEditButtonClick(potentialCandidateData)}
        >
          <EditIcon />
        </IconButton>
        {/* {this.props.user.role === 'admin' && ( */}
        <IconButton
          className=""
          disabled={!this.props.isEditable}
          aria-label="Delete"
          color="primary"
          onClick={() => {
            this.setState({
              showDeleteConfirmationPopUp: true,
              potentialCandidateIdDeletionRequest:
                potentialCandidateData.potentialCandidateId
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
        {/* )} */}
      </React.Fragment>
    );
  }

  handleRemoveEntryAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.removePotentialCandidateURL;

    const { potentialCandidateIdDeletionRequest } = this.state;
    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId: this.state.potentialCandidateIdDeletionRequest,
      removeFrom: 'proposed'
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        const oldPotentialCandidates = this.props.data;
        const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
          project => project.id === potentialCandidateIdDeletionRequest
        );
        const newPotentialCandidates = oldPotentialCandidates;
        newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
          ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
          proposed: false
        };
        this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

        this.handleCloseConfirmationPopUp();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    });
  };

  renderDeleteConfirmationPopUp() {
    return (
      this.state.showDeleteConfirmationPopUp && (
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={this.handleCloseConfirmationPopUp}
          open={this.state.showDeleteConfirmationPopUp}
          buttonPrimaryAction={this.handleRemoveEntryAPICall}
          // buttonPrimaryAction={this.handleRemoveEntryAPICall} TODO
          headlineText="Kandidat aus Auftrag entfernen?"
          descriptionText="Soll der Kandidat aus dem entsprechenden Auftrag entfernt werden und auftragspezifische Daten des Kandidatens gelöscht werden?" // contentText={this.renderContentText()}
          // buttonPrimaryText={TEXT.adminPage.deleteUserModal.buttonPrimaryText}
        />
      )
    );
  }

  render() {
    // console.log('DEBUG ProjectStatus this.props.data', this.props.data);
    console.log('DEBUG user', this.props.user);
    this.allPotentialCandidates = this.prepareAllPotentialCandidates(
      this.props.data
    );
    return (
      <div className="container-fluid">
        {this.renderDeleteConfirmationPopUp()}
        <div className="ProjectStatus row">
          <div className="col-12">
            <SafeMuiDatatable
              title="Auftragsstatus"
              tableName="projectStatus"
              data={this.prepareDataForTable(this.props.data)}
              columns={this.getColumns()}
              options={this.getOptions()}
              muiTheme={this.getTheme()}
              onTableChange={this.onTableChange}
              initialFilter={
                this.props.filterCandidateId
                  ? { candidateId: this.props.filterCandidateId }
                  : null
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

ProjectStatus.defaultProps = {
  jobTitle1: '',
  filterCandidateId: undefined
};

ProjectStatus.propTypes = {
  selectedProjectId: PropTypes.string.isRequired,
  projectNumber: PropTypes.string.isRequired,
  jobTitle1: PropTypes.string,
  data: PropTypes.array.isRequired,
  handleOpenPotentialCandidateUpdatePopUp: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  liftUpAllPotentialCandidates: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  filterCandidateId: PropTypes.string
};
export default withUser(ProjectStatus);
