import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import { Editor } from '@tinymce/tinymce-react';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DatePicker } from 'material-ui-pickers';
import withUser from '../utils/withUser';
import ValidatedTextField from '../generic_components/ValidatedTextField';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';
import { staticResources } from './staticContent/PastContactCreateUpdatePopUpStaticResources';

class PastContactCreateUpdatePopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.isPastContactDefaultPhoneCall = this.props
      .isPastContactDefaultPhoneCall
      ? 'telefonisch'
      : null;
    this.state = {
      inputFields: {
        contactType:
          this.isPastContactDefaultPhoneCall ||
          (this.props.isUpdate ? this.props.pastContactData.contactType : ''), // dropDown values hardCoded in text.js
        contactPerson: this.props.isUpdate
          ? this.props.pastContactData.contactPersonId &&
            this.props.pastContactData.contactPersonId // eslint-disable-line
          : '', // freeText
        companyStatus: this.props.isUpdate
          ? this.props.pastContactData.companyStatus
          : '', // dropDown values hardCoded in text.js
        comment: this.props.isUpdate ? this.props.pastContactData.comment : '',
        subject: this.props.isUpdate ? this.props.pastContactData.subject : '',
        contactDate: this.props.isUpdate
          ? this.props.pastContactData.contactDate
          : new Date(),
        intermediary: this.props.isUpdate
          ? this.props.pastContactData.intermediary &&
            this.props.pastContactData.intermediary._id // eslint-disable-line
          : this.props.user.id
      },
      showErrors: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.initialState = JSON.parse(JSON.stringify(this.state));
  }

  createPastContactAPICall = () => {
    const input = { ...this.state.inputFields };
    const requestBody = {
      companyId: this.props.companyId,
      contactHistoryEntry: {
        contactType: input.contactType,
        contactPerson: input.contactPerson,
        companyStatus: input.companyStatus,
        comment: input.comment,
        subject: input.subject,
        contactDate: input.contactDate,
        intermediary: input.intermediary
      }
    };
    const url = CONSTANTS.createCompanyContactHistoryEntryURL;
    console.log(`API ${url} request`, requestBody);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(
          TEXT.companyArchive.pastContactCreateUpdatePopUp.snackBarText
            .addPastContactCall.success
        );
        this.handlePopUpClose(true);
      })
      .fail(err => {
        const ignoreErrorCodes = [500];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 500) {
          this.props.handleSnackbarOpen(
            TEXT.companyArchive.pastContactCreateUpdatePopUp.snackBarText
              .addPastContactCall.failure
          );
        }
      });
  };

  handlePopUpClose(reloadRequest) {
    // reset popUp state to initial state
    this.setState({
      ...this.initialState
    });
    this.props.handleClose(reloadRequest);
  }

  updatePastContactAPICall = () => {
    const input = { ...this.state.inputFields };
    const requestBody = {
      companyId: this.props.companyId,
      pastContactId: this.props.pastContactData.id,
      pastContactData: {
        contactType: input.contactType,
        contactPerson: input.contactPerson,
        companyStatus: input.companyStatus,
        subject: input.subject,
        comment: input.comment,
        contactDate: input.contactDate,
        intermediary: input.intermediary
      }
    };
    const url = CONSTANTS.updateCompanyContactHistoryEntryURL;
    console.log(`API ${url} request`, requestBody);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.liftUpUpdatePastContactDetails(
          requestBody.pastContactId,
          requestBody.pastContactData
        );
        this.props.handleSnackbarOpen(
          TEXT.companyArchive.pastContactCreateUpdatePopUp.snackBarText
            .updatePastContactCall.success
        );
        this.handlePopUpClose(true);
      })
      .fail(err => {
        const ignoreErrorCodes = [500];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 500) {
          this.props.handleSnackbarOpen(
            TEXT.companyArchive.pastContactCreateUpdatePopUp.snackBarText
              .updatePastContactCall.failure
          );
        }
      });
  };

  handleCancelButtonClick = () => {
    this.handlePopUpClose(false);
  };

  onInputFieldChange = e => {
    // console.log('EVENT: ', e.target);
    let inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id ? e.target.id : e.target.name;
    if (inputFieldKey === 'mailAddress')
      inputFieldValue = inputFieldValue.toLowerCase().trim();
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [inputFieldKey]: inputFieldValue
      }
    }));
  };

  isValidForm() {
    const {
      contactType,
      contactPerson,
      companyStatus
    } = this.state.inputFields;
    const allDropDownsAreFilled = contactType && contactPerson && companyStatus;
    if (allDropDownsAreFilled) {
      this.setState({ showErrors: false });
      return true;
    }
    this.setState({ showErrors: true });
    return false;
  }

  handleSaveButtonClick = () => {
    const isValidForm = this.isValidForm();
    if (isValidForm === true) {
      if (this.props.isUpdate) this.updatePastContactAPICall();
      else this.createPastContactAPICall();
    }
  };

  handleEditorChange = e => {
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        comment: e.target.getContent()
      }
    }));
  };

  renderInputFields() {
    return (
      <div className="row">
        <div className="col-12 mt-1 mb-2">
          <FormControl
            error={
              this.state.showErrors && !this.state.inputFields.contactPerson
            }
            fullWidth
          >
            <InputLabel htmlFor="contactPerson">
              {
                TEXT.companyArchive.pastContactCreateUpdatePopUp.inputFields
                  .contactPerson
              }
            </InputLabel>
            {/* //TODO ist meine Meinung nach immer leer obwohl schon was ausgefüllt
            sein kann */}
            <Select
              fullWidth
              value={this.state.inputFields.contactPerson}
              label={
                TEXT.companyArchive.pastContactCreateUpdatePopUp.inputFields
                  .contactPerson
              }
              onChange={this.onInputFieldChange}
              inputProps={{
                name: 'contactPerson',
                id: 'contactPerson'
              }}
            >
              <MenuItem value="">
                <em>{staticResources.dropDowns.contactPerson.textNone}</em>
              </MenuItem>
              {this.props.counterParts.map(dropDowns => (
                <MenuItem key={dropDowns.id} value={dropDowns.id}>{`${
                  dropDowns.firstName
                } ${dropDowns.lastName}`}</MenuItem>
              ))}
            </Select>
            {this.state.showErrors && !this.state.inputFields.contactPerson && (
              <FormHelperText>
                {staticResources.dropDowns.contactPerson.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <div className="col-6 mt-2 mb-1">
          <FormControl
            error={this.state.showErrors && !this.state.inputFields.contactType}
            fullWidth
          >
            <InputLabel htmlFor="contactType">
              {
                TEXT.companyArchive.pastContactCreateUpdatePopUp.inputFields
                  .contactType
              }
            </InputLabel>
            <Select
              value={this.state.inputFields.contactType}
              fullWidth
              onChange={this.onInputFieldChange}
              inputProps={{
                name: 'contactType',
                id: 'contactType'
              }}
            >
              <MenuItem value="">
                <em>{staticResources.dropDowns.contactType.textNone}</em>
              </MenuItem>
              {staticResources.dropDowns.contactType.content.map(
                (dropDownEntry, index) => (
                  <MenuItem
                    key={staticResources.dropDowns.contactType.content[index]}
                    value={staticResources.dropDowns.contactType.content[index]}
                  >
                    {staticResources.dropDowns.contactType.content[index]}
                  </MenuItem>
                )
              )}
            </Select>
            {this.state.showErrors && !this.state.inputFields.contactType && (
              <FormHelperText>
                {staticResources.dropDowns.contactType.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <div className="col-6 mt-2 mb-1">
          <FormControl
            error={
              this.state.showErrors && !this.state.inputFields.companyStatus
            }
            fullWidth
          >
            <InputLabel htmlFor="companyStatus">
              {
                TEXT.companyArchive.pastContactCreateUpdatePopUp.inputFields
                  .companyStatus
              }
            </InputLabel>
            <Select
              value={this.state.inputFields.companyStatus}
              fullWidth
              onChange={this.onInputFieldChange}
              inputProps={{
                name: 'companyStatus',
                id: 'companyStatus'
              }}
            >
              <MenuItem value="">
                <em>{staticResources.dropDowns.companyStatus.textNone}</em>
              </MenuItem>
              {staticResources.dropDowns.companyStatus.content.map(
                (dropDownEntry, index) => (
                  <MenuItem
                    key={staticResources.dropDowns.companyStatus.content[index]}
                    value={
                      staticResources.dropDowns.companyStatus.content[index]
                    }
                  >
                    {staticResources.dropDowns.companyStatus.content[index]}
                  </MenuItem>
                )
              )}
            </Select>
            {this.state.showErrors && !this.state.inputFields.companyStatus && (
              <FormHelperText>
                {staticResources.dropDowns.companyStatus.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>

        <div className="col-6 mt-2 mb-1">
          <FormControl
            error={
              this.state.showErrors && !this.state.inputFields.intermediary
            }
            fullWidth
          >
            <InputLabel htmlFor="intermediary">
              {
                TEXT.companyArchive.pastContactCreateUpdatePopUp.inputFields
                  .intermediary
              }
            </InputLabel>
            <Select
              value={this.state.inputFields.intermediary}
              fullWidth
              onChange={this.onInputFieldChange}
              inputProps={{
                name: 'intermediary',
                id: 'intermediary'
              }}
            >
              <MenuItem value="">
                <em>{staticResources.dropDowns.companyStatus.textNone}</em>
              </MenuItem>
              {this.props.employees.map(dropDownEntry => (
                <MenuItem key={dropDownEntry.value} value={dropDownEntry.value}>
                  {dropDownEntry.label}
                </MenuItem>
              ))}
            </Select>
            {this.state.showErrors && !this.state.inputFields.companyStatus && (
              <FormHelperText>
                {staticResources.dropDowns.companyStatus.errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        {/* contactDate */}
        <div className="col-6 mt-2 mb-1">
          <div className="picker">
            <DatePicker
              keyboard
              id="contactDate"
              key="contactDate"
              label="Datum"
              format="dd.MM.yyyy"
              cancelLabel="Abbrechen"
              mask={value =>
                // handle clearing outside if value can be changed outside of the component
                value
                  ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
                  : []
              }
              value={this.state.inputFields.contactDate}
              onChange={date =>
                this.setState(prevState => ({
                  inputFields: {
                    ...prevState.inputFields,
                    contactDate: date
                  }
                }))
              }
              disableOpenOnEnter
              animateYearScrolling={false}
              // {...props}
            />
          </div>
        </div>

        <div className="col-12 mt-1 mb-1">
          <ValidatedTextField
            fullWidth
            id="subject"
            onChange={this.onInputFieldChange}
            value={this.state.inputFields.subject}
            label="Betreff"
          />
        </div>
        <div className="col-12 mt-1 mb-1">
          <Editor
            initialValue={this.state.inputFields.comment}
            id="comment"
            init={{
              language_url: '/langs/de.js',
              branding: false,
              height: '400',
              selector: 'textarea',
              fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
              plugins:
                'link code lists textcolor colorpicker textpattern imagetools',
              font_formats:
                'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif',
              toolbar:
                'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image'
            }}
            onChange={this.handleEditorChange}
          />
        </div>
      </div>
    );
  }

  renderPopUp() {
    return (
      <Dialog disableEnforceFocus open={this.props.open}>
        <DialogTitle>
          {TEXT.companyArchive.pastContactCreateUpdatePopUp.headline}
        </DialogTitle>
        <DialogContent>
          <div className="container">{this.renderInputFields()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelButtonClick} color="primary">
            {TEXT.companyArchive.pastContactCreateUpdatePopUp.buttons.cancel}
          </Button>
          <Button onClick={this.handleSaveButtonClick} color="primary">
            {TEXT.companyArchive.pastContactCreateUpdatePopUp.buttons.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return <div>{this.renderPopUp()}</div>;
  }
}

PastContactCreateUpdatePopUp.defaultProps = {
  isUpdate: false,
  pastContactData: {},
  isPastContactDefaultPhoneCall: false
};

PastContactCreateUpdatePopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  pastContactData: PropTypes.object,
  liftUpUpdatePastContactDetails: PropTypes.func.isRequired,
  counterParts: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPastContactDefaultPhoneCall: PropTypes.bool,
  employees: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(PastContactCreateUpdatePopUp);
