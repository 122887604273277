import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import withUser from '../utils/withUser';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as TEXT_GENERAL from '../text';

import isPermissionSet from '../utils/isPermissionSet';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

// PAGE: Eingehende Bewerbungen
class ProjectApplications extends PureComponent {
  constructor(props) {
    super(props);
    console.log('data', this.props.data);
    this.state = {
      customSorting: false,

      // for removal of potentialCandidate
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleRemoveEntryAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.removePotentialCandidateURL;

    const { potentialCandidateIdDeletionRequest } = this.state;
    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId: potentialCandidateIdDeletionRequest,
      removeFrom: 'applied'
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);

        const oldPotentialCandidates = this.props.data;
        console.log('DEBUG oldPotentialCandidates', oldPotentialCandidates);

        const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
          project => project.id === potentialCandidateIdDeletionRequest
        );
        const newPotentialCandidates = oldPotentialCandidates;
        newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
          ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
          applied: false
        };

        this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

        this.handleCloseConfirmationPopUp();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    });
  };

  renderDeleteConfirmationPopUp() {
    return (
      this.state.showDeleteConfirmationPopUp && (
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={this.handleCloseConfirmationPopUp}
          open={this.state.showDeleteConfirmationPopUp}
          buttonPrimaryAction={this.handleRemoveEntryAPICall}
          // buttonPrimaryAction={this.handleRemoveEntryAPICall} TODO
          headlineText="Kandidat aus Auftrag entfernen?"
          descriptionText="Soll der Kandidat aus dem entsprechenden Auftrag entfernt werden und auftragspezifische Daten des Kandidatens gelöscht werden?" // contentText={this.renderContentText()}
          // buttonPrimaryText={TEXT.adminPage.deleteUserModal.buttonPrimaryText}
        />
      )
    );
  }

  onDownloadButtonClick(projectId, isShortList) {
    console.log('DEBUG projectId', projectId);
    this.setState({
      isDownloadButtonActive: isShortList
        ? `shortList_${projectId}`
        : `longList_${projectId}`
    });
    const url = isShortList
      ? CONSTANTS.getShortListExcelURL
      : CONSTANTS.getLongListExcelURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId
    };

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      let a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhttp.response);
        // Give filename you wish to download
        a.download = isShortList
          ? 'Auftragsexport_Shortlist.xlsx'
          : 'Auftragsexport_Longlist.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        this.setState({
          isDownloadButtonActive: ''
        });
      }
    };
    // Post data to URL which handles post request
    xhttp.open('POST', url);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('x-auth', tokenFromLocalStorage);
    // You should set responseType as blob for binary responses
    xhttp.responseType = 'blob';
    xhttp.send(JSON.stringify(requestBody));
  }

  renderShortListDownloadButton = projectId => {
    if (isPermissionSet(this.props.user, 'shortLongList')) {
      const isShortList = true;
      return (
        <Button
          onClick={() => this.onDownloadButtonClick(projectId, isShortList)}
          disabled={
            this.state.isDownloadButtonActive === `shortList_${projectId}`
          }
          color="secondary"
          key={`shortList_${projectId}`}
        >
          Shortlist
        </Button>
      );
    }
    return '';
  };

  renderLongListDownloadButton = projectId => {
    const isShortList = false;
    if (isPermissionSet(this.props.user, 'shortLongList'))
      return (
        <Button
          onClick={() => this.onDownloadButtonClick(projectId, isShortList)}
          disabled={
            this.state.isDownloadButtonActive === `longList_${projectId}`
          }
          color="secondary"
          key={`longList_${projectId}`}
        >
          Longlist
        </Button>
      );
    return '';
  };

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'candidateId',
        options: {
          display: 'excluded',
          filter: false
        }
      },

      {
        name: 'Vorname',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: (value, metaData) => (
            <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
          )
        }
      },
      {
        name: 'Nachname',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: (value, metaData) => (
            <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
          )
        }
      },
      {
        name: 'Geburtsdatum',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: 'true',
          filter: false
        }
      },
      {
        name: 'PLZ',
        options: {
          display: 'true',
          filter: false
          // sort: true,
          // sortDirection: 'asc'
        }
      },
      {
        name: 'Wohnort',
        options: {
          display: 'true',
          filter: true
        }
      },
      {
        name: 'Zuordnung am',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: true,
          filter: true
        }
      },
      {
        name: 'Abgesagt am',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: true,
          filter: true
        }
      },
      // {
      //   name: 'Bewertung',
      //   options: {
      //     display: 'excluded',
      //     filter: true
      //   }
      // },

      {
        name: 'Bewertung',
        options: {
          customBodyRender: (value, tableMeta) => (
            <div className="text-right">
              {this.renderRatingButton(value, tableMeta.rowData)}
              {/* {this.renderDownloadPSheetButton(entry)} */}
            </div>
          ),
          // customHeadRender: (value, tableMeta, updateValue) => 'test',
          sort: true,
          sortDirection: 'asc',
          display: true,
          filter: false
        }
      },
      {
        name:
          this.props.user.role === 'admin'
            ? 'im Auftragsstatus / Shortlist / entfernen'
            : 'im Auftragsstatus',
        options: {
          display: 'true',
          filter: false
        }
      }
    ];
  }

  generateName(firstName, lastName) {
    let name = '';

    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  mapIsReferred(isReferred) {
    if (isReferred === false) return 'nein';
    if (isReferred === true) return 'ja';
    return '';
  }

  renderActionItems = entry => (
    <div className="col-12">
      {this.renderEditButton(entry)}
      {/* {this.renderDownloadPSheetButton(entry)} */}
    </div>
  );

  prepareDataForTable(data) {
    // TODO Remove this monster aka make it beautiful
    const formatedData = [];
    let projectApplications = data.filter(entry => entry.applied);
    if (!this.state.customSorting)
      projectApplications = projectApplications
        .sort((a, b) => {
          const sortingParam = 'firstName';
          if (a.candidate && !b.candidate) return -1;
          if (!a.candidate && b.candidate) return 1;
          if (!a.candidate && !b.candidate) return 0;
          if (a.candidate[sortingParam] < b.candidate[sortingParam]) return -1;
          if (a.candidate[sortingParam] > b.candidate[sortingParam]) return 1;
          return 0;
        })
        .sort((a, b) => {
          const sortingParam = 'rating';
          if (!a[sortingParam] && b[sortingParam]) return -1;
          if (a[sortingParam] && !b[sortingParam]) return 1;
          if (!a[sortingParam] && !b[sortingParam]) return 0;
          if (a[sortingParam] < b[sortingParam]) return -1;
          if (a[sortingParam] > b[sortingParam]) return 1;
          return 0;
        });
    else {
      if (this.state.sortedColumn === 'PLZ')
        projectApplications.sort((a, b) => {
          const sortingParam = 'postalCode';
          if (a.candidate && !b.candidate) return -1;
          if (!a.candidate && b.candidate) return 1;
          if (!a.candidate && !b.candidate) return 0;
          if (a.candidate[sortingParam] < b.candidate[sortingParam]) return -1;
          if (a.candidate[sortingParam] > b.candidate[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'Vorname')
        projectApplications.sort((a, b) => {
          const sortingParam = 'firstName';
          if (a.candidate && !b.candidate) return -1;
          if (!a.candidate && b.candidate) return 1;
          if (!a.candidate && !b.candidate) return 0;
          if (a.candidate[sortingParam] < b.candidate[sortingParam]) return -1;
          if (a.candidate[sortingParam] > b.candidate[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'Nachname')
        projectApplications.sort((a, b) => {
          const sortingParam = 'lastName';
          if (a.candidate && !b.candidate) return -1;
          if (!a.candidate && b.candidate) return 1;
          if (!a.candidate && !b.candidate) return 0;
          if (a.candidate[sortingParam] < b.candidate[sortingParam]) return -1;
          if (a.candidate[sortingParam] > b.candidate[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'Wohnort')
        projectApplications.sort((a, b) => {
          const sortingParam = 'city';
          if (a.candidate && !b.candidate) return -1;
          if (!a.candidate && b.candidate) return 1;
          if (!a.candidate && !b.candidate) return 0;
          if (!a.candidate[sortingParam] && b.candidate[sortingParam])
            return -1;
          if (a.candidate[sortingParam] && !b.candidate[sortingParam]) return 1;
          if (a.candidate[sortingParam] < b.candidate[sortingParam]) return -1;
          if (a.candidate[sortingParam] > b.candidate[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'Zuordnung am')
        projectApplications.sort((a, b) => {
          const sortingParam = 'addedDate';
          if (a[sortingParam] && !b[sortingParam]) return 1;
          if (!a[sortingParam] && b[sortingParam]) return -1;
          if (a[sortingParam] < b[sortingParam]) return -1;
          if (a[sortingParam] > b[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'Abgesagt am')
        projectApplications.sort((a, b) => {
          const sortingParam = 'rejectionDate';
          if (a[sortingParam] && !b[sortingParam]) return 1;
          if (!a[sortingParam] && b[sortingParam]) return -1;

          if (a[sortingParam] < b[sortingParam]) return -1;
          if (a[sortingParam] > b[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'Bewertung')
        projectApplications.sort((a, b) => {
          const sortingParam = 'rating';
          if (!a[sortingParam] && b[sortingParam]) return -1;
          if (a[sortingParam] && !b[sortingParam]) return 1;
          if (!a[sortingParam] && !b[sortingParam]) return 0;
          if (a[sortingParam] < b[sortingParam]) return -1;
          if (a[sortingParam] > b[sortingParam]) return 1;
          return 0;
        });
      else if (this.state.sortedColumn === 'im Auftragsstatus')
        projectApplications.sort((a, b) => {
          const sortingParam = 'proposed';
          if (!a[sortingParam] && b[sortingParam]) return -1;
          if (a[sortingParam] && !b[sortingParam]) return 1;
          if (a[sortingParam] < b[sortingParam]) return -1;
          if (a[sortingParam] > b[sortingParam]) return 1;
          return 0;
        });

      if (this.state.sortDirection === 'descending')
        projectApplications = projectApplications.reverse();
    }

    if (projectApplications)
      projectApplications.forEach(entry => {
        if (entry.applied === true) {
          // console.log('DEBUG ProjectApplications entry', entry.candidate);
          // map candidate
          // const currentCompany = entry.candidate
          //   ? entry.candidate.currentCompany
          //   : '';
          // const currentJob = entry.candidate ? entry.candidate.currentJob : '';
          // const phoneNumber = entry.candidate ? entry.candidate.phoneNumber : '';
          // const phoneNumberWork = entry.candidate
          //   ? entry.candidate.phoneNumberWork
          //   : '';
          // const phoneNumberMobile = entry.candidate
          //   ? entry.candidate.phoneNumberMobile
          //   : '';
          // const mailAddress = entry.candidate ? entry.candidate.mailAddress : '';
          const cPostalCode = entry.candidate ? entry.candidate.postalCode : '';
          const cCity = entry.candidate ? entry.candidate.city : '';

          // const candidateId = entry.candidate ? entry.candidate.id : null;

          const potentialCandidateData = {
            potentialCandidateId: entry.id,
            candidateId: entry.candidate && entry.candidate.id,

            // candidate data
            candidateFirstName: entry.candidate
              ? entry.candidate.firstName
              : '',
            candidateLastName: entry.candidate ? entry.candidate.lastName : '',
            candidateBirthDay: entry.candidate
              ? entry.candidate.dateOfBirth
              : '',
            candidatePostalCode: cPostalCode,
            candidateCity: cCity,
            addedDate: entry.addedDate,
            rejectionDate: entry.rejectionDate
            // rating: entry.rating
          };

          formatedData.push([
            ...Object.values(potentialCandidateData),
            entry.rating,
            this.renderActionItems(entry)
          ]);
        }
      });
    return formatedData;
  }

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: '500px'
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:nth-child(2)': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
            // ,
            // '&:nth-child(6)': {
            //   textAlign: 'right'
            // }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:first-child': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            },
            '&:nth-child(5)': {
              textAlign: 'center'
            }
          }
        }
      }
    };
  }

  onColumnSortChange = (changedColumn, direction) => {
    console.log('debug sorting direction', changedColumn, direction);
    this.setState({
      customSorting: true,
      sortedColumn: changedColumn,
      sortDirection: direction
    });
  };

  getOptions() {
    return {
      onColumnSortChange: (changedColumn, direction) =>
        this.onColumnSortChange(changedColumn, direction),
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: true,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  updateIsShortListAPICall = (potentialCandidateId, isShortList) => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId,
      updatedData: {
        isShortList
      }
    };

    // liftUp new state
    const oldPotentialCandidates = this.props.data;
    const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
      project => project && project.id === potentialCandidateId
    );
    const newPotentialCandidates = oldPotentialCandidates.slice();

    newPotentialCandidates[
      indexOfUpdatedPotentialCandidate
    ].isShortList = isShortList;

    this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

    // API call
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        if (isShortList)
          this.props.handleSnackbarOpen(
            'Der Kandidat wurde zur Shortlist hinzugefügt'
          );
        else
          this.props.handleSnackbarOpen(
            'Der Kandidat wurde von der Shortlist entfernt'
          );

        // this.props.refreshProject();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  updateProposedStatusAPICall = (potentialCandidateId, proposedNewValue) => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId,
      updatedData: {
        proposed: proposedNewValue
      }
    };

    // liftUp new state
    const oldPotentialCandidates = this.props.data;
    const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
      project => project && project.id === potentialCandidateId
    );
    const newPotentialCandidates = oldPotentialCandidates.slice();
    if (proposedNewValue) {
      delete newPotentialCandidates[indexOfUpdatedPotentialCandidate];
      newPotentialCandidates.unshift({
        ...oldPotentialCandidates[indexOfUpdatedPotentialCandidate],
        proposed: proposedNewValue
      });
    } else
      newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
        ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
        proposed: proposedNewValue
      };
    this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

    // API call
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);

        // this.props.refreshProject();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  updateProjectDetailsAPICall = (potentialCandidateId, rating, oldRating) => {
    if (rating !== oldRating) {
      const tokenFromLocalStorage = window.sessionStorage.getItem('token');
      const url = CONSTANTS.updatePotentialCandidateURL;

      const payload = {
        projectId: this.props.selectedProjectId,
        potentialCandidateId,
        updatedData: {
          rating
        }
      };

      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(
            TEXT_GENERAL.snackBarTexts.updateSuccess
          );

          const oldPotentialCandidates = this.props.data;
          console.log('DEBUG oldPotentialCandidates', oldPotentialCandidates);

          const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
            potentialCandidate => potentialCandidate.id === potentialCandidateId
          );

          const newPotentialCandidates = oldPotentialCandidates.slice();
          if (rating === 'A' && !CONSTANTS.isLienert) {
            delete newPotentialCandidates[indexOfUpdatedPotentialCandidate];
            newPotentialCandidates.unshift({
              ...oldPotentialCandidates[indexOfUpdatedPotentialCandidate],
              rating,
              proposed: true
            });
          } else
            newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
              ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
              rating
            };

          this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    }
  };

  renderRatingButton = (rating, potentialCandidateData) =>
    // const rating = potentialCandidateData.rating
    //   ? potentialCandidateData.rating
    //   : '';

    {
      console.log('debug potentialCandidateData', potentialCandidateData);
      return (
        <React.Fragment>
          <Button
            className=""
            aria-label="Edit"
            // color={rating === 'A' ? '#f0f' : 'default'}
            style={
              rating === 'A'
                ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
                : {
                    color: THEME.app.palette.primary.veryLight,
                    fontWeight: '400'
                  }
            }
            onClick={() =>
              this.updateProjectDetailsAPICall(
                potentialCandidateData[0],
                'A',
                rating
              )
            }
          >
            {CONSTANTS.isLienert ? '1' : 'A'}
          </Button>

          {CONSTANTS.isLienert && (
            <Button
              className=""
              aria-label="Edit"
              // color={rating === 'A' ? '#f0f' : 'default'}
              style={
                rating === 'AB'
                  ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
                  : {
                      color: THEME.app.palette.primary.veryLight,
                      fontWeight: '400'
                    }
              }
              onClick={() =>
                this.updateProjectDetailsAPICall(
                  potentialCandidateData[0],
                  'AB',
                  rating
                )
              }
            >
              1 - 2
            </Button>
          )}

          <Button
            className=""
            aria-label="Edit"
            // color={rating === 'B' ? 'primary' : 'default'}
            style={
              rating === 'B'
                ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
                : {
                    color: THEME.app.palette.primary.veryLight,
                    fontWeight: '400'
                  }
            }
            onClick={() =>
              this.updateProjectDetailsAPICall(
                potentialCandidateData[0],
                'B',
                rating
              )
            }
          >
            {CONSTANTS.isLienert ? '2' : 'B'}
          </Button>
          <Button
            className=""
            aria-label="Edit"
            // color={rating === 'C' ? 'primary' : 'default'}
            style={
              rating === 'C'
                ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
                : {
                    color: THEME.app.palette.primary.veryLight,
                    fontWeight: '400'
                  }
            }
            onClick={() =>
              this.updateProjectDetailsAPICall(
                potentialCandidateData[0],
                'C',
                rating
              )
            }
          >
            {CONSTANTS.isLienert ? '3' : 'C'}
          </Button>
        </React.Fragment>
      );
    };

  renderEditButton(potentialCandidateData) {
    const proposed = potentialCandidateData.proposed
      ? potentialCandidateData.proposed
      : '';
    const isShortList = potentialCandidateData.isShortList
      ? potentialCandidateData.isShortList
      : '';
    return (
      <React.Fragment>
        <Tooltip title="im Auftragsstatus">
          <Switch
            key={potentialCandidateData.potentialCandidateId}
            checked={proposed === true}
            onChange={() =>
              this.updateProposedStatusAPICall(
                potentialCandidateData.id,
                !proposed
              )
            }
            value="proposed"
            color="primary"
          />
        </Tooltip>
        {this.props.user.role === 'admin' && (
          <React.Fragment>
            <Tooltip title="in Shortlist aufnehmen">
              <Switch
                checked={isShortList === true}
                onChange={() =>
                  this.updateIsShortListAPICall(
                    potentialCandidateData.id,
                    !isShortList
                  )
                }
                value="proposed"
                color="primary"
              />
            </Tooltip>
            <Tooltip title="Kandidat aus Auftrag entfernen">
              <IconButton
                className=""
                disabled={!this.props.isEditable}
                aria-label="Delete"
                color="primary"
                onClick={() => {
                  this.setState({
                    showDeleteConfirmationPopUp: true,
                    potentialCandidateIdDeletionRequest:
                      potentialCandidateData.id
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    console.log(
      'DEBUG table data ProjectApplications this.props.data',
      this.props.data
    );
    return (
      <div className="container-fluid">
        {this.renderDeleteConfirmationPopUp()}
        <div className="ProjectApplications row">
          <div className="col-12">
            <SafeMuiDatatable
              title="Eingegangene Bewerbungen"
              tableName="projectApplications"
              data={this.prepareDataForTable(this.props.data)}
              columns={this.getColumns()}
              options={this.getOptions()}
              muiTheme={this.getTheme()}
              initialFilter={
                this.props.filterCandidateId
                  ? { candidateId: this.props.filterCandidateId }
                  : null
              }
            />
          </div>
          <div className="container-fluid">
            <div className="text-right pt-2">
              {this.renderShortListDownloadButton(this.props.selectedProjectId)}
              {this.renderLongListDownloadButton(this.props.selectedProjectId)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectApplications.defaultProps = {
  filterCandidateId: undefined
};

ProjectApplications.propTypes = {
  data: PropTypes.array.isRequired,
  selectedProjectId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  filterCandidateId: PropTypes.string
};
export default withUser(ProjectApplications);
