import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// mdi Icon
// import Icon from '@mdi/react';
// import { mdiDomain as mdiCompanyIcon } from '@mdi/js';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import MuiDatatableSaveResultListBox from '../generic_components/MuiDatatableSaveResultListBox';
import * as UTILS from '../utils/utilFunctions';
// import HelperFunctions from './utils/helperFunctions';
// TODO direct copy of companyResult

const muiTheme = {
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          //    width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

class ProjecResults extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveResultListToMarklistPopUpOpen: false
    };
  }

  prepareDataForListSave(data) {
    const indices = this.state.listOfElementIndex || [];
    const mappedData = indices.length
      ? indices.map(index => data[index])
      : data;
    return mappedData
      .filter(entry => !!entry)
      .map(entry => ({
        project: entry.id
      }));
  }

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded'
        }
      },
      { name: 'Unternehmen' },
      { name: 'Berufsbezeichnung' },
      { name: 'PLZ' },
      { name: 'Stadt' },
      { name: 'Status' },
      {
        name: 'Auftragsnummer',
        options: {
          customBodyRender: (value, metaData) =>
            this.props.projectNumberCustomBodyRenderer(value, metaData)
        }
      },
      {
        name: this.props.inCompanyArchive
          ? 'Erstellungsdatum'
          : 'Zuletzt geändert am:',
        options: {
          sortDirection: 'asc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      }
    ];
  }

  prepareDataForTable(data) {
    // console.log('######data', data);
    const formatedData = [];
    if (data) {
      data.forEach(entry => {
        const mappedStatus = entry.status !== 'undefined' ? entry.status : '';
        formatedData.push([
          entry.id,
          entry.companyName,
          entry.jobTitle1,
          entry.postalCode,
          entry.city,
          mappedStatus,
          entry.projectNumber,
          this.props.inCompanyArchive ? entry.creationDate : entry.lastUpdate
        ]);
      });
    }
    return formatedData;
  }

  getMuiDataTableOptions() {
    return {
      filterType: 'checkbox',
      responsiv: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: false,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'Sort'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'Download CSV',
          print: 'Print',
          viewColumns: 'View Columns',
          filterTable: 'Filter Table'
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Show Columns',
          titleAria: 'Show/Hide Table Columns'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      },
      customToolbar: () => (
        <IconButton
          variant="contained"
          color="primary"
          disabled={this.props.projectList.length === 0}
          onClick={() =>
            this.setState({ saveResultListToMarklistPopUpOpen: true })
          }
        >
          <StarIcon style={{ color: 'rgba(0,0,0,0.54)' }} />
        </IconButton>
      )
    };
  }

  onTableChange = (action, tableState) => {
    // console.log('debug onTableChange', action, tableState);
    const displayedProjectIndexes = tableState.displayData
      .map(entry => entry.dataIndex)
      .slice();
    // console.log('d2', displayedProjectIndexes);
    sessionStorage.setItem(
      'displayedProjectIndexes',
      JSON.stringify(displayedProjectIndexes)
    );
    this.setState({ listOfElementIndex: displayedProjectIndexes });
  };

  render() {
    return (
      <div>
        {this.props.handleProjectSelected ? (
          <SafeMuiDatatable
            title="Aufträge"
            tableName="projectResults"
            data={this.prepareDataForTable(this.props.projectList)}
            columns={this.getColumns()}
            onRowClick={this.props.handleProjectSelected}
            muiTheme={muiTheme}
            options={this.getMuiDataTableOptions()}
            onTableChange={this.onTableChange}
          />
        ) : (
          <SafeMuiDatatable
            title="Aufträge"
            tableName="projectResults"
            data={this.prepareDataForTable(this.props.projectList)}
            columns={this.getColumns()}
            options={this.getMuiDataTableOptions()}
            onTableChange={this.onTableChange}
          />
        )}
        <MuiDatatableSaveResultListBox
          data={this.prepareDataForListSave(this.props.projectList)}
          open={this.state.saveResultListToMarklistPopUpOpen}
          markListPopupType="project"
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          handleClose={() =>
            this.setState({ saveResultListToMarklistPopUpOpen: false })
          }
        />
      </div>
    );
  }
}

ProjecResults.defaultProps = {
  handleProjectSelected: undefined,
  projectNumberCustomBodyRenderer: value => value,
  inCompanyArchive: false
};
ProjecResults.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  handleProjectSelected: PropTypes.func,
  projectList: PropTypes.array.isRequired,
  projectNumberCustomBodyRenderer: PropTypes.func,
  inCompanyArchive: PropTypes.bool
};
export default ProjecResults;
