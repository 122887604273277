import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';
// material UI

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
// import TEXT from '../text';

import NotificationList from '../notifications/NotificationList';

class ReminderPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { notifications: [] };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Wiedervorlagen - ${UTILS.getTitle()}`;

    this.loadNotifications();
  }

  loadNotifications() {
    const url = CONSTANTS.getCounterPartReminderListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'x-auth': tokenFromLocalStorage
      },
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        // console.log('API register response body: ', responseBody);
        console.log(
          'API /notifications response status: ',
          status,
          responseBody
        );
        this.setState({ notifications: responseBody });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  reloadReminders = () => {
    this.loadNotifications();
  };

  sortNotifications() {
    const sortedNotifications = this.state.notifications;
    sortedNotifications.sort((a, b) => {
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      return dateA - dateB;
    });

    return sortedNotifications;
  }

  render() {
    return (
      <div className="container  h-100-minusAppBar" key="maincontainer">
        <div className="row align-items-center h-100">
          <div className="col-12 pt-3">
            <NotificationList
              notifications={this.sortNotifications()}
              onlyReminders
              timeSeparation
              onIsReadButtonClick={this.reloadReminders}
              onDeleteButtonClick={this.reloadReminders}
              onRowClick={this.props.liftUpStateToApp}
            />
          </div>
        </div>
      </div>
    );
  }
}
ReminderPage.defaultProps = {};
ReminderPage.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired
};
export default ReminderPage;
