import $ from 'jquery';
import * as CONFIG from '../config';

function json(payload) {
  return {
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(payload)
  };
}

function formData(payload) {
  return {
    contentType: false,
    processData: false,
    data: payload
  };
}

// eslint-disable-next-line import/prefer-default-export
export function post(url, payload) {
  console.log(`API ${url} request`, payload);
  const tokenFromLocalStorage = window.sessionStorage.getItem('token');
  const options =
    payload instanceof FormData ? formData(payload) : json(payload);
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      method: 'POST',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      ...options
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        resolve(responseBody);
      })
      .fail(err => {
        reject(err);
      });
  });
}
