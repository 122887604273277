import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';

import * as UTILS from '../utils/utilFunctions';

function mapUserStatusClass(userStatus) {
  let userStatusClass = 'status-ok';
  if (['accountLocked', 'passwordExpired'].includes(userStatus))
    userStatusClass = 'status-error';
  else if (['active'].includes(userStatus)) userStatusClass = 'status-active';
  return userStatusClass;
}

const columns = [
  {
    name: 'ID',
    options: {
      display: 'excluded',
      filter: false
    }
  },
  {
    name: 'Name',
    options: {
      filter: false
    }
  },
  {
    name: 'E-Mail',
    options: {
      customBodyRender: value => {
        const hrefParam = UTILS.parserMailLink(value);
        return <a href={hrefParam}>{value}</a>;
      },
      filter: false
    }
  },
  {
    name: 'Firmenzugehörigkeit',
    options: {
      display: false,
      filter: false
    }
  },
  { name: 'Rollen' },
  { name: 'Berater' },
  // {
  //   name: 'Berechtigungen',
  //   options: {
  //     display: false,
  //     filter: false
  //   }
  // },
  { name: 'Partner' },
  {
    name: 'Status',
    options: {
      customBodyRender: userStatus => (
        <div
          className={`adminPage-userStatus ${mapUserStatusClass(userStatus)}`}
        >
          {userStatus}
        </div>
      )
    }
  },
  {
    name: 'Aktionen',
    options: {
      filter: false
    }
  }
];

class UsersTable extends PureComponent {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //     };
  //   }

  renderButtons(user) {
    return (
      <div className="adminPage-usersTable__actionButtons">
        {user.roles.find(r => ['admin', 'employee'].includes(r)) && (
          <Tooltip title="Zusatzberechtigungen spezifizieren">
            <Button
              onClick={() => this.props.showEditAdditionalPermissions(user)}
              id={user.email}
              color="secondary"
              // className="editUserButton"
            >
              <i className="material-icons">tune</i>
            </Button>
          </Tooltip>
        )}
        <Tooltip title="Nutzerdaten bearbeiten">
          <Button
            onClick={() => this.props.showEditUser(user)}
            id={user.email}
            color="secondary"
            className="editUserButton"
          >
            <i className="material-icons">edit</i>
          </Button>
        </Tooltip>
        <Tooltip title="Nutzer entsperren">
          <Button
            onClick={() => this.props.showResetUser(user.email)}
            id={user.email}
            color="secondary"
            className="resetUserButton"
          >
            <i className="material-icons">lock_open</i>
          </Button>
        </Tooltip>
        <Tooltip title="Nutzer entfernen">
          <Button
            onClick={() => this.props.showDeleteUser(user.email)}
            id={user.email}
            color="secondary"
            className="deleteUserButton"
          >
            <i className="material-icons">delete</i>
          </Button>
        </Tooltip>
      </div>
    );
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(user => {
        const mappedUserName = `${
          user.firstname !== undefined ? user.firstname : ''
        } ${user.lastname !== undefined ? user.lastname : ''}`;

        formatedData.push([
          user.id,
          mappedUserName,
          user.email,
          user.company,
          user.roles.join(', '),
          UTILS.mapBoolean(user.isAdvisor),
          // user.scope.join(', '),
          user.partner
            ? `${user.partner.partnerName} (${user.partner.partnerId})`
            : '',
          user.status,
          this.renderButtons(user)
        ]);
      });
    return formatedData;
  }

  getOptions() {
    return {
      //   onColumnViewChange: this.onColumnViewChange,
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      rowsPerPage: 15,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  getTheme() {
    return {
      palette: {
        primary: {
          main: '#39a9dc',
          light: '#74c3e6',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableToolbar: {
          titleText: {
            textAlign: 'left',
            paddingLeft: '5px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:nth-child(2)': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:first-child': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        }
      }
    };
  }

  render() {
    return (
      <div>
        <div className="UsersTable">
          <SafeMuiDatatable
            title="Nutzerverwaltung"
            tableName="UsersTable"
            data={this.prepareDataForTable(this.props.data)}
            columns={columns}
            options={this.getOptions()}
            muiTheme={this.getTheme()}
          />
        </div>
      </div>
    );
  }
}

UsersTable.defaultProps = {};
UsersTable.propTypes = {
  data: PropTypes.array.isRequired,
  showDeleteUser: PropTypes.func.isRequired,
  showResetUser: PropTypes.func.isRequired,
  showEditUser: PropTypes.func.isRequired,
  showEditAdditionalPermissions: PropTypes.func.isRequired
};
export default UsersTable;
