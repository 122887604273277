import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';

import $ from 'jquery';
import { Document, Page } from 'react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import './DocumentPanel.css';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import DropContainer from './DropContainer';
import withUser from '../utils/withUser';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';
import * as CONFIG from '../config';

class DocumentPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      currentDocName: '',
      currentDoc: '',
      currentDocFileType: '',
      documentList: [],
      isLoadingDocument: false,
      pdfErrorMessage: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleDeleteClick = (fileName, documentId) => {
    const url = this.props.deleteURL;
    const documentRequest = {
      [`${this.props.archive}Id`]: this.props.id,
      fileName,
      documentId
    };

    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        if (response.status === 'success') {
          this.setState(
            prevState => ({
              documentList: prevState.documentList.filter(
                doc => doc.id !== documentId
              )
            }),
            () => this.forceUpdate()
          );
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  onDrop = acceptedFiles => {
    let foundFileWithSameName = false;
    acceptedFiles.forEach(file => {
      const fileName = file.name;

      this.props.documentList.forEach(doc => {
        if (fileName.toLowerCase() === doc.documentName.toLowerCase()) {
          foundFileWithSameName = true;
        }
      });
    });
    if (foundFileWithSameName) {
      this.props.handleSnackbarOpen(
        'Es ist bereits ein Dokument mit diesem Namen vorhanden!'
      );
      return;
    }
    const url = this.props.uploadDocumentURL;
    this.setState({ pdfErrorMessage: '', isLoadingDocument: true });

    const request = new FormData();
    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });

    request.append(`${this.props.archive}Id`, this.props.id);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        this.setState(
          prevState => ({
            isLoadingDocument: false,
            documentList: [
              ...prevState.documentList,
              ...responseBody.documentList
            ]
          }),
          () => this.forceUpdate()
        );
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pdfErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            isLoadingDocument: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pdfErrorMessage: 'Nicht unterstütztes Dateiformat',
            isLoadingDocument: false
          });
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  componentDidMount() {
    this.prepareDocumentPanel();
  }

  handleListItemClick = (fileName, documentId) => {
    if (this.state.currentDocName !== fileName) {
      this.setState({ isLoadingDocument: true });
      this.getDocumentApiCall(fileName, documentId);
    }
  };

  getDocumentApiCall = (fileName, documentId) => {
    const url = this.props.getDocumentURL;
    const documentRequest = {
      [`${this.props.archive}Id`]: this.props.id,
      fileName,
      documentId
    };

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        let result = '';
        if (response.contentType === 'pdf') {
          result = UTILS.convertBufferToPdf(response.content);
        } else if (
          response.contentType === 'jpeg' ||
          response.contentType === 'jpg' ||
          response.contentType === 'png'
        ) {
          result = UTILS.convertBufferToImageV2(
            response.content,
            response.contentType
          );
        } else {
          result = UTILS.convertBufferToDocx(
            response.content,
            response.contentType
          );
        }
        this.setState({
          currentDoc: result,
          currentDocName: fileName,
          currentDocFileType: response.contentType
        });

        return result;
      })
      .fail(err => {
        console.log(err);
      });
  };

  renderAvailableFiles() {
    return (
      <div className="text-center">
        <List dense>
          {this.state.documentList.map(taggedDocument => (
            <ListItem
              // key={`${this.state.currentDocName}listItem`}
              button
              selected={
                taggedDocument.documentName === this.state.currentDocName
              }
              onClick={() =>
                this.handleListItemClick(
                  taggedDocument.documentName,
                  taggedDocument.id
                )
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {taggedDocument.documentType ? (
                    taggedDocument.documentType
                  ) : (
                    <FolderIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={taggedDocument.documentName}
                secondary={
                  taggedDocument.creationDate
                    ? `Datum: ${UTILS.formatStringToDELocale(
                        taggedDocument.creationDate
                      )}`
                    : ''
                }
              />
              <ListItemSecondaryAction>
                {this.props.user.role === 'admin' && (
                  <IconButton
                    aria-label="Delete"
                    disabled={!taggedDocument.id}
                    onClick={() =>
                      this.handleDeleteClick(
                        taggedDocument.documentName,
                        taggedDocument.id
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  documentLoadingInfo() {
    return <CircularProgress size={110} thickness={3} />;
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoadingDocument: false });
  };

  documentPreview() {
    if (
      this.state.currentDocFileType &&
      this.state.currentDocFileType.toLowerCase() === 'pdf'
    ) {
      return (
        <Document
          file={this.state.currentDoc}
          loading={this.documentLoadingInfo()}
          noData={this.documentLoadingInfo()}
          onLoadSuccess={this.onDocumentLoad}
          error={this.documentLoadingInfo()}
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      );
    }
    if (
      this.state.currentDocFileType &&
      (this.state.currentDocFileType.toLowerCase() === 'jpeg' ||
        this.state.currentDocFileType.toLowerCase() === 'jpg' ||
        this.state.currentDocFileType.toLowerCase() === 'png')
    ) {
      this.setState({ isLoadingDocument: false });
      return (
        <div className="text-center">
          <img
            src={this.state.currentDoc}
            alt=""
            className="img-fluid rounded"
          />
        </div>
      );
    }
    this.setState({ isLoadingDocument: false });
    return (
      <h4 className="m-3">
        {TEXT.resultPage.sideBar.documentsView.fileOnlyAsDownload}
      </h4>
    );
  }

  /**
   * Sets the href for the button with the viewed file
   */
  downloadFile = () => {
    const element = document.createElement('a');
    const blob = UTILS.dataURIToBlob(this.state.currentDoc);
    element.setAttribute('href', URL.createObjectURL(blob));

    element.setAttribute(
      'download',
      `${this.state.currentDocName.split('.')[0]}.${
        this.state.currentDocFileType
      }`
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  showDocContent() {
    if (this.state.documentList && this.state.documentList.length !== 0) {
      return (
        <div className="row col-12">
          <Paper
            style={{ width: '100%', maxHeight: '1000px', overflow: 'auto' }}
          >
            <div className="col-12">
              <div className="text-right p-2">
                <DropContainer
                  onDrop={this.onDrop}
                  dropzoneErrorMessage={this.state.pdfErrorMessage}
                  // showLoadingIndicator={this.state.isLoadingDocument}
                >
                  {this.renderAvailableFiles()}
                </DropContainer>
              </div>
            </div>
            <div className="text-center col-12">
              <Button
                className="mb-3"
                variant="contained"
                color="primary"
                onClick={this.downloadFile}
                disabled={this.state.currentDoc === ''}
                download
              >
                Download
              </Button>

              {this.state.isLoadingDocument && (
                <div className="row">
                  <div className="col-12 text-center">
                    {' '}
                    <CircularProgress size={110} thickness={3} />
                  </div>
                </div>
              )}
              {this.documentPreview()}
            </div>
          </Paper>
        </div>
      );
    }
    return (
      <div>
        <div className="sideBarWrapper m-2 p-2">
          <div className="candidateArchive-emptyPage">
            <DropContainer
              onDrop={this.onDrop}
              dropzoneErrorMessage={this.state.pdfErrorMessage}
              showLoadingIndicator={this.state.isLoadingDocument}
              disabled={this.props.disabled}
              actionDescription={
                this.props.disabled ? '' : 'Dateien hier ablegen'
              }
            >
              {this.props.disabled
                ? this.props.disabledInfoText
                : this.props.noDocumentsText}
            </DropContainer>
          </div>
        </div>
      </div>
    );
  }

  displayPdfDocumentPages(numOfPages) {
    const pageList = [];
    for (let i = 1; i <= numOfPages; i++) {
      pageList.push(
        <Page
          width={800}
          className="mx-auto"
          pageNumber={i}
          renderAnnotations={false}
          renderTextLayer={false}
        />
      );
    }
    return pageList;
  }

  prepareDocumentPanel = () => {
    if (this.props.documentList && this.props.documentList.length > 0) {
      const documentFileName = this.props.documentList
        ? this.props.documentList[0].documentName
        : '';
      const documentId = this.props.documentList
        ? this.props.documentList[0].id
        : '';
      this.getDocumentApiCall(documentFileName, documentId);
      this.setState({
        documentList: this.props.documentList
      });
    }
  };

  render() {
    return this.showDocContent();
  }
}
DocumentPanel.defaultProps = {
  disabled: false,
  documentList: [],
  disabledInfoText: 'Dokument Upload deaktiviert',
  noDocumentsText: 'Keine Dokumente vorhanden'
};
DocumentPanel.propTypes = {
  documentList: PropTypes.array,
  id: PropTypes.string.isRequired,
  deleteURL: PropTypes.string.isRequired,
  getDocumentURL: PropTypes.string.isRequired,
  uploadDocumentURL: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledInfoText: PropTypes.string,
  noDocumentsText: PropTypes.string,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  archive: PropTypes.string.isRequired
};

export default withUser(DocumentPanel);
