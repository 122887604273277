import $ from 'jquery';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import LZString from 'lz-string';

// Material UI
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import withUser from '../utils/withUser';

// components
import SingleProjectResult from '../projectArchive/SingleProjectResult';
import ProjectResult from '../projectArchive/ProjectResult';
import ProjectDetailSearch from '../projectArchive/ProjectDetailSearch';
// popUp container
import ProjectDetailsUpdatePopUpContainer from '../projectArchive/ProjectDetailsUpdatePopUpContainer';
import ActionProtocolCreationPopUpContainer from '../projectArchive/ActionProtocolCreationPopUpContainer';
import PotentialCandidateUpdatePopUpContainer from '../projectArchive/PotentialCandidateUpdatePopUpContainer';
// generic components
import MainSearchField from '../generic_components/MainSearchField';
import onNextPreviousItemClick from '../utils/forwardBackwardsUtils';

import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import TEXT from '../projectArchive/staticContent/text';

// css
import './ProjectArchive.css';

class ProjectArchive extends PureComponent {
  constructor(props) {
    super(props);
    let currentTab = UTILS.getURLParam(this.props.history, 't');
    currentTab = currentTab ? parseInt(currentTab, 10) : 0;
    const projectIdFromParms = UTILS.getURLParam(this.props.history, 'id');
    sessionStorage.removeItem('displayedProjectIndexes');

    this.state = {
      searchFieldValidationError: false,
      // showResultList: false,
      isPopoverOpen: false,
      projectList: [],
      selectedProject: {},
      employees: [], // array of objects for dropdowns,
      partners: [], // for details search
      isProjectDetailsUpdatePopUpOpen: false,
      isActionProtocolCreationPopUpOpen: false,
      isPotentialCandidateUpdatePopUpOpen: false,
      currentTab,
      showResultList: false,
      showProjectDetails: false,
      showProjectDetailsColumn: true,
      isDetailSearchDrawerOpen: false,
      searchFieldAutoFocus: !(
        projectIdFromParms && projectIdFromParms.length > 0
      ),
      statusFilterCandidateId: undefined
    };
    this.mainSearchFieldChange = flowRight(
      debounce(this.mainSearchFieldChange, 200),
      e => e.persist() || e
    );

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Aufträge - ${UTILS.getTitle()}`;

    const searchQueryFromParams = UTILS.getURLParam(this.props.history, 'sq');
    const projectIdFromParms = UTILS.getURLParam(this.props.history, 'id');
    const lastSearchTypeFromParms = UTILS.getURLParam(this.props.history, 'ls');
    const maxAmountOfSearchResults = UTILS.getURLParam(
      this.props.history,
      'ma'
    );
    let detailSearchQueryFromParams = UTILS.getURLParam(
      this.props.history,
      'dq'
    );

    if (detailSearchQueryFromParams)
      detailSearchQueryFromParams = LZString.decompressFromEncodedURIComponent(
        detailSearchQueryFromParams
      );

    try {
      detailSearchQueryFromParams = JSON.parse(detailSearchQueryFromParams);
    } catch (e) {
      detailSearchQueryFromParams = undefined;
    }
    if (projectIdFromParms && projectIdFromParms.length > 0) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      this.getProjectById(projectIdFromParms);
    } else if (this.props.projectMarkListId) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      this.getProjectListByMarkListId();
    }
    if (
      searchQueryFromParams &&
      lastSearchTypeFromParms &&
      lastSearchTypeFromParms === 'query'
    ) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

      this.getProjectListBySearchAPICall(
        searchQueryFromParams,
        !(projectIdFromParms && projectIdFromParms.length > 0)
      );
    } else if (
      detailSearchQueryFromParams &&
      lastSearchTypeFromParms &&
      lastSearchTypeFromParms === 'detail'
    ) {
      console.log('query: ', detailSearchQueryFromParams);
      this.getProjectListByDetailSearchAPICall(
        detailSearchQueryFromParams,
        maxAmountOfSearchResults,
        !(projectIdFromParms && projectIdFromParms.length > 0)
      );
    } else if (!lastSearchTypeFromParms && !this.props.projectMarkListId) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

      this.getProjectListByLastUpdateAPICall(
        !(projectIdFromParms && projectIdFromParms.length > 0)
      );
    }

    this.getEmployeeListByPartnerAPICall();
    this.getAllPartnersOfCompany();
  }

  getProjectListByDetailSearchAPICall = (
    detailSearchFieldList,
    maxAmountOfSearchResults,
    showResultList
  ) => {
    const url = CONSTANTS.getProjectListByDetailSearchTermsURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const filteredDetailSearchFieldList = detailSearchFieldList.filter(
      item =>
        item.dropDownValue !== 'none' &&
        (item.fieldValue !== undefined && item.fieldValue.length !== 0) &&
        // WARNING code pope blessing
        Object.values(item.fieldValue).every(itemValue => itemValue !== '')
    );
    const payload = {
      detailSearchFieldList: filteredDetailSearchFieldList,
      maxAmountOfSearchResults
    };

    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.setState({
          showResultList,
          projectList: response
        });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  isValidateSearchTerm(searchTerm) {
    if (!UTILS.isSearchtermValid(searchTerm)) {
      this.setState({ searchFieldValidationError: true });
      return false;
    }
    if (this.state.searchFieldValidationError)
      this.setState({ searchFieldValidationError: false });
    return true;
  }

  getProjectListByMarkListId() {
    const url = CONSTANTS.getProjectListByMarkListIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { projectMarkListId: this.props.projectMarkListId };

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        if (responseBody.length === 1) {
          this.setState({
            projectList: responseBody
          });
          UTILS.setURLParam(this.props.history, 'id', responseBody[0].id);

          this.getProjectById(responseBody[0].id);
        } else
          this.setState({ showResultList: true, projectList: responseBody });
        this.props.liftUpStateToApp({
          projectMarkListId: undefined,
          showLoadingIndicatorGlobal: false
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getProjectListByLastUpdateAPICall(showResultList = true) {
    const url = CONSTANTS.getProjectListByLastUpdateURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        console.log(`API ${url} response `, responseBody);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false
        });
        this.setState({
          showResultList,
          projectList: responseBody
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getProjectListBySearchAPICall(searchTerm, showResultList = true) {
    if (!this.isValidateSearchTerm(searchTerm) || !searchTerm) return;
    this.setState({ searchTerm });
    const requestBody = { searchTerm };
    const url = CONSTANTS.getProjectListBySearchTermURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log(`API ${url} response `, responseBody);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false
        });
        this.setState({
          showResultList,
          projectList: responseBody
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  mainSearchFieldChange = e => {
    if (e.target.value !== '') {
      this.getProjectListBySearchAPICall(e.target.value);
      UTILS.setURLParam(this.props.history, 'sq', e.target.value);
      UTILS.setURLParam(this.props.history, 'ls', 'query');
    }
  };

  getAllPartnersOfCompany() {
    const url = CONSTANTS.getAllPartnersOfCompanyURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const sortedPartners = responseBody.partners.sort((a, b) => {
          if (a.partnerName > b.partnerName) return 1;
          if (a.partnerName < b.partnerName) return -1;
          return 0;
        });
        this.setState({ partners: sortedPartners });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getEmployeeListByPartnerAPICall() {
    const url = CONSTANTS.getEmployeeListByPartnerURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    // const requestBody = { onlyAdvisors: CONSTANTS.isLienert };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      // data: JSON.stringify(requestBody),
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.setState({ employees: responseBody });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getProjectById = id => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const requestBody = { projectId: id };
    const url = CONSTANTS.getProjectByIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        // Check for URL Param cid and set state only for the first call
        const statusFilterCandidateId = UTILS.getURLParam(
          this.props.history,
          'cid'
        );
        const stateObject = {
          selectedProject: responseBody.project,
          showResultList: false,
          showProjectDetails: true
        };
        if (statusFilterCandidateId) {
          UTILS.removeURLParam(this.props.history, 'cid');
          stateObject.statusFilterCandidateId = statusFilterCandidateId;
        } else {
          stateObject.statusFilterCandidateId = undefined;
        }
        this.setState(stateObject);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  refreshProject = () => {
    this.getProjectById(this.state.selectedProject.id);
  };

  liftUpStateToProjectArchive = (stateNameOrObject, value) => {
    if (typeof stateNameOrObject === 'object') {
      Object.keys(stateNameOrObject).forEach(key => {
        this.setState({
          [key]: stateNameOrObject[key]
        });
      });
    } else if (typeof value === 'string') {
      const key = stateNameOrObject;
      this.setState({ [key]: value });
    } else {
      const key = stateNameOrObject;
      this.setState(prevState => ({ [key]: { ...prevState[key], ...value } }));
    }
  };

  liftUpUpdatedPotentialCandidates = newPotentialCandidates => {
    this.setState(prevState => ({
      selectedProject: {
        ...prevState.selectedProject,
        potentialCandidates: newPotentialCandidates
      }
    }));
  };

  liftUpAllPotentialCandidates = allPotentialCandidates => {
    this.setState({ allPotentialCandidates });
  };

  handleCloseProjectDetailsUpdatePopUp = () => {
    this.setState({
      isProjectDetailsUpdatePopUpOpen: false
    });
  };

  handleCloseActionProtocolCreationPopUp = () => {
    this.setState({ isActionProtocolCreationPopUpOpen: false });
  };

  handleClosePotentialCandidateUpdatePopUp = () => {
    this.setState({
      isPotentialCandidateUpdatePopUpOpen: false,
      currentPotentialCandidateIndex: null
    });
  };

  handleOpenPotentialCandidateUpdatePopUp = (
    potentialCandidateData,
    allPotentialCandidates,
    displayedPotentialCandidateIndexes
  ) => {
    this.setState({
      isPotentialCandidateUpdatePopUpOpen: true,
      selectedPotentialCandidate: potentialCandidateData,
      allPotentialCandidates,
      displayedPotentialCandidateIndexes
    });
  };

  renderProjectDetailsUpdatePopUp() {
    return (
      <ProjectDetailsUpdatePopUpContainer
        open={this.state.isProjectDetailsUpdatePopUpOpen}
        isUpdate
        key={`${this.state.selectedProject.id}projectDetailsPopUp`}
        projectId={this.state.selectedProject.id}
        data={this.state.selectedProject}
        handleClose={this.handleCloseProjectDetailsUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        employees={this.state.employees}
        refreshProject={this.refreshProject}
      />
    );
  }

  renderActionProtocolCreationPopUp() {
    return (
      <ActionProtocolCreationPopUpContainer
        open={this.state.isActionProtocolCreationPopUpOpen}
        key={`${this.state.selectedProject.id}actionProtocolPopUp`}
        projectId={this.state.selectedProject.id}
        data={this.state.selectedProject}
        handleClose={this.handleCloseActionProtocolCreationPopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        employees={this.state.employees}
        refreshProject={this.refreshProject}
      />
    );
  }

  handleSelectNextCandidate = potentialCandidateId => {
    // console.log(
    //   'DEBUg this.state.selectedProject.potentialCandidates',
    //   this.state.selectedProject.potentialCandidates
    // );
    // console.log(
    //   'DEBUg allPotentialCandidates',
    //   this.state.allPotentialCandidates
    // );
    const displayedPotentialCandidateIndexes = JSON.parse(
      sessionStorage.getItem('displayedPotentialCandidateIndexes')
    );
    // console.log(
    //   'd2 PA displayedPotentialCandidateIndexes',
    //   displayedPotentialCandidateIndexes
    // );
    const {
      // displayedPotentialCandidateIndexes,
      allPotentialCandidates
    } = this.state;
    let displayedCandidates = [];
    if (displayedPotentialCandidateIndexes)
      displayedPotentialCandidateIndexes.forEach(potentialCandidateIndex => {
        displayedCandidates.push(
          allPotentialCandidates[potentialCandidateIndex]
        );
      });
    // in case this.state.displayedCandidates is undefined
    else displayedCandidates = allPotentialCandidates;

    const currentIndex = displayedCandidates.findIndex(
      potentialCandidate =>
        potentialCandidate.potentialCandidateId === potentialCandidateId
    );
    // alert(`test ${currentIndex}`);
    let nextIndex = currentIndex + 1;
    if (displayedCandidates[nextIndex] === undefined) nextIndex = 0;
    this.setState(prevState => {
      console.log(
        'prevState allPotentialCandidates',
        prevState.allPotentialCandidates
      );
      const nextCandidate = displayedCandidates[nextIndex];
      return {
        selectedPotentialCandidate: nextCandidate,
        currentPotentialCandidateIndex: nextIndex,
        displayedCandidates
      };
    });
  };

  renderPotentialCandidateUpdatePopUp() {
    const indexInformation =
      typeof this.state.currentPotentialCandidateIndex === 'number'
        ? `(${this.state.currentPotentialCandidateIndex + 1}/${
            this.state.displayedCandidates.length
          })`
        : '';
    return (
      <PotentialCandidateUpdatePopUpContainer
        open={this.state.isPotentialCandidateUpdatePopUpOpen}
        key={this.state.selectedPotentialCandidate.potentialCandidateId}
        projectId={this.state.selectedProject.id}
        potentialCandidateId={
          this.state.selectedPotentialCandidate.potentialCandidateId
        }
        data={this.state.selectedPotentialCandidate}
        isUpdate
        handleClose={this.handleClosePotentialCandidateUpdatePopUp}
        handleSelectNextCandidate={this.handleSelectNextCandidate}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshProject={this.refreshProject}
        indexInformation={indexInformation}
      />
    );
  }

  handleProjectSelected = selectedRow => {
    this.setState({
      showResultList: false
    });
    UTILS.setURLParam(this.props.history, 'id', selectedRow[0]);
    this.getProjectById(selectedRow[0]);
  };

  handleCollapseIconButtonClick = () => {
    this.setState(prevState => ({
      showProjectDetailsColumn: !prevState.showProjectDetailsColumn
    }));
  };

  render() {
    const collapseIconStyle = {
      position: 'absolute',
      bottom: '30px',
      left: 'calc(-30px + 16%)'
    };
    if (this.state.showProjectDetailsColumn === false)
      collapseIconStyle.left = '20px';
    const isProjectDataLoaded =
      this.state.selectedProject &&
      Object.keys(this.state.selectedProject).length > 0 &&
      this.state.employees.length > 0;
    return (
      <div className="ProjectArchive  h-100-minusAppBar">
        {isProjectDataLoaded && this.renderProjectDetailsUpdatePopUp()}
        {isProjectDataLoaded && this.renderActionProtocolCreationPopUp()}
        {isProjectDataLoaded &&
          this.state.isPotentialCandidateUpdatePopUpOpen &&
          this.renderPotentialCandidateUpdatePopUp()}
        <div
          className="container-fluid  h-100"
          style={{ height: '100%', padding: '0px' }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <IconButton
              aria-label="back"
              color="primary"
              disabled={
                !this.state.projectList ||
                this.state.projectList.length === 0 ||
                !this.state.selectedProject.id
              }
              onClick={() =>
                onNextPreviousItemClick(
                  false,
                  this.state.selectedProject.id,
                  this.state.projectList,
                  'displayedProjectIndexes',
                  this.getProjectById
                )
              }
            >
              <ArrowBackIosIcon />
            </IconButton>
            <div style={{ width: '33%' }}>
              <MainSearchField
                placeholder="Bitte suchen Sie nach einem Auftrag"
                autoFocus={this.state.searchFieldAutoFocus}
                onChange={this.mainSearchFieldChange}
                defaultValue={UTILS.getURLParam(this.props.history, 'sq')}
                onFocus={() => {
                  // if (this.state.projectList.length) console.log('Onfocus');
                  this.setState({ showResultList: true });
                  this.getProjectListBySearchAPICall(this.state.searchTerm);
                }}
                error={this.state.searchFieldValidationError}
                helperText={
                  this.state.searchFieldValidationError &&
                  TEXT.search.searchFieldErrorMessage
                }
                onExtendedSearchClick={() =>
                  this.setState({
                    isDetailSearchDrawerOpen: true
                  })
                }
              />
            </div>
            <IconButton
              aria-label="Forward"
              color="primary"
              disabled={
                !this.state.projectList ||
                this.state.projectList.length === 0 ||
                !this.state.selectedProject.id
              }
              onClick={() =>
                onNextPreviousItemClick(
                  true,
                  this.state.selectedProject.id,
                  this.state.projectList,
                  'displayedProjectIndexes',
                  this.getProjectById
                )
              }
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <Collapse
            direction="down"
            mountOnEnter
            in={this.state.showResultList}
            {...(this.state.showProjectDetails
              ? { timeout: 800 }
              : { timeout: 800 })}
          >
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="ProjecResults col-12">
                      <ProjectResult
                        projectList={this.state.projectList}
                        handleProjectSelected={this.handleProjectSelected}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
          <Fade
            direction="up"
            in={this.state.showProjectDetails}
            mountOnEnter
            unmountOnExit
            {...(this.state.showProjectDetails ? { timeout: 1000 } : {})}
          >
            <SingleProjectResult
              showProjectDetailsColumn={this.state.showProjectDetailsColumn}
              currentTab={this.state.currentTab}
              selectedProject={this.state.selectedProject}
              liftUpStateToProjectArchive={this.liftUpStateToProjectArchive}
              liftUpUpdatedPotentialCandidates={
                this.liftUpUpdatedPotentialCandidates
              }
              liftUpAllPotentialCandidates={this.liftUpAllPotentialCandidates}
              liftUpStateToApp={this.props.liftUpStateToApp}
              refreshProject={this.refreshProject}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              handleOpenPotentialCandidateUpdatePopUp={
                this.handleOpenPotentialCandidateUpdatePopUp
              }
              employees={this.state.employees}
              statusFilterCandidateId={this.state.statusFilterCandidateId}
            />
          </Fade>
          {this.state.showProjectDetails && (
            <Button
              variant="fab"
              color="primary"
              aria-label="Collapse"
              style={collapseIconStyle}
              onClick={this.handleCollapseIconButtonClick}
            >
              {this.state.showProjectDetailsColumn ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </Button>
          )}
          <div style={{ height: '100px' }} className="spacer" />
          {/* BEGIN Button */}
          {this.state.currentTab === 3 && (
            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}
              disabled={
                this.state.currentTab !== 3 ||
                !this.state.selectedProject.isEditable
              }
              variant="fab"
              color="primary"
              aria-label="Add"
              style={{ position: 'absolute', bottom: '30px', right: '30px' }}
              onClick={() =>
                this.setState({ isActionProtocolCreationPopUpOpen: true })
              }
            >
              <AddIcon />
            </Button>
          )}
          <Popover
            open={this.state.isPopoverOpen}
            anchorEl={this.anchorEl}
            anchorReference="anchorEl"
            // anchorPosition={{ top: positionTop, left: positionLeft }}
            onClose={() => this.setState({ isPopoverOpen: false })}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            {this.state.currentTab === 3 && (
              <MenuItem
                //  key={TEXT.companyArchive.addButton.createCompanyEntry}
                onClick={() =>
                  this.setState({
                    // isCreateCompanyPopUpOpen: true,
                    isActionProtocolCreationPopUpOpen: true
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                Neuer Eintrag: Auftragssteuerung
                {/* <p>Text</p>{//TEXT.companyArchive.addButton.createCompanyEntry} */}
              </MenuItem>
            )}
            <Divider />
          </Popover>
          {/* END Button */}
        </div>
        {this.state.employees.length > 0 && this.state.partners.length > 0 && (
          <ProjectDetailSearch
            liftUpStateToApp={this.props.liftUpStateToApp}
            isDrawerOpen={this.state.isDetailSearchDrawerOpen}
            handleDrawerClose={() =>
              this.setState({
                isDetailSearchDrawerOpen: false
              })
            }
            updateProjectArchive={detailSearchResult =>
              this.setState({
                isDetailSearchDrawerOpen: false,
                showResultList: true,
                projectList: detailSearchResult
              })
            }
            employeesList={this.state.employees}
            partnersList={this.state.partners}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
          />
        )}
      </div>
    );
  }
}
ProjectArchive.defaultProps = {
  projectMarkListId: undefined
};
ProjectArchive.propTypes = {
  projectMarkListId: PropTypes.string,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(withUser(ProjectArchive));
