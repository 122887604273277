import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

export function resetCandidateStatus(candidateId) {
  const payload = {
    candidateId,
    updateData: {
      isAvailable: true,
      statusType: 'available',
      statusExpirationDate: ''
    }
  };
  return post(CONSTANTS.updateCandidateURL, payload);
}

export function addCandidateToProjectApplication(projectId, candidateId) {
  const payload = {
    projectId,
    potentialCandidateData: {
      candidate: candidateId,
      applied: true,
      proposed: false
    }
  };
  return post(CONSTANTS.addPotentialCandidateURL, payload);
}

export function addCandidateToProject(projectId, candidateId) {
  const payload = {
    projectId,
    potentialCandidateData: {
      candidate: candidateId,
      applied: false,
      proposed: true
    }
  };
  return post(CONSTANTS.addPotentialCandidateURL, payload);
}

export function editCandidateStatus(
  candidateId,
  statusType,
  statusExpirationDate
) {
  const payload = {
    candidateId,
    updateData: {
      isAvailable: false,
      statusType,
      statusExpirationDate
    }
  };
  return post(CONSTANTS.updateCandidateURL, payload);
}

export function deleteCandidateDocuments(candidateId) {
  const payload = {
    candidateId
  };
  return post(CONSTANTS.deleteCandidateDocumentsURL, payload);
}

export function deleteCandidate(candidateId, force) {
  const payload = {
    candidateId,
    force
  };
  return post(CONSTANTS.deleteCandidateURL, payload);
}

export function deleteCandidateImage(candidateId) {
  const payload = {
    candidateId
  };
  return post(CONSTANTS.deleteCandidateImageURL, payload);
}

export function updateCandidatePicture(candidateId, acceptedFiles) {
  const request = new FormData();
  acceptedFiles.forEach((file, index) => {
    request.append(`file-${index}`, file);
  });
  request.append('candidateId', candidateId);

  return post(CONSTANTS.updateCandidatePictureURL, request);
}

export function getCandidatePdfDocument(candidateId) {
  const payload = {
    candidateId
  };
  return post(CONSTANTS.getCandidatePdfDocumentURL, payload);
}
