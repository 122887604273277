import MUIDataTable from 'mui-datatables';
import React, { PureComponent } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import { th } from 'date-fns/esm/locale';
import * as CONSTANTS from '../constants';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class SafeMuiDatatable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.columns = [];
    this.muiTheme = createMuiTheme(this.props.muiTheme);
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.initialFilter) {
      const columns = Object.keys(this.props.initialFilter);
      columns.forEach(columnName => {
        const columnIndex = this.props.columns.findIndex(
          c => c.name === columnName
        );
        if (!columnIndex || columnIndex < 0) {
          console.error(`Column ${columnName} not found`);
          return;
        }
        if (Array.isArray(this.props.initialFilter[columnName]))
          this.tableRef.current.filterUpdate(
            columnIndex,
            this.props.initialFilter[columnName],
            'multiselect'
          );
        else
          this.tableRef.current.filterUpdate(
            columnIndex,
            this.props.initialFilter[columnName]
          );
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.length !== this.props.data.length) {
      this.tableRef.current.changePage(0);
    }
  }

  replaceUndefinedWithEmptyString(data) {
    const formatedData = [];
    data.forEach(row => {
      const formatedRow = [];
      row.forEach(cell => {
        formatedRow.push(cell || '');
      });
      formatedData.push(formatedRow);
    });
    return formatedData;
  }

  prepareColumns(columns) {
    if (!this.props.tableName) return columns;

    const mappedColumns = columns;
    let savedColumnSettings = localStorage.getItem(this.props.tableName);
    if (savedColumnSettings) {
      savedColumnSettings = JSON.parse(savedColumnSettings);
      savedColumnSettings = savedColumnSettings.columnSettings;
    }
    console.log('columns', columns);

    // in case settings are found in local storage
    if (savedColumnSettings && savedColumnSettings.length > 0) {
      columns.forEach((column, index) => {
        if (savedColumnSettings.indexOf(column.name) === -1) {
          if (column.options && column.options.display !== 'excluded')
            mappedColumns[index].options.display = false;
          else if (!column.options) {
            mappedColumns[index].options = {};
            mappedColumns[index].options.display = false;
          }
        } else {
          if (!mappedColumns[index].options) mappedColumns[index].options = {};
          mappedColumns[index].options.display = true;
        }
      });
    }
    this.columns = mappedColumns;
    console.log('mappedColumns', mappedColumns);

    return mappedColumns;
  }

  onTableChange = (action, tableState) => {
    console.log('tableState', action, tableState);
    if (this.props.onTableChange) this.props.onTableChange(action, tableState);
    if (!this.props.tableName) return;
    const rowsPerPage = tableState.rowsPerPage;
    const columnSettings = [];
    tableState.columns.forEach(column => {
      if (column.display === 'true' || column.display === true) {
        columnSettings.push(column.name);
      }
    });
    const tableSettings = {
      rowsPerPage,
      columnSettings
    };

    localStorage.setItem(this.props.tableName, JSON.stringify(tableSettings));
  };

  getOptions() {
    // TODO check if everything still works
    // const copyOfOptions = JSON.parse(JSON.stringify(this.props.options));
    const copyOfOptions = Object.assign({}, this.props.options);
    console.log('copyOfOptions', copyOfOptions);
    if (this.props.onRowClick) {
      copyOfOptions.onRowClick = this.props.onRowClick;
    }
    if (!this.props.tableName) return copyOfOptions;
    let savedColumnSettings = localStorage.getItem(this.props.tableName);
    if (savedColumnSettings)
      savedColumnSettings = JSON.parse(savedColumnSettings);
    if (savedColumnSettings && savedColumnSettings.rowsPerPage)
      copyOfOptions.rowsPerPage = savedColumnSettings.rowsPerPage;
    copyOfOptions.onTableChange = this.onTableChange;
    copyOfOptions.onCellClick = this.props.onCellClick || this.onCellClick;
    // console.log('debug getOptions onCellClick')
    return copyOfOptions;
  }

  render() {
    return (
      <MuiThemeProvider theme={this.muiTheme}>
        <MUIDataTable
          {...this.props}
          title={this.props.title}
          data={this.replaceUndefinedWithEmptyString(this.props.data)}
          columns={this.prepareColumns(this.props.columns)}
          options={this.getOptions()}
          innerRef={this.tableRef}
          // options={this.props.options}
        />
      </MuiThemeProvider>
    );
  }
}

SafeMuiDatatable.defaultProps = {
  options: {
    filterType: 'checkbox',
    responsive: 'scroll',
    selectableRows: false,
    fixedHeader: true,
    filter: true,
    search: true,
    print: true,
    download: true,
    viewColumns: true,
    textLabels: {
      body: {
        noMatch: 'Keine passenden Einträge gefunden',
        toolTip: 'Sort'
      },
      pagination: {
        next: 'Nächste Seite',
        previous: 'Vorherige Seite',
        rowsPerPage: 'Zeilen pro Seite:',
        displayRows: 'von'
      },
      toolbar: {
        search: 'Suche',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table'
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET'
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns'
      },
      selectedRows: {
        text: 'rows(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows'
      }
    }
  },
  muiTheme: {
    palette: THEME.app.palette,
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        }
      }
    }
  },
  onRowClick: undefined,
  tableName: undefined,
  initialFilter: undefined, // {columnName: "Standort"}
  onTableChange: undefined,
  onCellClick: undefined
};

SafeMuiDatatable.propTypes = {
  options: PropTypes.object,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  muiTheme: PropTypes.object,
  onRowClick: PropTypes.func,
  tableName: PropTypes.string,
  initialFilter: PropTypes.object,
  onTableChange: PropTypes.func,
  onCellClick: PropTypes.func
};
export default SafeMuiDatatable;
