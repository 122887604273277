const tooltips = {
  companyArchive: {
    editButton: 'Datensatz ändern',
    showHistoryButton: 'Änderungsverlauf anzeigen',
    editCompanyStatusButton: 'Status ändern',
    markButton: 'Unternehmen markieren',
    deleteButton: 'Unternehmen löschen'
  },
  projectArchive: {
    editButton: 'Datensatz ändern',
    showHistoryButton: 'Änderungsverlauf anzeigen',
    editCompanyStatusButton: 'Status ändern',
    markButton: 'Auftrag markieren',
    deleteButton: 'Auftrag löschen'
  },
  candidateArchive: {
    editButton: 'Datensatz ändern',
    showHistoryButton: 'Änderungsverlauf anzeigen',
    editCandidateStatusButton: 'Status ändern',
    resetCandidateStatusButton: 'Status zurücksetzen',
    markButton: 'Kandidat markieren',
    showAddCandidateToProjectButton: 'Kandidat für Auftrag vorschlagen',
    showAddCandidateToProjectApplicationButton: 'Kandidat für Auftrag beworben',
    downloadPersonalDataSheet: 'Personalbogen herunterladen',
    downloadISheet: 'I-Blatt herunterladen',
    downloadPSheet: 'P-Blatt herunterladen',
    deletePicture: 'Kandidaten Profilbild löschen',
    deleteCandidateData: 'Kandidaten Daten löschen',
    deleteCandidate: 'Kandidat löschen'
  }
};
module.exports = tooltips;
