import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// import { hot } from 'react-hot-loader'; // , setConfig

import './App.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import de from 'date-fns/locale/de';
import UserContext from './utils/UserContext';

import ErrorPage from './pages/ErrorPage';
import Results from './pages/Results';
import IncomingCandidates from './pages/IncomingCandidates';
import Search from './pages/Search';
import Admin from './pages/Admin';
import MarkLists from './pages/MarkLists';
import Register from './pages/Register';
import RegisterTan from './pages/RegisterTan';
import CompanyArchive from './pages/CompanyArchive';
import ProjectArchive from './pages/ProjectArchive';
import CandidateArchive from './pages/CandidateArchive';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import IncomingMatchingProfiles from './pages/IncomingMatchingProfiles';
import NotificationPage from './pages/NotificationPage';
import ReminderPage from './pages/ReminderPage';
import PageContainer from './PageContainer';
import ReleaseNotePopUp from './general/releaseNote/ReleaseNotePopUp';

import LoadSearchBox from './components/LoadSearchBox';
import Sidebar from './components/Sidebar';
import MainMenuDrawer from './components/MainMenuDrawer';
import ErrorBoundary from './components/ErrorBoundary';
// import InfoSnackBar from './generic_components/InfoSnackbar';
// DatePickers

import * as CONSTANTS from './constants';
import * as CONFIG from './config';
import * as UTILS from './utils/utilFunctions';
import TEXT from './text';

const systemPartner = CONSTANTS.partner;
const THEME = require('./theme')[systemPartner];

const theme = createMuiTheme(THEME.app);

const rootElement = document.documentElement;
rootElement.style.setProperty('--status-green', '#00c767');
rootElement.style.setProperty('--status-red', '#c7000e');
rootElement.style.setProperty('--teal', THEME.app.palette.primary.main);
rootElement.style.setProperty('--grey', '#717473');
rootElement.style.setProperty('--dred', '#762128');
rootElement.style.setProperty('--blue', THEME.app.palette.primary.main);
rootElement.style.setProperty('--background-secondary', '#00c7671c');
rootElement.style.setProperty('--secondary', THEME.app.palette.secondary.main);
rootElement.style.setProperty(
  '--light-primary',
  THEME.app.palette.primary.light
);

// wraps the routed component in an ErrorBoundary
// eslint-disable-next-line react/prop-types
const SafeRoute = ({ render, ...props }) => (
  <Route
    {...props}
    render={routeProps => <ErrorBoundary>{render(routeProps)}</ErrorBoundary>}
  />
);

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // the array for rest calls without indizes
      searchArray: {},
      // the array for search with indizes for focusmanagement
      searchQuery: [
        {
          column: [
            {
              searchTerm: '',
              isSemantic: true,
              ID: new Date().getTime(),
              hasError: false
            }
          ],
          ID: new Date().getTime()
        }
      ],
      companyMarkListId: undefined,
      projectMarkListId: undefined,
      candidateMarkListId: undefined,
      createNewCandidate: false,
      suggestions: [],
      postalCode: '',
      resultCount: 0,
      isShowResultsButtonDisabled: true,
      loggedIn: false,
      user: {},
      sidePanelContent: undefined,
      markedCount: 0,
      snackbarOpen: false,
      snackbarText: '',
      // Dialogs
      saveSearchBoxOpen: false,
      loadSearchBoxOpen: false,
      sendOrderBoxOpen: false,
      mainMenuOpen: false,
      anchorEl: null, // anchor Element for menu
      mainAnchorEl: null, // anchor Element for Mainmenu

      // Loading indicators
      showLoadingIndicatorGlobal: false,
      showLoadingIndicatorLoggedIn: false,
      showLoadingIndicatorGlobalText: '',
      locationPath: '/',
      ratings: {
        rating1: 3,
        rating2: 3,
        rating3: 3,
        rating4: 3
      },
      resultsCalledFrom: '',
      notificationCount: 0,
      releaseNote: {},
      distance: 0,
      geoLocation: {},
      lat: 0,
      lon: 0,
      geoLocationSearchValue: ''
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
    this.UTILS_logoutLocalUser = UTILS.logoutLocalUser.bind(this);
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleSaveSearchBoxOpen = () => {
    this.setState({ saveSearchBoxOpen: true, mainAnchorEl: null });
  };

  handleLoadSearchBoxOpen = () => {
    this.setState({ loadSearchBoxOpen: true, mainAnchorEl: null });
  };

  handleSaveSearchBoxClose = success => {
    if (success) {
      this.handleSnackbarOpen(TEXT.searchPage.saveSearchInfoMessage);
    }
    this.setState({ saveSearchBoxOpen: false });
  };

  countryNameMap(country) {
    switch (country) {
      case 'germany':
        return 'Deutschland';
      case 'switzerland':
        return 'Schweiz';
      case 'austria':
        return 'Österreich';
      default:
        return country;
    }
  }

  handleLoadSearchBox = (
    searchQuery,
    geoLocation,
    distance,
    postalCode,
    filters
  ) => {
    const stateUpdate = {
      searchQuery,
      loadSearchBoxOpen: false,
      postalCode,
      resultsCalledFrom: 'loadedSearch',
      distance,
      filters
    };
    if (geoLocation) {
      stateUpdate.geoLocation = geoLocation;
      stateUpdate.geoLocationSearchValue = `${geoLocation.postalCode} -- ${
        geoLocation.city
      } -- ${this.countryNameMap(geoLocation.country)}`;
    }
    this.setState(stateUpdate);
  };

  handleLoadSearchBoxClose = () => {
    this.setState({ loadSearchBoxOpen: false });
  };

  handleSnackbarOpen = description => {
    this.setState({ snackbarOpen: true, snackbarText: description });
  };

  closeMenu = () => {
    this.setState({ mainAnchorEl: null });
  };

  liftUpStateToApp = (stateNameOrObject, value, callback = () => {}) => {
    if (typeof stateNameOrObject === 'object') {
      Object.keys(stateNameOrObject).forEach(key => {
        this.setState({
          [key]: stateNameOrObject[key]
        });
      });
    } else if (stateNameOrObject === 'loggedIn' && !value)
      this.UTILS_logoutLocalUser();
    else this.setState({ [stateNameOrObject]: value }, callback);
  };

  resetSearch = () => {
    this.setState({
      mainAnchorEl: null,
      postalCode: '',
      resultCount: 0,
      isShowResultsButtonDisabled: true,
      searchQuery: [
        {
          column: [
            {
              searchTerm: '',
              isSemantic: true,
              ID: new Date().getTime(),
              hasError: false
            }
          ],
          ID: new Date().getTime()
        }
      ],
      suggestions: [],
      distance: 0,
      geoLocation: {},
      geoLocationSearchValue: ''
    });
  };

  showResultButtonHandler = (
    searchQuery,
    ratings,
    postalCode,
    distance,
    lat,
    lon
  ) => {
    const searchArray = [];
    searchQuery.forEach((column, columnCount) => {
      if (searchQuery[columnCount].column[0].length !== 0) {
        const tempArray = searchQuery[columnCount].column.slice(0);
        tempArray.splice(-1, 1);
        searchArray.push(tempArray);
      }
    });

    this.setState({
      searchArray,
      ratings,
      postalCode,
      distance,
      lat,
      lon,
      resultsCalledFrom: 'searchPage'
    });
  };

  logout() {
    this.setState({ showLoadingIndicatorGlobal: true });
    console.log('API /logout request');
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.logoutURL;
    $.ajax({
      url,
      method: 'POST',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status, xhr) => {
        console.log('API logout response status code', xhr.status);

        this.setState({ showLoadingIndicatorGlobal: false });
        if (xhr.status === 200) {
          this.setState({ loggedIn: false, user: { role: [] } });
          this.UTILS_logoutLocalUser();
        }
      })
      .fail(err => {
        this.errorHandling(err, url, this.liftUpStateToApp);
        this.setState({ showLoadingIndicatorGlobal: false });
      });
  }

  getAppBarStyle() {
    switch (window.location.pathname) {
      case '/candidates':
        return THEME.appBar.candidates;
      case '/projects':
        return THEME.appBar.projects;
      case '/companies':
        return THEME.appBar.companies;
      default:
        return THEME.appBar;
    }
  }

  getThemeName() {
    switch (window.location.pathname) {
      case '/candidates':
        return 'Kandidaten';
      case '/projects':
        return 'Aufträge';
      case '/companies':
        return 'Unternehmen';
      case '/reminders':
        return 'Wiedervorlagen';
      case '/notifications':
        return 'Benachrichtigungen';
      case '/markLists':
        return 'Markierungen';
      default:
        return THEME.name;
    }
  }

  handleCloseReleaseNotePopUp = () => {
    this.setState({ releaseNote: {} });
  };

  render() {
    const sendOrderBadgeContent = this.state.markedCount;
    const { mainAnchorEl } = this.state;

    const currentPage = window.location.pathname;

    const showMenuBar = !CONFIG.pagesWithoutLogin.includes(currentPage);
    const showMenuBarLogout = ['/register', '/registerTan'].includes(
      currentPage
    );
    const showAdminLink = true;
    const showReleaseNotePopUp =
      this.state.releaseNote &&
      this.state.releaseNote.content &&
      !!this.state.releaseNote.content.length;
    return (
      <UserContext.Provider value={this.state.user}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
            <div className="App" style={{ flexGrow: 1 }}>
              <CssBaseline />
              {showReleaseNotePopUp && (
                <ReleaseNotePopUp
                  icon={this.state.releaseNote.icon}
                  headline={this.state.releaseNote.headline}
                  content={this.state.releaseNote.content}
                  subContent={this.state.releaseNote.subContent}
                  adminContent={this.state.releaseNote.adminContent}
                  open={showReleaseNotePopUp}
                  handleClose={this.handleCloseReleaseNotePopUp}
                />
              )}
              <Router>
                <PageContainer
                  loggedIn={this.state.loggedIn}
                  locationPath={this.state.locationPath}
                  liftUpStateToApp={this.liftUpStateToApp}
                >
                  <div>
                    {showMenuBar && (
                      <div>
                        <AppBar position="fixed" style={this.getAppBarStyle()}>
                          <Toolbar>
                            {/* New Menu in all Sizes */}
                            {this.state.user.role !== 'client' && (
                              <div>
                                <IconButton
                                  className="mainMenuDrawer"
                                  color="inherit"
                                  onClick={() => {
                                    this.setState({
                                      mainMenuOpen: true
                                    });
                                  }}
                                >
                                  <i className="material-icons">menu</i>
                                </IconButton>

                                <MainMenuDrawer
                                  open={this.state.mainMenuOpen}
                                  page={currentPage}
                                  liftUpStateToApp={this.liftUpStateToApp}
                                />
                              </div>
                            )}
                            {/* Mobile Menu start */}
                            {!isWidthUp('sm', this.props.width) &&
                            !showMenuBarLogout &&
                            !['/settings', '/markLists'].includes(
                              currentPage
                            ) ? (
                              <div>
                                <IconButton
                                  className="mainMenu"
                                  color="inherit"
                                  aria-owns={mainAnchorEl ? 'mainMenu' : null}
                                  aria-haspopup="true"
                                  onClick={event => {
                                    this.setState({
                                      mainAnchorEl: event.currentTarget
                                    });
                                  }}
                                >
                                  <i className="material-icons">menu</i>
                                </IconButton>

                                <Menu
                                  id="mainMenu"
                                  anchorEl={mainAnchorEl}
                                  open={Boolean(mainAnchorEl)}
                                  onClose={() => {
                                    this.setState({ mainAnchorEl: null });
                                  }}
                                >
                                  {currentPage === '/search' && (
                                    <div>
                                      <MenuItem onClick={this.resetSearch}>
                                        <i className="material-icons">
                                          autorenew
                                        </i>
                                        &nbsp;
                                        {TEXT.navBar.resetSearch}
                                      </MenuItem>

                                      <MenuItem
                                        onClick={this.handleLoadSearchBoxOpen}
                                      >
                                        <i className="material-icons">
                                          open_in_browser
                                        </i>
                                        &nbsp;
                                        {TEXT.navBar.loadSearch}
                                      </MenuItem>

                                      <MenuItem
                                        component={Link}
                                        to="/markLists"
                                        color="inherit"
                                        onClick={this.closeMenu}
                                      >
                                        <i className="material-icons">list</i>
                                        &nbsp;
                                        {TEXT.navBar.markLists}
                                      </MenuItem>
                                    </div>
                                  )}

                                  {currentPage === '/results' && (
                                    <div>
                                      <MenuItem
                                        component={Link}
                                        to="/search"
                                        color="inherit"
                                      >
                                        <i className="material-icons">search</i>
                                        &nbsp;
                                        {TEXT.navBar.changeSearch}
                                      </MenuItem>

                                      {this.state.resultsCalledFrom !==
                                        'markLists' && (
                                        <MenuItem
                                          onClick={this.handleSaveSearchBoxOpen}
                                        >
                                          <i className="material-icons">save</i>
                                          &nbsp;
                                          {`${TEXT.navBar.saveSearch}`}
                                        </MenuItem>
                                      )}
                                      {this.state.user.role === 'client' ? (
                                        <MenuItem
                                          onClick={() =>
                                            this.setState({
                                              sendOrderBoxOpen: true,
                                              mainAnchorEl: null
                                            })
                                          }
                                        >
                                          <i className="material-icons">
                                            people
                                          </i>
                                          &nbsp;
                                          {`${
                                            TEXT.navBar.sendOrder
                                          } (${sendOrderBadgeContent}/10)`}
                                        </MenuItem>
                                      ) : (
                                        ''
                                      )}
                                      <MenuItem
                                        onClick={this.handleLoadSearchBoxOpen}
                                      >
                                        <i className="material-icons">
                                          open_in_browser
                                        </i>
                                        &nbsp;
                                        {TEXT.navBar.loadSearch}
                                      </MenuItem>

                                      <MenuItem
                                        component={Link}
                                        to="/markLists"
                                        color="inherit"
                                        onClick={this.closeMenu}
                                      >
                                        <i className="material-icons">list</i>
                                        &nbsp;
                                        {TEXT.navBar.markLists}
                                      </MenuItem>

                                      {showAdminLink && (
                                        <Button
                                          component={Link}
                                          to="/settings"
                                          color="inherit"
                                          onClick={this.closeMenu}
                                        >
                                          <i className="material-icons">
                                            settings
                                          </i>
                                          &nbsp;
                                          {TEXT.navBar.admin}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </Menu>
                              </div>
                            ) : (
                              ''
                            )}
                            {/* Mobile end */}
                            <Button
                              component={Link}
                              to="/search"
                              color="inherit"
                            >
                              <img
                                src={THEME.logo}
                                alt={TEXT.imageAlt}
                                className="headerLogo"
                                style={{ maxHeight: '48px' }}
                              />
                            </Button>{' '}
                            <Typography
                              variant="h6"
                              color="inherit"
                              style={{ flex: 1 }}
                            >
                              <span>{this.getThemeName()}</span>
                            </Typography>
                            {isWidthUp('sm', this.props.width) &&
                            currentPage === '/results' ? (
                              <Button
                                component={Link}
                                to="/search"
                                color="inherit"
                                onClick={() => {
                                  console.log('HALLOOOOO');
                                  this.setState({ markedCount: 0 });
                                }}
                              >
                                {TEXT.navBar.changeSearch}
                                &nbsp;
                                <i className="material-icons">search</i>
                              </Button>
                            ) : (
                              ''
                            )}
                            {this.state.resultsCalledFrom !== 'markLists' &&
                            isWidthUp('sm', this.props.width) &&
                            currentPage === '/results' ? (
                              <Button
                                color="inherit"
                                onClick={this.handleSaveSearchBoxOpen}
                              >
                                {TEXT.navBar.saveSearch}
                                &nbsp;
                                <i className="material-icons">save</i>
                              </Button>
                            ) : (
                              ''
                            )}
                            {isWidthUp('sm', this.props.width) &&
                            currentPage === '/search' ? (
                              <Button
                                color="inherit"
                                onClick={this.resetSearch}
                              >
                                {TEXT.navBar.resetSearch}
                                &nbsp;
                                <i className="material-icons">autorenew</i>
                              </Button>
                            ) : (
                              ''
                            )}
                            {isWidthUp('sm', this.props.width) &&
                              ['/results', '/search'].includes(currentPage) && (
                                <Button
                                  color="inherit"
                                  onClick={this.handleLoadSearchBoxOpen}
                                >
                                  {TEXT.navBar.loadSearch}
                                  &nbsp;
                                  <i className="material-icons">
                                    open_in_browser
                                  </i>
                                </Button>
                              )}
                            {showMenuBarLogout && (
                              <Button
                                color="inherit"
                                onClick={() => {
                                  this.logout();
                                }}
                              >
                                {TEXT.navBar.logout}
                              </Button>
                            )}
                            {isWidthUp('sm', this.props.width) &&
                              ['/results', '/search'].includes(currentPage) && (
                                <Button
                                  component={Link}
                                  to="/markLists"
                                  color="inherit"
                                >
                                  {TEXT.navBar.markLists}
                                  &nbsp;
                                  <i className="material-icons">list</i>
                                </Button>
                              )}
                            {isWidthUp('sm', this.props.width) &&
                              currentPage === '/candidates' && (
                                <Button
                                  component={Link}
                                  to="/search"
                                  color="inherit"
                                >
                                  {TEXT.navBar.search}
                                </Button>
                              )}
                            {isWidthUp('sm', this.props.width) &&
                            currentPage === '/results' &&
                            this.state.user.role === 'client' &&
                            !showMenuBarLogout ? (
                              <Badge
                                badgeContent={sendOrderBadgeContent}
                                color="secondary"
                              >
                                <Button
                                  disabled={this.state.markedCount === 0}
                                  color="inherit"
                                  onClick={() =>
                                    this.setState({ sendOrderBoxOpen: true })
                                  }
                                >
                                  {`${
                                    TEXT.navBar.sendOrder
                                  } (${sendOrderBadgeContent}/10)`}
                                  &nbsp;
                                  <i className="material-icons">people</i>
                                </Button>
                              </Badge>
                            ) : (
                              ''
                            )}
                            <IconButton
                              component={Link}
                              to="/notifications"
                              color="inherit"
                            >
                              {this.state.notificationCount !== 0 ? (
                                <Badge
                                  badgeContent={this.state.notificationCount}
                                  color="error"
                                >
                                  <i className="material-icons">
                                    notifications
                                  </i>
                                </Badge>
                              ) : (
                                <i className="material-icons">notifications</i>
                              )}
                            </IconButton>
                            {!showMenuBarLogout && (
                              <IconButton
                                aria-owns={
                                  this.state.anchorEl ? 'menu-appbar' : null
                                }
                                aria-haspopup="true"
                                onClick={event => {
                                  this.setState({
                                    anchorEl: event.currentTarget
                                  });
                                }}
                                color="inherit"
                              >
                                <i className="material-icons">account_circle</i>
                              </IconButton>
                            )}
                            <Menu
                              id="menu-appbar"
                              anchorEl={this.state.anchorEl}
                              open={Boolean(this.state.anchorEl)}
                              onClose={() => {
                                this.setState({ anchorEl: null });
                              }}
                            >
                              {showAdminLink && (
                                <MenuItem
                                  component={Link}
                                  to="/settings"
                                  color="inherit"
                                  onClick={() => {
                                    this.setState({ anchorEl: null });
                                  }}
                                >
                                  <i className="material-icons">settings</i>
                                  &nbsp;
                                  {TEXT.navBar.admin}
                                </MenuItem>
                              )}

                              <MenuItem
                                onClick={() => {
                                  this.setState({ anchorEl: null });
                                  this.logout();
                                }}
                              >
                                <i className="material-icons">exit_to_app</i>
                                &nbsp;
                                {TEXT.navBar.logout}
                              </MenuItem>
                            </Menu>
                          </Toolbar>
                        </AppBar>
                        <div style={{ height: 64 }}>
                          {/* Shim - Not visible */}
                        </div>
                      </div>
                    )}
                  </div>
                  {(this.state.showLoadingIndicatorGlobal ||
                    this.state.showLoadingIndicatorLoggedIn) && (
                    <div id="loading-indicator-overlay">
                      <div
                        id="loading-indicator-container"
                        className="text-center"
                      >
                        <CircularProgress
                          className="mb-3"
                          size={60}
                          thickness={5}
                        />
                        {this.state.showLoadingIndicatorGlobalText !== '' && (
                          <h3 style={{ color: '#fff' }}>
                            {this.state.showLoadingIndicatorGlobalText}
                          </h3>
                        )}
                      </div>
                    </div>
                  )}
                  {/* <InfoSnackBar infoText="Hinweis 02.01.2019: Die Datenmigration ist bis zum heutigen Abend abgeschlossen. Momentane Änderungen an den Daten werden nicht berücksichtigt. Vielen Dank für Ihr Verständnis." /> */}
                  <LoadSearchBox
                    open={this.state.loadSearchBoxOpen}
                    handleClose={this.handleLoadSearchBoxClose}
                    handleLoad={this.handleLoadSearchBox}
                    liftUpStateToApp={this.liftUpStateToApp}
                  />
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    open={this.state.snackbarOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClose}
                    ContentProps={{
                      'aria-describedby': 'message-id'
                    }}
                    message={
                      <span id="message-id">{this.state.snackbarText}</span>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className="closeSnackbar"
                        onClick={this.handleSnackbarClose}
                      >
                        <i className="material-icons">close</i>
                      </IconButton>
                    ]}
                  />
                  <SafeRoute
                    exact
                    path="/"
                    render={props => (
                      <Login
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                        loggedIn={this.state.loggedIn}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/register"
                    render={props => (
                      <Register
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                      />
                    )}
                  />

                  <SafeRoute
                    exact
                    path="/registerTan"
                    render={props => (
                      <RegisterTan
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                      />
                    )}
                  />

                  <SafeRoute
                    exact
                    path="/search"
                    render={props => (
                      <Search
                        {...props}
                        searchQuery={this.state.searchQuery}
                        suggestions={this.state.suggestions}
                        liftUpStateToApp={this.liftUpStateToApp}
                        showResultButtonHandler={this.showResultButtonHandler}
                        postalCode={this.state.postalCode}
                        distance={this.state.distance}
                        geoLocation={this.state.geoLocation}
                        geoLocationSearchValue={
                          this.state.geoLocationSearchValue
                        }
                        resultCount={this.state.resultCount}
                        isShowResultsButtonDisabled={
                          this.state.isShowResultsButtonDisabled
                        }
                      />
                    )}
                  />

                  <SafeRoute
                    exact
                    path="/settings"
                    render={props => (
                      <Admin
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/companies"
                    render={props => (
                      <CompanyArchive
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                        companyMarkListId={this.state.companyMarkListId}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/projects"
                    render={props => (
                      <ProjectArchive
                        {...props}
                        projectMarkListId={this.state.projectMarkListId}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                        liftUpStateToApp={this.liftUpStateToApp}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/candidates"
                    render={props => (
                      <CandidateArchive
                        {...props}
                        candidateMarkListId={this.state.candidateMarkListId}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                        liftUpStateToApp={this.liftUpStateToApp}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/incomingCandidates"
                    render={props => (
                      <IncomingCandidates
                        {...props}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                        liftUpStateToApp={this.liftUpStateToApp}
                        createNewCandidate={this.state.createNewCandidate}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/notifications"
                    render={props => (
                      <NotificationPage
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/reminders"
                    render={props => (
                      <ReminderPage
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/markLists"
                    render={props => (
                      <MarkLists
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/incomingMatchingProfiles"
                    render={props => (
                      <IncomingMatchingProfiles
                        {...props}
                        liftUpStateToApp={this.liftUpStateToApp}
                        handleSnackbarOpen={this.handleSnackbarOpen}
                      />
                    )}
                  />
                  <SafeRoute
                    exact
                    path="/imprint"
                    render={props => <Imprint {...props} />}
                  />
                  <SafeRoute
                    exact
                    path="/privacy"
                    render={props => <Privacy {...props} />}
                  />

                  <SafeRoute
                    path="/results"
                    render={props => (
                      <div className="row static-container">
                        <div className="col-lg-12 col-xl-8 col-sm-12 no-padding-right">
                          <Results
                            {...props}
                            width={this.props.width}
                            query={this.state.searchArray}
                            sendOrderBoxOpen={this.state.sendOrderBoxOpen}
                            rating={this.state.ratings}
                            distance={this.state.distance}
                            lat={this.state.lat}
                            lon={this.state.lon}
                            geoLocationId={this.state.geoLocation.id}
                            calledFrom={this.state.resultsCalledFrom}
                            postalCode={this.state.postalCode}
                            liftUpStateToApp={this.liftUpStateToApp}
                            markedCount={this.state.markedCount}
                            handleSnackbarOpen={this.handleSnackbarOpen}
                            filters={this.state.filters}
                            // saveSearchBox
                            saveSearchBoxOpen={this.state.saveSearchBoxOpen}
                            handleSaveSearchBoxClose={
                              this.handleSaveSearchBoxClose
                            }
                          />
                        </div>
                        {isWidthUp('lg', this.props.width) ? (
                          <div className="col-4 no-padding-left">
                            <div className="container-fluid container-results pt-0">
                              <div className="row">
                                <Sidebar
                                  content={this.state.sidePanelContent}
                                  handleSnackbarOpen={this.handleSnackbarOpen}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  />
                  <Route
                    path="/err"
                    render={props => <ErrorPage {...props} />}
                  />
                </PageContainer>
              </Router>
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </UserContext.Provider>
    );
  }
}

App.propTypes = {
  width: PropTypes.string.isRequired
};
export default withWidth()(App);
