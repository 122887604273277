import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

// import TEXT from './staticContent/text';
// import { staticResources } from './staticContent/ProjectDetailsUpdatePopUpDropDowns';

class ProjectDetailsUpdatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateProjectDetailsAPICall = updatedData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    // map isReferred to boolean
    const mappedUpdatedData = updatedData;
    if (updatedData.isReferred) {
      if (updatedData.isReferred === 'ja') mappedUpdatedData.isReferred = true;
      else mappedUpdatedData.isReferred = false;
    }

    const payload = {
      projectId: this.props.projectId,
      potentialCandidateId: this.props.potentialCandidateId,
      updatedData: mappedUpdatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(
            TEXT_GENERAL.snackBarTexts.updateSuccess
          );
          this.props.refreshProject();
          // this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    // console.log('DEBUG contactDate', this.props.data.contactDate);
    const forms = {
      formFields: {
        source: {
          value: this.props.data.source,
          type: 'dropDown',
          content: [
            'Active Search',
            'Anzeige',
            'Datenbank',
            'Direct Search',
            'Empfehlung',
            'Experteer',
            'Initiativbewerbung',
            'LinkedIn',
            'Messe',
            'Monster CV',
            'Stepstone CV',
            'Xing'
          ],
          label: 'Herkunft'
        },
        department: {
          value: this.props.data.department,
          label: 'Abteilung',
          validator: 'isText'
        },
        contactTrackingType: {
          value: this.props.data.contactTrackingType,
          label: 'Art der Kontaktverfolgung',
          validator: 'isText'
        },
        contactDate: {
          value: UTILS.formatDateForDateField(this.props.data.contactDate),
          type: 'date',
          label: 'Kontaktaufname'
        },
        CVReceivedDate: {
          value: UTILS.formatDateForDateField(this.props.data.CVReceivedDate),
          type: 'date',
          label: 'CV erhalten am'
        },
        initialInterviewDate: {
          value: UTILS.formatDateForDateField(
            this.props.data.initialInterviewDate
          ),
          type: 'date',
          label: 'Tel. Vorabinterview am'
        },
        presentationDate: {
          value: UTILS.formatDateForDateField(this.props.data.presentationDate),
          type: 'date',
          label: 'Präsentation am'
        },
        status: {
          value: this.props.data.status,
          type: 'dropDown',
          content: [
            '',
            'Identifiziert',
            'Angesprochen',
            'Interessiert',
            'CV erhalten',
            'Tel. Vorabinterview',
            'Pers. Interview',
            'Präsentation',
            'Out'
          ],
          label: 'Status'
        },
        rejectionReason: {
          value: this.props.data.rejectionReason,
          type: 'dropDown',
          content: [
            '',
            'aktuell nicht wechselbereit',
            'andere Karriereziele',
            'außerhalb der Gehaltsbandbreite',
            'fehlende Führungserfahrung',
            'fehlende Mobilität',
            'fehlende Reisebereitschaft',
            'Keine Rückmeldung',
            'Matching Soft-Skills',
            'Überqualifiziert',
            'Unterqualifiziert',
            'zu viele Wechsel',
            'zufrieden im Unternehmen'
          ],
          label: 'Absagegrund'
        },
        firstInterviewDate: {
          value: UTILS.formatDateForDateField(
            this.props.data.firstInterviewDate
          ),
          type: 'date',
          label: 'Interview am'
        },
        applicationSendDate: {
          value: UTILS.formatDateForDateField(
            this.props.data.applicationSendDate
          ),
          type: 'date',
          label: 'Bewerb.-hin an'
        },
        reminderCompanyDate1: {
          value: UTILS.formatDateForDateField(
            this.props.data.reminderCompanyDate1
          ),
          type: 'date',
          label: 'Nachgefasst Unternehmen'
        },
        secondInterviewDate: {
          value: UTILS.formatDateForDateField(
            this.props.data.secondInterviewDate
          ),
          type: 'date',
          label: 'Vorstellungstermin'
        },
        reminderCandidateDate: {
          value: UTILS.formatDateForDateField(
            this.props.data.reminderCandidateDate
          ),
          type: 'date',
          label: 'Nachgefasst Kandidat'
        },
        isReferred: {
          value: this.props.data.isReferred,
          type: 'dropDown',
          content: ['ja', 'nein'],
          label: 'vermittelt'
        },
        signedDate: {
          value: UTILS.formatDateForDateField(this.props.data.signedDate),
          type: 'date',
          label: CONSTANTS.isLienert ? 'Eintrittsdatum' : 'Vertragsunterschrift'
        },
        remark: {
          value: this.props.data.remark,
          label: 'Bemerkung',
          // validator: 'isText',
          customClass: 'col-12 mb-2',
          additionalProps: { multiline: true }
        },
        comment: {
          value: this.props.data.comment,
          label: 'Kommentar',
          // validator: 'isText',
          customClass: 'col-12 mb-2',
          additionalProps: { multiline: true }
        }
      }
    };

    return forms;
  }

  handleContinue = () => {
    this.props.handleSelectNextCandidate(this.props.potentialCandidateId);
  };

  // handleSaveAndContinue = () => {
  //   this.props.handleSelectNextCandidate(this.props.potentialCandidateId);
  // };

  render() {
    // console.log('debug this.props.data', this.props.data);
    this.form = this.generateFormFields();
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText={`Eintrag ändern ${this.props.indexInformation} - ${
          this.props.data.candidateFirstName
        } ${this.props.data.candidateLastName}`}
        // key="hallo"
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: true,
          handleSubmit: this.updateProjectDetailsAPICall,
          handleClose: this.props.handleClose,

          // new save and continue functionality
          buttonSaveAndContinueText: 'Speichern und weiter',
          handleSaveAndContinue: this.handleContinue,
          buttonContinueText: 'weiter',
          handleContinue: this.handleContinue
        }}
      />
    );
  }
}
ProjectDetailsUpdatePopUpContainer.defaultProps = {
  // isUpdate: false,
  data: {},
  indexInformation: ''
};

ProjectDetailsUpdatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // isUpdate: PropTypes.bool,
  // data
  projectId: PropTypes.string.isRequired,
  potentialCandidateId: PropTypes.string.isRequired,
  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  // dropDown key values
  // employees: PropTypes.array.isRequired,

  // custom: only required for subSchema update
  // dataSetId: PropTypes.string,
  refreshProject: PropTypes.func.isRequired,
  handleSelectNextCandidate: PropTypes.func.isRequired,
  indexInformation: PropTypes.string
};

export default ProjectDetailsUpdatePopUpContainer;
