import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import { Stepper, Step, StepButton, Paper } from '@material-ui/core';

import SerialMailTemplateSelection from './SerialMailTemplateSelection';
import SerialMailEditorSection from './SerialMailEditorSection';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';

class SerialMailContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      templateId: '',
      signatureId: '',
      formFieldsTemplateSelection: {}
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleContinueButtonClick = (
    formFields,
    checkboxGeneralEntry,
    checkboxGeneralRejection
  ) => {
    this.setState({
      activeStep: 1,
      templateId: formFields.selectedTemplate.value,
      signatureId: formFields.selectedSignature.value,
      formFieldsTemplateSelection: formFields,
      checkboxGeneralEntry,
      checkboxGeneralRejection
    });
  };

  handleSendMailButtonClick = (serialMail, isTestMail) => {
    const pastContactFormFields = this.state.formFieldsTemplateSelection;
    const pastContact = {
      contactDate: pastContactFormFields.contactHistoryDate.value,
      contactType: 'Serienmail',
      project: pastContactFormFields.contactHistoryProject.value,
      rejected: this.state.checkboxGeneralRejection,
      subject: pastContactFormFields.contactHistorySubject.value,
      comment: pastContactFormFields.contactHistoryComment.value,
      isGeneralEntry: this.state.checkboxGeneralEntry
    };
    const requestBody = { serialMail, pastContact };

    const url = isTestMail
      ? CONSTANTS.sendTestMailURL
      : CONSTANTS.sendSerialMailURL;
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log(
          'API /sendMail or /sendTestMail response body: ',
          responseBody
        );
        this.setState({
          activeStep: 2
        });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  handleSendSerialMailButtonClick = () => {
    alert('Diese Funktionalität steht demnächst zur Verfügung');
  };

  render() {
    const steps = ['Vorlage auswählen', 'Serienmail versenden', 'Bestätigung'];
    const { activeStep } = this.state;
    return (
      <div>
        <div
          style={{
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton disabled completed={index < activeStep}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-1" />
            <div className="col-10">
              <Paper style={{ padding: '28px 48px' }}>
                {this.state.activeStep === 0 && (
                  <SerialMailTemplateSelection
                    markListId={this.props.markListId}
                    handleContinueButtonClick={this.handleContinueButtonClick}
                  />
                )}
                {this.state.activeStep === 1 && (
                  <SerialMailEditorSection
                    key={`${this.props.markList}-${this.state.templateId}`}
                    templateId={this.state.templateId}
                    signatureId={this.state.signatureId}
                    markList={this.props.markList}
                    handleSendMailButtonClick={this.handleSendMailButtonClick}
                    handleSendSerialMailButtonClick={
                      this.handleSendSerialMailButtonClick
                    }
                  />
                )}
                {this.state.activeStep === 2 && (
                  <div
                    style={{ padding: '50px 0 200px 0', textAlign: 'center' }}
                  >
                    <div className="confirmation-dialog-title">
                      <i className="material-icons confirmation-dialog-title-icon pb-3">
                        check_circle_outline
                      </i>
                    </div>
                    <p>
                      Die Serienmails werden im Hindergrund automatisch
                      versendet!
                    </p>
                  </div>
                )}
              </Paper>
            </div>
            <div className="col-10" />
          </div>
        </div>
      </div>
    );
  }
}

SerialMailContainer.propTypes = {
  markListId: PropTypes.string.isRequired,
  markList: PropTypes.object.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default SerialMailContainer;
