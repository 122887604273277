import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';

import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
// theme
const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class MonitoringTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.getColumns()
    };
  }

  getColumns() {
    return [
      {
        name: 'Name',
        options: {
          display: true,
          filter: true
        }
      },
      {
        name: 'E-Mail',
        options: {
          display: true,
          filter: true
        }
      },
      {
        name: 'Unternehmen',
        options: {
          display: true,
          filter: true
        }
      },
      {
        name: 'Datum',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: true,
          filter: true
        }
      },
      {
        name: 'Uhrzeit (GMT)',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: 'Suchanfrage',
        options: {
          display: true,
          filter: false
        }
      }
    ];
  }

  prepareDataForTable(data) {
    const formatedData = [];
    console.log('input data', data);
    if (data)
      data.forEach(entry => {
        // console.log('DEBUG MonitoringTable entry', entry);
        // if (entry.proposed === true) {
        const mappedData = {
          userName: entry.userName,
          userEmail: entry.userEmail,
          userCompany: entry.userCompany || '',
          searchDate: entry.searchDate,
          searchTime: entry.searchTime,
          searchTerms: entry.searchTerms
        };

        formatedData.push([
          ...Object.values(mappedData)
          // this.renderActionButtons(
          //   entry.id,
          //   entry.potentialCandidateId,
          //   entry.isEditable
          // )
        ]);
        // }
      });
    console.log('formatedData', formatedData);
    return formatedData;
  }

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableToolbar: {
          titleRoot: {
            textAlign: 'left'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:nth-child(2)': {
              paddingLeft: '30px'
            },
            paddingRight: '10px'
            // '&:last-child': {
            //   textAlign: 'right'
            // }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:first-child': {
              paddingLeft: '30px'
            },
            paddingRight: '10px'
            // '&:last-child': {
            //   textAlign: 'right'
            // }
          }
        }
      }
    };
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      rowsPerPage: 100,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  // PAGE: Admin.Monitoring
  render() {
    return (
      <div className="container-fluid">
        {/* {this.renderDeleteConfirmationPopUp()} */}
        <div className="MonitoringTable row">
          <div className="col-12">
            <SafeMuiDatatable
              title="Suchverlauf"
              tableName="monitoringTable"
              data={this.prepareDataForTable(this.props.data)}
              columns={this.state.columns}
              options={this.getOptions()}
              muiTheme={this.getTheme()}
            />
          </div>
        </div>
      </div>
    );
  }
}

MonitoringTable.defaultProps = {};

MonitoringTable.propTypes = {
  // refreshCandidate: PropTypes.func.isRequired,

  // selectedCandidateId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
  // handleSnackbarOpen: PropTypes.func.isRequired,
  // liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  // user: PropTypes.object.isRequired
};
export default withUser(MonitoringTable);
