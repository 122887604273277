import React, { Component } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';
import withUser from '../utils/withUser';
import { isPermissionSetIgnoreAdmin } from '../utils/isPermissionSet';

/**
 * shown labels and new additionalPermissions can be configured here
 */
const AVAILABLE_PERMISSIONS = {
  incomingCandidate: {
    label: 'Kandidateneingang & Datenschutzprozess',
    role: 'employee'
  },
  updateCompanyStatus: {
    label: 'Kundenschutz setzen (Unternehmensarchiv)',
    role: 'employee'
  },
  dataImportCompanies: { label: 'Datenimport Unternehmen', role: 'employee' },
  shortLongList: { label: 'Short- / Longlist', role: 'employee' },
  crossPartnerAdmin: {
    label: 'Partner-übergreifende Administration',
    role: 'admin',
    requiredPermission: 'crossPartnerAdmin'
  }
};

/**
 * helper function to handle newly added permissions to the above constant checkBoxLabels
 */
function mapAdditionalPermissions(user) {
  return Object.keys(AVAILABLE_PERMISSIONS)
    .filter(
      permissionKey =>
        !AVAILABLE_PERMISSIONS[permissionKey].role ||
        user.roles.includes(AVAILABLE_PERMISSIONS[permissionKey].role)
    )
    .map(
      permissionKey =>
        (user.additionalPermissions || []).find(
          p => p.key === permissionKey
        ) || {
          key: permissionKey,
          isSet: false
        }
    );
}

class EditAdditionalPermissionsPopUpContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalPermissions: mapAdditionalPermissions(this.props.data)
    };

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateUserAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateUserURL;

    const payload = {
      userEmail: this.props.userEmail,
      updateData: { additionalPermissions: this.state.additionalPermissions }
    };

    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
        this.props.refreshData();
        this.props.handleClose();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  mayToggle = additionalPermission =>
    !AVAILABLE_PERMISSIONS[additionalPermission.key].requiredPermission ||
    isPermissionSetIgnoreAdmin(
      this.props.user,
      AVAILABLE_PERMISSIONS[additionalPermission.key].requiredPermission
    );

  onCheckboxChange = checkboxKey => {
    this.setState(prevState => {
      const prevAdditionalPermissions = prevState.additionalPermissions;
      const indexOfUpdatedPermission = prevAdditionalPermissions.findIndex(
        additionalPermission => additionalPermission.key === checkboxKey
      );
      prevAdditionalPermissions[
        indexOfUpdatedPermission
      ].isSet = !prevAdditionalPermissions[indexOfUpdatedPermission].isSet;
      const updatedAdditionalPermissions = prevAdditionalPermissions;
      return { additionalPermission: updatedAdditionalPermissions };
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div>
          <DialogTitle id="form-dialog-title-success">
            <div className="createUser-dialog-title">
              Zusatzberechtigungen ändern
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="col-12">
              <FormLabel component="legend" className="pb-2 mt-3">
                Berechtigungen
              </FormLabel>
              <FormControl>
                {this.state.additionalPermissions.map(additionalPermission => (
                  <FormControlLabel
                    key={additionalPermission.key}
                    className="checkFilterLabel"
                    // key={element}
                    control={
                      <Checkbox
                        className="chkBox_"
                        checked={additionalPermission.isSet}
                        disabled={!this.mayToggle(additionalPermission)}
                        onChange={() =>
                          this.onCheckboxChange(additionalPermission.key)
                        }
                      />
                    }
                    label={
                      (AVAILABLE_PERMISSIONS[additionalPermission.key] || {})
                        .label || additionalPermission.key
                    }
                  />
                ))}
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Abbrechen
            </Button>
            <Button
              onClick={this.updateUserAPICall}
              color="primary"
              // disabled={!this.isValidForm()}
            >
              Speichern
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      // <GenericFormPopUp
      //   open={this.props.open}
      //   headlineText="Mitarbeiterberechtigungen ändern"
      //   genericFormProps={{
      //     formFields: this.form.formFields,
      //     isUpdate: this.props.isUpdate,
      //     handleSubmit: this.updateAPICall,
      //     // initialData: this.props.data,
      //     handleClose: this.props.handleClose

      //     // buttonCancelText: TEXT.projectDetailsUpdatePopUp.buttons.cancel,
      //     // buttonSaveText: TEXT.projectDetailsUpdatePopUp.buttons.save
      //   }}
      // />
    );
  }
}
EditAdditionalPermissionsPopUpContainer.defaultProps = {
  data: {}
};

EditAdditionalPermissionsPopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,

  // data
  userEmail: PropTypes.string.isRequired,
  data: PropTypes.object,

  handleClose: PropTypes.func.isRequired,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default withUser(EditAdditionalPermissionsPopUpContainer);
