import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// libraries
import $ from 'jquery';

// material UI
import {
  Checkbox,
  FormControlLabel,
  Button
  // Stepper,
  // Step,
  // StepButton,
  // Paper
} from '@material-ui/core';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import ValidatedTextField from '../../generic_components/ValidatedTextField';
import ValidatedDropDown from '../../generic_components/ValidatedDropDown';

function prepareTemplatesKeyValuePairs(templates) {
  return (templates || [])
    .filter(template => !!template.templateName)
    .map(template => ({
      value: template.id,
      label: template.templateName,
      isEditingMode: template.isEditingMode
    }));
}

function prepareProjectsKeyValuePairs(projects) {
  return (projects || [])
    .filter(
      (project, index, self) =>
        !!project.projectNumber &&
        self.findIndex(p => p.id === project.id) === index // only keep distinct values
    )
    .map(project => ({
      value: project.id,
      label: project.projectNumber
    }));
}

class SerialMailTemplateSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        contactHistorySubject: {
          value: ''
        },
        contactHistoryComment: {
          value: ''
        },
        contactHistoryDate: {
          value: UTILS.formatDateForDateFieldNew(new Date())
        },
        contactHistoryProject: {
          value: ''
        },
        selectedTemplate: {
          value: ''
        },
        selectedSignature: {
          value: ''
        }
      },
      projectList: [{ value: null, label: 'Liste lädt...' }],
      templateList: [{ value: null, label: 'Liste lädt...' }],
      signatureList: [{ value: null, label: 'Liste lädt...' }],
      checkboxGeneralEntry: false,
      checkboxGeneralRejection: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  loadProjectList() {
    const url = CONSTANTS.getProjectNumbersByCandidateMarkListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { markListId: this.props.markListId };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({
          projectList: prepareProjectsKeyValuePairs(responseBody.projects)
        });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  loadTemplateList() {
    const url = CONSTANTS.getTemplateListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { templateType: 'serialMail' };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({
          templateList: prepareTemplatesKeyValuePairs(responseBody)
        });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  loadSignatureList() {
    const url = CONSTANTS.getTemplateListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { templateType: 'signature' };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({
          signatureList: prepareTemplatesKeyValuePairs(responseBody)
        });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  componentDidMount() {
    this.loadProjectList();
    this.loadTemplateList();
    this.loadSignatureList();
  }

  onInputFieldChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id || e.target.name;
    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  handleContinueButtonClick = () => {
    this.props.handleContinueButtonClick(
      this.state.formFields,
      this.state.checkboxGeneralEntry,
      this.state.checkboxGeneralRejection
    );
  };

  isValidateFormInput = () => {
    let isValid = false;
    if (
      this.state.formFields.contactHistorySubject.value ||
      this.state.formFields.contactHistoryComment.value
    )
      isValid = true;
    // else if (this.state.checkboxGeneralRejection) isValid = true;
    else if (
      this.state.formFields.selectedTemplate.value &&
      this.state.checkboxGeneralEntry
    )
      isValid = true;
    return isValid;
  };

  contactHistoryFieldsAreDisabled() {
    let isDisabled = false;
    if (this.state.checkboxGeneralEntry) {
      isDisabled = true;
      const selectedTemplate = this.state.templateList.filter(
        templateEntry =>
          templateEntry.value === this.state.formFields.selectedTemplate.value
      )[0];
      // reset input for historyEntry
      if (selectedTemplate) {
        const selectedTemplateLabel = selectedTemplate.label;
        if (
          selectedTemplateLabel &&
          (this.state.formFields.contactHistorySubject.value !==
            selectedTemplateLabel ||
            this.state.formFields.contactHistoryComment.value)
        ) {
          this.setState(prevState => ({
            formFields: {
              ...prevState.formFields,
              contactHistorySubject: {
                ...prevState.formFields.contactHistorySubject,
                value: selectedTemplateLabel
              },
              contactHistoryComment: {
                ...prevState.formFields.contactHistoryComment,
                value: ''
              }
            }
          }));
        }
      }
    }
    return isDisabled;
  }

  isRejectionCheckboxDisabled() {
    let disabled = true;
    if (this.state.formFields.contactHistoryProject.value) disabled = false;
    return disabled;
  }

  handleCheckboxGeneralEntry = () => {
    this.setState(prevState => {
      const newState = !prevState.checkboxGeneralEntry;
      const updatedState = {
        checkboxGeneralEntry: newState
      };
      return updatedState;
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-8" style={{ paddingBottom: '1em' }}>
            <h6>Bitte wählen Sie eine Vorlage aus</h6>
          </div>
        </div>

        <div className="row pb-2">
          <div className="col-8" style={{ paddingBottom: '2em' }}>
            <ValidatedDropDown
              label="E-Mail Vorlage"
              placeHolderText="keine Vorlage"
              value={this.state.formFields.selectedTemplate.value}
              onChange={this.onInputFieldChange}
              id="selectedTemplate"
              key="selectedTemplate"
              content={this.state.templateList}
              fullWidth
            />
          </div>
        </div>
        <div className="row pb-2">
          <div className="col-8" style={{ paddingBottom: '2em' }}>
            <ValidatedDropDown
              label="Signatur Vorlage"
              placeHolderText="keine Vorlage"
              value={this.state.formFields.selectedSignature.value}
              onChange={this.onInputFieldChange}
              id="selectedSignature"
              key="selectedSignature"
              content={this.state.signatureList}
              fullWidth
            />
          </div>
        </div>

        <div
          className="row"
          // style={{ borderTop: '1px solid #ccc', paddingTop: '1.5em' }}
        >
          <div className="col-12">
            <h6>Kontakthistorie</h6>
            {CONSTANTS.isLienert ? (
              <p>
                Wenn Sie im Kandidatenarchiv unter &bdquo;vorgeschlagen auf /
                beworben auf&ldquo; ein Absagevermerk hinterlegen wollen, wählen
                Sie einen Auftrag und aktivieren Sie das
                &bdquo;Absagehöggeli&ldquo;.
              </p>
            ) : (
              <p>
                Wenn Sie im Kandidatenarchiv unter &bdquo;vorgeschlagen auf /
                beworben auf&ldquo; ein Absagevermerk hinterlegen wollen, wählen
                Sie einen Auftrag und aktivieren Sie das
                &bdquo;abgesagt&ldquo;-Häckchen.
              </p>
            )}
          </div>
          {/* <div className="col-4" /> */}
        </div>
        <div className="row">
          <div className="col-4">
            <ValidatedTextField
              label="Datum"
              value={this.state.formFields.contactHistoryDate.value}
              onChange={this.onInputFieldChange}
              id="contactHistoryDate"
              key="contactHistoryDate"
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="date"
            />
          </div>
          <div className="col-4">
            <ValidatedDropDown
              label="Auftrag"
              placeHolderText="keine Auswahl"
              value={this.state.formFields.contactHistoryProject.value}
              onChange={this.onInputFieldChange}
              id="contactHistoryProject"
              key="contactHistoryProject"
              content={this.state.projectList}
              fullWidth
            />
          </div>

          <div className="col-4">
            <FormControlLabel
              className="checkFilterLabel"
              fullWidth
              style={{ padding: '5px 20px 5px 5px' }}
              control={
                <Checkbox
                  className="chkBox_"
                  checked={this.state.checkboxGeneralRejection}
                  disabled={this.isRejectionCheckboxDisabled()}
                  onChange={() => {
                    this.setState(prevState => {
                      const newState = !prevState.checkboxGeneralRejection;
                      return { checkboxGeneralRejection: newState };
                    });
                  }}
                />
              }
              label={CONSTANTS.isLienert ? 'Absagehöggelie' : 'abgesagt'}
            />
          </div>
        </div>
        <div className="row" style={{ paddingTop: '1.5em' }}>
          <div className="col-12">
            <p>
              Wenn Sie im Bereich &bdquo;Kontakthistorie&ldquo; einen
              automatisierten Eintrag erzeugen wollen, stehen Ihnen die
              Textfelder &bdquo;Betreff&ldquo; und &bdquo;Notiz&ldquo; für eine
              individuelle Formulierung zur Verfügung. Aktivieren Sie nur das
              Häkchen, wird ein standardisierter Eintrag gemäß Ihrer
              Vorlagenauswahl vorgenommen.
            </p>
          </div>
          {/* <div className="col-4" /> */}
        </div>
        <div className="row mb-2">
          <div className="col-8">
            <ValidatedTextField
              label="Betreff"
              value={this.state.formFields.contactHistorySubject.value}
              onChange={this.onInputFieldChange}
              id="contactHistorySubject"
              key="contactHistorySubject"
              disabled={this.contactHistoryFieldsAreDisabled()}
              fullWidth
            />
          </div>
          <div className="col-4">
            <FormControlLabel
              className="checkFilterLabel"
              fullWidth
              style={{ padding: '5px 20px 5px 5px' }}
              control={
                <Checkbox
                  className="chkBox_"
                  checked={this.state.checkboxGeneralEntry}
                  onChange={this.handleCheckboxGeneralEntry}
                />
              }
              label="Standardeintrag"
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <ValidatedTextField
              label="Notiz"
              value={this.state.formFields.contactHistoryComment.value}
              onChange={this.onInputFieldChange}
              id="contactHistoryComment"
              key="contactHistoryComment"
              disabled={this.contactHistoryFieldsAreDisabled()}
              multiline
              fullWidth
            />
          </div>
        </div>

        <div className="mt-5 text-right">
          <Button
            onClick={this.handleContinueButtonClick}
            color="primary"
            variant="contained"
            disabled={!this.isValidateFormInput()}
          >
            Weiter
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

SerialMailTemplateSelection.propTypes = {
  markListId: PropTypes.string.isRequired,
  handleContinueButtonClick: PropTypes.func.isRequired
};

export default SerialMailTemplateSelection;
