import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import AlarmOn from '@material-ui/icons/AlarmOn';

import $ from 'jquery';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import CounterPartCUPopUp from './CounterPartCUPopUp';
import { deleteCounterPartAPICall } from './api/companyArchiveAPI';

import * as UTILS from '../utils/utilFunctions';

const columns = [
  {
    name: 'ID',
    options: {
      display: 'excluded'
    }
  },
  // { name: 'Bearbeiten' },
  { name: 'Name', options: { filter: false } },
  {
    name: 'Durchwahl',
    options: {
      filter: false,
      customBodyRender: value => {
        const hrefParam = `tel:${value}`;
        return <a href={hrefParam}>{value}</a>;
      }
    }
  },
  { name: 'Fax', options: { filter: false } },
  {
    name: 'Mobilfunk',
    options: {
      filter: false,
      customBodyRender: value => {
        const hrefParam = `tel:${value}`;
        return <a href={hrefParam}>{value}</a>;
      }
    }
  },
  {
    name: 'E-Mail',
    options: {
      filter: false,
      customBodyRender: value => {
        const hrefParam = UTILS.parserMailLink(value);
        return <a href={hrefParam}>{value}</a>;
      }
    }
  },
  { name: 'Bereich' },
  { name: 'Hierarchieebene' },
  { name: 'Betreuer' },
  { name: 'Aktionen', options: { filter: false } }
  // { name: 'edit_col' }
];

class CompanyContacts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCounterPartCUPopUpOpen: false,
      isDeleteCounterPartPopUpOpen: false,
      entryId: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  generateName(salutation, title, firstName, lastName) {
    let name = '';
    if (salutation) name = `${salutation} `;
    if (title) name = `${name + title} `;
    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  handleCloseCounterPartCUPopup = () => {
    this.setState({
      isCounterPartCUPopUpOpen: false
    });
  };

  generateSelectedCounterPartData(entryId) {
    const result = {};
    const foundEntry = this.props.data.find(entry => entry.id === entryId);
    Object.keys(foundEntry).forEach(key => {
      switch (key) {
        case 'contactInfo':
          Object.keys(foundEntry.contactInfo).forEach(subKey => {
            result[subKey] = foundEntry.contactInfo[subKey];
          });
          break;
        case 'advisor':
          result[key] = foundEntry[key].id;
          break;
        default:
          result[key] = foundEntry[key];
      }
    });
    return result;
  }

  async deleteCounterPart() {
    try {
      await deleteCounterPartAPICall(this.state.entryId, this.props.companyId);
      this.props.handleSnackbarOpen(
        'Ansprechpartner wurde erfolgreich gelöscht'
      );
      this.setState({ isDeleteCounterPartPopUpOpen: false });
      this.props.removeCounterPart(this.state.entryId);
    } catch (err) {
      this.errorHandling(err);
    }
  }

  renderButtons(entryId) {
    return (
      <div>
        <Tooltip title="Eintrag bearbeiten">
          <IconButton
            className=""
            aria-label="Edit"
            color="primary"
            onClick={() =>
              this.setState({ isCounterPartCUPopUpOpen: true, entryId })
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eintrag löschen">
          <IconButton
            className=""
            aria-label="Delete"
            color="primary"
            onClick={() =>
              this.setState({ entryId, isDeleteCounterPartPopUpOpen: true })
            }
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderPhoneLink = value => {
    const hrefParam = `tel:${value}`;
    return (
      <a href={hrefParam} onClick={this.props.openContactHistoryForPhone}>
        {value}
      </a>
    );
  };

  renderEmailAddressLink(mailAddress) {
    const hrefParam = UTILS.parserMailLink(mailAddress);
    return <a href={hrefParam}>{mailAddress}</a>;
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        formatedData.push([
          entry.id,
          entry.id === this.props.preSelectedCounterPartId ? (
            <div className="markedCandidate">
              {this.generateName(
                entry.salutation,
                entry.title,
                entry.firstName,
                entry.lastName
              )}{' '}
              <IconButton disabled>
                <AlarmOn />
              </IconButton>
            </div>
          ) : (
            this.generateName(
              entry.salutation,
              entry.title,
              entry.firstName,
              entry.lastName
            )
          ),
          entry.contactInfo &&
            this.renderPhoneLink(entry.contactInfo.phoneNumber),
          entry.contactInfo && entry.contactInfo.faxNumber,
          entry.contactInfo &&
            this.renderPhoneLink(entry.contactInfo.phoneNumberMobile),
          entry.contactInfo && entry.contactInfo.mailAddress,
          entry.division,
          entry.hierarchyLevel,
          entry.advisor ? entry.advisor.name : '',
          this.renderButtons(entry.id)
          // TODO new col for table (still working with the bug)
          // this.renderButtons()
        ]);
      });
    return formatedData;
  }

  renderCounterPartCUPopUp() {
    return (
      <CounterPartCUPopUp
        open={this.state.isCounterPartCUPopUpOpen}
        handleClose={this.handleCloseCounterPartCUPopup}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        companyId={this.props.companyId}
        isUpdate
        counterPartData={this.generateSelectedCounterPartData(
          this.state.entryId
        )}
        counterPartId={this.state.entryId}
        liftUpUpdateCounterPartDetails={
          this.props.liftUpUpdateCounterPartDetails
        }
      />
    );
  }

  onCellClick(colData, cellMeta, rowIndex, dataIndex) {
    console.log('debug onCellClick', {
      colData,
      cellMeta,
      rowIndex,
      dataIndex
    });
    if (colData && colData.props && colData.props.href === 'tel:')
      console.log('debug onCellClick triggerPoint');
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: true,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  render() {
    $(document).ready(() => {
      $('.markedCandidate')
        .parent()
        .parent()
        .css('background-color', '#e8e8e8');
    });
    return (
      <div>
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={() =>
            this.setState({ isDeleteCounterPartPopUpOpen: false })
          }
          open={this.state.isDeleteCounterPartPopUpOpen}
          buttonPrimaryAction={() => this.deleteCounterPart()}
          headlineText="Ansprechpartner löschen"
          descriptionText="Soll der entsprechende Eintrag gelöscht werden?"
        />
        {this.state.isCounterPartCUPopUpOpen && this.renderCounterPartCUPopUp()}
        <div className="CompanyContacts">
          <SafeMuiDatatable
            title="Ansprechpartner"
            tableName="companyContacts"
            data={this.prepareDataForTable(this.props.data)}
            options={this.getOptions()}
            onCellClick={this.onCellClick}
            columns={columns}
          />
        </div>
      </div>
    );
  }
}
CompanyContacts.defaultProps = {
  preSelectedCounterPartId: undefined,
  openContactHistoryForPhone: () => null
};
CompanyContacts.propTypes = {
  data: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpUpdateCounterPartDetails: PropTypes.func.isRequired,
  openContactHistoryForPhone: PropTypes.func,
  preSelectedCounterPartId: PropTypes.string,
  removeCounterPart: PropTypes.func.isRequired
};
export default CompanyContacts;
