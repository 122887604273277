import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';

import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';

import IncomingCandidateResults from '../general/incomingCandidates/IncomingCandidateResult';
import IncomingCandidateStepper from '../general/incomingCandidates/IncomingCandidateStepper';

import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';

// css
import './IncomingCandidates.css';

class IncomingCandidates extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      incomingCandidateList: [],
      showResultList: true,
      showIncomingCandidateDetails: false,
      selectedIncomingCandidate: {
        creationDate: `${new Date().getFullYear()}-${new Date().getMonth() +
          1}-${new Date().getDate()}`
      },
      creationDate: `${new Date().getFullYear()}-${new Date().getMonth() +
        1}-${new Date().getDate()}`,
      selectedDuplicateCandidate: {},
      createNewCandidate: false
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Kandidateneingang - ${UTILS.getTitle()}`;
    if (this.props.createNewCandidate) {
      this.setState({
        showIncomingCandidateDetails: true,
        showResultList: false,
        createNewCandidate: true
      });
      this.props.liftUpStateToApp({
        createNewCandidate: false
      });
    } else {
      this.getIncomingCandidateList();
      // this.props.liftUpStateToApp('showLoadingIndicatorGlobal', true);
    }
  }

  handleTagDocument = (fileName, tag) => {
    this.setState(prevState => {
      const taggedDocuments = JSON.parse(
        JSON.stringify(prevState.selectedIncomingCandidate.taggedDocuments)
      );
      console.log('handle1', taggedDocuments);

      taggedDocuments.forEach((taggedDocument, index) => {
        if (taggedDocument.documentName === fileName) {
          console.log('handle2', taggedDocuments);

          taggedDocuments[index].documentType = tag;
        }
      });
      console.log('handle3', taggedDocuments);
      return {
        selectedIncomingCandidate: {
          ...prevState.selectedIncomingCandidate,
          taggedDocuments
        }
      };
    });
  };

  getIncomingCandidateList() {
    const url = CONSTANTS.getIncomingCandidateListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.setState({
          showResultList: true,
          incomingCandidateList: responseBody
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getIncomingCandidateById(id) {
    const requestBody = { incomingCandidateId: id };
    const url = CONSTANTS.getIncomingCandidateByIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.setState({
          selectedIncomingCandidate: responseBody,
          showResultList: false,
          showIncomingCandidateDetails: true
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  handleIncomingCandidateSelected = selectedRow => {
    this.setState({ showResultList: false });
    this.getIncomingCandidateById(selectedRow[0]);
  };

  handleRemoveCandidate = incomingCandidateId => {
    this.setState(prevState => {
      let incomingCandidateList = prevState.incomingCandidateList;
      incomingCandidateList = incomingCandidateList.filter(
        incomingCandidate => incomingCandidate.id !== incomingCandidateId
      );
      return {
        incomingCandidateList,
        showResultList: true,
        showIncomingCandidateDetails: false
      };
    });
  };

  liftUpStateToIncomingCandidates = updateObject => {
    this.setState({
      ...updateObject
    });
  };

  liftUpSelectedCandidateToIncomingCandidates = updateObject => {
    this.setState(prevState => ({
      ...prevState,
      selectedIncomingCandidate: {
        ...prevState.selectedIncomingCandidate,
        ...updateObject
      }
    }));
  };

  renderIncomingCanidatesList() {
    return (
      <Collapse
        direction="down"
        mountOnEnter
        unmountOnExit
        in={this.state.showResultList}
        timeout={800}
      >
        <div className="row mt-3">
          <div className="col-12">
            <div className="ProjecResults col-12">
              <IncomingCandidateResults
                incomingCandidateList={this.state.incomingCandidateList}
                handleIncomingCandidateSelected={
                  this.handleIncomingCandidateSelected
                }
              />
            </div>
          </div>
        </div>
      </Collapse>
    );
  }

  renderSingleIncomingCandidateResults() {
    return (
      <Fade
        direction="up"
        in={this.state.showIncomingCandidateDetails}
        mountOnEnter
        unmountOnExit
        {...(this.state.showIncomingCandidateDetails ? { timeout: 1000 } : {})}
      >
        <IncomingCandidateStepper
          key={
            this.state.selectedIncomingCandidate.id + this.state.creationDate
          }
          selectedIncomingCandidate={this.state.selectedIncomingCandidate}
          liftUpSelectedCandidateToIncomingCandidates={
            this.liftUpSelectedCandidateToIncomingCandidates
          }
          handleTagDocument={this.handleTagDocument}
          liftUpStateToApp={this.props.liftUpStateToApp}
          liftUpStateToIncomingCandidates={this.liftUpStateToIncomingCandidates}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          handleBackButton={() =>
            this.setState({
              showResultList: true,
              showIncomingCandidateDetails: false
            })
          }
          selectedDuplicateCandidate={this.state.selectedDuplicateCandidate}
          handleRemoveCandidate={this.handleRemoveCandidate}
          createNewCandidate={this.state.createNewCandidate}
        />
      </Fade>
    );
  }

  render() {
    return (
      <div className="IncomingCandidates  h-100-minusAppBar">
        <div className="container-fluid  h-100 mt-1">
          {this.renderIncomingCanidatesList()}
          {this.renderSingleIncomingCandidateResults()}
        </div>
      </div>
    );
  }
}
IncomingCandidates.defaultProps = {};
IncomingCandidates.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  createNewCandidate: PropTypes.bool.isRequired
};
export default IncomingCandidates;
