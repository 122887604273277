const text = {
  roles: {
    employee: 'Interner Partner',
    client: 'Kunde',
    admin: 'Admin'
  },
  ConfirmationModal: {
    buttonPrimaryText: 'Bestätigen',
    buttonSecondaryText: 'Abbrechen',
    headlineText: 'Bestätigung erforderlich',
    descriptionText: 'Bitte bestätigen Sie Ihre ausgeführte Aktion'
  },
  CompanyImportModal: {
    buttonPrimaryText: 'Bestätigen',
    buttonSecondaryText: 'Abbrechen',
    buttonCloseText: 'Schließen',
    headlineText: 'Datei hochladen',
    descriptionText: 'Datei hier ablegen oder klicken.',
    importSuccessfull: 'Daten wurden erfolgreich importiert',
    successfull: 'Erfolgreiche Imports:',
    failed: 'Fehlgeschlagene Imports:',
    failedRows: 'In Folgenden Zeilen traten Fehler auf:'
  },
  navBar: {
    changeSearch: 'Suche anpassen',
    saveSearch: 'Suche speichern',
    resetSearch: 'Neue Suche',
    loadSearch: 'Suche laden',
    sendOrder: 'Interviewliste',
    logout: 'Abmelden',
    admin: 'Einstellungen',
    returnToSearchPage: 'Zurück zur Suchseite',
    returnToResultsPage: 'Zurück zur Detailansicht',
    markLists: 'Markierungen',
    search: 'Semantische Suche'
  },
  registerPage: {
    inputFields: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      password: 'Neues Passwort wählen',
      passwordRepeated: 'Neues Passwort wiederholen',
      phoneNumber: 'Telefonnummer'
    },
    passwordRequirements: [
      'Mindestlänge 8 Zeichen',
      'Mindestens ein Sonderzeichen',
      'Mindestens eine Zahl',
      'Mindestens einen Großbuchstaben',
      'Mindestens einen Kleinbuchstaben'
    ],
    button: 'Registrierung abschließen',
    errorMsg: {
      newAndOldPasswordIsIdenticital: 'Bitte wählen Sie ein neues Passwort'
    }
  },
  loginPage: {
    headline: 'Login Seite',
    errorMsg: {
      error400: 'Bitte überprüfen Sie Ihre Login-Daten!',
      accountLocked: 'Ihr Nutzer ist gesperrt!',
      startPasswordExpired: 'Ihr Start Passwort ist abgelaufen',
      accessOnlyToDifferentPortal:
        'Fehlende Berechtigungen zum Login für dieses Portal'
    },
    inputfield: {
      email: {
        label: 'E-Mail'
      },
      password: {
        label: 'Passwort'
      },
      tan: {
        label: 'TAN'
      }
    },
    button: 'Login',
    imageAlt: 'Match-Flix Logo'
  },
  registerTanPage: {
    headline: '2-Faktor Authentifizierung',
    description:
      'Aktivieren Sie die 2 Faktor Authentifizierung, um die Sicherheit Ihres Accounts zu erhöhen.',
    steps: [
      {
        headline: 'Schritt 1',
        description:
          'Downloaden Sie den "Google Authenticator" aus dem App Store Ihres Smartphones'
      },
      {
        headline: 'Schritt 2',
        description: 'Scannen Sie den QR Code mit dem „Google Authenticator“'
      },
      {
        headline: 'Schritt 3',
        description:
          'Geben Sie die angezeigte sechsstellige TAN ein und schließen Sie die Aktivierung ab'
      }
    ],
    tanFieldLabel: 'Initiale TAN',
    buttonValidateTan: 'TAN validieren',
    buttonRedirect: 'Zum Portal',
    successMessage:
      'Die Zwei-Faktor Authentifizierung wurde erfolgreich aktiviert',
    errorMessage:
      'Ihre eingegebene Tan ist nicht gültig. Versuchen Sie es erneut!'
  },
  searchPage: {
    searchResultCountBox: {
      title: 'Treffer der aktuellen Suche:',
      greyText: 'Bitte geben Sie einen Suchbegriff ein.',
      greenText:
        'Erfolgreich! Suche weiter einschränken oder die Kandidaten anzeigen lassen.',
      redText:
        'Zu viele Suchergebnisse, bitte schränken Sie die Suche weiter ein.',
      button: 'Trefferliste anzeigen'
    },
    saveSearchInfoMessage: 'Suche wurde gespeichert',
    searchInputField: {
      mandatoryPlaceholder: 'Weiteres Muss-Kriterium',
      optionalPlaceholder: 'Optional / Oder auch',
      errorMessage: 'Ungültige Eingabe'
    },
    sendSearchBox: {
      headline: 'Angaben zum Arbeitgeber',
      postalCodeInfoMessage:
        'Vor dem Absenden muss noch eine Postleitzahl hinterlegt werden', // NOT USED
      sendSearchBoxMessage:
        'Bitte bewerten Sie noch die angebotene Stelle für eine Berechnung der Wechselwilligkeit',
      postalCodeLabel: 'Postleitzahl des Arbeitgebers',
      postalCodeError:
        'Bitte geben Sie eine fünfstellige numerische Postleitzahl ein',
      starsLabel1: 'Attraktives Gehaltsniveau',
      starsLabel2: 'Attraktiver Arbeitgeber',
      starsLabel3: 'Attraktiver Wohnort',
      starsLabel4: 'Karriereangebot',
      cancelButton: 'Abbrechen',
      continueButton: 'Weiter zur Trefferliste'
    },
    helpBox: {
      onFirstSearchField: 'Für welche Position / Funktion suchen Sie?',
      onOtherSearchFieldsWithoutSuggestion:
        'Welche weiteren Kriterien sind wichtig?',
      onOtherSearchFieldsWithSuggestions:
        'Welche weiteren Kriterien sind wichtig? Andere User suchten in Verbindung mit Ihren Suchkriteren auch nach:'
    },
    tooltip: 'Zusätzlich wird nach folgenden Begriffen gesucht: '
  },

  companyArchive: {
    searchFieldErrorMessage: 'Bitte geben Sie einen gültigen Suchbegriff ein.',
    addButton: {
      createCompanyEntry: 'Unternehmen anlegen',
      excelImport: 'Excel Import',
      createContactEntry: 'Ansprechpartner',
      createCommentEntry: 'Kontakthistorie',
      createReminder: 'Wiedervorlage ',
      createProject: 'Auftrag anlegen'
    },

    createProjectModal: {
      headline: 'Neuen Auftrag anlegen?',
      description: [
        'Wollen Sie wirklich einen neuen Auftrag für die folgende Firma anlegen?',
        'Die Auftragsnummer wird automatisch generiert. Optional können Sie eine Auftragsnummer wählen, die noch nicht vergeben ist: '
      ],
      buttonPrimaryText: 'Bestätigen'
    },

    companyDetails: {
      markCompanyButton: 'Unternehmen speichern',
      listOfInfoSections: [
        {
          companyTypeSection: {
            companyType: 'Unternehmenszusatz'
          }
        },
        {
          address: {
            street: 'Straße',
            postbox: 'Postfach',
            postalCode: 'PLZ',
            city: 'Stadt',
            country: 'Land'
          }
        },
        {
          contactInfo: {
            phoneNumber: 'Telefonnummer',
            faxNumber: 'Faxnummer'
          }
        },
        {
          urls: {
            url: 'URL',
            urlCareerPage: 'Karriereseite'
          }
        },
        {
          general: {
            advisor: 'Betreuer',
            category: 'Kategorie',
            industrySector: 'Industriesektor',
            companySize: 'Unternehmensgröße'
          }
        },
        {
          comments: {
            comment: 'Bemerkungen'
          }
        },
        {
          general: {
            id: 'Unternehmens ID'
          }
        }
      ],
      companyStatus: {
        available: 'verfügbar',
        globalLock: 'Rahmenvertrag',
        clientLock: 'Kundenschutz',
        acquisitionLock: 'Akquiseschutz'
      }
    },
    pastContacts: {
      // mapping for pastContacts table
      companyStatus: {
        notReached: 'AP nicht erreicht',
        currentlyNoInterest: 'derzeit kein Bedarf',
        stayInContact: 'in Kontakt bleiben',
        noFeedback: 'kein Feedback',
        noInterest: 'kein Interesse',
        newCounterPartRecommanded: 'neuen AP empfohlen',
        appointmentConfirmed: 'Termin bestätigt',
        appointmentIntiation: 'Termin in Anbahnung',
        appointmentArranged: 'Termin vereinbart',
        appointmentMoved: 'Termin verschoben',
        documentsSend: 'Unterlagen zugeschickt',
        returnDocuments: 'Unterlagen zuschicken',
        reminderSet: 'Wiedervorlage vereinbart'
      },
      // mapping for pastContacts table
      contactType: {
        call: 'telefonisch',
        email: 'E-Mail',
        letter: 'Brief',
        personal: 'persönlich'
      }
    },
    addCounterPartPopUp: {
      headline: 'Ansprechpartner hinzufügen',
      headlineUpdate: 'Ansprechpartner bearbeiten',
      inputFields: {
        salutation: 'Anrede',
        title: 'Titel',
        firstName: 'Vorname',
        lastName: 'Nachname',
        phoneNumber: 'Telefon',
        phoneNumberMobile: 'Mobilfunk',
        faxNumber: 'Faxnummer',
        mailAddress: 'E-Mail',
        division: 'Bereich',
        hierarchyLevel: 'Hierarchieebene',
        advisor: 'Betreuer'
      },
      buttons: {
        cancel: 'Abbrechen',
        save: 'Speichern'
      },
      snackBarText: {
        addCounterPartCall: {
          success: 'Der Ansprechpartner wurde erfolgreich hinzugefügt',
          failure: 'Der Ansprechpartner konnte nicht hinzugefügt werden'
        },
        updateCounterPartCall: {
          success: 'Der Ansprechpartner wurde erfolgreich geändert',
          failure: 'Der Ansprechpartner konnte nicht geändert werden'
        }
      }
    },
    pastContactCreateUpdatePopUp: {
      headline: 'Kommunikationseintrag hinzufügen',
      inputFields: {
        contactType: 'Art des Kontakts', // dropdown values hardcoded below
        contactPerson: 'Ansprechpartner', // free text field
        companyStatus: 'Ergebnis', // dropdown values hardcoded below
        comment: 'Bemerkung', // free text field
        contactDate: 'Datum',
        intermediary: 'Akquisiteur'
      },
      dropDowns: {
        general: {
          pleaseSelect: 'Bitte wählen Sie'
        },
        companyStatus: [
          { key: 'notReached', label: 'AP nicht erreicht' },
          { key: 'currentlyNoInterest', label: 'derzeit kein Bedarf' },
          { key: 'stayInContact', label: 'in Kontakt bleiben' },
          { key: 'noFeedback', label: 'kein Feedback' },
          { key: 'noInterest', label: 'kein Interesse' },
          { key: 'newCounterPartRecommanded', label: 'neuen AP empfohlen' },
          { key: 'appointmentConfirmed', label: 'Termin bestätigt' },
          { key: 'appointmentIntiation', label: 'Termin in Anbahnung' },
          { key: 'appointmentArranged', label: 'Termin vereinbart' },
          { key: 'appointmentMoved', label: 'Termin verschoben' },
          { key: 'documentsSend', label: 'Unterlagen zugeschickt' },
          { key: 'returnDocuments', label: 'Unterlagen zuschicken' },
          { key: 'reminderSet', label: 'Wiedervorlage vereinbart' }
        ],
        contactType: [
          { key: 'call', label: 'telefonisch' },
          { key: 'email', label: 'E-Mail' },
          { key: 'letter', label: 'Brief' },
          { key: 'personal', label: 'persönlich' }
        ]
      },
      buttons: {
        cancel: 'Abbrechen',
        save: 'Speichern'
      },
      snackBarText: {
        addPastContactCall: {
          success: 'Der Kommunikationseintrag wurde erfolgreich hinzugefügt',
          failure: 'Der Kommunikationseintrag konnte nicht hinzugefügt werden'
        },
        updatePastContactCall: {
          success: 'Der Kommunikationseintrag wurde erfolgreich geändert',
          failure: 'Der Kommunikationseintrag konnte nicht geändert werden'
        }
      }
    },
    addReminderPopup: {
      headline: 'Wiedervorlage hinzufügen',
      inputFields: {
        contactType: 'Art des Kontakts', // dropdown values hardcoded below
        contactPerson: 'Ansprechpartner', // free text field
        companyStatus: 'Ergebnis', // dropdown values hardcoded below
        comment: 'Bemerkung' // free text field
      },
      dropDowns: {
        general: {
          pleaseSelect: 'Bitte wählen Sie'
        }
      },
      buttons: {
        cancel: 'Abbrechen',
        save: 'Speichern'
      },
      snackBarText: {
        success: 'Die Wiedervorlage wurde erfolgreich hinzugefügt',
        failure: 'Der Wiedervorlage konnte nicht hinzugefügt werden'
      }
    },
    updateCompanyStatusPopUp: {
      headline: 'Sperre anpassen',
      inputFields: {
        statusType: 'Art der Sperre', // dropDown
        partner: 'Partner', // dropDown
        creationDate: 'Gültig ab:', // date field
        expirationDate: 'Gültig bis einschließlich' // date field
      },
      dropDowns: {
        general: {
          pleaseSelect: 'Bitte wählen Sie'
        },
        statusType: [
          { key: 'available', label: 'keine Sperre' },
          { key: 'globalLock', label: 'Rahmenvertrag' },
          { key: 'clientLock', label: 'Kundenschutz' },
          { key: 'acquisitionLock', label: 'Akquiseschutz' }
        ],
        partner: [
          // { key: 'all', label: 'übergreifend' },
          { key: '5ba1161e16b86c5e749a86bf', label: 'PT Landshut' },
          { key: 'todo', label: 'TODO' }
        ]
      },
      buttons: {
        cancel: 'Abbrechen',
        save: 'Speichern'
      },
      snackBarText: {
        success: 'Der Firmensperre wurde erfolgreich geändert',
        failure: 'Der Firmensperre konnte nicht geändert werden'
      }
    }
  },
  resultPage: {
    errorUserTrysToSelectMoreThan10Candidates:
      'Sie können maximal 10 Kandidaten markieren',
    resetFiltersButton: 'Filter leeren',
    sortingOptions: {
      matchPercentage: 'Passgenauigkeit',
      willingnessToChange: 'Wechselwahrscheinlichkeit',
      postalcode: 'Entfernung',
      age: 'Alter'
      // salary: 'Gehalt',
      // willingnessToRelocate: 'Umzugsbereitschaft'
    },
    skillDetails: {
      languageTableHeading: {
        leftColumn: 'Sprache',
        rightColumn: 'Grad der Beherrschung'
      },
      otherSkillsTableHeading: {
        leftColumn: 'EDV-/Sonstige Kenntnisse',
        rightColumn: 'Grad der Beherrschung'
      }
    },
    sideBar: {
      skillLevel0: 'Anfänger',
      skillLevel1: 'Grundkenntnisse (A1)',
      skillLevel2: 'erweiterte Grundkenntnisse (A2)',
      skillLevel3: 'gute Kenntnisse (B1)',
      skillLevel4: 'sehr gute Kenntnisse (B2)',
      skillLevel5: 'verhandlungssicher / fließend (C1)',
      skillLevel6: 'Muttersprache / Dolmetscher (C2)',
      documentsView: {
        selectDoc: {
          documentName: 'Dokument',
          label: 'Dokument auswählen'
        },
        downloadButton: 'Dokument herunterladen',
        pdfErrorNoDocumentAvailable:
          'Kein Dokument vorhanden oder kein Dokument ausgewählt.',
        candidateHasNoDocs: 'Dieser Kandidat verfügt über keine Dokumente.',
        candidateHasNoTaggedDocs:
          'Dieser Kandidat verfügt über keine getaggten Dokumente.',
        fileOnlyAsDownload: 'Diese Datei wird nur als Download bereitgestellt.'
      }
    },
    selectCandidateForSidebar:
      'Wählen Sie einen Kandidaten aus, um weitere Informationen anzuzeigen.',
    amountOfHits: 'Trefferanzahl:',
    labelRowsPerPage: 'Kandidaten pro Seite:',
    labelPaginationFromTo1: 'bis',
    labelPaginationFromTo2: 'aus',
    boostYourResults: 'Gewichten Sie Ihre Suche',
    adoptChanges: 'Änderungen übernehmen',
    boostDescription:
      'Markieren Sie einzelne Suchbegriffe, die für Sie besonders relevant sind',
    results: 'Suchergebnisse:',
    noJobDescription: 'Keine Angaben zum Job oder den Branchen.',
    lastJobs: 'Bisherige Positionen:',
    languageSkills: 'Sprachkenntnisse:',
    additionalSkills: 'EDV-Kenntnisse:',
    branches: 'Branchen:',
    description: 'Berufstätigkeit:',
    noJobDuration: 'Anstellungsdauer nicht vorhanden.',
    available: 'verfügbar',
    notAvailable: 'nicht verfügbar',
    age: 'Alter:',
    lastChange: 'Letzte Änderung:',
    phoneNumber: 'Telefon:',
    mailAddress: 'E-Mail:',
    candidateId: 'Kandidatennummer',
    addToInterviewList: 'Zur Interviewliste hinzufügen',
    isInInterviewList: 'In Interviewliste',
    textCopied: 'Kandidatennummer wurde kopiert.',
    expandButton: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen'
    },
    addToOrder: 'zu Auftrag hinzufügen',
    matchPercentage: 'Passgenauigkeit',
    willingnessToChange1: 'Wechselwahr',
    willingnessToChange2: 'scheinlichkeit',
    noResults: 'Keine Ergebnisse passen zu Ihren Filterkriterien.',
    company: 'Name der Firma',
    firstName: 'Vorname',
    lastName: 'Nachname',
    lastPositions: {
      currentPosition: 'Derzeitige Position (',
      formerPosition: 'Letzte Position (',
      years: 'Jahre',
      months: 'Monate'
    },
    sendOrderBox: {
      sendOrder: 'Interviewanfrage absenden',
      yourCandidates: 'Ihre ausgewählten Kandidaten:',
      yourOrder: 'Ihre Interviewanfrage',
      lastJob: 'Letzter Job:',
      success: 'Ihre Interviewanfrage wurde erfolgreich übermittelt!',
      successText:
        'Wir werden uns sobald wie möglich mit Ihnen in Verbindung setzen.',
      startNextSearch: 'Weitere Suche starten'
    },
    saveSearchBox: {
      headline: 'Suche speichern',
      description:
        'Bitte geben Sie einen Namen für Ihre zu speichernde Suche ein:',
      labelInputField: 'Beschreibung der Suche',
      leftButton: 'Abbrechen',
      rightButton: 'Speichern'
    }
  },
  errorPage: {
    headlineLarge: 'Oh, No...',
    headlineSmall: 'Technischer Fehler',
    infoMessage:
      'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    tryAgain: 'Erneut versuchen'
  },
  markListPopup: {
    headline: {
      candidate: 'Kandidat zur Liste hinzufügen',
      company: 'Unternehmen zur Liste hinzufügen',
      project: 'Auftrag zur Liste hinzufügen'
    },
    placeholder: 'Listen filtern oder neue Liste erstellen',
    addItemToMarkListButton: 'Kandidat speichern',
    button: 'Zu neuer Liste hinzufügen',
    subheadline: 'Zu bestehender Liste hinzufügen:',
    noExistingList: 'Es existieren keine gespeicherten Listen',
    cancelButton: 'Abbrechen ',
    addItemToMarkListInfo: 'Kandidat zur Liste hinzufügen',
    itemIsInListInfo: 'Kandidat ist bereits in der Liste vorhanden',
    addProjectToMarkListInfo: 'Auftrag zur Liste hinzufügen',
    projectIsInListInfo: 'Auftrag ist bereits in der Liste vorhanden',
    companyIsInListInfo: 'Unternehmen ist bereits in der Liste vorhanden',
    addCompanyToListInfo: 'Unternehmen zur Liste hinzufügen',
    snackBarItemAddCandidateInfoToList: 'Kandidat wurde zu Liste "',
    snackBarItemAddCandidateInfoToNewList: 'Kandidat wurde zu neuer Liste "',
    snackBarItemAddProjectInfoToList: 'Auftrag wurde zu Liste "',
    snackBarItemAddProjectInfoToNewList: 'Auftrag wurde zu neuer Liste "',
    addedInfo: '" hinzugefügt',
    snackbarInfoCompany: {
      addItemToNewMarkList: 'Unternehmen wurde zu Liste "',
      addItemToExistingMarkList: 'Unternehmen wurde zu neuer Liste "'
    }
  },
  adminPage: {
    createUserButton: 'Nutzer hinzufügen',
    headline: 'Nutzerverwaltung',
    userDetailsTable: {
      labels: {
        name: 'Name',
        email: 'E-Mail',
        company: 'Firmenzugehörigkeit',
        roles: 'Rollen',
        scope: 'Berechtigungen',
        status: 'Status',
        actions: 'Aktionen'
      }
    },
    createUserPopup: {
      successPopup: {
        headline: 'Nutzer erfolgreich angelegt',
        description:
          'Der Nutzer erhält in Kürze eine E-Mail mit initialen Login-Daten, um die Registrierung abzuschließen.',
        buttonClose: 'Schließen',
        selectPartner: 'Systempartner auswählen',
        buttonAgain: 'Weitere Nutzer einladen'
      },
      headline: 'Nutzer erstellen',
      description:
        'Laden Sie einen Nutzer zur Registrierung ein. Der Nutzer erhält eine E-Mail mit den initialen Login-Daten und kann die Registrierung abschließen',
      inputFields: {
        email: 'E-Mail',
        company: 'Unternehmen (z.B. Würth)'
      },
      checkbox: {
        roles: {
          label: 'Rollen',
          admin: 'Admin',
          employee: 'Interner Mitarbeiter',
          client: 'Externer Kunde'
        },
        partnerCompany: {
          label: 'Marke',
          personaltotal: 'Personal-Total',
          senator: 'Senator Partners',
          lienert: 'Lienert'
        },
        partnerList: {
          label: 'Systempartner'
        },
        scope: {
          label: 'Berechtigungen',
          partner1: 'Senator',
          partner2: 'Personal-Total'
        }
      },
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Einladung versenden'
      },
      snackbarText: {
        userCreationSuccess: ' Nutzer wurde erfolgreich angelegt',
        userCreationFailure409: 'Fehler: Der Nutzer existiert bereits',
        userCreationFailure400:
          'Fehler: Der Nutzer konnte nicht angelegt werden, überprüfen Sie Ihre Eingaben'
      }
    },
    deleteUserModal: {
      headline: 'Nutzer löschen',
      description: 'Wollen Sie folgenden Nutzer permanent löschen?',
      buttonPrimaryText: 'Nutzer löschen'
    },
    resetUserModal: {
      headline: 'Login-Daten zurücksetzen?',
      description: [
        'Bei Bestätigung dieser Aktion wird das Passwort des folgenden Nutzers zurückgesetzt, ein neues Passwort per E-Mail versandt und eine ggf. vorliegende Account Sperrung aufgehoben. Der Nutzer wird erneut aufgefordert sein Passwort zu ändern und ggf. die 2-Faktor Authentifizierung einzurichten.',
        'Nutzerdaten (z.B. gespeicherte Markierungen des Nutzers) gehen bei dieser Aktion nicht verloren.'
      ],
      buttonPrimaryText: 'Bestätigen'
    },
    snackBar: {
      deleteUserSuccess: 'Nutzer wurde erfolgreich gelöscht',
      resetUserSuccess: 'Nutzer wurde erfolgreich zurückgesetzt'
    }
  },
  markListsPage: {
    categoryList: [
      { savedListsCandidates: 'Kandidaten Listen' },
      { savedListsProjects: 'Auftragslisten' },
      { savedListsCompanies: 'Unternehmenslisten' }
    ],
    noListsAvailable: 'Es wurden keine Markierungen gefunden',
    headline: 'Markierungen',
    emptyList: 'Die Liste ist leer',
    deleteListButton: 'Liste löschen',
    shareListButton: 'Liste teilen',
    serialMailButton: 'Serienmail',
    candidateDetailsButton: 'Detailansicht',
    companyDetailsButton: 'Detailansicht',
    projectDetailsButton: 'Detailansicht',
    deletionConfirmationModul: {
      headline: 'Liste löschen',
      description:
        'Wollen Sie die Liste und die gespeicherten Markierungen wirklich löschen?',
      buttonPrimaryText: 'Löschen'
    },
    removeItemConfirmationModul: {
      headline: 'Kandidaten aus Liste entfernen',
      description: 'Wollen Sie den Kandidaten aus der Liste entfernen?',
      buttonPrimaryText: 'Entfernen'
    },
    removeCompanyConfirmationModul: {
      headline: 'Unternehmen aus Liste entfernen',
      description: 'Wollen Sie das Unternehmen aus der Liste entfernen?',
      buttonPrimaryText: 'Entfernen'
    },
    removeProjectConfirmationModul: {
      headline: 'Auftrag aus Liste entfernen',
      description: 'Wollen Sie den Auftrag aus der Liste entfernen?',
      buttonPrimaryText: 'Entfernen'
    },
    snackBar: {
      success: {
        deleteMarkList: 'Die Liste wurde erfolgreich gelöscht!',
        removeItemFromMarkList:
          'Der Kandidat wurde erfolgreich aus der Liste entfernt!',
        removeCompanyFromMarkList:
          'Der Unternehmen wurde erfolgreich aus der Liste entfernt!',
        genericText: 'Eintrag wurde entfernt!'
      }
    },
    noListsExisting: 'Es existieren keine gespeicherten Listen'
  },
  filter: {
    mainFilter: {
      headline: 'Filter nach Personendetails',
      matchPercentage: 'Übereinstimmung:',
      age: 'Alter',
      name: 'Name',
      companyName: 'Firma',
      willingnessToChange: 'Wechselwilligkeit',
      salary: 'Gehalt',
      postalCode: 'Distanz zur angegebenen Postleitzahl',
      willingnessToRelocate: {
        headline: 'Umzugsbereitschaft',
        dropdown: [
          { key: 'all', value: 'Keine Einschränkung' },
          { key: 'yes', value: 'Ja' },
          { key: 'no', value: 'Nein' },
          { key: 'notspecified', value: 'Keine Angabe' }
        ]
      },
      showMarked: {
        headline: 'Nur markierte Kandidaten anzeigen'
      }
    },
    jobFilter: {
      headline: 'Filter nach Jobdetails',
      durationCurrentJob: {
        headline: 'Dauer der aktuellen Position',
        labels: {
          label1: '<1 Jahr',
          label2: '1-2 Jahre',
          label3: '2-5 Jahre',
          label4: '5-10 Jahre',
          label5: '>10 Jahre'
        }
      },
      currentPosition: {
        headline: 'Aktuelle Position',
        placeholderFilteredOptions: 'Filteroptionen einschränken'
      },
      workExperience: {
        headline: 'Berufserfahrung',
        labels: {
          label1: '<1 Jahr',
          label2: '1-2 Jahre',
          label3: '2-5 Jahre',
          label4: '5-10 Jahre',
          label5: '>10 Jahre'
        }
      },
      industry: {
        headline: 'Branche'
      },
      currentEmployer: {
        headline: 'Aktueller Arbeitgeber'
      }
    },
    sticky: {
      headline: 'Gesetzte Filter'
    }
  },
  commonTerms: {
    cancel: 'Abbrechen',
    ok: 'Ok',
    logout: 'Logout',
    NA: 'N/A'
  },
  errorHandling: {
    networkError: 'Bitte überprüfen Sie Ihre Internetverbindung',
    httpError: 'Es ist ein technischer Fehler aufgetreten'
  },
  validationErrorMessages: {
    isEmail: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
    isText: 'Die Eingabe beinhaltet ungülte Zeichen.',
    isNumerical: 'Bitte geben Sie nur Zahlen zwischen 0-9 ein.',
    isPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    isDate: 'Bitte geben Sie ein gültiges Datum ein.',
    isEmpty: 'Dieses Feld ist ein Pflichtfeld!',
    isPostalCode: 'Bitte geben Sie eine gültige Postleitzahl ein.',
    isUrl: 'Bitte geben Sie eine gültige URL ein.'
  },
  snackBarTexts: {
    updateSuccess: 'Die Änderung wurde erfolgreich gespeichert',
    noUpdate: 'Es wurden keine Änderungen vorgenommen',
    noCreate: 'Die Daten konnten nicht gespeichert werden',
    createSuccess: 'Die Daten wurden erfolgreich gespeichert',
    deleteSuccess: 'Der Eintrag wurde erfolgreich entfernt',
    noDelete: 'Der Eintrag konnte nicht entfernt werden'
  }
};
module.exports = text;
