import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Material UI
import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
// Icons
import CompareArrows from '@material-ui/icons/CompareArrows';

import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import Tooltip from '@material-ui/core/Tooltip';
import { DatePicker } from 'material-ui-pickers';
import ValidatedTextField from './ValidatedTextField';

const styles = () => ({
  cssLabel: {
    color: purple[500],
    '&$cssFocused': {
      color: purple[500]
    }
  },
  cssLabelGreen: {
    color: green[500],
    '&$cssFocused': {
      color: green[500]
    }
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: purple[500]
    }
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: purple[500]
    }
  },
  cssUnderlineGreen: {
    '&:after': {
      borderBottomColor: green[500]
    }
  },
  cssOutlinedInputGreen: {
    '&$cssFocused $notchedOutline': {
      borderColor: green[500]
    }
  },
  notchedOutline: {}
});
class SwitchInputField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      valueType: 'newValue'
    };
  }

  componentDidMount() {
    if (!this.props.newValue) {
      this.setState({ valueType: 'oldValue' });
      if (this.props.isDate) {
        this.props.onChange(this.props.oldValue, this.props.id);
      } else {
        this.props.onChange({
          target: { value: this.props.oldValue, id: this.props.id }
        });
      }
    }
  }

  handleClickSwitchInput = () => {
    let nextValueType;
    switch (this.state.valueType) {
      case 'newValue':
        nextValueType = 'oldValue';
        break;
      case 'oldValue':
        nextValueType = 'newValue';
        break;

      default:
        nextValueType = 'oldValue';
        break;
    }
    this.setState({
      valueType: nextValueType
    });
    if (this.props.isDate) {
      this.props.onChange(this.props[nextValueType], this.props.id);
    } else {
      this.props.onChange({
        target: { value: this.props[nextValueType], id: this.props.id }
      });
    }
  };

  onChange = e => {
    this.setState({
      valueType: 'customValue'
    });
    this.props.onChange(e);
  };

  onDateFieldChange = (date, id) => {
    this.setState({
      valueType: 'customValue'
    });
    this.props.onChange(date, id);
  };

  isInputValid() {
    console.log('isValid');
    return true;
  }

  render() {
    const { classes } = this.props;
    let cssLabel;
    let cssInput;
    switch (this.state.valueType) {
      case 'newValue':
        cssLabel = classes.cssLabelGreen;
        cssInput = classes.cssOutlinedInputGreen;
        break;
      case 'oldValue':
        cssLabel = classes.cssLabel;
        cssInput = classes.cssOutlinedInput;
        break;
      default:
        break;
    }
    if (this.props.isDate) {
      return (
        <div className="picker">
          <DatePicker
            {...this.props}
            keyboard
            format="dd.MM.yyyy"
            cancelLabel="Abbrechen"
            mask={value =>
              // handle clearing outside if value can be changed outside of the component
              value
                ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
                : []
            }
            // value={this.props.value === '' ? null : this.props.value}
            onChange={date => this.onDateFieldChange(date, this.props.id)} // TODO: new change method
            disableOpenOnEnter
            variant="outlined"
            animateYearScrolling={false}
            InputProps={{
              classes: {
                root: cssInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={this.state.valueType}>
                    <IconButton onClick={this.handleClickSwitchInput}>
                      <CompareArrows />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </div>
      );
    }
    return (
      <ValidatedTextField
        {...this.props}
        ref={this.props.forwardRef}
        color="#f44"
        variant="outlined"
        onChange={this.onChange}
        // style={{ border: '1px solid red', borderRadius: '4px' }}
        InputLabelProps={{
          classes: {
            root: cssLabel,
            focused: classes.cssFocused
          }
        }}
        InputProps={{
          classes: {
            root: cssInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline
          },
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={this.state.valueType}>
                <IconButton onClick={this.handleClickSwitchInput}>
                  <CompareArrows />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
    );
  }
}
SwitchInputField.defaultProps = {
  newValue: undefined,
  oldValue: undefined,
  isDate: false
};

SwitchInputField.propTypes = {
  newValue: PropTypes.string,
  oldValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isDate: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  forwardRef: PropTypes.object.isRequired
};
export default withStyles(styles)(SwitchInputField);
