import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { deletePastContactAPICall } from './api/companyArchiveAPI';
import ConfirmationModal from '../generic_components/ConfirmationModal';

// components
import PastContactCreateUpdatePopUp from './PastContactCreateUpdatePopUp';
// import * as CONSTANTS from '../constants';
// import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
// import TEXT from '../text';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import HTMLNotes from '../components/HTMLNotes';

const columns = [
  {
    name: 'ID',
    options: {
      display: 'excluded'
    }
  },

  {
    name: 'Datum',
    options: {
      sortDirection: 'asc',
      customBodyRender: value => UTILS.formatDateToDELocale(new Date(value))
    }
  },
  { name: 'Akquisiteur' },
  { name: 'Art der Kontaktaufnahme' },
  {
    name: 'Ansprechpartner',
    options: {
      filter: false,
      customBodyRender: value => {
        if (typeof value === 'string') {
          return value;
        }
        if (typeof value === 'object' && value.name) {
          return value.name;
        }
        return '';
      }
    }
  },
  { name: 'Ergebnis', options: { filter: true } },
  { name: 'Betreff' },
  {
    name: 'Bemerkung',
    options: {
      filter: false,
      customBodyRender: value => <HTMLNotes html={value} />
    }
  },

  { name: 'Aktionen', options: { filter: false } }
];

class CompanyComments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPastContactCreateUpdatePopUp: false,
      isDeletePastContactPopUpOpen: false,
      entryId: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleClosePastContactCreateUpdatePopUp = () => {
    this.setState({
      isPastContactCreateUpdatePopUp: false
    });
    this.props.handleClosePastContactCreateUpdatePopUp(true);
  };

  async deletePastContact() {
    try {
      await deletePastContactAPICall(this.state.entryId, this.props.companyId);
      this.props.handleSnackbarOpen(
        'Kontakthistorie Eintrag wurde erfolgreich gelöscht'
      );
      this.setState({ isDeletePastContactPopUpOpen: false });
      this.props.removePastContact(this.state.entryId);
    } catch (err) {
      this.errorHandling(err);
    }
  }

  renderButtons(entryId) {
    return (
      <div>
        <Tooltip title="Eintrag bearbeiten">
          <IconButton
            className=""
            aria-label="Edit"
            color="primary"
            onClick={() =>
              // TODO change entry id use
              this.setState({ isPastContactCreateUpdatePopUp: true, entryId })
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eintrag löschen">
          <IconButton
            className=""
            aria-label="Delete"
            color="primary"
            onClick={() =>
              this.setState({ entryId, isDeletePastContactPopUpOpen: true })
            }
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        formatedData.push([
          entry.id,

          entry.contactDate,
          `${entry.intermediary ? entry.intermediary.firstname : ''} ${
            entry.intermediary ? entry.intermediary.lastname : ''
          }`,
          entry.contactType,
          entry.contactPerson,
          entry.companyStatus,
          entry.subject,
          entry.comment,
          this.renderButtons(entry.id)
        ]);
      });
    return formatedData;
  }

  generateSelectedPastContactData(entryId) {
    const result = {};
    const foundEntry = this.props.data.find(entry => entry.id === entryId);
    Object.keys(foundEntry).forEach(key => {
      result[key] = foundEntry[key];
    });
    return result;
  }

  renderPastContactCreateUpdatePopUp() {
    return (
      <PastContactCreateUpdatePopUp
        open={this.state.isPastContactCreateUpdatePopUp}
        handleClose={this.handleClosePastContactCreateUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        companyId={this.props.companyId}
        isUpdate
        pastContactData={this.generateSelectedPastContactData(
          this.state.entryId
        )}
        liftUpUpdatePastContactDetails={
          this.props.liftUpUpdatePastContactDetails
        }
        counterParts={this.props.counterParts}
        employees={this.props.employees}
      />
    );
  }

  render() {
    return (
      <div>
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={() =>
            this.setState({ isDeletePastContactPopUpOpen: false })
          }
          open={this.state.isDeletePastContactPopUpOpen}
          buttonPrimaryAction={() => this.deletePastContact()}
          headlineText="Kontakthistorie Eintrag löschen"
          descriptionText="Soll der entsprechende Eintrag gelöscht werden?"
        />
        {this.state.isPastContactCreateUpdatePopUp &&
          this.props.employees &&
          this.props.employees.length > 0 &&
          this.renderPastContactCreateUpdatePopUp()}
        <div className="CompanyComments">
          <SafeMuiDatatable
            title="Kontakthistorie"
            tableName="companyComments"
            data={this.prepareDataForTable(this.props.data)}
            columns={columns}
          />
        </div>
      </div>
    );
  }
}
CompanyComments.propTypes = {
  data: PropTypes.array.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  liftUpUpdatePastContactDetails: PropTypes.func.isRequired,
  handleClosePastContactCreateUpdatePopUp: PropTypes.func.isRequired,
  counterParts: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  removePastContact: PropTypes.func.isRequired
};
export default CompanyComments;
