import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// material UI
import Fab from '@material-ui/core/Fab';
// Icons
import AddIcon from '@material-ui/icons/Add';

import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

import CreateTemplatePopUpContainer from './CreateTemplatePopUpContainer';
import UpdateTemplatePopUpContainer from './UpdateTemplatePopUpContainer';
import ViewHtmlPopUp from '../generic_components/ViewHtmlPopUp';
import TemplatesTable from './TemplatesTable';

class TemplatesTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || {},
      showCreateTemplateModular: false,
      showUpdateTemplateModular: false,
      showViewTemplateModular: false,
      selectedEntry: null,
      employees: []
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    if (!Object.keys(this.state.data).length) this.loadTemplates();
    this.getEmployeeListByPartnerAPICall();
  }

  getEmployeeListByPartnerAPICall() {
    const url = CONSTANTS.getEmployeeListByPartnerURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.setState({ employees: responseBody });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  loadTemplates = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const url = CONSTANTS.getTemplatesURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'x-auth': tokenFromLocalStorage
      },
      // data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        // console.log('API register response body: ', responseBody);
        console.log(
          'API /getPastSearchesOfClients response body: ',
          responseBody
        );
        this.setState({ data: responseBody.reverse() });
        this.props.liftUpStateToAdminPage({
          templateData: responseBody.reverse()
        });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        console.log('Error API /getPastSearchesOfClients err: ', err);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  };

  handleCloseCreateTemplatePopUp = () => {
    this.setState({ showCreateTemplateModular: false });
  };

  handleCloseUpdateTemplatePopUp = () => {
    this.setState({ showUpdateTemplateModular: false, selectedEntry: null });
  };

  handleCloseViewTemplatePopUp = () => {
    this.setState({ showViewTemplateModular: false, selectedEntry: null });
  };

  handleOpenUpdateTemplatePopUp = selectedEntryData => {
    this.setState({
      showUpdateTemplateModular: true,
      selectedEntry: selectedEntryData
    });
  };

  handleOpenViewTemplatePopUp = selectedEntryData => {
    this.setState({
      showViewTemplateModular: true,
      selectedEntry: selectedEntryData
    });
  };

  showCreateTemplate = () => {
    this.setState({
      showCreateTemplateModular: true
    });
  };

  renderCreateTemplatePopUp() {
    return (
      <CreateTemplatePopUpContainer
        open={this.state.showCreateTemplateModular}
        // key={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        handleClose={this.handleCloseCreateTemplatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshTemplates={this.loadTemplates}
        employees={this.state.employees}
        userId={this.props.user.id}
      />
    );
  }

  renderViewHtmlPopUp() {
    return (
      <ViewHtmlPopUp
        open={this.state.showViewTemplateModular}
        html={
          this.state.selectedEntry
            ? this.state.selectedEntry.templateContent
            : null
        }
        handleClose={this.handleCloseViewTemplatePopUp}
      />
    );
  }

  renderUpdateTemplatePopUp() {
    return (
      <UpdateTemplatePopUpContainer
        open={this.state.showUpdateTemplateModular}
        // key={this.state.selectedEntry.entryId}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        handleClose={this.handleCloseUpdateTemplatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshTemplates={this.loadTemplates}
        employees={this.state.employees}
        userId={this.props.user.id}
      />
    );
  }

  // PAGE: Admin.Monitoring
  render() {
    return (
      <div>
        {this.state.showCreateTemplateModular &&
          this.renderCreateTemplatePopUp()}
        {this.state.showViewTemplateModular && this.renderViewHtmlPopUp()}
        {this.state.showUpdateTemplateModular &&
          this.renderUpdateTemplatePopUp()}
        {this.state.data && this.state.data.length > 0 ? (
          <TemplatesTable
            handleOpenUpdateTemplatePopUp={this.handleOpenUpdateTemplatePopUp}
            handleOpenViewTemplatePopUp={this.handleOpenViewTemplatePopUp}
            data={this.state.data}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            refreshTemplates={this.loadTemplates}
          />
        ) : (
          <div>
            <div className="sideBarWrapper p-2">
              <div className="emptyPage">Legen Sie neue Templates an</div>
            </div>
          </div>
        )}
        <Fab
          buttonRef={node => {
            this.anchorEl = node;
          }}
          // disabled={this.state.currentTab !== 3}
          // variant="fab"
          color="primary"
          aria-label="Add"
          style={{ position: 'absolute', bottom: '30px', right: '30px' }}
          onClick={this.showCreateTemplate}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

TemplatesTab.defaultProps = {
  data: {}
};

TemplatesTab.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  liftUpStateToAdminPage: PropTypes.func.isRequired,
  data: PropTypes.array,
  user: PropTypes.object.isRequired
};
export default withUser(TemplatesTab);
