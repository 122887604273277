import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

// Basic utils, constants and configs
import IconButton from '@material-ui/core/IconButton';
import $ from 'jquery';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class MarkItemPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listName: '',
      isDisabled: true,
      savedLists: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.initialState = JSON.parse(JSON.stringify(this.state));
  }

  componentDidMount() {
    this.loadMarkListsAPICall();
  }

  handleClose = () => {
    // reset state except savedLists
    const initialState = this.initialState;
    initialState.savedLists = this.state.savedLists;
    this.setState({
      ...initialState
    });
    this.props.handleClose();
  };

  loadMarkListsAPICall() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.loadMarkListsURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify({ markListType: this.props.markListPopupType }),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /loadMarkLists response', responseBody, status);
        this.setState({
          savedLists: responseBody.allMarkListsOfUser
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  generateSnackBarMessage(requestPackage, responseBody) {
    if (this.props.item.candidate) {
      return `${
        responseBody.isNewList
          ? TEXT.markListPopup.snackBarItemAddCandidateInfoToList
          : TEXT.markListPopup.snackBarItemAddCandidateInfoToNewList
      }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
    }
    if (this.props.item.project) {
      return `${
        responseBody.isNewList
          ? TEXT.markListPopup.snackBarItemAddProjectInfoToList
          : TEXT.markListPopup.snackBarItemAddProjectInfoToNewList
      }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
    }
    return `${
      responseBody.isNewList
        ? TEXT.markListPopup.snackbarInfoCompany.addItemToNewMarkList
        : TEXT.markListPopup.snackbarInfoCompany.addItemToExistingMarkList
    }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
  }

  addUserItemToMarkList(listName = '') {
    this.setState({
      isDisabled: false
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const item = this.props.item;
    if (!(item.matchPercentage && item.willingnessToChange)) {
      item.matchPercentage = 0;
      item.willingnessToChange = 0;
    }
    const requestPackage = {
      item: this.props.item,
      listName: 'New List'
    };
    if (this.state.listName !== '' && listName === '') {
      requestPackage.listName = this.state.listName.trim();
    } else {
      requestPackage.listName = listName.trim();
    }
    const url = CONSTANTS.addItemToMarkListURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestPackage),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(
          'API /addItemToMarkList success response',
          responseBody,
          status
        );

        this.setState(
          {
            savedLists: responseBody.allMarkListsOfUser
          },
          this.handleClose()
        );
        // TODO does not check if trimmed list in MarkList Collection exists
        const infoMessage = this.generateSnackBarMessage(
          requestPackage,
          responseBody
        );
        this.props.handleSnackbarOpen(infoMessage);
      })
      .fail(err => {
        // TODO handle Error
        console.log('error while deletion of search', JSON.stringify(err));
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
    this.setState({
      isDisabled: true
    });
  }

  isItemInMarkList(savedList) {
    let result;
    if (savedList.companyMarks.length !== 0) {
      result = savedList.companyMarks.find(
        item => item.company === this.props.item.company
      );
    } else if (savedList.candidateMarks.length !== 0) {
      result = savedList.candidateMarks.find(
        item => item.candidate === this.props.item.candidate
      );
    } else if (savedList.projectMarks.length !== 0) {
      result = savedList.projectMarks.find(
        item => item.project === this.props.item.project
      );
    }
    if (result !== undefined) {
      return true;
    }
    return false;
  }

  isExistingList(listName) {
    return this.state.savedLists.some(list => list.name === listName);
  }

  markListLengthByContent(savedList) {
    if (this.props.item.company) {
      return `${savedList.name} (${savedList.companyMarks.length})`;
    }
    if (this.props.item.project) {
      return `${savedList.name} (${savedList.projectMarks.length})`;
    }
    return `${savedList.name} (${savedList.candidateMarks.length})`;
  }

  markListInfoByContentType(savedList) {
    if (this.props.item.candidate) {
      return savedList.candidateMarks.find(
        item => item.candidate === this.props.item.candidate
      )
        ? TEXT.markListPopup.itemIsInListInfo
        : TEXT.markListPopup.addItemToMarkListInfo;
    }
    if (this.props.item.project) {
      return savedList.projectMarks.find(
        item => item.project === this.props.item.project
      )
        ? TEXT.markListPopup.projectIsInListInfo
        : TEXT.markListPopup.addProjectToMarkListInfo;
    }
    return savedList.companyMarks.find(
      item => item.company === this.props.item.company
    )
      ? TEXT.markListPopup.companyIsInListInfo
      : TEXT.markListPopup.addCompanyToListInfo;
  }

  renderListItems() {
    let originList = [];
    if (this.state.listName === '') {
      originList = this.state.savedLists;
    } else {
      if (this.state.savedLists) {
        originList = this.state.savedLists.filter(item =>
          item.name
            .toLowerCase()
            .includes(this.state.listName.trim().toLowerCase())
        );
      }
      if (originList.length === 0) {
        return TEXT.markListPopup.noExistingList;
      }
    }

    const resultArray = [];

    if (originList) {
      originList.forEach(savedList => {
        const { _id: listId } = savedList;
        let markListLengthByType = 0;
        if (this.props.item.candidate) {
          markListLengthByType = savedList.candidateMarks.length;
        } else if (this.props.item.company) {
          markListLengthByType = savedList.companyMarks.length;
        } else if (this.props.item.project) {
          markListLengthByType = savedList.projectMarks.length;
        }
        if (markListLengthByType !== 0) {
          resultArray.push(
            <ListItem
              button
              color="primary"
              key={listId}
              disabled={this.isItemInMarkList(savedList)}
              onClick={() => this.addUserItemToMarkList(savedList.name)}
            >
              <ListItemText
                primary={this.markListLengthByContent(savedList)}
                secondary={this.markListInfoByContentType(savedList)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="AddToList"
                  disabled={this.isItemInMarkList(savedList)}
                  onClick={() => this.addUserItemToMarkList(savedList.name)}
                >
                  <i className="material-icons">add_circle</i>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
      });
    }
    return resultArray;
  }

  render() {
    return (
      // Max dialog height if list of Items to Large ?
      <Dialog
        open={this.props.open}
        aria-labelledby="markCandidatePopUp-dialog-title"
      >
        <DialogTitle>
          {TEXT.markListPopup.headline[this.props.markListPopupType]}
        </DialogTitle>
        <DialogContent>
          {/* TODO is texfield input limit required ? */}
          <TextField
            value={this.state.listName}
            name="listName"
            label={TEXT.markListPopup.placeholder}
            fullWidth
            autoFocus
            margin="normal"
            inputProps={{
              maxLength: 35
            }}
            onChange={e => {
              this.setState({
                listName: e.target.value,
                isDisabled:
                  e.target.value.length === 0 ||
                  this.isExistingList(e.target.value)
              });
            }}
          />
          <div className="float-right mb-4">
            <Button
              className="button-more-info"
              variant="contained"
              disabled={this.state.isDisabled}
              color="secondary"
              onClick={() => this.addUserItemToMarkList()}
            >
              {TEXT.markListPopup.button}
              <i className="material-icons pl-2">playlist_add</i>
            </Button>
          </div>
          <div className="container mt-5 pt-5">
            {this.renderListItems().length > 0 && (
              <DialogContentText>
                {TEXT.markListPopup.subheadline}
              </DialogContentText>
            )}
            <List>{this.renderListItems()}</List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {TEXT.markListPopup.cancelButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

MarkItemPopUp.defaultProps = {
  open: false,
  item: {}
};

MarkItemPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  item: PropTypes.shape({
    candidate: PropTypes.string,
    company: PropTypes.string,
    project: PropTypes.string,
    matchPercentage: PropTypes.number,
    willingnessToChange: PropTypes.number
  }),
  markListPopupType: PropTypes.string.isRequired
};

export default MarkItemPopUp;
