import $ from 'jquery';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// generic components
import DetailSearch from '../generic_components/DetailSearch';

// static data
import { staticResources } from './staticContent/PastContactCreateUpdatePopUpStaticResources';
import { getDropDownValues } from './staticContent/CompanyDetailSearchDropDown';
import { CompanyCreateUpdatePopUpText } from './staticContent/CompanyCreateUpdatePopUpStaticResources';
import { companyArchive } from '../text';

// utils
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';

class CompanyDetailSearch extends PureComponent {
  constructor(props) {
    super(props);
    const companyStatusList = Object.keys(
      companyArchive.companyDetails.companyStatus
    ).map(key => ({
      label: `${companyArchive.companyDetails.companyStatus[key]}`,
      value: `${key}`
    }));
    const partner = this.props.partnerList.map(entry => ({
      label: `${entry.partnerName} (${entry.partnerId})`,
      value: entry.id
    }));
    this.dropDownList = getDropDownValues(
      this.props.employeesList,
      partner,
      staticResources.dropDowns.contactType.content,
      companyStatusList,
      CompanyCreateUpdatePopUpText.industrySectorDropDown.dropDownList
    ).sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getCompanyListByDetailSearchAPICall = (
    detailSearchFieldList,
    maxAmountOfSearchResults
  ) => {
    const url = CONSTANTS.getCompanyListByDetailSearchTermsURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      detailSearchFieldList,
      maxAmountOfSearchResults
    };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.props.updateCompanyArchive(
          response,
          Number.parseInt(maxAmountOfSearchResults, 10)
        );
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  render() {
    return (
      <DetailSearch
        isDrawerOpen={this.props.isDrawerOpen}
        handleDrawerClose={this.props.handleDrawerClose}
        mainSearchFieldPlaceholderText={
          this.props.mainSearchFieldPlaceholderText
        }
        appBarTitle={this.props.appBarTitle}
        dropDownOptionDataList={this.dropDownList}
        onSearch={this.getCompanyListByDetailSearchAPICall}
        detailSearchType="company"
        handleSnackbarOpen={this.props.handleSnackbarOpen}
      />
    );
  }
}

CompanyDetailSearch.defaultProps = {
  mainSearchFieldPlaceholderText: 'Bitte suchen Sie nach einem Unternehmen',
  appBarTitle: 'Detailsuche für Unternehmen',
  isDrawerOpen: false,
  employeesList: [],
  partnerList: []
};

CompanyDetailSearch.propTypes = {
  mainSearchFieldPlaceholderText: PropTypes.string,
  appBarTitle: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  handleDrawerClose: PropTypes.func.isRequired,
  updateCompanyArchive: PropTypes.func.isRequired,
  employeesList: PropTypes.array,
  partnerList: PropTypes.array,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default CompanyDetailSearch;
