import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

// import TEXT from './staticContent/text';
function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    employees.forEach(employee => {
      if (employee.lastname) {
        const value = employee.id;
        const label = `${employee.firstname || ''} ${employee.lastname}`;
        const mappedEmployee = { value, label };
        mappedDropDownEntries.push(mappedEmployee);
      }
    });
  }
  return mappedDropDownEntries;
}

class UpdateTemplatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();
    // this.state = {
    // };
    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    console.log('debug updatedData', updatedData);
    const payload = {
      templateId: this.props.entryId,
      updatedTemplate: {
        createdByUser: updatedData.createdByUser,
        templateType: updatedData.templateType,
        templateName: updatedData.templateName,
        isEditingMode: updatedData.isEditingMode,
        templateHeading: updatedData.templateHeading,
        templateContent: updatedData.templateContent
      }
    };
    if (Object.keys(updatedData).length > 0) {
      const tokenFromLocalStorage = window.sessionStorage.getItem('token');
      const url = CONSTANTS.updateTemplateURL;
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(
            TEXT_GENERAL.snackBarTexts.updateSuccess
          );
          this.props.refreshTemplates();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    console.log('debug this.props.data', this.props.data);
    const forms = {
      formFields: {
        templateType: {
          value: this.props.data.templateType
            ? this.props.data.templateType
            : '',
          label: 'Templatetyp',
          type: 'dropDown',
          content: [
            { label: 'Signatur', value: 'signature' },
            { label: 'E-Mail Vorlage', value: 'serialMail' }
          ],
          required: true
        },
        createdByUser: {
          value: this.props.data.createdByUserId || this.props.userId,
          label: 'Nutzer (für Signatur)',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees),
          required: true
        },

        templateName: {
          value: this.props.data.templateName,
          label: 'Name des Templates',
          customClass: 'col-12 mb-2'
        },
        templateHeading: {
          value: this.props.data.templateHeading,
          label: 'Betreff (für E-Mail Template)',
          customClass: 'col-12 mb-2'
        },
        templateContent: {
          value: this.props.data.templateContent,
          type: 'editor',
          customClass: 'col-12 mt-5 mb-2',
          additionalProps: {
            init: {
              height: '400'
            }
          }
        }
      }
    };

    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    // console.log('DEBUG this.props.data', this.props.data);
    return (
      <div>
        <GenericFormPopUp
          open={this.props.open}
          headlineText="Neues Template erstellen"
          key="updateTemplate"
          genericFormProps={{
            formFields: this.form.formFields,
            handleSubmit: this.updateAPICall,
            handleClose: this.props.handleClose
          }}
        />
      </div>
    );
  }
}
UpdateTemplatePopUpContainer.defaultProps = {};

UpdateTemplatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshTemplates: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  entryId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default UpdateTemplatePopUpContainer;
