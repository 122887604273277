import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import NotesIcon from '@material-ui/icons/Notes';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';

import TOOL_TIPS from '../textTooltips';
import withUser from '../utils/withUser';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import TEXT_GENERAL from '../text';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class CandidateApplications extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.getColumns(),

      // for removal of potentialCandidate
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: '',
      projectIdForPotentialCandidateDeletionRequest: ''
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  // getProjectListByCandidateIdAPICall(candidateId) {
  //   const tokenFromLocalStorage = window.sessionStorage.getItem('token');
  //   const url = CONSTANTS.getProjectListByCandidateIdURL;

  //   const payload = {
  //     candidateId
  //   };
  //   console.log(`API ${url} request`, payload);
  //   $.ajax({
  //     url,
  //     method: 'POST',
  //     dataType: 'json',
  //     contentType: 'application/json; charset=utf-8',
  //     headers: { 'x-auth': tokenFromLocalStorage },
  //     data: JSON.stringify(payload),
  //     timeout: CONFIG.ajaxTimeout
  //   })
  //     .done((responseBody, status) => {
  //       console.log(`API /${url} response`, responseBody, status);
  //       this.setState({ data: responseBody.projects });
  //       this.props.refreshCandidate();
  //     })
  //     .fail(err => {
  //       this.errorHandling(err, url);
  //     });
  // }

  componentDidMount() {
    console.log(
      'this.props.selectedCandidateId',
      this.props.selectedCandidateId
    );
    // this.onDownloadButtonClick({ id: '5c38b3d5f09dd03b4b93717b' });
    // this.getProjectListByCandidateIdAPICall(this.props.selectedCandidateId);
  }

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'Auftragsnummer',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: (value, metaData) => (
            <Link
              to={`projects?id=${metaData.rowData[0]}&cid=${
                this.props.selectedCandidateId
              }&t=2`}
            >
              {value}
            </Link>
          )
        }
      },

      {
        name: 'Unternehmen',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Position',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Standort',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Datum (zum Auftrag hinzugefügt)',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      },
      {
        name: 'Abgesagt',
        options: {
          display: true,
          filter: false,
          customBodyRender: value =>
            value && <CheckIcon style={{ color: 'Red' }} />
        }
      },
      {
        name: 'Protokolle / Aktionen',
        options: {
          display: true,
          filter: false
        }
      }
      // {
      //   name: 'Aktionen',
      //   options: {
      //     display: 'true',
      //     filter: false
      //   }
      // }
    ];
  }

  handleRemoveEntryAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.removePotentialCandidateURL;

    const {
      potentialCandidateIdDeletionRequest,
      projectIdForPotentialCandidateDeletionRequest
    } = this.state;
    const payload = {
      projectId: projectIdForPotentialCandidateDeletionRequest,
      potentialCandidateId: potentialCandidateIdDeletionRequest,
      removeFrom: 'applied'
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        const oldPotentialCandidates = this.props.data;
        const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
          project =>
            project.potentialCandidateId === potentialCandidateIdDeletionRequest
        );
        const newPotentialCandidates = oldPotentialCandidates;
        newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
          ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
          applied: false
        };

        this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

        this.handleCloseConfirmationPopUp();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    });
  };

  renderDeleteConfirmationPopUp() {
    return (
      this.state.showDeleteConfirmationPopUp && (
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={this.handleCloseConfirmationPopUp}
          open={this.state.showDeleteConfirmationPopUp}
          buttonPrimaryAction={this.handleRemoveEntryAPICall}
          headlineText="Kandidat aus Auftrag entfernen?"
          descriptionText="Soll der Kandidat aus dem entsprechenden Auftrag entfernt werden und auftragspezifische Daten des Kandidatens gelöscht werden?"
        />
      )
    );
  }

  renderActionButtons(projectId, potentialCandidateId, isEditable, project) {
    return (
      <React.Fragment>
        {this.renderDownloadISheetButton(projectId, project)}
        {this.renderDownloadPSheetButton(projectId)}
        {this.props.user.role === 'admin' && (
          <IconButton
            className=""
            disabled={!isEditable}
            aria-label="Delete"
            color="primary"
            onClick={() => {
              this.setState({
                showDeleteConfirmationPopUp: true,
                potentialCandidateIdDeletionRequest: potentialCandidateId,
                projectIdForPotentialCandidateDeletionRequest: projectId
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </React.Fragment>
    );
  }

  // generateName(firstName, lastName) {
  //   let name = '';

  //   if (firstName) name = `${name + firstName} `;
  //   if (lastName) name = `${name + lastName} `;
  //   return name;
  // }

  mapIsReferred(isReferred) {
    if (isReferred === false) return 'nein';
    if (isReferred === true) return 'ja';
    return '';
  }

  onDownloadButtonClick(projectId) {
    console.log('DEBUG projectId', projectId);
    this.setState({
      isDownloadButtonActive: projectId
    });
    const url = CONSTANTS.getPSheetURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      candidateId: this.props.selectedCandidateId,
      projectId
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const file = `data:application/pdf;base64,${responseBody.buffer}`;
        const downloadElement = document.createElement('a');
        const fileName = 'P-Blatt.pdf';

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isDownloadButtonActive: ''
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null, [400, 500, 514]);
      });
  }

  onISheetDownloadButtonClick(projectId, project) {
    console.log('DEBUG projectId', projectId);
    this.setState({
      isISheetDownloadButtonActive: projectId
    });

    const url = CONSTANTS.getISheetURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      candidateId: this.props.selectedCandidateId,
      projectId
    };

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      let a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhttp.response);
        // Give filename you wish to download
        a.download = `${this.props.selectedCandidate.lastName} ${
          this.props.selectedCandidate.firstName
        }_${project.projectNumber}_${new Date().toLocaleDateString()}.docx`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        this.setState({
          isISheetDownloadButtonActive: ''
        });
      }
    };
    // Post data to URL which handles post request
    xhttp.open('POST', url);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('x-auth', tokenFromLocalStorage);
    // You should set responseType as blob for binary responses
    xhttp.responseType = 'blob';
    xhttp.send(JSON.stringify(requestBody));
  }

  renderDownloadISheetButton = (projectId, project) => (
    <Tooltip title={TOOL_TIPS.candidateArchive.downloadISheet}>
      <IconButton
        className="downloadISheetButton"
        color="primary"
        // disabled
        disabled={this.state.isISheetDownloadButtonActive === projectId}
        onClick={() => this.onISheetDownloadButtonClick(projectId, project)}
      >
        <NotesIcon />
      </IconButton>
    </Tooltip>
  );

  renderDownloadPSheetButton = projectId => (
    <Tooltip title={TOOL_TIPS.candidateArchive.downloadPSheet}>
      <IconButton
        className="downloadPSheetButton"
        color="primary"
        // disabled
        disabled={this.state.isDownloadButtonActive === projectId}
        onClick={() => this.onDownloadButtonClick(projectId)}
      >
        <NotesIcon />
      </IconButton>
    </Tooltip>
  );

  prepareDataForTable(data) {
    const formatedData = [];
    console.log('input data', data);
    if (data)
      data.forEach(entry => {
        // console.log('DEBUG CandidateStatus entry', entry);
        if (entry.applied) {
          const mappedData = {
            entryId: entry.id,
            projectNumber: entry.projectNumber,
            company: entry.companyName,
            jobTitle1: entry.jobTitle1,
            partner: entry.partner,
            addedDate: entry.addedDate,
            rejectionDate: entry.rejectionDate
          };

          formatedData.push([
            ...Object.values(mappedData),
            this.renderActionButtons(
              entry.id,
              entry.potentialCandidateId,
              entry.isEditable,
              entry
            )
          ]);
        }
      });
    console.log('formatedData', formatedData);
    return formatedData;
  }

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:nth-child(2)': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            '&:first-child': {
              paddingLeft: '30px'
            },
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        }
      }
    };
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: false,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      rowsPerPage: 100,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  // PAGE: Beworben auf
  render() {
    return (
      <div className="container-fluid">
        {this.renderDeleteConfirmationPopUp()}
        <div className="CandidateStatus row">
          <div className="col-12">
            {this.props.data &&
            this.props.data.length > 0 &&
            this.prepareDataForTable(this.props.data).length > 0 ? (
              <SafeMuiDatatable
                title="Beworben auf"
                tableName="candidateApplications"
                data={this.prepareDataForTable(this.props.data)}
                columns={this.state.columns}
                options={this.getOptions()}
                muiTheme={this.getTheme()}
              />
            ) : (
              <div>
                <div className="sideBarWrapper p-2">
                  <div className="candidateArchive-emptyPage">
                    Der Kandidat hat sich auf keinen Auftrag beworben
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CandidateApplications.defaultProps = {};

CandidateApplications.propTypes = {
  // refreshCandidate: PropTypes.func.isRequired,

  selectedCandidate: PropTypes.object.isRequired,
  selectedCandidateId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(CandidateApplications);
