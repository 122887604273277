import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';
// material UI

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
// import TEXT from '../text';

import NotificationList from '../notifications/NotificationList';

class NotificationPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { notifications: [] };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Benachrichtigungen - ${UTILS.getTitle()}`;

    this.loadNotifications();
  }

  loadNotifications = () => {
    const url = CONSTANTS.getAllNotificationsOfUserURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'x-auth': tokenFromLocalStorage
      },
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        // console.log('API register response body: ', responseBody);
        console.log('API /notifications response status: ', status);
        this.setState({ notifications: responseBody });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  };

  render() {
    return (
      <div className="container h-100-minusAppBar" key="maincontainer">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 pt-3">
            <NotificationList
              timeSeparation
              notifications={this.state.notifications}
              onIsReadButtonClick={this.loadNotifications}
              onDeleteButtonClick={this.loadNotifications}
              onRowClick={this.props.liftUpStateToApp}
            />
          </div>
        </div>
      </div>
    );
  }
}
NotificationPage.defaultProps = {};
NotificationPage.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired
};
export default NotificationPage;
