const baseUrl = 'https://api.cloud.match-flix.de'; // development
export const partner = 'lienert'; // personaltotal matchflix senator lienert // change for test purposes
// logic which view to show
export const isLienert = partner === 'lienert'; // dont change!!!

export function domain() {
  switch (partner) {
    case 'matchflix':
      return 'match-flix.de';
    case 'personaltotal':
      return 'personal-total.de';
    case 'senator':
      return 'senator-partners.de';
    case 'lienert':
      // return 'joerg-lienert.ch';
      return '';
    default:
      return '';
  }
}

export function parserMailAddress() {
  if (domain() === '') return '';
  const prefix = 'mailparser';
  const mailAddress = `${prefix}@${domain()}`;
  return mailAddress;
  // return '';
}

export const testUsers = [
  '5bc5e136233d354dac83f6d2', // Simon dev
  '5bac03bcb2abda2670253e34', // 	m.barhainski@senator-partners.de prod
  '5bd1ba8021dde851a36627ab', // Simon prod
  '5c33cdb7605d3f5115d12ebd' // Monika prod
];
// urls
export const amountOfResultsURL = `${baseUrl}/getAmountOfResults`;
export const getSuggestionsURL = `${baseUrl}/getSuggestionSearchTerms`;
export const getSearchResultsURL = `${baseUrl}/getSearchResults`;
export const getNASearchResultsURL = `${baseUrl}/getNASearchResults`;
export const getSearchResultsByMarkListIdURL = `${baseUrl}/getSearchResultsByMarkListId`;
export const getCandidateImage2URL = `${baseUrl}/getCandidateImages`;
export const loginURL = `${baseUrl}/login`;
export const logoutURL = `${baseUrl}/logout`;
export const verifyTokenURL = `${baseUrl}/user`;
export const saveSearchURL = `${baseUrl}/saveSearch`;
export const getSavedSearchesURL = `${baseUrl}/getSavedSearches`;
export const getDeleteSearchesURL = `${baseUrl}/deleteSavedSearch`;
export const sendOrderURL = `${baseUrl}/sendOrder`;
export const getCandidateDocumentByIdURL = `${baseUrl}/getCandidateDocumentById`;
export const inviteUserToRegistrationURL = `${baseUrl}/inviteUserToRegistration`;
export const getUsers = `${baseUrl}/users`;
export const registerURL = `${baseUrl}/register`;
export const get2FASecretURL = `${baseUrl}/get2FASecret`;
export const verifyTanURL = `${baseUrl}/verifyTan`;
export const addItemToMarkListURL = `${baseUrl}/addItemToMarkList`;
export const loadMarkListsURL = `${baseUrl}/loadMarkLists`;
export const loadPopulatedMarkListsURL = `${baseUrl}/loadPopulatedMarkLists`;
export const deleteMarkListURL = `${baseUrl}/deleteMarkList`;
export const shareMarkListURL = `${baseUrl}/shareMarkList`;
export const removeItemFromMarkListURL = `${baseUrl}/removeItemFromMarkList`;
export const deleteUserURL = `${baseUrl}/deleteUser`;
export const resetUserURL = `${baseUrl}/resetUser`;
export const getCompanyURL = `${baseUrl}/getCompany`;
export const getCompanyListByIdsURL = `${baseUrl}/getCompanyListByIds`;
export const getCompanyListBySearchTermURL = `${baseUrl}/getCompanyListBySearchTerm`;
export const getCompanyListByDetailSearchTermsURL = `${baseUrl}/getCompanyListByDetailSearchTerms`;
export const createCompanyContactURL = `${baseUrl}/createCompanyContact`;
export const createCompanyContactHistoryEntryURL = `${baseUrl}/createCompanyContactHistoryEntry`;
export const updateCompanyContactHistoryEntryURL = `${baseUrl}/updateCompanyContactHistoryEntry`;
export const updateCounterPartDetailsURL = `${baseUrl}/updateCounterPartDetails`;
export const createCounterPartReminderURL = `${baseUrl}/createCounterPartReminder`;
export const getAllPartnersOfCompanyURL = `${baseUrl}/getAllPartnersOfCompany`;
export const getAllPartnersURL = `${baseUrl}/getAllPartners`;
export const updateCompanyStatusURL = `${baseUrl}/updateCompanyStatus`;
export const updateCompanyLogoURL = `${baseUrl}/updateCompanyLogo`;
export const getCounterPartReminderListURL = `${baseUrl}/getCounterPartReminderList`;
export const getCounterPartReminderListByCompanyURL = `${baseUrl}/getCounterPartReminderListByCompany`;
export const getAllNotificationsOfUserURL = `${baseUrl}/getAllNotificationsOfUser`;
export const createCompanyURL = `${baseUrl}/createCompany`;
export const updateCompanyDetailsURL = `${baseUrl}/updateCompany`;
export const markCounterPartRemindersAsDoneURL = `${baseUrl}/markCounterPartRemindersAsDone`;
export const candidatePlaceholderPictureURL = '/avatar.png';
export const markNotificationsAsReadURL = `${baseUrl}/markNotificationsAsRead`;
export const getCompanyChangeHistoryURL = `${baseUrl}/getCompanyChangeHistory`;
export const getProjectChangeHistoryURL = `${baseUrl}/getProjectChangeHistory`;
export const deleteCounterPartRemindersURL = `${baseUrl}/deleteCounterPartReminders`;
export const uploadXlsForCompanyImportURL = `${baseUrl}/uploadXlsForCompanyImport`;
export const uploadXlsForJobFeedImportURL = `${baseUrl}/uploadXlsForJobFeedImport`;
export const downloadExcelExampleFileURL = `${baseUrl}/downloadExcelExampleFile`;
export const deleteCompanyURL = `${baseUrl}/deleteCompany`;
export const getCompanyDocumentByIdURL = `${baseUrl}/getCompanyDocumentById`;
export const uploadCompanyDocumentsURL = `${baseUrl}/uploadCompanyDocuments`;
export const deleteCompanyDocumentURL = `${baseUrl}/deleteCompanyDocument`;
export const deleteCompanyContactURL = `${baseUrl}/deleteCompanyContact`;
export const deleteCompanyContactHistoryEntryURL = `${baseUrl}/deleteCompanyContactHistoryEntry`;

export const geoLocationLookUpURL = `${baseUrl}/geoLocationLookUp`;

// ProjectArchive
export const getProjectByIdURL = `${baseUrl}/getProjectById`;
export const createProjectURL = `${baseUrl}/createProject`;
export const getEmployeeListByPartnerURL = `${baseUrl}/getEmployeeListByPartner`;
export const updateProjectURL = `${baseUrl}/updateProject`;
export const getProjectListBySearchTermURL = `${baseUrl}/getProjectListBySearchTerm`;
export const getProjectListByDetailSearchTermsURL = `${baseUrl}/getProjectListByDetailSearchTerms`;
export const getProjectListCompanyIdURL = `${baseUrl}/getProjectListByCompanyId`;
export const createActionProtocolURL = `${baseUrl}/createActionProtocol`;
export const updatePotentialCandidateURL = `${baseUrl}/updatePotentialCandidate`;
export const deleteActionProtocolURL = `${baseUrl}/deleteActionProtocol`;
export const getProjectListByLastUpdateURL = `${baseUrl}/getProjectListByLastUpdate`;
export const getProjectListByMarkListIdURL = `${baseUrl}/getProjectListByMarkListId`;
export const updateProjectSubSchemaURL = `${baseUrl}/updateProjectSubSchema`;
export const updateProjectPictureURL = `${baseUrl}/updateProjectPicture`;
export const uploadProjectDocumentsURL = `${baseUrl}/uploadProjectDocuments`;
export const downloadProjectDocumentURL = `${baseUrl}/downloadProjectDocument`;
export const deleteProjectDocumentURL = `${baseUrl}/deleteProjectDocument`;
export const removePotentialCandidateURL = `${baseUrl}/removePotentialCandidate`;
export const getProjectStatusReportURL = `${baseUrl}/getProjectStatusReport`;
export const deleteProjectURL = `${baseUrl}/deleteProject`;
export const getJobPostingPdfURL = `${baseUrl}/getJobPostingPdf`;
export const getProjectDocumentByIdURL = `${baseUrl}/getProjectDocumentById`;
export const uploadNewProjectDocumentsURL = `${baseUrl}/uploadNewProjectDocuments`;
export const deleteNewProjectDocumentURL = `${baseUrl}/deleteNewProjectDocument`;
export const removeProjectPictureURL = `${baseUrl}/removeProjectPicture`;
// CandidateArchive
export const getCandidateByIdURL = `${baseUrl}/getCandidateById`;
export const updateCandidateSubSchemaURL = `${baseUrl}/updateCandidateSubSchema`;
export const getCandidateListBySearchTermURL = `${baseUrl}/getCandidateListBySearchTerm`;
export const getCandidateListByDetailSearchTermsURL = `${baseUrl}/getCandidateListByDetailSearchTerms`;
export const updateCandidateURL = `${baseUrl}/updateCandidate`;
export const addCandidateSubSchemaEntryURL = `${baseUrl}/addCandidateSubSchemaEntry`;
export const removeCandidateSubSchemaEntryURL = `${baseUrl}/removeCandidateSubSchemaEntry`;
export const getProjectListByCandidateIdURL = `${baseUrl}/getProjectListByCandidateId`;
export const addPotentialCandidateURL = `${baseUrl}/addPotentialCandidate`;
export const updateCandidateDocumentTagURL = `${baseUrl}/updateCandidateDocumentTag`;
export const sendDataPrivacyMailURL = `${baseUrl}/sendDataPrivacyMail`;
export const getCandidateListByNameURL = `${baseUrl}/getCandidateListByName`;
export const createCandidateURL = `${baseUrl}/createCandidate`;
export const overwriteCandidateURL = `${baseUrl}/overwriteCandidate`;
export const mergeCandidateURL = `${baseUrl}/mergeCandidate`;
export const getCandidateChangeHistoryURL = `${baseUrl}/getCandidateChangeHistory`;
export const updateCandidatePictureURL = `${baseUrl}/updateCandidatePicture`;
export const updateIncomingCandidatePictureURL = `${baseUrl}/updateIncomingCandidatePicture`;
export const uploadCandidateDocumentURL = `${baseUrl}/uploadCandidateDocument`;
export const uploadIncomingCandidateDocumentURL = `${baseUrl}/uploadIncomingCandidateDocument`;
export const deleteIncomingCandidateURL = `${baseUrl}/deleteIncomingCandidate`;
export const getCandidatePdfDocumentURL = `${baseUrl}/getCandidatePdfDocument`;
export const getPSheetURL = `${baseUrl}/getPSheet`;
export const getISheetURL = `${baseUrl}/getISheet`;
export const getCandidateDossierURL = `${baseUrl}/getCandidateDossier`;
export const getShortListExcelURL = `${baseUrl}/getShortListExcel`;
export const getLongListExcelURL = `${baseUrl}/getLongListExcel`;
export const deleteCandidateURL = `${baseUrl}/deleteCandidate`;
export const deleteCandidateDocumentsURL = `${baseUrl}/deleteCandidateDocuments`;
export const updateIncomingCandidateURL = `${baseUrl}/updateIncomingCandidate`;
export const getCandidateListByMatchingProfileURL = `${baseUrl}/getCandidateListByMatchingProfile`;
export const deleteCandidateDocumentURL = `${baseUrl}/deleteCandidateDocument`;
export const getCandidateListByMarkListIdURL = `${baseUrl}/getCandidateListByMarkListId`;
export const deleteCandidateImageURL = `${baseUrl}/deleteCandidateImage`;
// general
export const getIncomingCandidateListURL = `${baseUrl}/getIncomingCandidateList`;
export const getIncomingCandidateByIdURL = `${baseUrl}/getIncomingCandidateById`;
export const getAllDetailSearchQueriesURL = `${baseUrl}/getAllDetailSearchQueries`;
export const loadDetailSearchURL = `${baseUrl}/loadDetailSearch`;
export const saveDetailSearchURL = `${baseUrl}/saveDetailSearch`;
export const deleteDetailSearchURL = `${baseUrl}/deleteDetailSearch`;
export const saveArchiveSearchResultToMarkListURL = `${baseUrl}/saveArchiveSearchResultToMarkList`;

// serialMail & templates
export const getTemplateListURL = `${baseUrl}/getTemplateList`;
export const getSignatureListURL = `${baseUrl}/getSignatureList`;
export const getTemplatesURL = `${baseUrl}/getTemplates`;
export const getProjectNumbersByCandidateMarkListURL = `${baseUrl}/getProjectNumbersByCandidateMarkList`;
export const getTemplateByIdURL = `${baseUrl}/getTemplateById`;
export const getSignatureTemplateURL = `${baseUrl}/getSignatureTemplate`;
export const sendSerialMailURL = `${baseUrl}/sendSerialMail`;
export const sendTestMailURL = `${baseUrl}/sendTestMail`;
export const createTemplateURL = `${baseUrl}/createTemplate`;
export const updateTemplateURL = `${baseUrl}/updateTemplate`;
export const deleteTemplateURL = `${baseUrl}/deleteTemplate`;

// admin page
export const updateUserURL = `${baseUrl}/updateUser`;
export const getPastSearchesOfClientsURL = `${baseUrl}/getPastSearchesOfClients`;
