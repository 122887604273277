import React, { Component } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';

import { mayManageMatchflix } from '../utils/isPermissionSet';
import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

// import TEXT from './staticContent/text';
// import { staticResources } from './staticContent/ProjectDetailsUpdatePopUpDropDowns';

class UserUpdatePopUpContainer extends Component {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    console.log('DEBUG updatedData', updatedData);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateUserURL;

    // map update data
    const mappedUpdatedData = {};
    if (updatedData.partner) mappedUpdatedData.partner = updatedData.partner;
    if (Object.keys(updatedData).includes('isAdvisor'))
      // isAdvisor is set to true or false
      mappedUpdatedData.isAdvisor = updatedData.isAdvisor;
    if (Object.keys(updatedData).includes('roles'))
      mappedUpdatedData.roles = [updatedData.roles]; // roles is stored as array in DB
    if (typeof updatedData.firstName === 'string')
      mappedUpdatedData.firstname = updatedData.firstName;
    if (typeof updatedData.lastName === 'string')
      mappedUpdatedData.lastname = updatedData.lastName;
    if (typeof updatedData.company === 'string')
      mappedUpdatedData.company = updatedData.company;

    const payload = {
      userEmail: this.props.userEmail,
      updateData: mappedUpdatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(
            TEXT_GENERAL.snackBarTexts.updateSuccess
          );
          this.props.refreshData();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    // console.log('DEBUG contactDate', this.props.data.contactDate);
    const forms = {
      formFields: {
        firstName: {
          value: this.props.data.firstname,
          label: 'Vorname'
          // customClass: 'col-12 mb-2'
        },
        lastName: {
          value: this.props.data.lastname,
          label: 'Nachname'
        }
      }
    };

    if (this.props.data.roles[0] === 'client')
      forms.formFields.company = {
        value: this.props.data.company,
        label: 'Firma',
        customClass: 'col-12 mb-2',
        tooltip:
          "Geben Sie möglichst viele unterschiedliche Varianten des Firmennamens durch ';' getrennt ein. Groß- und Kleinschreibung muss nicht berücksichtigt werden z.B.: 'würth; Wuerth;Adolf Würth GmbH'"
      };
    else {
      forms.formFields.roles = {
        value: this.props.data.roles[0],
        label: 'Rolle',
        type: 'dropDown',
        content: [
          { label: 'Admin', value: 'admin' },
          { label: 'Mitarbeiter', value: 'employee' },
          ...(mayManageMatchflix(this.props.user)
            ? [{ label: 'Externer Kunde', value: 'client' }]
            : [])
        ]
      };
      forms.formFields.isAdvisor = {
        value: this.props.data.isAdvisor,
        label: 'Berater',
        type: 'dropDown',
        content: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
      };
      forms.formFields.partner = {
        value: this.props.data.partner && this.props.data.partner.id,
        label: 'Partner',
        type: 'dropDown',
        content: this.props.partners
      };
    }

    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    console.log('DEBUG this.props.data', this.props.data);
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText="Nutzerdaten ändern"
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: this.props.isUpdate,
          handleSubmit: this.updateAPICall,
          // initialData: this.props.data,
          handleClose: this.props.handleClose

          // buttonCancelText: TEXT.projectDetailsUpdatePopUp.buttons.cancel,
          // buttonSaveText: TEXT.projectDetailsUpdatePopUp.buttons.save
        }}
      />
    );
  }
}
UserUpdatePopUpContainer.defaultProps = {
  isUpdate: false,
  data: {}
};

UserUpdatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,

  // data
  userEmail: PropTypes.string.isRequired,
  data: PropTypes.object,

  isUpdate: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default withUser(UserUpdatePopUpContainer);
