import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];
// prepare column options according to save viewColumn settings
function prepareColumns(columns) {
  const mappedColumns = columns;
  const projectStatusColumnSettings = [];
  columns.forEach(column => {
    if (column.options) {
      if (
        column.options.display === 'true' ||
        column.options.display === true
      ) {
        projectStatusColumnSettings.push(column.name);
      }
    } else {
      projectStatusColumnSettings.push(column.name);
    }
  });

  return mappedColumns;
}

class CandidateAdditionalSkills extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { columns: prepareColumns(this.props.columns) };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        const educationData = {
          entryId: entry.id,
          skill: entry.skillName,
          skillLevel: entry.skillLevel
        };

        formatedData.push([
          ...Object.values(educationData),
          this.renderEditButton(educationData)
        ]);
      });

    return formatedData;
  }

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingRight: '10px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        }
      }
    };
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  renderEditButton(entry) {
    return (
      <div className="candidateTableAction">
        <IconButton
          className=""
          aria-label="Edit"
          color="primary"
          onClick={() => this.props.handleOpenCandidateSkillUpdatePopUp(entry)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          className=""
          aria-label="Delete"
          color="primary"
          onClick={() =>
            this.props.handleDeleteEntry('additionalSkills', entry.entryId)
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="CandidateAdditionalSkills row">
          <div className="col-12">
            <SafeMuiDatatable
              title="EDV und sonstige Kenntnisse"
              tableName="candidateAdditionalSkills"
              data={this.prepareDataForTable(this.props.data.additionalSkills)}
              columns={this.state.columns}
              options={this.getOptions()}
              muiTheme={this.getTheme()}
            />
          </div>
        </div>
      </div>
    );
  }
}

CandidateAdditionalSkills.defaultProps = {
  columns: [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'EDV / Sonstige Kenntnisse',
      options: {
        display: true,
        filter: false
      }
    },
    {
      name: 'Grad der Beherrschung',
      options: {
        display: true,
        filter: false
      }
    },
    {
      name: 'Aktionen',
      options: {
        display: true,
        filter: false
      }
    }
  ]
};

CandidateAdditionalSkills.propTypes = {
  data: PropTypes.object.isRequired,

  columns: PropTypes.array,
  handleOpenCandidateSkillUpdatePopUp: PropTypes.func.isRequired,
  handleDeleteEntry: PropTypes.func.isRequired
};
export default CandidateAdditionalSkills;
