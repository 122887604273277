import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// mdi Icon
import Icon from '@mdi/react';
import { mdiDomain as mdiCompanyIcon } from '@mdi/js';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import MuiDatatableSaveResultListBox from '../generic_components/MuiDatatableSaveResultListBox';
import HelperFunctions from './utils/helperFunctions';

const columns = [
  {
    name: 'ID',
    options: {
      display: 'false'
    }
  },
  { name: 'Verfügbarkeit' },
  { name: 'Unternehmen' },
  { name: 'PLZ' },
  { name: 'Ort' },
  { name: 'Homepage' }
];

const muiTheme = {
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

class CompanyResults extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveResultListToMarklistPopUpOpen: false
    };
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        const statusColor = HelperFunctions.getCompanyStatusColor(entry.status);
        formatedData.push([
          entry.id,
          <Icon path={mdiCompanyIcon} size={1} color={statusColor} />,
          entry.name,
          entry.address.postalCode,
          entry.address.city,
          entry.contactInfo && entry.contactInfo.url
        ]);
      });
    return formatedData;
  }

  prepareDataForListSave(data) {
    const indices = this.state.listOfElementIndex || [];
    const mappedData = indices.length
      ? indices.map(index => data[index])
      : data;
    return mappedData
      .filter(entry => !!entry)
      .map(entry => ({
        company: entry.id
      }));
  }

  getMuiDataTableOptions() {
    return {
      filterType: 'checkbox',
      responsiv: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: false,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'Sort'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'Download CSV',
          print: 'Print',
          viewColumns: 'View Columns',
          filterTable: 'Filter Table'
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Show Columns',
          titleAria: 'Show/Hide Table Columns'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      },
      customToolbar: () => (
        <IconButton
          variant="contained"
          color="primary"
          disabled={this.props.companyList.length === 0}
          onClick={() =>
            this.setState({ saveResultListToMarklistPopUpOpen: true })
          }
        >
          <StarIcon style={{ color: 'rgba(0,0,0,0.54)' }} />
        </IconButton>
      )
    };
  }

  onTableChange = (action, tableState) => {
    // console.log('debug onTableChange', action, tableState);
    const displayedCompanyIndexes = tableState.displayData
      .map(entry => entry.dataIndex)
      .slice();
    // console.log('d2', displayedCompanyIndexes);
    sessionStorage.setItem(
      'displayedCompanyIndexes',
      JSON.stringify(displayedCompanyIndexes)
    );
    this.setState({ listOfElementIndex: displayedCompanyIndexes });
  };

  renderTitle() {
    return (
      <span>
        Unternehmen
        {this.props.companyList.length ===
          this.props.maxAmountOfSearchResults && (
          <Tooltip
            title={`Wenn Sie mehr als ${
              this.props.maxAmountOfSearchResults
            } Ergebnisse sehen wollen, verwenden Sie die Detailssuche. Dort können Sie die maximale Anzahl an Treffern einstellen.`}
          >
            <span>
              {' '}
              - auf {this.props.maxAmountOfSearchResults} Ergebnisse beschränkt
            </span>
          </Tooltip>
        )}
      </span>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="CompanyResults col-12">
            <SafeMuiDatatable
              title={this.renderTitle()}
              tableName="companyResults"
              data={this.prepareDataForTable(this.props.companyList)}
              columns={columns}
              onRowClick={this.props.handleCompanySelected}
              muiTheme={muiTheme}
              options={this.getMuiDataTableOptions()}
              onTableChange={this.onTableChange}
            />
            <MuiDatatableSaveResultListBox
              data={this.prepareDataForListSave(this.props.companyList)}
              open={this.state.saveResultListToMarklistPopUpOpen}
              markListPopupType="company"
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              handleClose={() =>
                this.setState({ saveResultListToMarklistPopUpOpen: false })
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
CompanyResults.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  handleCompanySelected: PropTypes.func.isRequired,
  companyList: PropTypes.array.isRequired,
  maxAmountOfSearchResults: PropTypes.number.isRequired
};
export default CompanyResults;
