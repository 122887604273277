const SALARY_DROPDOWN_CONTENT = require('../../general/staticContent/salaryDropDown');
const DESIIRED_RAISE_FORCHANGE_DROPDOWN_CONTENT = require('../../general/staticContent/desiredRaiseForChangeDropDown');
const WILLINGNESS_TO_RELOCATE_DROPDOWN_CONTENT = require('../../general/staticContent/willingnessToRelocateDropdown');
const CHANNEL_FOR_INFORMATION_DROPDOWN_CONTENT = require('../../general/staticContent/channelForInformationDropDown');
const MOTIVATION_FOR_CHANGE_DROPDOWN_CONTENT = require('../../general/staticContent/motivationForChangeDropDown');
const FREQUENCY_OF_INFORMATION_DROPDOWN_CONTENT = require('../../general/staticContent/frequencyOfInformationDropDown');
const PARTNER = require('../../constants').partner;

const staticContent = {
  generateDropDownValues(partners, employeesList) {
    const partnerObjectList = partners.map(entry => ({
      label: `${entry.partnerName} (${entry.partnerId})`,
      value: entry.id
    }));
    const employees = employeesList.map(entry => ({
      label: `${entry.firstname} ${entry.lastname} (${entry.email})`,
      value: entry.id
    }));
    const dataPrivacyDropDowns = {
      willingnessToRelocate: WILLINGNESS_TO_RELOCATE_DROPDOWN_CONTENT,
      desiredRaiseForChange: DESIIRED_RAISE_FORCHANGE_DROPDOWN_CONTENT,
      desiredSalary: SALARY_DROPDOWN_CONTENT,
      channelForInformation: CHANNEL_FOR_INFORMATION_DROPDOWN_CONTENT,
      motivationForChange: MOTIVATION_FOR_CHANGE_DROPDOWN_CONTENT,
      frequencyOfInformation: FREQUENCY_OF_INFORMATION_DROPDOWN_CONTENT
    };
    const dropDownOptionObjects = [
      {
        fieldId: 'lastName',
        label: 'Nachname',
        fieldType: 'textField'
      },
      {
        fieldId: 'firstName',
        label: 'Vorname',
        fieldType: 'textField'
      },
      {
        fieldId: 'candidateChangeHistory.changes.user',
        label: 'geändert durch',
        fieldType: 'dropDown',
        dropDownValues: employees
      },
      {
        fieldId: 'postalCode',
        label: 'PLZ',
        fieldType: 'textField'
      },
      {
        fieldId: '_id',
        label: 'Kandidatennummer (Kandidaten ID)',
        fieldType: 'textField'
      },
      {
        fieldId: 'origin.originCandidateId',
        label: 'Kandidatennummer (Alt)',
        fieldType: 'textField'
      },
      {
        fieldId: 'mailAddress',
        label: 'Email',
        fieldType: 'textField'
      },
      {
        fieldId: 'dateOfBirth',
        label: 'Geburtsdatum',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'currentJob',
        label: 'Position',
        fieldType: 'textField'
      },
      {
        fieldId: 'currentCompany',
        label: 'Firma (bisheriger Arbeitgeber)',
        fieldType: 'textField'
      },
      {
        fieldId: 'creationDate',
        label: 'Eingegeben am',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'lastUpdate.date',
        label: 'geändert am',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'dataPrivacy.consentCurrentProject',
        label: 'Einverständnis aktuelles Projekt',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'Ja', value: true },
          { label: 'Nein', value: false }
        ]
      },
      {
        fieldId: 'dataPrivacy.consentAllProjects',
        label: 'Einverständnis zukünftige Projekte',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'Ja', value: true },
          { label: 'Nein', value: false }
        ]
      },
      {
        fieldId: 'dataPrivacy.consentCancellationRight',
        label: 'Widerrufsrecht zur Kenntnis genommen',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'Ja', value: true },
          { label: 'Nein', value: false }
        ]
      },
      {
        fieldId: 'dataPrivacy.newsletter',
        label: 'Newsletter / Marketing',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'Ja', value: true },
          { label: 'Nein', value: false }
        ]
      },
      {
        fieldId: 'willingnessToRelocate',
        label: 'Umzugsbereitschaft',
        fieldType: 'dropDown',
        dropDownValues: dataPrivacyDropDowns.willingnessToRelocate
      },
      {
        fieldId: 'desiredSalary',
        label: 'Jahreszielgehalt',
        fieldType: 'dropDown',
        dropDownValues: dataPrivacyDropDowns.desiredSalary
      },
      {
        fieldId: 'desiredRaiseForChange',
        label: 'Mehr Gehalt in %',
        fieldType: 'dropDown',
        dropDownValues: dataPrivacyDropDowns.desiredRaiseForChange
      },
      {
        fieldId: 'motivationForChange',
        label: 'Motivation für Wechsel',
        fieldType: 'dropDown',
        dropDownValues: dataPrivacyDropDowns.motivationForChange
      },
      {
        fieldId: 'channelForInformation',
        label: 'Karriereangebote zuschicken über',
        fieldType: 'dropDown',
        dropDownValues: dataPrivacyDropDowns.channelForInformation
      },
      {
        fieldId: 'frequencyOfInformation',
        label: 'Häufigkeit der Angebote',
        fieldType: 'dropDown',
        dropDownValues: dataPrivacyDropDowns.frequencyOfInformation
      },
      {
        fieldId: 'origin.partner',
        label: 'Ort der Eingabe',
        fieldType: 'dropDown',
        dropDownValues: partnerObjectList
      },
      {
        fieldId: 'statusType',
        label: 'Status',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'verfügbar', value: 'available' },
          { label: 'nicht verfügbar', value: 'locked' },
          { label: 'vermittelt', value: 'placed' }
        ]
      },
      {
        fieldId: 'salutation',
        label: 'Anrede',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'Herr', value: 'Herr' },
          { label: 'Frau', value: 'Frau' }
        ]
      },
      {
        fieldId: 'city',
        label: 'Wohnort',
        fieldType: 'textField'
      }
    ];
    if (PARTNER === 'lienert') {
      const lienertAdditions = [
        {
          fieldId: 'candidateDB',
          label: 'Kandidaten DB',
          fieldType: 'dropDown',
          dropDownValues: [
            { label: 'Ja', value: true },
            { label: 'Nein', value: false }
          ]
        },
        {
          fieldId: 'advisor',
          label: 'Betreuer',
          fieldType: 'dropDown',
          dropDownValues: employees
        }
      ];
      lienertAdditions.forEach(item => {
        dropDownOptionObjects.push(item);
      });
    }
    const result = dropDownOptionObjects.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return result;
  }
};
module.exports = staticContent;
