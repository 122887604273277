import React, { Component } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxes from '../generic_components/CheckBoxes';
import RadioButtons from '../generic_components/RadioButtons';
import ValidatedDropDown from '../generic_components/ValidatedDropDown';

import ProjectMatchingFormWorkFieldSelectionPopUp from './ProjectMatchingFormWorkFieldSelectionPopUp';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

const hierarchyCheckBoxOptions = [
  'CEO/MGL',
  'Führungsposition',
  'Fachkader/Spezialist',
  'Mitarbeiter',
  'Nachwuchskraft'
];

const regionCheckBoxOptions = [
  'Ganze Schweiz',
  'Zentralschweiz',
  'Zürich/SH',
  'Ostschweiz/GR/FL',
  'Mittelland',
  'Bern',
  'Basel',
  'Oberwallis',
  'Westschweiz',
  'Tessin'
];
const salaryCheckBoxOptionsProject = [
  { key: 70000, label: "<= 70'000.--" },
  { key: 100000, label: "<= 100'000.--" },
  { key: 130000, label: "<= 130'000.--" },
  { key: 160000, label: "<= 160'000.--" },
  { key: 190000, label: "<= 190'000.--" },
  { key: 220000, label: "<= 220'000.--" },
  { key: 250000, label: "<= 250'000.--" },
  { key: 280000, label: "<= 280'000.--" },
  { key: 310000, label: "<= 310'000.--" }
];

const salaryCheckBoxOptionsCandidate = [
  { key: 70000, label: ">= 70'000.--" },
  { key: 100000, label: ">= 100'000.--" },
  { key: 130000, label: ">= 130'000.--" },
  { key: 160000, label: ">= 160'000.--" },
  { key: 190000, label: ">= 190'000.--" },
  { key: 220000, label: ">= 220'000.--" },
  { key: 250000, label: ">= 250'000.--" },
  { key: 280000, label: ">= 280'000.--" },
  { key: 310000, label: ">= 310'000.--" }
];

/**
 * option only available in candidateView
 */
// const financialShareCheckBox = ['Interesse an finanzieller Beteiligung'];

const languageNameDropDownOptions = [
  '',
  'Deutsch',
  'Englisch',
  'Französisch',
  'Spanisch',
  'Italienisch',
  'Russisch',
  'Holländisch',
  'Afghanisch',
  'Afrikaans',
  'Ägyptisch',
  'Albanisch',
  'Alt Hebräisch',
  'Altgriechisch',
  'Amharisch',
  'Arabisch',
  'Armenisch',
  'Asarisch',
  'Aserbaidschanisch',
  'Assyrisch',
  'Äthiopisch',
  'Bamun',
  'Baskisch',
  'Bengalisch',
  'Birmanisch',
  'Bosnisch',
  'Bosnisch',
  'Brasilianisch',
  'Bulgarisch',
  'Cantonesisch',
  'Catalanisch',
  'Cebuano',
  'Chinesisch',
  'Dänisch',
  'Dari',
  'Elsässisch',
  'Esperanto',
  'Estnisch',
  'Farsi',
  'Finnisch',
  'Flämisch',
  'Gälisch',
  'Gebärdensprache',
  'Georgisch',
  'Griechisch',
  'Gujarati',
  'Haussa',
  'Hebräisch',
  'Hindu',
  'Indisch',
  'Indonesisch',
  'Irakisch',
  'Iranisch',
  'Irisch',
  'Isländisch',
  'Israelisch',
  'Iwrit',
  'Japanisch',
  'Jugoslawisch',
  'Kambodschanisch',
  'Kanadisch',
  'Kantonesisch',
  'Karatschaisch',
  'Kasachisch',
  'Katalanisch',
  'Khmer',
  'Kinyarwanda',
  'Kisuaheli',
  'Koreanisch',
  'Kroatisch',
  'Laotisch',
  'Latein',
  'Lettisch',
  'Litauisch',
  'Luxenburgisch',
  'Madegassisch',
  'Makedonisch',
  'Malayalam',
  'Malaysisch',
  'Mandarin',
  'Marokkanisch',
  'Mazedonisch',
  'Moldawisch',
  'Mongolisch',
  'Neu Griechisch',
  'Neu Hebräisch',
  'Neugriechisch',
  'Niederländisch',
  'Norwegisch',
  'Norwegisch',
  'Norwegisch',
  'Oromo',
  'Pakistanisch',
  'Paschtu',
  'Persisch',
  'Philippinisch',
  'Polnisch',
  'Portugiesisch',
  'Punjabi',
  'Putonhua',
  'Rumänisch',
  'Sanskrit',
  'Schwedisch',
  'Serbokroatisch',
  'Skanidnavisch',
  'Slawisch',
  'Slowakisch',
  'Slowenisch',
  'Sorbisch',
  'Suaheli',
  'Swahili',
  'Syrisch',
  'Tadschikisch',
  'Tagalog',
  'Tamil',
  'Tartarisch',
  'Telugu',
  'Thai',
  'Tigrigna',
  'Tschechisch',
  'Tunesisch',
  'Türkisch',
  'Ukrainisch',
  'Ungarisch',
  'Urdu',
  'Urkainisch',
  'Usbekisch',
  'Vietnamesisch',
  'Weißrussisch',
  'Yoruba',
  'Yugoslawisch',
  'Zaghawa'
];

const languageSkillLevelDropDownOptions = [
  '',
  'Muttersprache / Dolmetscher (C2 - GERR)',
  'verhandlungssicher / fließend (C1 - GERR)',
  'sehr gute Kenntnisse (B2 - GERR)',
  'gute Kenntnisse (B1 - GERR)',
  'erweiterte Grundkenntnisse (A2 - GERR)',
  'Grundkenntnisse (A1 - GERR)',
  'Anfänger'
];

const sectorDropDownOptions = [
  '',
  // 'Alle',
  'Bank/Finanzinstitute Versicherungen',
  'Baugewerbe, Haupt-/Neben-/ Zulieferbranche',
  'Beratung',
  'Bildung',
  'Chemie/Pharma',
  'Dienstleistungen',
  'Energie/-produktion/-versorgung/-verteilung',
  'Gastgewerbe/Hotellerie',
  'Gesundheits-/Sozialwesen',
  'Gewerbe/Handwerk',
  'Handel',
  'Immobilien',
  'Industrie',
  'Informatik/Telekommunikation',
  'Konsum-/Luxusgüterindustrie',
  'Land-/Forstwirtschaft/Holz',
  'Medien/Druckerei/Verlage',
  'Medizinaltechnik',
  'Rechts-/Wirtschaftsberatung',
  'Tourismus/Reisen/Freizeit',
  'Transport/Logistik',
  'NPO/NGO/Verbände',
  'Öffentliche Verwaltung'
];

// helper functions
const removeEmptyStringsFromArray = originalArr => {
  let mappedArray = [];
  if (Array.isArray(originalArr))
    mappedArray = originalArr.filter(entry => !!entry);
  return mappedArray;
};

const removeEmptyLanguageEntriesFromArray = originalArr => {
  let mappedArray = [];
  if (Array.isArray(originalArr))
    mappedArray = originalArr.filter(entry => !!entry.languageName);
  return mappedArray;
};

// const languageDropDowns = ['Deutsch', 'Englisch', ]

// Tab content : AA Matching
class ProjectMatchingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        hierarchy: props.data.hierarchy ? [...props.data.hierarchy] : [''],
        region: props.data.region || [],
        salary: [Number(props.data.salary)] || '',
        // financialShare: [],
        languageSkills: props.data.languageSkills
          ? [
              ...props.data.languageSkills,
              { languageName: '', languageLevel: '' }
            ]
          : [{ languageName: '', languageLevel: '' }],
        workFields: props.data.workFields || [],
        sector: props.data.sector ? [...props.data.sector, ''] : ['']
      },
      isOnline: props.data.isOnline,
      onlineSince: props.data.onlineSince,
      amountMatched: props.data.amountMatched,
      amountMatchedWithCandidateEntry:
        props.data.amountMatchedWithCandidateEntry,
      selectedWorkField: 'none',
      showProjectMatchingFormWorkFieldSelectionPopUp: false
    };

    this.allFormFieldKeys = Object.keys(this.state.formFields);

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleCloseProjectMatchingFormWorkFieldSelectionPopUp(clickedWorkField) {
    console.log('DEBUG clickedWorkField', clickedWorkField);
    this.setState(prevState => {
      const prevWorkFields = prevState.formFields.workFields;
      const newWorkFields = prevWorkFields.slice();

      // duplicate check / prevents double click
      const isAlreadySelected = prevWorkFields.some(el => {
        const foundDuplicate = el.key === clickedWorkField.key;
        return foundDuplicate;
      });
      console.log('debug isAlreadySelected', isAlreadySelected);

      // add in case no duplicate
      if (!isAlreadySelected) newWorkFields.push(clickedWorkField);

      return {
        formFields: {
          ...prevState.formFields,
          workFields: newWorkFields
        },
        showProjectMatchingFormWorkFieldSelectionPopUp: false,
        selectedWorkField: 'none'
      };
    });
  }

  removeCurrentWorkFieldFormWorkFieldSelectionPopUp(workFieldKeyToBeRemoved) {
    console.log('DEBUG workFieldKeyToBeRemoved', workFieldKeyToBeRemoved);
    this.setState(prevState => {
      const newWorkField = prevState.formFields.workFields.slice();
      const filterdWorkFields = newWorkField.filter(
        workFields => workFields.key !== workFieldKeyToBeRemoved
      );
      return {
        formFields: {
          ...prevState.formFields,
          workFields: filterdWorkFields
        },
        showProjectMatchingFormWorkFieldSelectionPopUp: false,
        selectedWorkField: 'none'
      };
    });
  }

  validateCheckBoxSelection(checkBoxFormId) {
    let isValid = false;
    if (checkBoxFormId === 'hierarchy') {
      // allow max 2 check box checked
      if (this.state.formFields.hierarchy.length < 3) isValid = true;
    } else isValid = true;
    return isValid;
  }

  handleRadioChange = (checkBoxFormId, checkBoxId) => {
    console.log('DEBUG handleCheckBoxChange', checkBoxFormId, checkBoxId);
    this.setState(prevState => {
      const dropDownSelection = [checkBoxId];
      const updatedState = {
        ...prevState.formFields,
        [checkBoxFormId]: dropDownSelection
      };
      console.log('DEBUG updatedState', updatedState);
      return { formFields: updatedState };
    });
  };

  handleCheckBoxChange = (checkBoxFormId, checkBoxId) => {
    console.log('DEBUG handleCheckBoxChange', checkBoxFormId, checkBoxId);
    this.setState(prevState => {
      let dropDownSelection = prevState.formFields[checkBoxFormId].slice();

      // special case for selection of all regions
      if (checkBoxFormId === 'region' && checkBoxId === 'Ganze Schweiz') {
        if (dropDownSelection.includes(checkBoxId)) dropDownSelection = [];
        else dropDownSelection = regionCheckBoxOptions;
      }
      // case: checkBox has been already checked
      else if (dropDownSelection.includes(checkBoxId)) {
        // remove checked option
        const index = dropDownSelection.indexOf(checkBoxId);
        dropDownSelection.splice(index, 1);
      }

      // add checked option in case validation is passed (e.g. limit of allowed selected checkboxes)
      else if (this.validateCheckBoxSelection(checkBoxFormId))
        dropDownSelection.push(checkBoxId);
      const updatedState = {
        ...prevState.formFields,
        [checkBoxFormId]: dropDownSelection
      };
      console.log('DEBUG updatedState', updatedState);
      return { formFields: updatedState };
    });
  };

  handleDropDownChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => {
      const originalLanguageState = prevState.formFields.languageSkills;

      // deep clone of original languageSkills state
      let newLanguageState = originalLanguageState
        // .slice()
        .map(l => Object.assign({}, l));

      const languageState = newLanguageState[index];
      languageState[name] = value;

      if (name === 'languageName') {
        // remove empty languageName fields
        newLanguageState = removeEmptyLanguageEntriesFromArray(
          newLanguageState
        );

        // remove duplicates
        let duplicateFound = false;
        newLanguageState = newLanguageState.filter(item =>
          // check if new value exist already in original languageState
          {
            const isDuplicate = originalLanguageState.some(
              originalEl =>
                originalEl.languageName === value && item.languageName === value
            );
            const keepLanguage = duplicateFound || !isDuplicate;
            if (isDuplicate) duplicateFound = true;
            return keepLanguage;
          }
        );

        // add new empty row
        newLanguageState.push({
          languageName: '',
          languageLevel: ''
        });
      }

      // set updated state for languageSkills formFields
      return {
        formFields: {
          ...prevState.formFields,
          languageSkills: newLanguageState
        }
      };
    });
  };

  handleSectorDropDownChange = (e, index) => {
    const value = e.target.value;

    this.setState(prevState => {
      const sectorState = prevState.formFields.sector;
      sectorState[index] = value;

      // remove all empty dropDowns
      const trimmedArr = removeEmptyStringsFromArray(sectorState);

      // remove duplicates
      const newSectorState = trimmedArr.filter(
        (item, pos) => trimmedArr.indexOf(item) === pos
      );

      // add empty dropDown
      newSectorState.push('');
      return {
        formFields: { ...prevState.formFields, sector: newSectorState }
      };
    });
  };

  mapLanguageSkills(languageArr) {
    const mappedLanguageSkills = [];
    if (Array.isArray(languageArr))
      languageArr.forEach(languageSkills => {
        if (languageSkills.languageName)
          mappedLanguageSkills.push(languageSkills);
      });
    return mappedLanguageSkills;
  }

  updateProjectMatchingAPICall(updatedData) {
    // TODO API call
    let isOnline;
    let onlineSince;
    if (!this.props.data.isOnline && this.state.isOnline) {
      isOnline = true;
      onlineSince = new Date();
    }
    if (this.props.data.isOnline && !this.state.isOnline) {
      isOnline = false;
    }
    const requestBody = {
      projectId: this.props.selectedProjectId,
      updateData: {
        matching: {
          hierarchy: updatedData.hierarchy,
          region: updatedData.region,
          salary: updatedData.salary,
          languageSkills: this.mapLanguageSkills(updatedData.languageSkills),
          workFields: updatedData.workFields,
          sector: removeEmptyStringsFromArray(updatedData.sector),
          isOnline,
          onlineSince
        }
      }
    };
    const url = CONSTANTS.updateProjectURL;
    console.log(`API call request ${url}: `, updatedData);

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        if (responseBody.status === 'no_job_posting_found') {
          this.props.handleSnackbarOpen(
            'Die Anzeige ist noch nicht online, bitte versuchen Sie es später nochmals'
          );
        } else {
          this.props.handleSnackbarOpen(
            TEXT_GENERAL.snackBarTexts.updateSuccess
          );
        }
        if (onlineSince) this.setState({ onlineSince });
        // this.setState(prevState => {
        //   const updatedJobPosting = prevState.initialData.jobPosting;
        //   Object.keys(updateData).forEach(key => {
        //     updatedJobPosting[key] = updateData[key];
        //   });
        //   const newState = {
        //     initialData: {
        //       ...prevState.initialData,
        //       jobPosting: updatedJobPosting
        //     }
        //   };
        //   return newState;
        // });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getUpdateData(key) {
    // let result;

    // const initialValue = this.initialState.formFields[key]; // LATER
    // // special case for dropDowns with key value pairs
    // if (typeof initialValue === 'object')
    //   initialValue = this.props.initialData[key].id;

    let currentValue;
    // case inputField
    if (this.state.formFields[key]) currentValue = this.state.formFields[key];
    // case dropDowns
    else currentValue = this.state.formFields[key];

    // TODO impl. later with changeHistory
    // if (initialValue !== currentValue) {
    // handle case initialValue and currentValue both undefined, empty or null
    // if (initialValue || currentValue)
    const result = currentValue;
    // }
    return result;
  }

  generateUpdateData() {
    const updatedData = {};
    this.allFormFieldKeys.forEach(fieldKey => {
      const updatedValue = this.getUpdateData(fieldKey);
      if (updatedValue !== undefined) {
        updatedData[fieldKey] = updatedValue;
      }
    });

    return updatedData;
  }

  updateData() {
    const updatedData = this.generateUpdateData();
    console.log('updatedData inside genericForm', updatedData);
    if (this.props.customSubmit)
      this.props.customSubmit({
        matching: {
          hierarchy: updatedData.hierarchy,
          region: updatedData.region,
          salary: updatedData.salary[0],
          languageSkills: this.mapLanguageSkills(updatedData.languageSkills),
          workFields: updatedData.workFields,
          sector: removeEmptyStringsFromArray(updatedData.sector)
        }
      });
    else this.updateProjectMatchingAPICall(updatedData);
  }

  handleSubmitButtonClick = e => {
    e.preventDefault();
    const formFields = this.state.formFields;

    if (
      formFields.hierarchy &&
      formFields.hierarchy.length > 0 &&
      formFields.region &&
      formFields.region.length > 0 &&
      (formFields.salary > 0 || formFields.salary.length > 0) &&
      formFields.languageSkills &&
      formFields.languageSkills.length > 0 &&
      formFields.workFields &&
      formFields.workFields.length > 0 &&
      formFields.sector &&
      formFields.sector.length > 0
    ) {
      this.updateData();
    } else {
      this.props.handleSnackbarOpen(
        'Bitte füllen Sie alle Felder aus. Ihre Daten wurden nicht gespeichert.'
      );
    }
  };

  renderLanguageDropDowns() {
    return (
      <div className="col-4">
        <Tooltip title="Es muss mindestens eine Muttersprache gewählt werden">
          <FormLabel htmlFor="Sprachkenntnisse">Sprachkenntnisse</FormLabel>
        </Tooltip>
        {this.state.formFields.languageSkills.map((languageSkills, index) => (
          <div className="row">
            <div className="col-6">
              <ValidatedDropDown
                id="languageName"
                // ref={this.formFieldRefs[index]}
                content={languageNameDropDownOptions}
                // label=""
                onChange={e => this.handleDropDownChange(e, index)}
                value={languageSkills.languageName}
                furtherPropsSelect={{
                  fullWidth: true,
                  disabled: this.props.inCandiateArchive
                }}
                // {...this.state.inputFields.country}
              />
            </div>
            <div className="col-6">
              <ValidatedDropDown
                id="languageLevel"
                // ref={this.formFieldRefs[index]}
                content={languageSkillLevelDropDownOptions}
                // label=""
                onChange={e => this.handleDropDownChange(e, index)}
                value={languageSkills.languageLevel}
                furtherPropsSelect={{
                  fullWidth: true,
                  disabled: this.props.inCandiateArchive
                }}
                // {...this.state.inputFields.country}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderWorkFieldSelection() {
    const workFields = this.state.formFields.workFields.slice();
    const placeHolderText = 'Tätigkeitsfeld hinzufügen';
    if (!workFields.includes(placeHolderText)) workFields.push(placeHolderText);
    return (
      <div className="col-4">
        <FormLabel htmlFor="Ausrichtung / Wunschtätigkeit">
          Ausrichtung / Wunschtätigkeit
        </FormLabel>
        {workFields.map(workField => {
          const workFieldName =
            typeof workField === 'string' ? workField : workField.value;

          return (
            // <div className="row">
            //   <div className="col-6">
            <Button
              id="workFields"
              fullWidth
              classes={
                workFieldName !== placeHolderText
                  ? { text: 'text-transform-none' }
                  : {}
              }
              color={workFieldName !== placeHolderText ? null : 'primary'}
              disabled={this.props.inCandiateArchive}
              onClick={() =>
                this.setState({
                  selectedWorkField: workField.key,
                  showProjectMatchingFormWorkFieldSelectionPopUp: true
                })
              }
            >
              {workFieldName}
            </Button>
            //   </div>
            // </div>
          );
        })}
      </div>
    );
  }

  renderSectorDropDowns() {
    return (
      <div className="col-4">
        <FormLabel htmlFor="Branchen">Branchen</FormLabel>
        {this.state.formFields.sector.map((sector, index) => (
          <ValidatedDropDown
            id="sector"
            // ref={this.formFieldRefs[index]}
            content={sectorDropDownOptions}
            // label=""
            onChange={e => this.handleSectorDropDownChange(e, index)}
            value={sector}
            furtherPropsSelect={{
              fullWidth: true,
              disabled: this.props.inCandiateArchive
            }} // {...this.state.inputFields.country}
          />
        ))}
      </div>
    );
  }

  renderSaveButton = () => (
    <div className="col-4 offset-4 mt-5 text-center">
      <Button
        onClick={this.handleSubmitButtonClick}
        variant="contained"
        type="submit"
        color="primary"
        fullWidth
      >
        {this.props.inMatchingSearch ? 'Suchen' : 'Speichern'}
      </Button>
    </div>
  );

  render() {
    // console.log('debug state formFields', this.state.formFields);

    return (
      <div className="container">
        <div className="row pt-5 pb-5 pl-2 pr-2">
          {!this.props.inCandiateArchive && !this.props.inMatchingSearch && (
            <div>
              <div className="col-2">
                <p className="projectDetailLabel">Aktiv seit:</p>
                <p>
                  {this.state.onlineSince &&
                    UTILS.formatDateToDELocale(
                      new Date(this.state.onlineSince)
                    )}
                </p>
              </div>
              <div className="col-2">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => {
                        this.setState(prevState => ({
                          isOnline: !prevState.isOnline
                        }));
                      }}
                      value="matchingIsOnline"
                      checked={this.state.isOnline}
                      color="primary"
                    />
                  }
                  label="Matching Aktiv"
                />
              </div>
              <div className="col-8">
                <div className="row">
                  <div className="col-4">
                    <p className="projectDetailLabel">
                      Anzahl Matchingprofile:
                    </p>
                    <p>
                      {this.state.amountMatched ? this.state.amountMatched : ''}
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="projectDetailLabel">
                      Matchingprofile mit Kandidateneintrag:
                    </p>
                    <p>
                      {this.state.amountMatchedWithCandidateEntry
                        ? this.state.amountMatchedWithCandidateEntry
                        : ''}
                    </p>
                  </div>
                  <div className="col-4">
                    <p className="projectDetailLabel">
                      Anonyme Matchingprofile:
                    </p>
                    <p>
                      {this.state.amountMatched &&
                      this.state.amountMatchedWithCandidateEntry
                        ? this.state.amountMatched -
                          this.state.amountMatchedWithCandidateEntry
                        : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-12 mb-2 ">
            <CheckBoxes
              id="hierarchy"
              sectionLabel="Hierarchiestufe"
              options={hierarchyCheckBoxOptions}
              handleCheckBoxChange={this.handleCheckBoxChange}
              checked={this.state.formFields.hierarchy}
              layoutHorizontal
              disabled={this.props.inCandiateArchive}
            />
          </div>
          <div className="col-12 mb-2">
            <CheckBoxes
              id="region"
              sectionLabel="Arbeitsregionen"
              options={regionCheckBoxOptions}
              handleCheckBoxChange={this.handleCheckBoxChange}
              checked={this.state.formFields.region}
              layoutHorizontal
              disabled={this.props.inCandiateArchive}

              // allSelector="Ganze Schweiz"
            />
          </div>
          <div className="col-12 mb-2">
            <RadioButtons
              id="salary"
              sectionLabel="Salär Bandbreite"
              options={
                this.props.inCandiateArchive
                  ? salaryCheckBoxOptionsCandidate
                  : salaryCheckBoxOptionsProject
              }
              handleChange={this.handleRadioChange}
              selected={this.state.formFields.salary}
              layoutHorizontal
              disabled={this.props.inCandiateArchive}
            />
          </div>
          {/* Finanzielle Beteiligung wohl nur in der Kandidatenansicht benötigt */}
          {/* <div className="col-12 mb-2">
          <CheckBoxes
            id="financialShare"
            options={financialShareCheckBox}
            handleCheckBoxChange={this.handleCheckBoxChange}
            checked={this.state.financialShare}
            layoutHorizontal
          />
        </div> */}

          {this.renderLanguageDropDowns()}
          {this.renderWorkFieldSelection()}
          {this.renderSectorDropDowns()}
          {this.props.inCandiateArchive && (
            <div>
              <div className="col-12 mt-3">
                <FormLabel htmlFor="Branchen">
                  Finanzielle Beteiligung
                </FormLabel>
              </div>
              <div className="col-12">
                <FormControlLabel
                  className="checkBoxArea"
                  disabled
                  control={
                    <Checkbox
                      className="checkBox"
                      checked={this.props.data.interestedInStake}
                      // disabled
                    />
                  }
                  label="Interessiert an finanzieller Beteiligung"
                />
              </div>
            </div>
          )}

          {!this.props.inCandiateArchive && this.renderSaveButton()}

          {/* PopUps */}
          <ProjectMatchingFormWorkFieldSelectionPopUp
            key={this.state.selectedWorkField || 'defaultKey'}
            open={this.state.showProjectMatchingFormWorkFieldSelectionPopUp}
            selectedWorkField={this.state.selectedWorkField}
            handleClose={clickedWorkField =>
              this.handleCloseProjectMatchingFormWorkFieldSelectionPopUp(
                clickedWorkField
              )
            }
            removeCurrentWorkFieldFormWorkFieldSelectionPopUp={clickedWorkField =>
              this.removeCurrentWorkFieldFormWorkFieldSelectionPopUp(
                clickedWorkField
              )
            }
          />
        </div>
      </div>
    );
  }
}
ProjectMatchingForm.defaultProps = {
  data: {},
  inCandiateArchive: false,
  selectedProjectId: '',
  inMatchingSearch: false,
  customSubmit: undefined
};

ProjectMatchingForm.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  selectedProjectId: PropTypes.string,
  data: PropTypes.object,
  inCandiateArchive: PropTypes.bool,
  inMatchingSearch: PropTypes.bool,
  customSubmit: PropTypes.func
};

export default ProjectMatchingForm;
