module.exports = {
  determineStatusColor(statusType) {
    if (statusType === 'available') return 'green';
    if (statusType === 'globalLock') return 'orange';
    return 'red';
  },
  /**
   *  verify if company lock is already expired and set it to available after expiration
   */
  verifyCompanyStatus(companyStatus) {
    let mappedCompanyStatus = companyStatus;

    const expirationDate =
      companyStatus.expirationDate && companyStatus.expirationDate != null
        ? new Date(companyStatus.expirationDate)
        : new Date(0);

    // console.log('expirationDate.getTime() ', expirationDate.getTime());
    const isDateInPast =
      expirationDate.getTime() + 86400000 < new Date().getTime();
    if (isDateInPast && companyStatus.statusType !== 'globalLock') {
      mappedCompanyStatus = { statusType: 'available' };
    }
    return mappedCompanyStatus;
  },

  /**
   *  verify if candidate lock is already expired and set it to available after expiration
   */
  verifyCandidateStatus(candidateStatus) {
    const {
      statusType,
      statusExpirationDate,
      statusSetByUser
    } = candidateStatus;

    const expirationDate = statusExpirationDate
      ? new Date(statusExpirationDate)
      : new Date(0);

    const isDateInPast = expirationDate.getTime() < new Date().getTime();
    return {
      statusExpirationDate: isDateInPast ? null : expirationDate,
      statusType: isDateInPast ? 'available' : statusType || 'available',
      statusSetByUser
    };
  },

  /**
   *  map the companyStatus to corresponding color (green, orange or red)
   */
  getCompanyStatusColor(companyStatus) {
    const mappedCompanyStatus = this.verifyCompanyStatus(companyStatus);
    const statusColor = this.determineStatusColor(
      mappedCompanyStatus.statusType
    );
    return statusColor;
  }
};
