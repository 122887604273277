import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import $ from 'jquery';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Editor } from '@tinymce/tinymce-react';

import './JobPosting.css';

import DropContainer from '../generic_components/DropContainer';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import DefaultTextForEditor from './DefaultTextForEditor';
import ValidatedTextField from '../generic_components/ValidatedTextField';
import ValidatedDropDown from '../generic_components/ValidatedDropDown';
import * as UTILS from '../utils/utilFunctions';

import TEXT_GENERAL from '../text';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';

class JobPosting extends PureComponent {
  constructor(props) {
    super(props);
    let defaultText = JSON.parse(
      JSON.stringify(DefaultTextForEditor.defaultText)
    );
    defaultText = defaultText.replace(
      '[§PROJECTNUMBER§]',
      props.selectedProject.projectNumber
    );
    if (props.selectedProject.partner)
      defaultText = defaultText.replace(
        '[§EMAIL§]',
        CONSTANTS.partner === 'senator'
          ? 'cv@senator-partners.de'
          : 'cv@personal-total.de'
      );
    if (props.selectedProject.editingUser)
      defaultText = defaultText.replace(
        '[§SALUTATION§] [§TITLE§] [§CONSULTANT§]',
        `${props.selectedProject.editingUser.firstName} ${
          props.selectedProject.editingUser.lastName
        }`
      );
    if (props.selectedProject.editingUser)
      defaultText = defaultText.replace('[§PHONENUMbER§]', '[Telefonnummer]');
    if (props.selectedProject.partner)
      defaultText = defaultText.replace(
        '[§URL§]',
        CONSTANTS.partner === 'senator'
          ? 'www.senator-partners.de'
          : 'www.personal-total.de'
      );

    this.selectedProject = JSON.parse(
      JSON.stringify(this.props.selectedProject)
    );
    this.state = {
      isPdfExportButtonActive: true,
      pictureErrorMessage: '',
      pictureLoading: false,
      documentErrorMessage: '',
      documentsLoading: false,
      isDownloadButtonActive: false,
      isDeleteButtonActive: false,
      isConfirmationModalOpen: false,

      isButtonActive: false,

      inputFields: {
        location: {
          value: this.selectedProject.jobPosting.location || '',
          validator: 'isText'
        },
        postalCode: {
          value: this.selectedProject.jobPosting.postalCode || '',
          validator: 'isText'
        },
        city: {
          value: this.selectedProject.jobPosting.city || '',
          validator: 'isText'
        },
        title: {
          value: this.selectedProject.jobPosting.title || '',
          validator: 'isText'
        },
        country: {
          value: this.selectedProject.jobPosting.country || ''
        },

        isOnline: {
          value: this.selectedProject.jobPosting.isOnline || false
        },
        isOnlineNzz: {
          value: this.selectedProject.jobPosting.isOnlineNzz || false
        },
        isOnlineJobsCh: {
          value: this.selectedProject.jobPosting.isOnlineJobsCh || false
        },

        description: {
          value: this.selectedProject.jobPosting.description || defaultText
        },
        companyNameInPosting: {
          value: this.selectedProject.jobPosting.companyNameInPosting || ''
        }
      },
      initialData: this.props.selectedProject,
      showDeleteProjectImageModal: false
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  onInputFieldChange = e => {
    this.setState({
      isButtonActive: true
    });

    let inputFieldValue = e.target.value;
    let inputFieldKey = e.target.id || e.target.name;
    if (e.target.type === 'checkbox') {
      inputFieldValue = e.target.checked;
      inputFieldKey = e.target.value;
    }
    if (inputFieldKey === 'mailAddress')
      inputFieldValue = inputFieldValue.toLowerCase().trim();
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [inputFieldKey]: {
          ...prevState.inputFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  getUpdateData(key) {
    let result;

    const initialValue = this.state.initialData.jobPosting[key];

    let currentValue;
    // case inputField
    // TODO
    if (this.state.inputFields[key])
      currentValue = this.state.inputFields[key].value;
    // case dropDowns
    else currentValue = this.state.inputFields[key].value;

    if (initialValue !== currentValue) {
      // handle case initialValue and currentValue both undefined, empty or null
      if (initialValue || currentValue) result = currentValue;
    }
    return result;
  }

  addDatesToUpdatedData(updateData) {
    const updatedData = updateData;
    updatedData.lastRefreshedDate = new Date();
    return updatedData;
  }

  generateUpdateData = () => {
    const updatedData = {};
    Object.keys(this.state.inputFields).forEach(fieldKey => {
      const updatedValue = this.getUpdateData(fieldKey);
      if (updatedValue !== undefined) updatedData[fieldKey] = updatedValue;
    });
    // only if there were changes
    if (Object.keys(updatedData).length > 0) {
      if (
        !this.state.initialData.jobPosting.datePosted &&
        updatedData.isOnline
      ) {
        const dateIn30Days = new Date();
        dateIn30Days.setDate(dateIn30Days.getDate() + 90);

        updatedData.datePosted = new Date();
        updatedData.validThroughDate = dateIn30Days;
      }
      updatedData.lastRefreshedDate = new Date();
    }

    return updatedData;
  };

  handleEditorChange = e => {
    this.setState({
      isButtonActive: true
    });
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        description: {
          ...prevState.inputFields.description,
          value: e.target.getContent()
        }
      }
    }));
  };

  onDeleteButtonClick = () => {
    this.setState({
      isDeleteButtonActive: true,
      documentErrorMessage: '',
      documentsLoading: true
    });
    const url = CONSTANTS.deleteProjectDocumentURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { projectId: this.selectedProject.id };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.liftUpStateToProjectArchive({
          selectedProject: {
            ...this.props.selectedProject,
            document: undefined
          }
        });
        this.setState({
          isDeleteButtonActive: false,
          documentsLoading: false
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null);
      });
  };

  onDowloadButtonClick = () => {
    this.setState({
      isDownloadButtonActive: true
    });
    const url = CONSTANTS.downloadProjectDocumentURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId: this.selectedProject.id,
      fileName: this.selectedProject.document
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const file = `data:application/pdf;base64,${responseBody.buffer}`;
        const downloadElement = document.createElement('a');
        const fileName = this.selectedProject.document;

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isDownloadButtonActive: false
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null);
      });
  };

  onSaveButtonClick = onlyRefresh => {
    // Validation
    if (CONSTANTS.isLienert) {
      if (
        this.state.inputFields.isOnline ||
        this.state.inputFields.isOnlineJobsCh ||
        this.state.inputFields.isOnlineNzz
      ) {
        if (!this.props.selectedProject.document) {
          this.props.handleSnackbarOpen(
            'Fehler: Es muss ein Dokument hinterlegt sein'
          );
          return;
        }
        if (!this.state.inputFields.title) {
          this.props.handleSnackbarOpen(
            'Fehler: Es muss ein Anzeigentitel hinterlegt sein'
          );
          return;
        }
      }
    }
    this.setState({
      isButtonActive: false
    });

    let updateData = {};
    if (onlyRefresh) {
      const dateIn30Days = new Date();
      dateIn30Days.setDate(dateIn30Days.getDate() + 90);

      updateData.validThroughDate = dateIn30Days;

      updateData.lastRefreshedDate = new Date();
    } else {
      updateData = this.generateUpdateData();
    }

    const requestBody = {
      projectId: this.selectedProject.id,
      updateData: {
        jobPosting: {
          ...updateData
        }
      }
    };
    const url = CONSTANTS.updateProjectURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    if (Object.keys(updateData).length > 0) {
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(requestBody),
        timeout: CONFIG.ajaxTimeout
      })
        .done(() => {
          this.props.handleSnackbarOpen(
            TEXT_GENERAL.snackBarTexts.updateSuccess
          );

          this.setState(prevState => {
            const updatedJobPosting = prevState.initialData.jobPosting;
            Object.keys(updateData).forEach(key => {
              updatedJobPosting[key] = updateData[key];
            });
            const newState = {
              initialData: {
                ...prevState.initialData,
                jobPosting: updatedJobPosting
              }
            };
            return newState;
          });
        })
        .fail(err => {
          this.errorHandling(err, url, null);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  onImageDrop = acceptedFiles => {
    const url = CONSTANTS.updateProjectPictureURL;
    const projectId = this.props.selectedProject.id;
    this.setState({ pictureErrorMessage: '', pictureLoading: true });

    const request = new FormData();

    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });

    request.append('projectId', projectId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        this.props.liftUpStateToProjectArchive({
          selectedProject: {
            ...this.props.selectedProject,
            picture: responseBody.picture
          }
        });
        this.setState({
          pictureLoading: false
        });
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pictureErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            pictureLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pictureErrorMessage: 'Nicht unterstütztes Dateiformat',
            pictureLoading: false
          });
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  handleDeleteProjectPictureAPICall = () => {
    const url = CONSTANTS.removeProjectPictureURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      projectId: this.props.selectedProject.id
    };
    console.log('DEBUGG', payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.liftUpStateToProjectArchive({
          selectedProject: {
            ...this.props.selectedProject,
            picture: undefined
          }
        });
        this.setState({ showDeleteProjectImageModal: false });
        this.props.handleSnackbarOpen(
          'Das Projectbild wurde erfolgreich gelöscht'
        );
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  onDocumentsDrop = acceptedFile => {
    const url = CONSTANTS.uploadProjectDocumentsURL;
    const projectId = this.props.selectedProject.id;
    this.setState({ documentErrorMessage: '', documentsLoading: true });

    const request = new FormData();
    request.append('file', acceptedFile[0]);

    request.append('projectId', projectId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        this.props.liftUpStateToProjectArchive({
          selectedProject: {
            ...this.props.selectedProject,
            document: responseBody.uploadedFile
          }
        });
        this.setState({
          documentsLoading: false
        });
        console.log(responseBody);
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            documentErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            documentsLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            documentErrorMessage: 'Nicht unterstütztes Dateiformat',
            documentsLoading: false
          });
        }
        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  onPdfExportButtonClick() {
    this.setState({
      isPdfExportButtonActive: false
    });
    const url = CONSTANTS.getJobPostingPdfURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId: this.selectedProject.id
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const file = `data:application/pdf;base64,${responseBody.buffer}`;
        const downloadElement = document.createElement('a');
        const fileName = 'Stellenanzeige.pdf';

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isPdfExportButtonActive: true
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null, [400, 500, 514]);
      });
  }

  renderProjectImage() {
    if (this.props.selectedProject.picture) {
      const image = UTILS.convertBufferToImage(
        this.props.selectedProject.picture
      );
      return (
        <div className="row">
          <div className="col-12 text-center">
            <img src={image} alt="logo" className="img-fluid rounded" />
            <div className="pt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({
                    showDeleteProjectImageModal: true
                  });
                }}
              >
                <i className="material-icons">delete</i>&nbsp;Bild löschen
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12 text-center">
          <i
            className={`material-icons jobPosting-icon-size-60px-${
              !this.props.selectedProject.isEditable ? 'disabled' : 'enabled'
            }`}
          >
            cloud_upload
          </i>
        </div>
      </div>
    );
  }

  renderDocumentName() {
    if (this.props.selectedProject.document) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <p>{this.props.selectedProject.document} </p>
          </div>
          <div className="col-12 text-center">
            <Button
              onClick={this.onDowloadButtonClick}
              variant="contained"
              color="primary"
              disabled={this.state.isDownloadButtonActive}
            >
              <i className="material-icons pr-2">cloud_download</i>
              Download
            </Button>
          </div>
          <div className="col-12 text-center">
            <Button
              onClick={() => this.setState({ isConfirmationModalOpen: true })}
              variant="contained"
              color="primary"
              disabled={this.state.isDeleteButtonActive}
              className="mt-3"
            >
              <i className="material-icons pr-2">delete</i>
              Dokument löschen
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12 text-center">
          <i
            className={`material-icons jobPosting-icon-size-60px-${
              !this.props.selectedProject.isEditable ? 'disabled' : 'enabled'
            }`}
          >
            cloud_upload
          </i>
        </div>
      </div>
    );
  }

  render() {
    console.log('initialData', this.state.initialData);
    return (
      <div className="container-fluid">
        <ConfirmationModal
          handleClose={() => this.setState({ isConfirmationModalOpen: false })}
          open={this.state.isConfirmationModalOpen}
          buttonPrimaryAction={() => {
            this.setState({ isConfirmationModalOpen: false });
            this.onDeleteButtonClick();
          }}
          headlineText="Datei Löschen ?"
          buttonPrimaryText="bestätigen"
          icon="warning"
        />
        <ConfirmationModal
          key="confirmation-deleteProjectImage"
          handleClose={() =>
            this.setState({ showDeleteProjectImageModal: false })
          }
          open={this.state.showDeleteProjectImageModal}
          buttonPrimaryAction={this.handleDeleteProjectPictureAPICall}
          headlineText="Bild löschen?"
          icon="delete"
          descriptionText=""
        />
        <Paper>
          <div className="row p-3">
            <div className="col-3">
              <p className="projectDetailLabel">Anzeige geschaltet am:</p>
              <p>
                {this.state.initialData.jobPosting.datePosted &&
                  UTILS.formatDateToDELocale(
                    new Date(this.state.initialData.jobPosting.datePosted)
                  )}
              </p>
            </div>
            <div className="col-3">
              <p className="projectDetailLabel">Letztes Update:</p>

              {this.state.initialData.jobPosting.lastRefreshedDate && (
                <p>
                  {UTILS.formatDateToDELocale(
                    new Date(
                      this.state.initialData.jobPosting.lastRefreshedDate
                    )
                  )}
                </p>
              )}
            </div>
            <div className="col-3">
              <p className="projectDetailLabel">Gültig bis:</p>
              <p>
                {this.state.initialData.jobPosting.validThroughDate &&
                  UTILS.formatDateToDELocale(
                    new Date(this.state.initialData.jobPosting.validThroughDate)
                  )}
              </p>
            </div>
            {this.state.inputFields.isOnline.value &&
              new Date(this.state.initialData.jobPosting.validThroughDate) >=
                new Date() &&
              !CONSTANTS.isLienert && (
                <div className="col-3">
                  <p className="projectDetailLabel">Link zur Anzeige:</p>
                  <p>
                    <a
                      href={`https://jobs.${CONSTANTS.domain()}/${
                        this.props.selectedProject.projectNumber
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      - Link -
                    </a>
                  </p>
                </div>
              )}
            <div className="col-12">
              <span style={{ paddingRight: '24px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!this.state.isPdfExportButtonActive}
                  onClick={() => {
                    this.onPdfExportButtonClick();
                  }}
                >
                  <i className="material-icons pr-2">file_copy</i>
                  PDF Export
                </Button>
              </span>
              {CONSTANTS.isLienert && (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.onInputFieldChange}
                      value="isOnlineJobsCh"
                      disabled={!this.props.selectedProject.isEditable}
                      checked={this.state.inputFields.isOnlineJobsCh.value}
                      color="primary"
                    />
                  }
                  label="jobs.ch"
                />
              )}
              {CONSTANTS.isLienert && (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.onInputFieldChange}
                      value="isOnlineNzz"
                      disabled={!this.props.selectedProject.isEditable}
                      checked={this.state.inputFields.isOnlineNzz.value}
                      color="primary"
                    />
                  }
                  label="nzz.ch"
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    onChange={this.onInputFieldChange}
                    value="isOnline"
                    disabled={!this.props.selectedProject.isEditable}
                    checked={this.state.inputFields.isOnline.value}
                    color="primary"
                  />
                }
                label="Online"
              />{' '}
              <FormControlLabel
                control={
                  <IconButton
                    disabled={!this.props.selectedProject.isEditable}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.onSaveButtonClick(true);
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                }
                label="Refresh"
              />
            </div>
          </div>
          {CONSTANTS.isLienert && (
            <div className="row p-3">
              <div className="col-6">
                <DropContainer
                  dropzoneErrorMessage={this.state.pictureErrorMessage}
                  showLoadingIndicator={this.state.pictureLoading}
                  onDrop={this.onImageDrop}
                  actionDescription="Bild hier ablegen"
                  disabled={!this.props.selectedProject.isEditable}
                  cloudIcon={false}
                >
                  {this.renderProjectImage()}
                </DropContainer>
              </div>
              <div className="col-6">
                <DropContainer
                  dropzoneErrorMessage={this.state.documentErrorMessage}
                  showLoadingIndicator={this.state.documentsLoading}
                  onDrop={this.onDocumentsDrop}
                  disabled={!this.props.selectedProject.isEditable}
                  actionDescription="Dokument hier ablegen"
                  cloudIcon={false}
                >
                  {this.renderDocumentName()}
                </DropContainer>
              </div>
            </div>
          )}
          <div className="row p-3">
            <div className="col-6 mt-1 mb-1">
              <ValidatedTextField
                fullWidth
                value={this.state.inputFields.location}
                id="location"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.location}
                label="Arbeitsort"
              />
            </div>
            <div className="col-6 mt-1 mb-1">
              <ValidatedTextField
                fullWidth
                value={this.state.inputFields.postalCode}
                id="postalCode"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.postalCode}
                label="Postleitzahl"
              />
            </div>
          </div>
          <div className="row p-3">
            <div className="col-6 mt-1 mb-1">
              <ValidatedTextField
                fullWidth
                value={this.state.inputFields.city}
                id="city"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.city}
                label="Stadt"
              />
            </div>
            <div className="col-6 mt-1 mb-1">
              <ValidatedDropDown
                id="country"
                value={this.state.inputFields.country}
                content={['Deutschland', 'Österreich', 'Schweiz']}
                label="Land"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.country}
              />
            </div>
            <div className="col-6 mt-1 mb-1 pb-3">
              <ValidatedTextField
                fullWidth
                value={this.state.inputFields.title}
                id="title"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.title}
                label="Anzeigentitel"
              />
            </div>
            <div className="col-6 mt-1 mb-1 pb-3">
              <ValidatedTextField
                fullWidth
                value={this.state.inputFields.companyNameInPosting}
                id="companyNameInPosting"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.companyNameInPosting}
                label="Angezeigter Unternehmensname"
              />
            </div>
            <div className="col-6 mt-1 mb-1 pb-3">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.onSaveButtonClick();
                }}
                disabled={
                  !this.state.isButtonActive ||
                  !this.props.selectedProject.isEditable
                }
              >
                <SaveIcon />
                Speichern
              </Button>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-12">
              <Editor
                initialValue={this.state.inputFields.description.value}
                id="description"
                init={{
                  language_url: '/langs/de.js',
                  branding: false,
                  height: '900',
                  fontsize_formats:
                    '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
                  plugins:
                    'link code lists textcolor colorpicker textpattern imagetools',
                  font_formats:
                    'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif',
                  toolbar:
                    'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image'
                }}
                onChange={this.handleEditorChange}
              />
            </div>
          </div>
          <div className="row p-3">
            <div className="col-12" />
          </div>
        </Paper>
      </div>
    );
  }
}

JobPosting.defaultProps = {};
JobPosting.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToProjectArchive: PropTypes.func.isRequired
};
export default JobPosting;
