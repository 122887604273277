import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';

import TEXT from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class PostalCodeFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { range: this.props.range };
    this.onChange = this.onChange.bind(this);
    this.onAfterChange = this.onAfterChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ range: nextProps.range });
  }

  onChange(range) {
    this.setState({ range });
  }

  onAfterChange(range) {
    this.props.onFilterChange(
      'distance',
      range,
      FILTER_HELPER.filterFunctions.distance
    );
  }

  render() {
    const capDistanceSymbol = 'unendlich';
    const subCapDistanceSymbol = `${this.state.range[1]}km`;
    const headline = `${TEXT.filter.mainFilter.postalCode} (${
      this.state.range[0]
    }km - ${
      this.state.range[1] > 240 ? capDistanceSymbol : subCapDistanceSymbol
    }):`;
    return (
      <div className="col-12 pb-5">
        <p>{headline}</p>
        <Range
          // defaultValue={[0, 1000]}
          value={this.state.range}
          step={10}
          min={0}
          max={250}
          marks={{
            0: '0',
            50: '50km',
            100: '100km',
            150: '150km',
            200: '200km',
            250: '∞'
          }}
          onChange={this.onChange}
          onAfterChange={this.onAfterChange}
        />
      </div>
    );
  }
}

PostalCodeFilter.defaultProps = {};
PostalCodeFilter.propTypes = {
  // onFilterChange: PropTypes.func.isRequired,
  // postalCodeList: PropTypes.arrayOf(PropTypes.string).isRequired
  onFilterChange: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default PostalCodeFilter;
