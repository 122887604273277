import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import NotificationListItem from './NotificationListItem';
import ReminderListItem from './ReminderListItem';

class NotificationList extends PureComponent {
  renderNotificationItems(dateSpanFrom, dateSpanTill, timeSeparation = true) {
    const array = [];
    let isFirstItem = true;
    this.props.notifications.forEach(link => {
      let skipTheDate = false;

      if (timeSeparation) {
        let timeTriggerDate = new Date(); // hack?
        if (link.dueDate) {
          timeTriggerDate = new Date(link.dueDate).getTime();
        } else if (link.triggerDate) {
          timeTriggerDate = new Date(link.triggerDate).getTime();
        }

        const timespanFrom =
          new Date().getTime() - dateSpanFrom * 24 * 60 * 60 * 1000;
        const timespanTill =
          new Date().getTime() - dateSpanTill * 24 * 60 * 60 * 1000;
        if (
          !(timeTriggerDate <= timespanFrom && timeTriggerDate > timespanTill)
        ) {
          skipTheDate = true;
        }
      }
      if (!skipTheDate) {
        if (!isFirstItem) {
          array.push(<Divider key={`dividerFor${link.id}`} />);
        }
        isFirstItem = false;
        if (this.props.onlyReminders) {
          array.push(
            <ReminderListItem
              key={link.id}
              dueDate={link.dueDate}
              title={`Wiedervorlage ${link.company.name}`}
              description={link.note}
              counterPart={link.counterPart}
              isRead={link.isDone}
              id={link.id}
              companyId={link.company.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onDeleteButtonClick={this.props.onDeleteButtonClick}
              onRowClick={this.props.onRowClick}
            />
          );
        } else if (link.type === 'counterPartReminder') {
          array.push(
            <NotificationListItem
              key={link.id}
              dueDate={link.triggerDate}
              title="Wiedervorlage"
              description={
                (link.notificationObject.counterPartReminder.company || {})
                  .name || '(Unternehmen nicht gefunden)'
              }
              isRead={link.isRead}
              id={link.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={link.notificationObject}
              type={link.type}
            />
          );
        } else if (link.type === 'candidateStatusExpired') {
          const candidateName = link.notificationObject.candidateStatusExpired
            .candidate
            ? (link.notificationObject.candidateStatusExpired.candidate
                .firstName &&
                `${
                  link.notificationObject.candidateStatusExpired.candidate
                    .firstName
                } `) +
              (link.notificationObject.candidateStatusExpired.candidate
                .lastName &&
                `${
                  link.notificationObject.candidateStatusExpired.candidate
                    .lastName
                } `)
            : '(Kandidat nicht gefunden)';
          array.push(
            <NotificationListItem
              key={link.id}
              dueDate={link.triggerDate}
              title="Kandidat im Gespräch ist abgelaufen"
              description={candidateName}
              isRead={link.isRead}
              id={link.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={link.notificationObject}
              type={link.type}
            />
          );
        }
      }
    });
    console.log('länge', array.length);
    if (array.length > 0) {
      return array;
    }
    if (this.props.onlyReminders)
      return (
        <div className="col-12 text-center p-3">
          Keine Wiedervorlagen vorhanden{' '}
        </div>
      );
    return (
      <div className="col-12 text-center p-3">
        Keine Benachrichtigungen vorhanden{' '}
      </div>
    );
  }

  renderNotificationList() {
    if (this.props.notifications.length > 0) {
      if (this.props.timeSeparation) {
        return (
          <React.Fragment>
            <h6 className="mb-3 mt-3">Letzte 24 Stunden</h6>
            <Paper>
              <List>{this.renderNotificationItems(0, 1)}</List>
            </Paper>
            {this.props.onlyReminders && (
              <React.Fragment>
                <h6 className="mb-3 mt-3">In Zukunft</h6>
                <Paper>
                  <List>{this.renderNotificationItems(-99999, 0)}</List>
                </Paper>
              </React.Fragment>
            )}
            <h6 className="mb-3 mt-3">Letzte Woche</h6>
            <Paper>
              <List>{this.renderNotificationItems(1, 7)}</List>
            </Paper>
            <h6 className="mb-3 mt-3">Älter als 7 Tage</h6>
            <Paper>
              <List>{this.renderNotificationItems(7, 999999)}</List>
            </Paper>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <Paper>
            <List>{this.renderNotificationItems(0, 0, false)}</List>
          </Paper>
        </React.Fragment>
      );
    }
    if (this.props.onlyReminders)
      return (
        <div className="col-12 text-center p-3">
          Keine Wiedervorlagen vorhanden{' '}
        </div>
      );
    return (
      <div className="col-12 text-center p-3">
        Keine Benachrichtigungen vorhanden{' '}
      </div>
    );
  }

  render() {
    return this.renderNotificationList();
  }
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  onlyReminders: PropTypes.bool,
  onIsReadButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  timeSeparation: PropTypes.bool
};
NotificationList.defaultProps = {
  onlyReminders: false,
  timeSeparation: false
};
export default NotificationList;
