import React from 'react';
import * as UTILS from '../utils/utilFunctions';

const Imprint = () => {
  document.title = `Impressum - ${UTILS.getTitle()}`;
  return (
    <div id="imprint" className="container h-100-minusAppBar ">
      <div className="row">
        <div className="col-12 mt-3">
          <p>
            HR Consult Group&nbsp;AG
            <br />
            Martin-Kollar-Straße 10
            <br />
            81829 München
            <br />
            Telefon: +49 (0) 89 41 41 97-97
            <br />
            Telefax: +49 (0) 89 41 41 97-99
            <br />
            E-Mail:&nbsp;
            <a href="mailto:info@hr-consult-group.de">
              info@hr-consult-group.de
            </a>
            <br />
            Web:&nbsp;
            <a
              href="http://www.hr-consult-group.de"
              target="_blank"
              rel="noreferrer noopener"
            >
              www.hr-consult-group.de
            </a>
          </p>

          <p>
            Ust.-ID: DE 183155334
            <br />
            Rechtsform: Aktiengesellschaft
            <br />
            Sitz der Gesellschaft: München
            <br />
            Amtsgericht München; HRB 114 709
          </p>

          <p>
            Vorsitzender des Aufsichtsrats:&nbsp;Joseph Hausberger
            <br />
            Vorstand: Dr. Armin Betz, Jürgen Grenz, Ralf Prechtl
          </p>

          <p>&nbsp;</p>

          <p>
            <strong>DATENSCHUTZERKLÄRUNG</strong>
          </p>

          <p>
            <strong>Personenbezogene Daten</strong>
            <br />
            Personenbezogene Daten sind Einzelangaben über persönliche oder
            sachliche Verhältnisse einer bestimmten oder bestimmbaren
            natürlichen Person. Darunter fallen Informationen wie z.B. Ihr
            richtiger Name, Ihre Anschrift, Ihre Telefonnummer und Ihr
            Geburtsdatum. Informationen, die nicht direkt mit Ihrer wirklichen
            Identität in Verbindung gebracht werden - wie z. B. Ihre
            favorisierten Webseiten - sind keine personenbezogenen Daten.
          </p>

          <p>
            <strong>
              Erhebung und Verarbeitung von personenbezogenen Daten
            </strong>
            <br />
            Sie können im Regelfall alle Internetseiten der HR Consult Group
            aufrufen, ohne personenbezogene Angaben zu machen. Wenn Sie die
            Webseiten der HR Consult Group besuchen, werden vom Webserver
            standardmäßig zum Zweck der Systemsicherheit temporär die
            Verbindungsdaten des anfragenden Rechners, die Webseiten, die
            besucht, das Datum und die Dauer des Besuches, die Erkennungsdaten
            des verwendeten Browser- und Betriebssystem-Typs sowie die Webseite,
            von der aus Sie die Webseiten der HR Consult Group besuchen,
            gespeichert. Die HR Consult Group behält sich vor, diese
            anonymisierten Daten auszuwerten, um das Nutzerverhalten kennen zu
            lernen und Statistiken darüber aufzustellen. Darüber hinausgehende
            personenbezogene Angaben wie Ihr Name, Ihre Anschrift, Telefonnummer
            oder E-Mail-Adresse werden nicht erfasst, es sei denn, diese Angaben
            werden von Ihnen freiwillig gemacht, z.B. im Rahmen einer
            Registrierung zur Durchführung eines Vertrages oder einer
            Informationsanfrage.
          </p>

          <p>
            <strong>Nutzung und Weitergabe der personenbezogenen Daten</strong>
            <br />
            Die von Ihnen zur Verfügung gestellten personenbezogenen Daten
            verwendet die HR Consult Group ausschließlich zum Zweck der
            technischen Administration der Webseiten und zur Erfüllung Ihrer
            Wünsche und Anforderungen, also in der Regel zur Unterstützung bei
            der Stellensuche oder zur Beantwortung Ihrer Anfrage.
          </p>

          <p>
            Eine Weitergabe, Verkauf oder sonstige Übermittlung Ihrer
            personenbezogenen Daten an Dritte erfolgt nicht, es sei denn, dass
            dies zum Zwecke der Vertragsabwicklung erforderlich ist oder Sie
            ausdrücklich eingewilligt haben. So kann es beispielsweise
            erforderlich sein, dass die HR Consult Group bei der Zusendung von
            Bewerbungsunterlagen Ihre Anschrift und Ihren Lebenslauf an Kunden
            weitergibt. Soweit die HR Consult Group gesetzlich oder per
            Gerichtsbeschluss dazu verpflichtet ist, werden personenbezogene
            Daten im geforderten Umfang an auskunftsberechtigte Stellen
            übermittelt.
          </p>

          <p>
            <strong>Einsatz von Cookies</strong>
            <br />
            Cookies sind Text-Dateien, die beim Besuch von Webseiten auf der
            Festplatte des Besuchers abgelegt werden und der
            (Zwischen-)Speicherung von Daten dienen. Sie beinhalten
            Informationen über Besucherdauer, besuchte Seiten oder Eingaben des
            Besuchers. Die meisten Webbrowser sind so eingestellt, dass sie
            Cookies automatisch akzeptieren. Diese Funktion können Sie jederzeit
            im Browser deaktivieren: Sie können Ihren Browser so einstellen,
            dass er Sie benachrichtigt, sobald Cookies gesendet werden.
          </p>

          <p>
            <strong>Links zu anderen Webseiten</strong>
            <br />
            Hyperlinks sind in der Regel Verknüpfungen zu anderen
            Internetseiten. Die Internetseiten der HR Consult Group enthalten
            ebenfalls Links zu anderen Webseiten. Es wird ausdrücklich darauf
            hingewiesen, dass die HR Consult Group keinen Einfluss auf den
            redaktionellen Inhalt fremder Webseiten hat und darauf, ob dort
            Datenschutzbestimmungen eingehalten werden.
          </p>

          <p>
            <strong>Recht auf Widerruf</strong>
            <br />
            Wenn Sie die HR Consult Group auffordern, Ihre personenbezogenen
            Daten nicht für die weitere Kontaktaufnahme zu verwenden und/oder zu
            löschen, so wird entsprechend verfahren. Daten, die für die
            Auftragsabwicklung bzw. zu kaufmännischen Zwecken zwingend
            erforderlich sind, werden von einer Kündigung/Löschung nicht
            berührt.
          </p>

          <p>
            <strong>Haftungsfreistellung</strong>
            <br />
            Mit dem Urteil vom 12. Mai 1998 hat das Landgericht Hamburg
            entschieden, dass durch die Ausbringung eines Links die Inhalte der
            gelinkten Seiten ggf. mit zu verantworten sind. Dies kann - so das
            LG - nur durch ausdrückliche Distanzierung von den Inhalten anderer
            Seiten verhindert werden. Von unserer Webseite führen Links zu
            anderen Seiten im Internet. Für alle diese Links gilt: Wir betonen
            ausdrücklich, dass wir keinerlei Einfluss auf die Gestaltung und die
            Inhalte der gelinkten Seiten haben. Daher distanzieren wir uns mit
            Nachdruck von allen Inhalten gelinkter Seiten und machen uns ihre
            Inhalte nicht zu eigen. Diese Erklärung gilt für alle Links und für
            die gesamten Inhalte aller Seiten, zu denen unsere Links führen,
            direkt oder indirekt.
          </p>

          <p>
            <strong>Hinweis</strong>
            <br />
            Aus Gründen der besseren Lesbarkeit verwenden wir in unseren Texten
            meist nur die männliche Form. Selbstverständlich sind immer beide
            Geschlechter angesprochen.
          </p>

          <p>
            <strong>Datenschutz - Google Analytics</strong>
          </p>

          <p>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
            den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Im Falle der Aktivierung der
            IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von
            Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
            Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
            Reports über die Websiteaktivitäten zusammenzustellen und um weitere
            mit der Websitenutzung und der Internetnutzung verbundene
            Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
            Rahmen von Google Analytics von Ihrem Browser übermittelte
            IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können.
            <br />
            <br />
            Sie können darüber hinaus die Erfassung der durch das Cookie
            erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
            Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
            durch Google verhindern, indem sie das unter dem folgenden Link (
            <a
              className="external free"
              href="http://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              http://tools.google.com/...
            </a>
            ) verfügbare Browser-Plugin herunterladen und installieren. Nähere
            Informationen hierzu finden Sie unter{' '}
            <a
              className="external free"
              href="http://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              http://tools.google.com/....
            </a>{' '}
            bzw. unter{' '}
            <a
              className="external free"
              href="https://www.google.com/analytics/learn/privacy.html"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              http://www.google.com/analytics/...
            </a>{' '}
            (allgemeine Informationen zu Google Analytics und Datenschutz).
            <br />
            <br />. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät abgelegt.
            Löschen Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link
            erneut klicken.
            <br />
            <br />
            Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics
            um den Code „gat._anonymizeIp();“ erweitert wurde, um eine
            anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu
            gewährleisten
          </p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
