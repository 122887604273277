import React, { Component } from 'react';
import PropTypes from 'prop-types';

import $ from 'jquery';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import UnarchiveButton from '@material-ui/icons/Unarchive';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import './IncomingCandidates.css';

const options = {
  filterType: 'checkbox',
  responsiv: 'scroll',
  selectableRows: false,
  fixedHeader: false,
  filter: true,
  search: true,
  print: false,
  download: false,
  viewColumns: false,
  textLabels: {
    body: {
      noMatch: 'Keine passenden Einträge gefunden',
      toolTip: 'Sort'
    },
    pagination: {
      next: 'Nächste Seite',
      previous: 'Vorherige Seite',
      rowsPerPage: 'Zeilen pro Seite:',
      displayRows: 'von'
    },
    toolbar: {
      search: 'Suche',
      downloadCsv: 'Download CSV',
      print: 'Print',
      viewColumns: 'View Columns',
      filterTable: 'Filter Table'
    },
    filter: {
      all: 'All',
      title: 'FILTERS',
      reset: 'RESET'
    },
    viewColumns: {
      title: 'Show Columns',
      titleAria: 'Show/Hide Table Columns'
    },
    selectedRows: {
      text: 'rows(s) selected',
      delete: 'Delete',
      deleteAria: 'Delete Selected Rows'
    }
  }
};

class IncomingMatchingProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incomingCandidateList: [],
      employeeList: []
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Matchingprofil Eingang - ${UTILS.getTitle()}`;
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    this.getIncomingCandidateList();
    this.getEmployeeListByPartner();
  }

  getCandidateDbValue(candidateDB) {
    if (candidateDB === true) return 'Ja';
    if (candidateDB === false) return 'Nein';
    return candidateDB;
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data) {
      data.forEach(entry => {
        formatedData.push([
          entry.id,
          entry.location,
          entry.creationDate,
          entry.firstName,
          entry.lastName,
          entry.mailAddress,
          entry.postalCode,
          entry.city,
          entry.taggedDocuments[0] && entry.taggedDocuments[0].documentName, // TODO
          entry.advisor,
          this.getCandidateDbValue(entry.candidateDB),
          entry.id
        ]);
      });
    }
    return formatedData;
  }

  getAdvisorIdByString(value) {
    let employeeId = '';
    this.state.employeeList.forEach(employee => {
      console.log(
        'value',
        value,
        `${employee.firstname} ${employee.lastname}`,
        `${employee.firstname} ${employee.lastname}` === value
      );

      if (`${employee.firstname} ${employee.lastname}` === value)
        employeeId = employee.id;
    });
    return employeeId;
  }

  setStateOfEntry = (entryId, key, value) => {
    this.setState(prevState => {
      const prevIncomingCandidateList = prevState.incomingCandidateList;
      prevIncomingCandidateList.forEach((entry, index) => {
        if (entry.id === entryId) prevIncomingCandidateList[index][key] = value;
      });
      return { incomingCandidateList: prevIncomingCandidateList };
    });
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    let parsedValue = '';
    if (key === 'candidateDB') {
      if (value === 'Ja') parsedValue = true;
      if (value === 'Nein') parsedValue = false;
    }

    if (key === 'advisor') {
      parsedValue = this.getAdvisorIdByString(value);
    }
    this.updateIncomingCandidate(entryId, key, parsedValue);
  };

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'exclude',
          filter: false
        }
      },
      {
        name: 'Standort',
        options: {
          filter: true
        }
      },
      {
        name: 'Datum Eingang',
        options: {
          filter: true,
          customBodyRender: value =>
            value ? UTILS.formatDateToDELocale(new Date(value)) : ''
        }
      },

      {
        name: 'Vorname',
        options: {
          filter: false
        }
      },
      {
        name: 'Nachname',
        options: {
          filter: false
        }
      },
      {
        name: 'E-Mail',
        options: {
          filter: false
        }
      },
      {
        name: 'PLZ',
        options: {
          filter: true
        }
      },
      {
        name: 'Stadt',
        options: {
          filter: true
        }
      },
      {
        name: 'PDF-Datei',
        options: {
          filter: false,
          customBodyRender: (value, metaData) => (
            <Button
              color="primary"
              onClick={() =>
                this.getCandidateDocumentById(metaData.rowData[0], value)
              }
            >
              PDF Herunterladen
            </Button>
          )
        }
      },
      {
        name: 'Berater',
        options: {
          filter: true,
          customBodyRender: (value, metaData) => (
            <FormControl required fullWidth>
              <Select
                value={value}
                onChange={e => {
                  this.setStateOfEntry(
                    metaData.rowData[0],
                    'advisor',
                    e.target.value
                  );
                }}
                name="advisor"
                inputProps={{
                  id: 'advisor-required'
                }}
              >
                <MenuItem value="" />
                {this.state.employeeList.map(employee => (
                  <MenuItem
                    key={`${employee.firstname} ${employee.lastname}`}
                    value={`${employee.firstname} ${employee.lastname}`}
                  >
                    {`${employee.firstname} ${employee.lastname}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
      },
      {
        name: 'Kandidaten DB',

        options: {
          filter: true,
          customBodyRender: (value, metaData) => (
            <FormControl required fullWidth>
              <Select
                value={value.toString()}
                onChange={e => {
                  this.setStateOfEntry(
                    metaData.rowData[0],
                    'candidateDB',
                    e.target.value
                  );
                }}
                name="candidateDB"
                inputProps={{
                  id: 'candidateDB-required'
                }}
              >
                <MenuItem value="" />
                <MenuItem value="Ja">Ja</MenuItem>
                <MenuItem value="Nein">Nein</MenuItem>
              </Select>
            </FormControl>
          )
        }
      },
      {
        name: 'Aktion',
        options: {
          filter: false,
          customBodyRender: value => (
            <Tooltip title="Zum Extraktor senden">
              <IconButton
                className=""
                aria-label="Edit"
                color="primary"
                onClick={() => {
                  this.updateIncomingCandidate(value, 'active', true);
                }}
              >
                <UnarchiveButton />
              </IconButton>
            </Tooltip>
          )
        }
      }
    ];
  }

  getCandidateDocumentById = (incomingCandidateId, fileName) => {
    const url = CONSTANTS.getCandidateDocumentByIdURL;
    const documentRequest = {
      candidateId: incomingCandidateId,
      fileName,
      isTaggedDocument: true
    };

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        let result = '';
        result = UTILS.convertBufferToPdf(response.content);

        const element = document.createElement('a');
        const blob = UTILS.dataURIToBlob(result);
        element.setAttribute('href', URL.createObjectURL(blob));

        element.setAttribute('download', fileName);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

        return result;
      })
      .fail(err => {
        console.log(err);
      });
  };

  getIncomingCandidateList() {
    const url = CONSTANTS.getIncomingCandidateListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const request = { active: false };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),

      timeout: CONFIG.ajaxTimeout * 2
    })
      .done(responseBody => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.setState({
          incomingCandidateList: responseBody
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  }

  updateIncomingCandidate(incomingCandidateId, key, value) {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const url = CONSTANTS.updateIncomingCandidateURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const request = { incomingCandidateId, updateData: { [key]: value } };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),

      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        if (responseBody)
          this.props.handleSnackbarOpen(
            'Ihre Änderungen wurden erfolgreich gespeichert'
          );
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        if (key === 'active') window.location = '/incomingCandidates';
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  }

  getEmployeeListByPartner() {
    const url = CONSTANTS.getEmployeeListByPartnerURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const request = { onlyAdvisors: true };

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),

      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.setState({
          employeeList: responseBody
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  }

  render() {
    return (
      <div className="IncomingCandidates  h-100-minusAppBar">
        <div className="container-fluid  h-100 mt-1">
          <div className="row">
            <div className="col-12">
              {this.state.incomingCandidateList.length > 0 &&
              this.state.employeeList.length > 0 ? (
                <SafeMuiDatatable
                  title="Matching Eingang"
                  data={this.prepareDataForTable(
                    this.state.incomingCandidateList
                  )}
                  columns={this.getColumns()}
                  options={options}
                />
              ) : (
                <div>
                  <div className="incomingCandidates-emptyPage">
                    Es liegen keine unbearbeiteten Matchingprofile vor
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
IncomingMatchingProfiles.defaultProps = {};
IncomingMatchingProfiles.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};
export default IncomingMatchingProfiles;
