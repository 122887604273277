import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import TEXT from './staticContent/text';
import * as UTILS from '../../utils/utilFunctions';
import GenericForm from '../../generic_components/GenericForm';
import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';

class IncomingCandidateDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  generateFormFields() {
    const forms = {
      firstName: {
        value: this.props.selectedIncomingCandidate.firstName,
        label: TEXT.incomingCandidateDetails.firstName,
        customClass: 'col-5 mb-2',
        required: true,
        validator: 'isCharsOnly'
      },
      lastName: {
        value: this.props.selectedIncomingCandidate.lastName,
        label: TEXT.incomingCandidateDetails.lastName,
        customClass: 'col-5 mb-2',
        required: true,
        validator: 'isCharsOnly'
      },

      mailAddress: {
        value: this.props.selectedIncomingCandidate.mailAddress,
        label: TEXT.incomingCandidateDetails.mailAddress,
        customClass: 'col-6 mb-2',
        validator: 'isEmail'
      }
    };

    return forms;
  }

  deleteIncomingCandidate() {
    const url = CONSTANTS.deleteIncomingCandidateURL;
    const request = {
      incomingCandidateId: this.props.selectedIncomingCandidate.id
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(request)
    })
      .done(() => {
        this.props.handleRemoveCandidate(
          this.props.selectedIncomingCandidate.id
        );
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  handleConfirmButton = updatedData => {
    this.props.liftUpSelectedCandidateToIncomingCandidates(updatedData);

    this.props.handleNext();
  };

  getProjectNumberText() {
    if (
      !this.props.selectedIncomingCandidate.projectNumber ||
      this.props.selectedIncomingCandidate.projectNumber.length === 0
    )
      return '';
    if (this.props.selectedIncomingCandidate.applied) return ' (beworben)';
    return ' (vorgeschlagen)';
  }

  renderInputFields() {
    return (
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-12 mt-3 mb-3">
            <GenericForm
              // key={this.props.selectedIncomingCandidate.id}
              formFields={this.generateFormFields()}
              handleSubmit={this.handleConfirmButton}
              buttonSaveText={TEXT.incomingCandidates.buttonNext}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log('incoming', this.props.selectedIncomingCandidate);
    return (
      <Paper>
        <div className="container pt-3">
          <div className="row">
            <div className="col-9">
              <h2>{TEXT.incomingCandidates.details}</h2>
            </div>
            <div className="col-3">
              <Button
                // variant="contained"
                className="deleteIncomingCandidate-button pull-right"
                color="primary"
                onClick={() => this.deleteIncomingCandidate()}
              >
                Kandidat löschen
                <DeleteIcon />
              </Button>
            </div>
          </div>
          {this.renderInputFields()}
        </div>
      </Paper>
    );
  }
}

IncomingCandidateDetails.defaultProps = {};
IncomingCandidateDetails.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired,
  handleRemoveCandidate: PropTypes.func.isRequired
};
export default IncomingCandidateDetails;
