import React, { PureComponent } from 'react';
import './AllSearchFields.css';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import SearchField from './SearchField';

import TEXT from '../text';

class AllSearchFields extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
    this.handleSearchFieldBlur = this.handleSearchFieldBlur.bind(this);
    this.renderSearchfields = this.renderSearchfields.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  onSearchChange(searchQuery, searchFieldIndex, columnIndex) {
    this.props.onSearchChange(searchQuery, searchFieldIndex, columnIndex);
  }

  handleOnKeyDown(e, searchFieldIndex, columnIndex) {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      this.handleSearchFieldBlur(e.target.value, searchFieldIndex, columnIndex);
      if (searchFieldIndex === 0) {
        // TODO Focus right neighbor
      } else {
        // TODO: Focus downstairs neighbor
      }
    }
  }

  handleSearchFieldChange(value, searchFieldIndex, columnIndex) {
    const searchQuery = this.props.searchQuery;
    searchQuery[columnIndex].column[searchFieldIndex].searchTerm = value;
    this.onSearchChange(searchQuery, searchFieldIndex, columnIndex);
    // TODO autocomplete
  }

  handleSearchFieldBlur(value, searchFieldIndex, columnIndex) {
    this.props.onSearchFieldBlur(value, searchFieldIndex, columnIndex);
  }

  renderColumns() {
    return this.props.searchQuery.map((column, columnIndex) => (
      <div
        className="searchFieldColumn col-sm-6 col-md-3"
        key={`${column.ID}`}
      >
        {/* {this.props.currentFocusedField.columnIndex === columnIndex &&
          
          // {this.props.searchQuery.length - 1 === columnIndex &&
          this.props.helpBoxDOM} */}
        <Paper className="paperbox">
          {this.renderSearchfields(column.column, columnIndex)}
        </Paper>
        {this.props.currentFocusedField.columnIndex === columnIndex &&
          // {this.props.searchQuery.length - 1 === columnIndex &&
          this.props.helpBoxDOM}
      </div>
    ));
  }

  renderSearchfields(column, columnIndex) {
    const array = [];
    let placeholder = '';

    column.forEach((searchField, searchFieldIndex) => {
      let errorText = '';
      if (searchFieldIndex === 0) {
        placeholder = TEXT.searchPage.searchInputField.mandatoryPlaceholder;
      } else placeholder = TEXT.searchPage.searchInputField.optionalPlaceholder;
      if (searchField.hasError) {
        errorText = TEXT.searchPage.searchInputField.errorMessage;
      }
      array.push(
        <div className="col-12" key={`${columnIndex}_${searchField.ID}`}>
          <SearchField
            className="searchField"
            hasError={searchField.hasError}
            errorText={errorText}
            placeholder={placeholder}
            value={searchField.searchTerm}
            searchFieldIndex={searchFieldIndex}
            columnIndex={columnIndex}
            onSearchFieldChange={this.handleSearchFieldChange}
            onSearchFieldBlur={this.handleSearchFieldBlur}
            onSearchFieldFocus={this.props.onSearchFieldFocus}
            onKeyDown={this.handleOnKeyDown}
          />
        </div>
      );
    });
    return array;
  }

  render() {
    return this.renderColumns();
  }
}

AllSearchFields.propTypes = {
  onSearchFieldBlur: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchFieldFocus: PropTypes.func.isRequired,
  searchQuery: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.arrayOf(
        PropTypes.shape({
          searchTerm: PropTypes.string,
          isSemantic: PropTypes.bool,
          ID: PropTypes.number
        })
      ),
      ID: PropTypes.number
    })
  ).isRequired,
  helpBoxDOM: PropTypes.object.isRequired,
  currentFocusedField: PropTypes.object.isRequired
};
export default AllSearchFields;
