import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AllSearchResults.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import $ from 'jquery';
import TEXT from '../text';
import SingleSearchResult from './SingleSearchResult';
import SortLabel from './SortLabel';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import withUser from '../utils/withUser';
import MarkItemPopUp from './MarkItemPopUp';
import AddCandidateToProjectPopUp from '../candidateArchive/popUps/AddCandidateToProjectPopUp';

/**
 * Displays the
 *    - Sort options
 *    - Hit count
 *    - SingleSearchResult's in pagination with the pagination controls
 * @author victor
 * @extends Component
 */
class AllSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      markCandidatePopUpOpen: false,
      selectedUserMarkItem: {},
      showAddCandidateToProjectPopUp: false,
      addCandidateToProjectCandidateId: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  liftUpStateToAllSearchResults = (stateNameOrObject, value) => {
    this.setState({ [stateNameOrObject]: value });
  };

  componentDidUpdate() {
    if (['admin', 'employee'].includes(this.props.user.role))
      this.downloadCandidateImages();
  }

  /**
   * Control for the next or the previous page
   * @param  {[type]} event [description] TODO
   * @param  {number} page  the next or the previous page number
   */
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  /**
   * The amount of pages that are displayed on a single page
   * @param  {[type]} event [description] TODO
   */
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  /**
   * Download the Image for a candidate and store it in the state of AllSearchResults
   * after a base64 conversion
   */
  downloadCandidateImages() {
    const allCandidates = this.props.results;
    const startResult = this.state.page * this.state.rowsPerPage;
    // slice onlz the viewed candidates in pagination page,
    // filter only those  without an image then return the array of ID's
    // of those candidates
    const displayedCandidatesIDs = {
      candidateIDs: this.props.results
        .slice(startResult, startResult + this.state.rowsPerPage)
        .filter(candidate => candidate.picture === undefined)
        .filter(candidate => !candidate.anonymized)
        .map(candidate => ({ ID: candidate.ID, origins: candidate.origin }))
    };
    console.log('');
    // check if image is already downloaded
    if (displayedCandidatesIDs.candidateIDs.length > 0) {
      /*
      loading indicator is handled directly by instances of SingleSearchResult component
      timeout is not required
       */
      const url = CONSTANTS.getCandidateImage2URL;
      const tokenFromLocalStorage = window.sessionStorage.getItem('token');

      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        headers: { 'x-auth': tokenFromLocalStorage },
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(displayedCandidatesIDs)
      })
        .done(response => {
          // slice allCandidates for the ones that are viewd,
          // then filter all those that have the picture value undefined
          const displayedCandidatesWithoutPicture = allCandidates
            .slice(startResult, startResult + this.state.rowsPerPage)
            .filter(candidateObj => {
              if (candidateObj.picture) return false;
              return true;
            });
          // for each ID in the response array find candidate in
          // allCandidates array and set the value of the coresponding picture
          response.results.forEach(candidateObjIterator => {
            const indexOfCandidateToAddPicture = displayedCandidatesWithoutPicture.findIndex(
              candidate => candidate.ID === candidateObjIterator.ID
            );
            if (
              displayedCandidatesWithoutPicture[indexOfCandidateToAddPicture] &&
              candidateObjIterator.picture
            ) {
              displayedCandidatesWithoutPicture[
                indexOfCandidateToAddPicture
              ].picture = UTILS.convertBufferToImage(
                candidateObjIterator.picture
              );
            } else if (
              displayedCandidatesWithoutPicture[indexOfCandidateToAddPicture] &&
              !candidateObjIterator.picture
            ) {
              displayedCandidatesWithoutPicture[
                indexOfCandidateToAddPicture
              ].picture = CONSTANTS.candidatePlaceholderPictureURL;
            }
          });

          // update results in the parent component with allCandidates
          this.props.liftUpResults(allCandidates);
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    }
  }

  /**
   * a loading indicator component if this.props.showLoadingIndicator=true or
   * a SingleSearchResult component Array if the above are false
   * @return {JSX}
   */
  renderSearchResults() {
    let result = <TableRow />;
    if (!this.props.showLoadingIndicator && this.props.results.length > 0) {
      const startResult = this.state.page * this.state.rowsPerPage;
      result = this.props.results
        .slice(startResult, startResult + this.state.rowsPerPage)
        .map((singleResult, index) => {
          const mappedSingleResult = singleResult;
          mappedSingleResult.age =
            singleResult.age && singleResult.age > 1
              ? singleResult.age
              : TEXT.commonTerms.NA;
          return (
            <TableRow
              key={singleResult.ID + singleResult.lastName + singleResult.score}
            >
              <td>
                <SingleSearchResult
                  index={startResult + index}
                  handleMarkCandidate={this.props.handleMarkCandidate}
                  handleAddCandidateToProject={this.handleAddCandidateToProject}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  liftUpStateToAllSearchResults={
                    this.liftUpStateToAllSearchResults
                  }
                  {...mappedSingleResult}
                />
              </td>
            </TableRow>
          );
        });
    } else if (
      !this.props.showLoadingIndicator &&
      this.props.results.length === 0
    ) {
      result = (
        <TableRow>
          <h4 className="no-result-info">{TEXT.resultPage.noResults}</h4>
        </TableRow>
      );
    }
    return result;
  }

  renderPaginationControls() {
    return (
      <TableRow>
        <TablePagination
          count={this.props.results.length}
          rowsPerPage={this.state.rowsPerPage}
          rowsPerPageOptions={[10, 30, 50]}
          labelRowsPerPage={TEXT.resultPage.labelRowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} ${TEXT.resultPage.labelPaginationFromTo1} ${to} ${
              TEXT.resultPage.labelPaginationFromTo2
            } ${count}`
          }
          // TODO check if required for "last" and "first" page controls
          // ActionsComponent={TablePaginationActionsWrapped}
        />
      </TableRow>
    );
  }

  renderSortButtons() {
    const sortButtons = [];
    Object.values(TEXT.resultPage.sortingOptions).forEach(optionString => {
      if (!this.props.disableDistanceSorting || optionString !== 'Entfernung') {
        sortButtons.push(
          <SortLabel
            key={optionString}
            isActive={optionString === this.props.sortBy}
            direction={this.props.sortDescending}
            label={optionString}
            onClick={this.props.sortResults}
          />
        );
      }
    });
    return sortButtons;
  }

  handleEditCandidateStatusAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateCandidateURL;
    const payload = {
      candidateId: this.state.addCandidateToProjectCandidateId,
      updateData: {
        isAvailable: false,
        statusType: 'locked',
        statusExpirationDate: new Date().setMonth(new Date().getMonth() + 3) // plus 3 months
      }
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        if (responseBody.success === true) {
          this.props.handleSnackbarOpen('Der Status wurde erfolgreich gesetzt');
        } else {
          // this.props.handleSnackbarOpen('SFehler');
          this.props.handleSnackbarOpen(
            `Sie haben bereits ${
              responseBody.errorDetails.lockedByUserCount
            } Kandidaten auf im Gespräch gestellt. Bitte entsperren Sie zunächst andere Kandidaten.`
          );
        }
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  addCandidateToProjectAPICall = projectId => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.addPotentialCandidateURL;
    const payload = {
      projectId,
      potentialCandidateData: {
        candidate: this.state.addCandidateToProjectCandidateId,
        applied: false,
        proposed: true
      }
    };

    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        if (responseBody.success) {
          this.props.handleSnackbarOpen(
            'Der Kandidat wurde erfolgreich zum Auftrag hinzugefügt'
          );
          if (CONSTANTS.isLienert) this.handleEditCandidateStatusAPICall();
        } else if (
          !responseBody.success &&
          responseBody.error === 'candidateIsAlreadyPotentialCandidate'
        )
          this.props.handleSnackbarOpen(
            'Der Kandidat befindet sich bereits im Status "vorgeschlagen" dem ausgewählten Auftrag zugeordnet'
          );
        else
          this.props.handleSnackbarOpen(
            'Es ist ein technischer Fehler aufgetreten'
          );
        this.setState({ showAddCandidateToProjectPopUp: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  renderAddCandidateToProjectPopUp() {
    return (
      <AddCandidateToProjectPopUp
        open={this.state.showAddCandidateToProjectPopUp}
        handleClose={() => {
          this.setState({ showAddCandidateToProjectPopUp: false });
        }}
        handleAddCandidateToProject={this.addCandidateToProjectAPICall}
      />
    );
  }

  render() {
    return (
      <div className="AllSearchResults">
        <div className="d-flex justify-content-between p-2">
          <div className="">{this.renderSortButtons()}</div>
          <div className="pr-2">
            {`${TEXT.resultPage.amountOfHits} ${this.props.resultCount}`}
          </div>
        </div>
        <div className="m-2 text-left">
          <div className="p-2 mt-1 text-left cl-teal result-bar">
            {TEXT.resultPage.results}
          </div>
        </div>
        <MarkItemPopUp
          open={this.state.markCandidatePopUpOpen}
          handleClose={() => this.setState({ markCandidatePopUpOpen: false })}
          item={this.state.selectedUserMarkItem}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          markListPopupType="candidate"
        />
        <Table>
          <TableHead className="delete-border">
            {this.props.resultCount ? this.renderPaginationControls() : <tr />}
          </TableHead>
          <TableBody>{this.renderSearchResults()}</TableBody>
          <TableFooter>
            {this.props.resultCount ? this.renderPaginationControls() : <tr />}
          </TableFooter>
        </Table>
        {this.state.showAddCandidateToProjectPopUp &&
          this.renderAddCandidateToProjectPopUp()}
      </div>
    );
  }
}

AllSearchResults.defaultProps = {
  role: []
};

AllSearchResults.propTypes = {
  showLoadingIndicator: PropTypes.bool.isRequired,
  resultCount: PropTypes.number.isRequired,
  liftUpResults: PropTypes.func.isRequired,
  sortResults: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDescending: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      isAvailable: PropTypes.bool,
      currentResidence: PropTypes.string,
      currentCountry: PropTypes.string,
      age: PropTypes.string,
      lastChange: PropTypes.string,
      ID: PropTypes.string.isRequired,
      picture: PropTypes.string,
      jobs: PropTypes.arrayOf(
        PropTypes.shape({
          jobText: PropTypes.string,
          description: PropTypes.string,
          company: PropTypes.string,
          duration: PropTypes.number,
          branches: PropTypes.arrayOf(PropTypes.string),
          ID: PropTypes.string.isRequired
        })
      ),
      score: PropTypes.number.isRequired,
      isMarked: PropTypes.bool.isRequired,
      willingnessToChange: PropTypes.number.isRequired,
      distance: PropTypes.number.isRequired,
      willingnessToRelocate: PropTypes.string,
      brand: PropTypes.array
    })
  ).isRequired,
  user: PropTypes.object.isRequired,
  // PropTypes.shape({
  //   role: PropTypes.array.isRequired
  // }).isRequired,
  role: PropTypes.array,
  handleMarkCandidate: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  disableDistanceSorting: PropTypes.func.isRequired
};

export default withUser(AllSearchResults);
