import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import TEXT from './staticContent/text';

import GenericArrayForm from '../../generic_components/GenericArrayForm';

class IncomingCandidateSkills extends PureComponent {
  generateFormRows(candidate) {
    const forms = [];
    if (candidate.additionalSkills)
      candidate.additionalSkills.forEach(skills => {
        forms.push({
          skillName: {
            value: skills.skillName,
            label: TEXT.incomingCandidateSkills.skillName,
            customClass: 'col-6 mb-2'
          },
          skillLevel: {
            value: skills.skillLevel,
            label: TEXT.incomingCandidateSkills.skillLevel,
            customClass: 'col-6 mb-2'
          }
        });
      });

    return forms;
  }

  handleConfirmButton = updatedData => {
    const update = { additionalSkills: updatedData };

    this.props.liftUpSelectedCandidateToIncomingCandidates(update);

    this.props.handleNext();
  };

  renderInputFields() {
    return (
      <div className="row">
        <div className="col-12 mt-3">
          <GenericArrayForm
            // key={this.props.selectedIncomingCandidate.id}
            formRows={this.generateFormRows(
              this.props.selectedIncomingCandidate
            )}
            oldValue={this.generateFormRows(
              this.props.selectedIncomingCandidate
            )}
            newValue={this.generateFormRows(
              this.props.selectedDuplicateCandidate
            )}
            handleSubmit={this.handleConfirmButton}
            handleCancel={this.props.handleBack}
            buttonSaveText={TEXT.incomingCandidates.buttonNext}
            title={TEXT.incomingCandidates.skills}
            buttonCancelText={TEXT.incomingCandidates.buttonBack}
            buttonSubmitText={TEXT.incomingCandidates.buttonNext}
            baseModel={{
              skillName: {
                value: '',
                label: TEXT.incomingCandidateSkills.skillName,
                customClass: 'col-6 mb-2'
              },
              skillLevel: {
                value: '',
                label: TEXT.incomingCandidateSkills.skillLevel,
                customClass: 'col-6 mb-2'
              }
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return <Paper>{this.renderInputFields()}</Paper>;
  }
}

IncomingCandidateSkills.defaultProps = { selectedDuplicateCandidate: {} };

IncomingCandidateSkills.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired,
  selectedDuplicateCandidate: PropTypes.object
};
export default IncomingCandidateSkills;
