import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import TEXT from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class CompanyNameFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { companyName: '' };
    this.onChangeDebounced = flowRight(
      debounce(this.onChangeDebounced, 400),
      e => e.persist() || e
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyName !== prevProps.companyName) {
      this.setState({ companyName: this.props.companyName }); // eslint-disable-line
    }
  }

  /**
   * OnChange method for the StarRating component, calls the onFilterChange function
   * @param  {number} value number of selected newSetFilterParameters
   */
  onChangeDebounced = event => {
    this.props.onFilterChange(
      'companyName',
      event.target.value,
      FILTER_HELPER.filterFunctions.companyName
    );
  };

  onChange = event => {
    this.setState({ companyName: event.target.value });
    this.onChangeDebounced(event);
  };

  render() {
    return (
      <div className="col-12 pb-5">
        <TextField
          id="standard-search"
          label={TEXT.filter.mainFilter.companyName}
          type="search"
          margin="normal"
          onChange={this.onChange}
          value={this.state.companyName}
        />
      </div>
    );
  }
}
CompanyNameFilter.defaultProps = {};
CompanyNameFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired
};
export default CompanyNameFilter;
