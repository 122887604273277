import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import $ from 'jquery';

import './Register.css';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

const TEXT = require('../text');

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

const theme = createMuiTheme(THEME.login);

class Register extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: {
        firstname: '',
        lastname: '',
        // phoneNumber: '',
        password: '',
        passwordRepeated: ''
      },
      errorMsg: '',
      // validations
      passwordRequirements: [false, false, false, false, false],
      validatePasswordRepeated: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Registrierung - ${UTILS.getTitle()}`;
  }

  formValidation = () => {
    let isValid = false;

    // validatePasswordRequirements
    let passwordRequirementsValid = true;
    for (let i = 0; i < this.state.passwordRequirements.length; i++) {
      if (this.state.passwordRequirements[i] === false) {
        passwordRequirementsValid = false;
        break;
      }
    }
    isValid =
      passwordRequirementsValid &&
      this.state.validatePasswordRepeated &&
      this.state.inputFields.firstname.length > 0 &&
      this.state.inputFields.lastname.length > 1;
    return isValid;
  };

  validatePasswordRepeated = (passwordParam, passwordRepeatedParam) => {
    const password =
      typeof passwordParam === 'undefined'
        ? this.state.inputFields.password
        : passwordParam;
    const passwordRepeated =
      typeof passwordRepeatedParam === 'undefined'
        ? this.state.inputFields.passwordRepeated
        : passwordRepeatedParam;
    const validatePasswordRepeated =
      password === passwordRepeated && passwordRepeated.length > 0;
    this.setState({ validatePasswordRepeated });
  };

  validatePassword = password => {
    const passwordRequirements = this.state.passwordRequirements;
    // check min minlength
    passwordRequirements[0] = password.length >= 8;

    // check for specialChar
    const specialCharRegex = new RegExp(/[^\w]/);
    passwordRequirements[1] = specialCharRegex.test(password);

    // check for number
    const numberRegex = new RegExp(/[0-9]/);
    passwordRequirements[2] = numberRegex.test(password);

    const upperCaseRegex = new RegExp(/[A-Z]/);
    passwordRequirements[3] = upperCaseRegex.test(password);

    const lowerCaseRegex = new RegExp(/[a-z]/);
    passwordRequirements[4] = lowerCaseRegex.test(password);
  };

  handleChange = event => {
    const key = event.target.id;
    const value = event.target.value;
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [key]: value
      }
    }));
    if (key === 'password') {
      this.validatePassword(value);
      this.validatePasswordRepeated(value, undefined);
    }
    if (key === 'passwordRepeated')
      this.validatePasswordRepeated(undefined, value);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.registerAPIcall();
  };

  validateForm() {
    return true; // this.state.email.length > 4 && this.state.password.length > 5;
  }

  registerAPIcall() {
    this.setState({ errorMsg: '' });
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const requestBody = {
      ...this.state.inputFields
    };
    const url = CONSTANTS.registerURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    console.log('API /register request: ', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /register response body: ', responseBody);
        console.log('API /register response status: ', status);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        if (responseBody && responseBody.status === 'active')
          window.location = '/search';
        else window.location = '/registerTan';
      })
      // error case user not found / login data not validate
      // error cases user.status = blocked or deactived
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        if (
          err.status === 400 &&
          err.responseText === 'newAndOldPasswordIsIdenticital'
        ) {
          this.setState({
            errorMsg: TEXT.registerPage.errorMsg.newAndOldPasswordIsIdenticital
          });
        } else {
          this.errorHandling(err, url);
        }
      });
  }

  renderPasswordRequirmentIcons() {
    let classNameIconMissing = 'material-icons list-icon-missing  pr-1';
    if (this.state.inputFields.password.length <= 0)
      classNameIconMissing = 'material-icons list-icon-missing-gray  pr-1';
    return <i className={classNameIconMissing}>cancel</i>;
  }

  renderPasswordRepeatIcons() {
    let classNameIconMissing = 'material-icons list-icon-missing  pr-1';
    if (this.state.inputFields.passwordRepeated.length <= 0)
      classNameIconMissing = 'material-icons list-icon-missing-gray  pr-1';
    return <i className={classNameIconMissing}>cancel</i>;
  }

  render() {
    const fontColorHeadline = THEME.login.palette.primary.light;
    const fontColorDescription = THEME.login.palette.primary.contrastText;
    const fontStyleHeadline = { color: fontColorHeadline };
    const fontStyleDescription = { color: fontColorDescription };

    const iconCheck = (
      <i className="material-icons list-icon-check pr-1">check_circle</i>
    );
    const iconMissing = this.renderPasswordRequirmentIcons();
    const iconNotMatching = this.renderPasswordRepeatIcons();

    return (
      <div
        className="container-fluid h-100-minusAppBar"
        style={THEME.loginBackground}
      >
        <div className="row justify-content-center align-items-center h-100">
          <div className="Login col col-sm-12 col-md-9 col-lg-8 col-xl-6">
            {/* {this.props.loggedIn && <Redirect to="/search" />} */}

            {/* {this.state.errorMsg && (
              <div className="errorContainer">{this.state.errorMsg}</div>
            )} */}
            {this.state.errorMsg && (
              <div className="errorContainer">{this.state.errorMsg}</div>
            )}
            <form onSubmit={this.handleSubmit}>
              <Paper
                style={THEME.loginPaper}
                className="registerPaper text-center"
                elevation={24}
              >
                <img
                  src={THEME.loginLogo}
                  alt={TEXT.loginPage.imageAlt}
                  className="registerLogo"
                />
                <MuiThemeProvider theme={theme}>
                  <div className="container register-container">
                    <div className="register-textContainer">
                      <div
                        className="register-welcomeHeadline"
                        style={fontStyleHeadline}
                      >
                        Willkommen!
                      </div>
                      <div
                        className="register-welcomeSubheadline"
                        style={fontStyleHeadline}
                      >
                        <p>
                          Vervollständigen Sie Ihre Daten und schließen Sie die
                          Registrierung ab.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  col-md-6">
                        <TextField
                          id="firstname"
                          onChange={this.handleChange}
                          value={this.state.inputFields.firstname}
                          label={TEXT.registerPage.inputFields.firstname}
                          autoFocus
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <TextField
                          fullWidth
                          id="lastname"
                          onChange={this.handleChange}
                          value={this.state.inputFields.lastname}
                          label={TEXT.registerPage.inputFields.lastname}
                        />
                      </div>
                      {/* <div className="col-12  col-md-6">
                        <TextField
                          fullWidth
                          id="phoneNumber"
                          onChange={this.handleChange}
                          value={this.state.inputFields.phoneNumber}
                          label={TEXT.registerPage.inputFields.phoneNumber}
                        />
                      </div> */}
                      <div className="col-12 col-sm-6">
                        <div className="registerPage-textContainer">
                          <div
                            className="registerPage-passwordRequirements-container"
                            style={fontStyleDescription}
                            // style={fontStyleDescription}
                          >
                            Wählen Sie ein neues sicheres Passwort, welches
                            folgende Kriterien erfüllt:
                            <div className="list-passwordRequirements">
                              <ul>
                                <li>
                                  <div className="registerPage-listItem">
                                    {this.state.passwordRequirements[0]
                                      ? iconCheck
                                      : iconMissing}
                                    {TEXT.registerPage.passwordRequirements[0]}
                                  </div>
                                </li>
                                <li>
                                  <div className="registerPage-listItem">
                                    {this.state.passwordRequirements[1]
                                      ? iconCheck
                                      : iconMissing}
                                    {TEXT.registerPage.passwordRequirements[1]}
                                  </div>
                                </li>
                                <li>
                                  <div className="registerPage-listItem">
                                    {this.state.passwordRequirements[2]
                                      ? iconCheck
                                      : iconMissing}
                                    {TEXT.registerPage.passwordRequirements[2]}
                                  </div>
                                </li>
                                <li>
                                  <div className="registerPage-listItem">
                                    {this.state.passwordRequirements[3]
                                      ? iconCheck
                                      : iconMissing}
                                    {TEXT.registerPage.passwordRequirements[3]}
                                  </div>
                                </li>
                                <li>
                                  <div className="registerPage-listItem">
                                    {this.state.passwordRequirements[4]
                                      ? iconCheck
                                      : iconMissing}
                                    {TEXT.registerPage.passwordRequirements[4]}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <TextField
                          id="password"
                          label={TEXT.registerPage.inputFields.password}
                          value={this.state.password}
                          onChange={this.handleChange}
                          margin="normal"
                          type="password"
                          fullWidth
                        />
                        <div className="registerPage-passwordRepeated">
                          <TextField
                            id="passwordRepeated"
                            label={
                              TEXT.registerPage.inputFields.passwordRepeated
                            }
                            value={this.state.password}
                            onChange={this.handleChange}
                            margin="normal"
                            type="password"
                            fullWidth
                          />
                          <div className="registerPage-passwordRepeated-validationCheck">
                            {this.state.validatePasswordRepeated
                              ? iconCheck
                              : iconNotMatching}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button
                    className="login-button mt-3"
                    variant="contained"
                    color="primary"
                    disabled={!this.formValidation()}
                    type="submit"
                  >
                    {TEXT.registerPage.button}
                  </Button>
                </MuiThemeProvider>
              </Paper>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired
  // loggedIn: PropTypes.bool.isRequired
};

export default Register;
