import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// libraries
// import $ from 'jquery';

// material UI
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

// import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';

import SerialMailContainer from './SerialMailContainer';

class SerialMailDrawer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  transition(props) {
    return <Slide direction="up" mountOnEnter unmountOnExit {...props} />;
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={this.transition}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.props.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Serienmail
            </Typography>
          </Toolbar>
        </AppBar>
        <SerialMailContainer
          key={this.props.markListId}
          markList={this.props.markList}
          markListId={this.props.markListId}
          liftUpStateToApp={this.props.liftUpStateToApp}
        />
      </Dialog>
    );
  }
}

SerialMailDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  markListId: PropTypes.string.isRequired,
  markList: PropTypes.object.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default SerialMailDrawer;
