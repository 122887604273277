import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton'; // for Snackbar

import { mayManageMatchflix } from '../utils/isPermissionSet';
import withUser from '../utils/withUser';
import TEXT from '../text';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

class CreateUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessMessage: false,
      email: '',
      role: '',
      company: '',
      checkbox: {
        scope: {
          senator: {
            isChecked: true
          },
          personaltotal: {
            isChecked: true
          }
        }
      },
      partners: [],
      selectPartner: '',
      snackbarOpen: false,
      snackbarText: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  sortPartners(partners, sortedByBrand = 'pt') {
    let selectedBrand = sortedByBrand;
    if (sortedByBrand === 'personaltotal') selectedBrand = 'pt';
    else if (sortedByBrand === 'lienert') selectedBrand = 'LIENERT';
    const sortedPartners = partners.sort((a, b) => {
      if (
        !b.partnerName ||
        (a.partnerName &&
          a.partnerName.includes(selectedBrand) &&
          b.partnerName &&
          !b.partnerName.includes(selectedBrand))
      )
        return -1;
      if (
        !a.partnerName ||
        (b.partnerName &&
          b.partnerName.includes(selectedBrand) &&
          a.partnerName &&
          !a.partnerName.includes(selectedBrand))
      )
        return 1;
      return 0;
    });

    return sortedPartners;
  }

  getPartnersAPICall() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getAllPartnersURL;

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.setState({
          partners: this.sortPartners(responseBody.partners)
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  componentDidMount() {
    this.getPartnersAPICall();
  }

  getCheckedCheckboxes(checkboxType) {
    const checkedCheckboxes = [];
    const checkbox = this.state.checkbox[checkboxType];
    const checkboxKeys = Object.keys(checkbox);
    checkboxKeys.forEach(checkboxKey => {
      if (checkbox[checkboxKey].isChecked) checkedCheckboxes.push(checkboxKey);
    });
    return checkedCheckboxes;
  }

  createUserAPIcall = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const scope = ['employee', 'admin'].includes(this.state.role)
      ? this.getCheckedCheckboxes('scope')
      : [];
    const company =
      this.state.role === 'client'
        ? this.state.company.trim().toLowerCase()
        : undefined;

    const requestBody = {
      email: this.state.email.toLowerCase(),
      roles: this.state.role,
      company,
      scope,
      partner: this.state.selectPartner
    };
    const url = CONSTANTS.inviteUserToRegistrationURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.setState({
          showSuccessMessage: true
          // snackbarOpen: true,
          // snackbarText:
          //   TEXT.adminPage.createUserPopup.snackbarText.userCreationSuccess
        });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        const ignoreErrorCodes = [400, 409];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 409) {
          this.setState({
            snackbarOpen: true,
            snackbarText:
              TEXT.adminPage.createUserPopup.snackbarText.userCreationFailure409
          });
        }
        if (err.status === 400) {
          this.setState({
            snackbarOpen: true,
            snackbarText:
              TEXT.adminPage.createUserPopup.snackbarText.userCreationFailure400
          });
        }
      });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  onInputFieldChange = e => {
    // const inputFieldKey = 'firstName';
    let inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id;
    if (inputFieldKey === 'email')
      inputFieldValue = inputFieldValue.toLowerCase().trim();
    this.setState({ [inputFieldKey]: inputFieldValue });
  };

  handleChangeRole = event => {
    this.setState({
      role: event.target.value,
      company: '',
      checkbox: {
        scope: {
          senator: {
            isChecked: true
          },
          personaltotal: {
            isChecked: true
          }
        }
      }
    });
  };

  handleChangeCompany = event => {
    this.setState({ company: event.target.value });
  };

  onCheckboxChange = (checkboxType, checkboxKey) => {
    this.setState(prevState => ({
      checkbox: {
        ...prevState.checkbox,
        [checkboxType]: {
          ...prevState.checkbox[checkboxType],
          [checkboxKey]: {
            ...prevState.checkbox[checkboxType][checkboxKey],
            isChecked: !prevState.checkbox[checkboxType][checkboxKey].isChecked
          }
        }
      }
    }));
  };

  handleClose = () => {
    this.setState({ showSuccessMessage: false });
    this.props.handleClose();
  };

  renderSuccessMessage() {
    return (
      <div>
        <DialogTitle>
          <div className="createUser-success-dialog-title">
            <i className="material-icons successIcon-userCreation pb-3">
              check_circle_outline
            </i>
            {TEXT.adminPage.createUserPopup.successPopup.headline}
          </div>
        </DialogTitle>
        <DialogContent id="createUser-success-dialog-content">
          <DialogContentText>
            {TEXT.adminPage.createUserPopup.successPopup.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ showSuccessMessage: false });
            }}
            color="primary"
          >
            {TEXT.adminPage.createUserPopup.successPopup.buttonAgain}
          </Button>
          <Button onClick={this.handleClose} color="primary">
            {TEXT.adminPage.createUserPopup.successPopup.buttonClose}
          </Button>
        </DialogActions>
      </div>
    );
  }

  isValidForm = () => {
    const isValid =
      this.state.email.length > 4 &&
      (this.state.role !== 'client' || this.state.company.length > 1) &&
      (this.state.selectPartner.length > 0 || this.state.role === 'client');
    return isValid;
  };

  getPartnersMenuItems() {
    const partnersMenuItems = [
      <MenuItem value="">
        <em>Keine Auswahl getroffen</em>
      </MenuItem>
    ];
    // const filteredPartners = this.state.partners()
    const sortedPartners = this.sortPartners(
      this.state.partners,
      this.state.company
    );
    sortedPartners.forEach(partner => {
      partnersMenuItems.push(
        <MenuItem key={partner.id} value={partner.id}>
          {`${partner.partnerName} ID: ${partner.partnerId}`}
        </MenuItem>
      );
    });
    return partnersMenuItems;
  }

  renderPartnerDropDown() {
    return (
      <FormControl className="w-100">
        <InputLabel htmlFor="partners-select">
          {TEXT.adminPage.createUserPopup.selectPartner}
        </InputLabel>
        <Select
          value={this.state.selectPartner}
          onChange={event =>
            this.setState({ selectPartner: event.target.value })
          }
          inputProps={{
            name: 'partners',
            id: 'partners-select'
          }}
        >
          {this.getPartnersMenuItems()}
        </Select>
      </FormControl>
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          {this.state.showSuccessMessage ? (
            this.renderSuccessMessage()
          ) : (
            <div>
              <DialogTitle id="form-dialog-title-success">
                <div className="createUser-dialog-title">
                  {TEXT.adminPage.createUserPopup.headline}
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {TEXT.adminPage.createUserPopup.description}
                </DialogContentText>
                <div className="container">
                  <div className="row pt-2">
                    <div className="col-12">
                      <TextField
                        fullWidth
                        id="email"
                        onChange={this.onInputFieldChange}
                        value={this.state.email}
                        label={TEXT.adminPage.createUserPopup.inputFields.email}
                      />
                    </div>
                  </div>

                  <div className="row pt-4">
                    <div className="col-12">
                      <FormLabel component="legend" className="pb-2 mt-3">
                        {TEXT.adminPage.createUserPopup.checkbox.roles.label}
                      </FormLabel>
                      <FormControl>
                        <RadioGroup
                          aria-label="role"
                          name="role"
                          value={this.state.role}
                          onChange={this.handleChangeRole}
                        >
                          {mayManageMatchflix(this.props.user) && (
                            <FormControlLabel
                              className="checkFilterLabel"
                              value="client"
                              control={<Radio className="chkBox_" />}
                              label="Externer Kunde (Match-flix)"
                            />
                          )}
                          <FormControlLabel
                            className="checkFilterLabel"
                            value="employee"
                            control={<Radio className="chkBox_" />}
                            label="Mitarbeiter / Systempartner"
                          />
                          <FormControlLabel
                            className="checkFilterLabel"
                            value="admin"
                            control={<Radio className="chkBox_" />}
                            label="Administrator"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {['employee', 'admin'].includes(this.state.role) && (
                      <div className="col-12">
                        <FormLabel component="legend" className="pb-2 mt-3">
                          {
                            TEXT.adminPage.createUserPopup.checkbox.partnerList
                              .label
                          }
                        </FormLabel>

                        {this.renderPartnerDropDown()}
                      </div>
                    )}
                  </div>

                  <div className="row pt-3">
                    <div className="col-12">
                      {this.state.role === 'client' && (
                        <Tooltip title="Geben Sie möglichst viele unterschiedliche Varianten des Firmennamens durch ';' getrennt ein. Groß- und Kleinschreibung muss nicht berücksichtigt werden z.B.: 'würth; Wuerth;Adolf Würth GmbH'">
                          <TextField
                            fullWidth
                            id="company"
                            onChange={this.onInputFieldChange}
                            value={this.state.company}
                            label={
                              TEXT.adminPage.createUserPopup.inputFields.company
                            }
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.props.handleClose} color="primary">
                  {TEXT.adminPage.createUserPopup.buttons.cancel}
                </Button>
                <Button
                  onClick={this.createUserAPIcall}
                  color="primary"
                  disabled={!this.isValidForm()}
                >
                  {TEXT.adminPage.createUserPopup.buttons.submit}
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
        {/* // TODO impl. error snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{this.state.snackbarText}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="closeSnackbar"
              onClick={this.handleSnackbarClose}
            >
              <i className="material-icons">close</i>
            </IconButton>
          ]}
        />
      </div>
    );
  }
}
CreateUser.defaultProps = {};
CreateUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(CreateUser);
