import $ from 'jquery';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// generic components
import DetailSearch from '../generic_components/DetailSearch';

// static data
import { staticResources } from './staticContent/ProjectDetailsUpdatePopUpDropDowns';
import { generateDropDownList } from './staticContent/ProjectDetailSearchDropDowns';

// utils
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';

class ProjectDetailSearch extends PureComponent {
  constructor(props) {
    super(props);
    const employees = this.props.employeesList.map(entry => ({
      label: `${entry.firstname === '' ? '' : entry.firstname} ${
        entry.lastname === '' ? 'Kein Name vorhanden' : entry.lastname
      }`,
      value: entry.id
    }));
    const partners = this.props.partnersList.map(entry => ({
      label: `${entry.partnerName}`,
      value: entry.id
    }));
    this.dropDownDataList = generateDropDownList(
      employees,
      staticResources.dropDowns.status.content,
      staticResources.dropDowns.statusDetails.content.filter(
        item => item !== ''
      ),
      staticResources.dropDowns.serviceType.content.filter(item => item !== ''),
      partners
    ).sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getProjectListByDetailSearchAPICall = (
    detailSearchFieldList,
    maxAmountOfSearchResults
  ) => {
    const url = CONSTANTS.getProjectListByDetailSearchTermsURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      detailSearchFieldList,
      maxAmountOfSearchResults
    };

    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.props.updateProjectArchive(response);
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  render() {
    return (
      <DetailSearch
        isDrawerOpen={this.props.isDrawerOpen}
        handleDrawerClose={this.props.handleDrawerClose}
        mainSearchFieldPlaceholderText={
          this.props.mainSearchFieldPlaceholderText
        }
        dropDownOptionDataList={this.dropDownDataList}
        appBarTitle={this.props.appBarTitle}
        onSearch={this.getProjectListByDetailSearchAPICall}
        detailSearchType="project"
        handleSnackbarOpen={this.props.handleSnackbarOpen}
      />
    );
  }
}

ProjectDetailSearch.defaultProps = {
  mainSearchFieldPlaceholderText: 'Bitte suchen Sie nach einem Auftrag',
  appBarTitle: 'Detailsuche für Aufträge',
  isDrawerOpen: false
};

ProjectDetailSearch.propTypes = {
  mainSearchFieldPlaceholderText: PropTypes.string,
  appBarTitle: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  handleDrawerClose: PropTypes.func.isRequired,
  updateProjectArchive: PropTypes.func.isRequired,
  employeesList: PropTypes.array.isRequired,
  partnersList: PropTypes.array.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default ProjectDetailSearch;
