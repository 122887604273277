import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Link } from 'react-router-dom';
// material UI
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

// import { Link } from 'react-router-dom';

// import $ from 'jquery';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import TEXT_GENERAL from '../text';
import HTMLNotes from '../components/HTMLNotes';
// import TEXT_GENERAL from '../text';
const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

// PAGE: Kontakthistorie
class CandidatePastContactsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // columns: prepareColumns(this.props.columns),
      deletionRequest: {
        showConfirmationPopUpForEntry: null,
        entryId: ''
      }
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    console.log(
      'this.props.selectedCandidateId',
      this.props.selectedCandidateId
    );
  }

  handleRemoveEntryAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.removeCandidateSubSchemaEntryURL;

    const payload = {
      candidateId: this.props.selectedCandidateId,
      subSchemaName: this.state.deletionRequest.showConfirmationPopUpForEntry,
      subSchemaId: this.state.deletionRequest.entryId
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        this.props.refreshCandidate();
        this.handleCloseConfirmationPopUp();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  generateName(firstName, lastName) {
    let name = '';

    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  mapIsReferred(isReferred) {
    if (isReferred === false) return 'nein';
    if (isReferred === true) return 'ja';
    return '';
  }

  prepareDataForTable(data) {
    const formatedData = [];

    if (data)
      data.forEach(entry => {
        const pastContactData = {
          entryId: entry.id,
          projectId: entry.project && entry.project.id,
          contactDate: entry.contactDate,
          intermediary: `${
            entry.intermediary ? entry.intermediary.firstname : ''
          } ${entry.intermediary ? entry.intermediary.lastname : ''}`,
          contactType: entry.contactType,
          // contactPerson: entry.contactPerson,
          project: entry.project && entry.project.projectNumber,
          company: entry.project && entry.project.company,
          subject: entry.subject,
          comment: entry.comment
        };
        // const pastContactFullData = pastContactData;

        // pastContactFullData.intermediary.id = entry.intermediary.id;
        const mappedEntry = JSON.parse(JSON.stringify(entry));
        mappedEntry.entryId = entry.id;
        mappedEntry.project = entry.project && entry.project.id;
        formatedData.push([
          ...Object.values(pastContactData),
          this.renderEditButton(mappedEntry)
        ]);
      });

    return formatedData;
  }

  handleCloseConfirmationPopUp = () => {
    this.setState({
      deletionRequest: { showConfirmationPopUpForEntry: null }
    });
  };

  handleDeletionRequest = (subSchemaName, entryId) => {
    this.setState(
      {
        deletionRequest: {
          showConfirmationPopUpForEntry: subSchemaName,
          entryId
        }
      },
      console.log('updatedState deletionRequest', this.state.deletionRequest)
    );
  };

  handleSetRejectionDate = entry => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateCandidateSubSchemaURL;

    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const payload = {
      candidateId: this.props.selectedCandidateId,
      subSchemaName: 'pastContacts',
      subSchemaId: entry.entryId,
      subSchemaUpdatedData: { rejected: !entry.rejected }
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        const correctEntry = this.props.data.find(
          element => entry.entryId === element.id
        );

        correctEntry.rejected = !entry.rejected;

        this.props.liftSubschemaToCandidateArchive(
          entry.entryId,
          correctEntry,
          'pastContacts'
        );
        this.props.liftSubschemaToCandidateArchive(
          entry.project,
          {
            rejectionDate: !entry.rejected ? entry.contactDate : null
          },
          'projects'
        );
        this.handleUpdateProject(entry);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleUpdateProject = entry => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    const payload = {
      projectId: entry.project,
      candidateId: this.props.selectedCandidateId,
      updatedData: {
        rejectionDate: !entry.rejected ? entry.contactDate : null
      }
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
        this.forceUpdate();
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  getTheme() {
    return {
      palette: THEME.app.palette,
      overrides: {
        MUIPopover: {
          root: {
            top: 0
          }
        },
        MUIDataTableViewCol: {
          root: {
            maxHeight: '350px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingRight: '10px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            '&:last-child': {
              textAlign: 'right'
            }
          }
        }
      }
    };
  }

  getColumns() {
    const columns = [
      {
        name: 'ID',

        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'projectID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'Datum des Kontakts',
        options: {
          customBodyRender: value =>
            UTILS.formatDateToDELocale(new Date(value)),
          filter: false
        }
      },
      { name: 'Durch' },
      { name: 'Typ' },
      {
        name: 'Auftragsnummer',
        options: {
          display: true,
          filter: false,
          customBodyRender: (value, metaData) => (
            <Link to={`projects?id=${metaData.rowData[1]}`}>{value}</Link>
          )
        }
      },
      { name: 'Unternehmen' },
      {
        name: 'Betreff',
        options: {
          display: true,
          filter: false,

          customBodyRender: value => <HTMLNotes html={value} maxLength={100} />
        }
      },
      {
        name: 'Notiz',
        options: {
          display: true,
          filter: false,
          type: 'html',
          customBodyRender: value => <HTMLNotes html={value} />
        }
      },
      {
        name: 'Aktionen',
        options: {
          display: true,
          filter: false
        }
      }
    ];
    return columns;
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scroll',
      selectableRows: false,
      fixedHeader: false,
      filter: true,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }

  renderEditButton(entry) {
    // console.log('entry', entry);
    return (
      <div className="candidateTableAction">
        <Tooltip title="Absagedatum setzen">
          <Switch
            key={entry.entryId}
            checked={entry.rejected}
            disabled={!entry.project}
            onChange={() => this.handleSetRejectionDate(entry)}
          />
        </Tooltip>
        <Tooltip title="Eintrag bearbeiten">
          <IconButton
            className=""
            aria-label="Edit"
            color="primary"
            onClick={() =>
              this.props.handleOpenCandidatePastContactUpdatePopUp(entry)
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eintrag löschen">
          <IconButton
            className=""
            aria-label="Delete"
            color="primary"
            onClick={() =>
              this.handleDeletionRequest('pastContacts', entry.entryId)
            }
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderRemoveEntryConfirmationPopUp() {
    const subSchemaName = this.state.deletionRequest
      .showConfirmationPopUpForEntry;
    const entryText = 'Kontakthistorie';
    return (
      <ConfirmationModal
        key="confirmation-entry-deletion"
        handleClose={this.handleCloseConfirmationPopUp}
        open={!!subSchemaName}
        buttonPrimaryAction={this.handleRemoveEntryAPICall}
        headlineText={`${entryText} löschen`}
        descriptionText="Soll der entsprechende Eintrag gelöscht werden?"
        // contentText={this.renderContentText()}
        // buttonPrimaryText={TEXT.adminPage.deleteUserModal.buttonPrimaryText}
      />
    );
  }

  render() {
    // console.log(renderViewHtmlPopUp
    //   'DEBUG CandidatePastContactsTab this.props.data',
    //   this.props.data
    // );
    const isConfirmationPopUpOpen = !!this.state.deletionRequest
      .showConfirmationPopUpForEntry;
    return (
      <div className="container-fluid">
        {isConfirmationPopUpOpen && this.renderRemoveEntryConfirmationPopUp()}

        <div className="CandidatePastContactsTab row">
          <div className="col-12">
            {this.props.data && this.props.data.length > 0 ? (
              <SafeMuiDatatable
                title="Kontakthistorie"
                tableName="candidatePastContacts"
                data={this.prepareDataForTable(this.props.data)}
                columns={this.getColumns()}
                options={this.getOptions()}
                muiTheme={this.getTheme()}
                // onCellClick={this.onCellClick}
              />
            ) : (
              <div>
                <div className="sideBarWrapper p-2">
                  <div className="candidateArchive-emptyPage">
                    Es liegt keine Kontakthistorie vor
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CandidatePastContactsTab.propTypes = {
  refreshCandidate: PropTypes.func.isRequired,
  selectedCandidateId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleOpenCandidatePastContactUpdatePopUp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftSubschemaToCandidateArchive: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};
export default CandidatePastContactsTab;
