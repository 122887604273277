import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';

const style = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row'
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column'
  }
};

class RadioButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true
      // errorText: ''
    };
  }

  onChange(checkBoxFormId, checkBoxId) {
    this.props.handleChange(checkBoxFormId, checkBoxId);
  }

  render() {
    return (
      <FormControl error={!this.state.isValid} fullWidth>
        {this.props.sectionLabel && (
          <FormLabel htmlFor={this.props.sectionLabel}>
            {this.props.required
              ? `${this.props.sectionLabel}*`
              : this.props.sectionLabel}
          </FormLabel>
        )}

        <div
          className="row"
          style={
            this.props.layoutHorizontal ? style.horizontal : style.vertical
          }
        >
          {this.props.options.map(option => {
            let label;
            let key;
            if (typeof option === 'string') {
              label = option;
              key = option;
            } else {
              label = option.label;
              key = option.key;
            }

            const selected = Array.isArray(this.props.selected)
              ? this.props.selected.includes(key)
              : [this.props.selected].includes(key);

            return (
              <FormGroup key={key}>
                <FormControlLabel
                  className="checkBoxArea"
                  style={{ minWidth: '200px' }}
                  control={
                    <div className="pl-3">
                      <Radio
                        className="checkBox"
                        checked={selected}
                        onChange={() => this.onChange(this.props.id, key)}
                        disabled={this.props.disabled}
                      />
                    </div>
                  }
                  label={label}
                />

                {!this.state.isValid && (
                  <FormHelperText>{this.state.errorText}</FormHelperText>
                )}
              </FormGroup>
            );
          })}
        </div>
      </FormControl>
    );
  }
}

RadioButtons.defaultProps = {
  required: false,
  sectionLabel: '',
  layoutHorizontal: false,
  // allSelector: null
  disabled: false
};

RadioButtons.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  sectionLabel: PropTypes.string,
  // allSelector: PropTypes.string,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  layoutHorizontal: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RadioButtons;
