import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// material UI
import Button from '@material-ui/core/Button';

import { Editor } from '@tinymce/tinymce-react';
import { DatePicker } from 'material-ui-pickers';
import Tooltip from '@material-ui/core/Tooltip';

import ValidatedTextField from './ValidatedTextField';
import SwitchInputField from './SwitchInputField';
import ValidatedDropDown from './ValidatedDropDown';

class GenericForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formFields: this.props.formFields
    };
    this.initialState = JSON.parse(JSON.stringify(this.state));

    this.allFormFieldKeys = Object.keys(this.state.formFields);
    // console.log('state constructor');
    // validation
    this.formFieldRefs = [];

    this.allFormFieldKeys.forEach(formFieldKey => {
      if (
        this.state.formFields[formFieldKey].type !== 'editor' &&
        // this.state.formFields[formFieldKey].type !== 'dateField' &&
        this.state.formFields[formFieldKey].ignoreValidation !== true
      ) {
        //  console.log('SSSSS', this.state.formFields[formFieldKey].label);
        this.formFieldRefs.push(React.createRef());
      }
    });
  }

  // componentDidMount() {
  //   setTimeout(() => {
  //     $('.the-modal').bind('shown', () => {
  //       Editor.execCommand('mceAddControl', false, 'mce-<?=$reply["id"]?>');
  //     });

  //     $('.the-modal').bind('hide', () => {
  //       Editor.execCommand('mceRemoveControl', false, 'mce-<?=$reply["id"]?>');
  //     });
  //   }, 3000);
  // }

  getNewData(key) {
    let currentValue;

    // case inputField
    if (this.state.formFields[key])
      currentValue = this.state.formFields[key].value;
    // case dropDowns
    else currentValue = this.state.formFields[key].value;

    return currentValue;
  }

  getUpdateData(key) {
    let result;

    const initialValue = this.initialState.formFields[key].value;
    // // special case for dropDowns with key value pairs
    // if (typeof initialValue === 'object')
    //   initialValue = this.props.initialData[key].id;

    let currentValue;
    // case inputField
    if (this.state.formFields[key])
      currentValue = this.state.formFields[key].value;
    // case dropDowns
    else currentValue = this.state.formFields[key].value;

    if (initialValue !== currentValue) {
      // handle case initialValue and currentValue both undefined, empty or null
      if (initialValue || currentValue) result = currentValue;
    }
    return result;
  }

  generateNewData() {
    const newData = {};
    this.allFormFieldKeys.forEach(fieldKey => {
      const newValue = this.getNewData(fieldKey);
      if (newValue !== undefined) newData[fieldKey] = newValue;
    });
    return newData;
  }

  /**
   * handles nested fields such as links__xing
   */
  populateUpdatedFieldToUpdatedData(fieldKey, updatedValue, updatedData) {
    const newUpdatedData = updatedData;
    const fieldKeyNested = fieldKey.split('__');
    console.log('fieldKeyNested', fieldKeyNested);
    // first level key
    if (fieldKeyNested.length === 1) {
      // console.log('fieldKeyNested1', fieldKeyNested);
      newUpdatedData[fieldKey] = updatedValue;
      // second level nested key: use following syntax for key: firstLevel__secondLevel
    } else if (fieldKeyNested.length === 2) {
      // console.log('fieldKeyNested2', fieldKeyNested);

      newUpdatedData[fieldKeyNested[0]] =
        newUpdatedData[fieldKeyNested[0]] || {};
      newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]] = updatedValue;
      // third level nested key: use following syntax for key: firstLevel__secondLevel__thirdLevel
    } else if (fieldKeyNested.length === 3) {
      console.log('fieldKeyNested3', fieldKeyNested);
      newUpdatedData[fieldKeyNested[0]] =
        newUpdatedData[fieldKeyNested[0]] || {};
      newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]] =
        newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]] || {};
      newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]][
        fieldKeyNested[2]
      ] = updatedValue;
    }
    return newUpdatedData;
  }

  generateUpdateData() {
    let updatedData = {};
    this.allFormFieldKeys.forEach(fieldKey => {
      const updatedValue = this.getUpdateData(fieldKey);
      if (updatedValue !== undefined) {
        updatedData = this.populateUpdatedFieldToUpdatedData(
          fieldKey,
          updatedValue,
          updatedData
        );
      }
    });

    return updatedData;
  }

  updateData(isContinue) {
    const updatedData = this.generateUpdateData();
    console.log('updatedData inside genericForm', updatedData);
    this.props.handleSubmit(updatedData);

    if (this.props.handleSaveAndContinue !== undefined) {
      if (isContinue) this.props.handleSaveAndContinue();
      else if (this.props.handleClose !== undefined) this.props.handleClose();
    }
  }

  saveNewData(isContinue) {
    const newData = this.generateNewData();
    this.props.handleSubmit(newData);
    if (this.props.handleSaveAndContinue !== undefined) {
      if (isContinue) this.props.handleSaveAndContinue();
      else if (this.props.handleClose !== undefined) this.props.handleClose();
    }
  }

  // validation
  validateAllFormFields = () => {
    let fieldsValid = true;

    this.formFieldRefs.forEach(ref => {
      if (ref.current && !ref.current.isInputValid()) fieldsValid = false;
    });
    return fieldsValid;
  };

  handleConfirmButtonClick(e, isContinue) {
    e.preventDefault();
    const isValidForm = this.validateAllFormFields();
    if (isValidForm) {
      if (this.props.isUpdate) this.updateData(isContinue);
      else this.saveNewData(isContinue);
    }
  }

  onDropDownChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.name;
    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  handleEditorChange = e => {
    const inputFieldValue = e.target.getContent();
    const inputFieldKey = e.target.id;

    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  onInputFieldChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id;

    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  onDateFieldChange = (date, id) => {
    const inputFieldValue = date;
    const inputFieldKey = id;

    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  renderDropDown(dropDownId, index) {
    const currentDropDownState = this.state.formFields[dropDownId];
    return (
      <ValidatedDropDown
        id={dropDownId}
        key={dropDownId}
        ref={this.formFieldRefs[index]}
        errorMessage={currentDropDownState.errorMessage}
        content={currentDropDownState.content}
        onChange={this.onDropDownChange}
        {...currentDropDownState}
      />
    );
  }

  renderDateField(fieldId) {
    return (
      <div className="picker">
        <DatePicker
          keyboard
          id={fieldId}
          key={fieldId}
          label={this.state.formFields[fieldId].label}
          format="dd.MM.yyyy"
          cancelLabel="Abbrechen"
          mask={value =>
            // handle clearing outside if value can be changed outside of the component
            value
              ? [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
              : []
          }
          value={
            this.state.formFields[fieldId].value === ''
              ? null
              : this.state.formFields[fieldId].value
          }
          onChange={date => this.onDateFieldChange(date, fieldId)}
          disableOpenOnEnter
          animateYearScrolling={false}
          // {...props}
        />
      </div>
    );
  }

  renderEditor(inputFieldId, index, furtherProps) {
    // console.log('currentState', this.state.formFields[inputFieldId]);
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value.toString() : '';
    return (
      <Editor
        initialValue={value}
        id={inputFieldId}
        init={{
          language_url: '/langs/de.js',
          branding: false,
          height: props.init && props.init.height ? props.init.height : '300',
          selector: 'textarea',
          fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
          plugins:
            'link code lists textcolor colorpicker textpattern imagetools',
          font_formats:
            'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif',
          toolbar:
            'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image',

          setup(editor) {
            editor.on('init', () => {
              $('#editor_container').css('display', 'block');
              editor.execCommand('fontName', false, 'Georgia');
              editor.execCommand('fontSize', false, '10');
            });
          }
        }}
        onChange={this.handleEditorChange}
        // {...props}
      />
    );
  }

  renderInputField(inputFieldId, index, furtherProps) {
    // console.log('currentState', this.state.formFields[inputFieldId]);
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value.toString() : '';

    if (this.state.formFields[inputFieldId].tooltip)
      return (
        <div>
          <Tooltip title={this.state.formFields[inputFieldId].tooltip}>
            <ValidatedTextField
              fullWidth
              id={inputFieldId}
              key={inputFieldId}
              autoFocus={index === 0}
              ref={this.formFieldRefs[index]}
              onChange={this.onInputFieldChange}
              // label={this.props.TEXT.labels[inputFieldId]}
              {...props}
              value={value}
            />
          </Tooltip>
        </div>
      );
    return (
      <ValidatedTextField
        fullWidth
        id={inputFieldId}
        key={inputFieldId}
        autoFocus={index === 0}
        ref={this.formFieldRefs[index]}
        onChange={this.onInputFieldChange}
        // label={this.props.TEXT.labels[inputFieldId]}
        {...props}
        value={value}
      />
    );
  }

  renderSwitchInputField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };

    const value = props.value ? props.value.toString() : '';
    return (
      <SwitchInputField
        fullWidth
        id={inputFieldId}
        key={inputFieldId}
        autoFocus={index === 0}
        forwardRef={this.formFieldRefs[index]}
        onChange={this.onInputFieldChange}
        {...props}
        value={value}
      />
    );
  }

  renderSwitchDateInputField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value : null;
    return (
      <SwitchInputField
        isDate
        id={inputFieldId}
        key={inputFieldId}
        autoFocus={index === 0}
        innerRef={this.formFieldRefs[index]}
        onChange={this.onDateFieldChange}
        {...props}
        value={value}
      />
    );
  }

  renderForm() {
    return (
      <div className="row">
        {this.allFormFieldKeys.map((fieldKey, index) => {
          const { customClass, type, additionalProps } = this.state.formFields[
            fieldKey
          ];
          let typeOfField = 'textField';
          if (type) typeOfField = type;
          if (typeOfField === 'dropDown') {
            return (
              <div
                className={customClass || this.props.fieldClass}
                key={fieldKey}
              >
                {this.renderDropDown(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'date') {
            return (
              <div
                className={customClass || this.props.fieldClass}
                key={fieldKey}
              >
                {this.renderDateField(fieldKey)}
              </div>
            );
          }

          if (typeOfField === 'editor') {
            return (
              <div
                className={customClass || this.props.fieldClass}
                key={fieldKey}
                style={{ display: 'none' }}
                id="editor_container"
              >
                {this.renderEditor(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'switchInputField') {
            return (
              <div
                className={customClass || this.props.fieldClass}
                key={fieldKey}
              >
                {this.renderSwitchInputField(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'switchDateInputField') {
            return (
              <div
                className={customClass || this.props.fieldClass}
                key={fieldKey}
              >
                {this.renderSwitchDateInputField(
                  fieldKey,
                  index,
                  additionalProps
                )}
              </div>
            );
          }
          return (
            <div
              className={customClass || this.props.fieldClass}
              key={fieldKey}
            >
              {this.renderInputField(fieldKey, index, additionalProps)}
            </div>
          );
        })}
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="row">
        <div className="col-12 text-right pt-2">
          {this.props.handleClose !== undefined && (
            <span className="pl-2">
              <Button onClick={this.props.handleClose} color="primary">
                {this.props.buttonCancelText}
              </Button>
            </span>
          )}
          {this.props.handleContinue !== undefined && (
            <span className="pl-2">
              <Button onClick={this.props.handleContinue} color="primary">
                {this.props.buttonContinueText}
              </Button>
            </span>
          )}
          {this.props.handleSaveAndContinue !== undefined && (
            <span className="pl-2">
              <Button
                onClick={e => this.handleConfirmButtonClick(e, true)}
                color="primary"
              >
                {this.props.buttonSaveAndContinueText}
              </Button>
            </span>
          )}
          <span className="pl-2">
            <Button
              onClick={e =>
                this.handleConfirmButtonClick(e, this.props.alwaysContinue)
              }
              type="submit"
              color="primary"
              variant="contained"
            >
              {this.props.buttonSaveText}
            </Button>
          </span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <form>
        <div className="container">
          {this.props.buttonsOnTop && this.renderButtons()}
          {this.renderForm()}
          {this.props.buttonsOnBottom && this.renderButtons()}
        </div>
      </form>
    );
  }
}
GenericForm.defaultProps = {
  handleClose: undefined,
  isUpdate: false,

  fieldClass: 'col-6 mb-2',

  // text elements
  buttonCancelText: 'Abbrechen',
  buttonSaveText: 'Speichern',
  buttonSaveAndContinueText: 'Speichern und weiter',
  handleSaveAndContinue: undefined,
  buttonContinueText: 'weiter',
  handleContinue: undefined,
  alwaysContinue: false,
  buttonsOnTop: false,
  buttonsOnBottom: true
};

GenericForm.propTypes = {
  handleClose: PropTypes.func, // leave empty to not render the cancel button
  buttonsOnTop: PropTypes.bool,
  buttonsOnBottom: PropTypes.bool,
  isUpdate: PropTypes.bool,
  formFields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // class for input field. Only used for input fields without customClass property
  fieldClass: PropTypes.string,

  // text elements
  buttonCancelText: PropTypes.string,
  buttonSaveText: PropTypes.string,
  buttonSaveAndContinueText: PropTypes.string,
  handleSaveAndContinue: PropTypes.func,
  buttonContinueText: PropTypes.string,
  handleContinue: PropTypes.func,
  alwaysContinue: PropTypes.bool
};

export default GenericForm;
