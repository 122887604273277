import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

// material UI
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Icons
import AddIcon from '@material-ui/icons/Add';

// components
// Users
import CreateUser from '../components/CreateUser';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import UsersTable from '../adminPage/UsersTable';
import UserUpdatePopUpContainer from '../adminPage/UserUpdatePopUpContainer';
import EditAdditionalPermissionsPopUpContainer from '../adminPage/EditAdditionalPermissionsPopUpContainer';

// Monitoring
import MonitoringTab from '../adminPage/MonitoringTab';
import TemplatesTab from '../adminPage/TemplatesTab';

import withUser from '../utils/withUser';

import './Admin.css';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

const styles = {
  root: {},
  label: {}
};

function sortDropDownEntriesAlphabetically(dropDownContent) {
  const sortedDropDownContent = dropDownContent.sort((a, b) => {
    if (
      (a.label.includes('pt-') ||
        a.label.includes('senator') ||
        a.label.includes('LIENERT')) &&
      !(
        b.label.includes('pt-') ||
        b.label.includes('senator') ||
        b.label.includes('LIENERT')
      )
    )
      return -1;
    if (
      !(
        a.label.includes('pt-') ||
        a.label.includes('senator') ||
        a.label.includes('LIENERT')
      ) &&
      (b.label.includes('pt-') ||
        b.label.includes('senator') ||
        b.label.includes('LIENERT'))
    )
      return 1;
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  });
  return sortedDropDownContent;
}

function preparePartnersKeyValuePairs(partners) {
  const mappedDropDownEntries = [];
  if (partners && partners.length > 0) {
    partners.forEach(partner => {
      if (partner.partnerId && partner.partnerName) {
        const value = partner.id;
        const label = `${partner.partnerName || ''} (${partner.partnerId ||
          '-'})`;
        const mappedPartner = { value, label };
        mappedDropDownEntries.push(mappedPartner);
      }
    });
  }
  console.log(
    'preparePartnersKeyValuePairs() mappedDropDownEntries ',
    mappedDropDownEntries
  );
  return sortDropDownEntriesAlphabetically(mappedDropDownEntries);
}

class Admin extends PureComponent {
  constructor(props) {
    super(props);
    const defaultTab = this.props.user.role === 'admin' ? 0 : 1;
    let currentTab = UTILS.getURLParam(this.props.history, 't');
    currentTab = currentTab ? parseInt(currentTab, 10) : defaultTab;
    this.state = {
      currentTab,

      showCreateUserModular: false,
      showDeleteUserModular: false,
      showResetUserModular: false,
      showEditUserModular: false,
      showEditAdditionalPermissionsModular: false,
      userEmailRequestedForDeletion: '',
      userEmailRequestedForReset: '',
      selectedUser: {}, // user
      users: [],
      partners: [],
      pastSearchData: {},
      templateData: {}
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Einstellungen - ${UTILS.getTitle()}`;
    console.log('Component did mount');
  }

  getPartnersAPICall() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getAllPartnersURL;

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log('API getAllPartnersURL response', responseBody);
        this.setState({
          partners: preparePartnersKeyValuePairs(responseBody.partners)
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  showCreateUser = () => {
    this.setState({
      showCreateUserModular: true
    });
  };

  showEditUser = user => {
    console.log('userRequestedForEdit', user);
    this.setState({
      showEditUserModular: true,
      selectedUser: user
    });
  };

  showEditAdditionalPermissions = user => {
    console.log('userRequestedForEdit', user);
    this.setState({
      showEditAdditionalPermissionsModular: true,
      selectedUser: user
    });
  };

  showDeleteUser = userEmail => {
    console.log('userEmailRequestedForDeletion', userEmail);
    this.setState({
      showDeleteUserModular: true,
      userEmailRequestedForDeletion: userEmail
    });
  };

  showResetUser = userEmail => {
    console.log('userEmailRequestedForReset', userEmail);
    this.setState({
      showResetUserModular: true,
      userEmailRequestedForReset: userEmail
    });
  };

  handleCloseCreateUser = () => {
    this.setState({
      showCreateUserModular: false
    });
    this.loadUsers();
  };

  handleCloseDeleteUserModal = () => {
    this.setState({
      showDeleteUserModular: false,
      userEmailRequestedForDeletion: ''
    });
  };

  handleCloseResetUserModal = () => {
    this.setState({
      showResetUserModular: false,
      userEmailRequestedForReset: ''
    });
  };

  loadUsers = () => {
    const url = CONSTANTS.getUsers;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'x-auth': tokenFromLocalStorage
      },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        // console.log('API register response body: ', responseBody);
        console.log('API /users response body: ', responseBody);
        this.setState({ users: responseBody });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  };

  handleResetUserAPICall = () => {
    console.log('inside handleResetUserAPICall');
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.resetUserURL;
    const request = {
      email: this.state.userEmailRequestedForReset
    };

    this.handleCloseResetUserModal();
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'x-auth': tokenFromLocalStorage
      },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /resetUser response', responseBody, status);
        this.props.handleSnackbarOpen(TEXT.adminPage.snackBar.resetUserSuccess);
        this.loadUsers();
      })

      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleDeleteUserAPICall = () => {
    console.log('inside handleDeleteUserAPICall');
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    const url = CONSTANTS.deleteUserURL;
    const request = {
      email: this.state.userEmailRequestedForDeletion
    };

    this.handleCloseDeleteUserModal();
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /deleteUser response', responseBody, status);
        this.props.handleSnackbarOpen(
          TEXT.adminPage.snackBar.deleteUserSuccess
        );
        this.loadUsers();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  liftUpStateToAdminPage = updateState => {
    this.setState(updateState);
  };

  renderContentText() {
    return (
      <div className="userDeleteConfirmation-contentText">
        {this.state.userEmailRequestedForDeletion}
      </div>
    );
  }

  renderDescriptionTextResetUser() {
    return (
      <div className="userResetConfirmation-descriptionText">
        {TEXT.adminPage.resetUserModal.description.map(sentence => (
          <p>{sentence}</p>
        ))}
      </div>
    );
  }

  renderContentTextResetUser() {
    return (
      <div className="userResetConfirmation-contentText">
        {this.state.userEmailRequestedForReset}
      </div>
    );
  }

  handleCloseEditAdditionalPermissionsPopUp = () => {
    this.setState({ showEditAdditionalPermissionsModular: false });
  };

  renderEditAdditionalPermissionsPopUp() {
    return (
      <EditAdditionalPermissionsPopUpContainer
        key={this.state.selectedUser.email}
        open={this.state.showEditAdditionalPermissionsModular}
        userEmail={this.state.selectedUser.email}
        data={this.state.selectedUser}
        isUpdate
        handleClose={this.handleCloseEditAdditionalPermissionsPopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshData={this.loadUsers}
        partners={this.state.partners}
      />
    );
  }

  handleCloseUserUpdatePopUp = () => {
    this.setState({ showEditUserModular: false });
  };

  renderUserUpdatePopUp() {
    return (
      <UserUpdatePopUpContainer
        key={this.state.selectedUser.email}
        open={this.state.showEditUserModular}
        userEmail={this.state.selectedUser.email}
        data={this.state.selectedUser}
        isUpdate
        handleClose={this.handleCloseUserUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshData={this.loadUsers}
        partners={this.state.partners}
      />
    );
  }

  render() {
    if (
      this.state.currentTab === 0 &&
      (!this.state.users || !this.state.users.length)
    )
      this.loadUsers();
    if (this.state.currentTab === 0 && this.state.partners.length === 0)
      this.getPartnersAPICall();
    return (
      <div className="adminPage h-100vh">
        {this.state.showEditAdditionalPermissionsModular &&
          this.renderEditAdditionalPermissionsPopUp()}
        {this.state.showEditUserModular && this.renderUserUpdatePopUp()}
        <Tabs
          value={this.state.currentTab}
          onChange={(event, value) => {
            UTILS.setURLParam(this.props.history, 't', value);

            this.setState({ currentTab: value });
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label="Nutzer"
            key="user"
            disabled={this.props.user.role !== 'admin'}
          />

          <Tab label="Templates" key="templates" />
          {CONSTANTS.partner === 'matchflix' && (
            <Tab label="Monitoring" key="monitoring" />
          )}
        </Tabs>
        {/* <h1 className="pt-5 pb-3">{TEXT.adminPage.headline}</h1> */}
        {/* <Button
          onClick={this.showCreateUser}
          variant="contained"
          color="primary"
          className="createUserButton"
          classes={
            {
              // root: this.props.classes.root, // class name, e.g. `classes-nesting-root-x`
              // label: this.props.classes.label // class name, e.g. `classes-nesting-label-x`
            }
          }
        >
          <i className="material-icons pr-1">add</i>
          {TEXT.adminPage.createUserButton}
        </Button> */}

        {this.state.currentTab === 0 && (
          <React.Fragment>
            <div className="table-users-container">
              <div className="table-users">
                {this.state.users &&
                  Object.keys(this.state.users).length > 0 && (
                    <UsersTable
                      data={this.state.users}
                      showDeleteUser={this.showDeleteUser}
                      showResetUser={this.showResetUser}
                      showEditUser={this.showEditUser}
                      showEditAdditionalPermissions={
                        this.showEditAdditionalPermissions
                      }
                    />
                  )}
              </div>
            </div>
            <Fab
              buttonRef={node => {
                this.anchorEl = node;
              }}
              // disabled={this.state.currentTab !== 3}
              // variant="fab"
              color="primary"
              aria-label="Add"
              style={{ position: 'absolute', bottom: '30px', right: '30px' }}
              onClick={this.showCreateUser}
            >
              <AddIcon />
            </Fab>
            {this.state.showCreateUserModular && (
              <CreateUser
                open={this.state.showCreateUserModular}
                handleClose={this.handleCloseCreateUser}
                liftUpStateToApp={this.props.liftUpStateToApp}
              />
            )}

            <ConfirmationModal
              key="confirmation-user-reset"
              handleClose={this.handleCloseResetUserModal}
              open={this.state.showResetUserModular}
              buttonPrimaryAction={this.handleResetUserAPICall}
              headlineText={TEXT.adminPage.resetUserModal.headline}
              descriptionText={this.renderDescriptionTextResetUser()}
              contentText={this.renderContentTextResetUser()}
              buttonPrimaryText={
                TEXT.adminPage.resetUserModal.buttonPrimaryText
              }
            />

            <ConfirmationModal
              key="confirmation-user-deletion"
              handleClose={this.handleCloseDeleteUserModal}
              open={this.state.showDeleteUserModular}
              buttonPrimaryAction={this.handleDeleteUserAPICall}
              headlineText={TEXT.adminPage.deleteUserModal.headline}
              descriptionText={TEXT.adminPage.deleteUserModal.description}
              contentText={this.renderContentText()}
              buttonPrimaryText={
                TEXT.adminPage.deleteUserModal.buttonPrimaryText
              }
            />
          </React.Fragment>
        )}
        {this.state.currentTab === 1 && (
          // <div className="container-fluid">
          //   <div className="mt-5">
          <React.Fragment>
            <div className="table-monitoring-container">
              <div className="table-monitoring">
                <TemplatesTab
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  liftUpStateToAdminPage={this.liftUpStateToAdminPage}
                  data={this.state.templateData}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  key="templatesTab"
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.currentTab === 2 && CONSTANTS.partner === 'matchflix' && (
          // <div className="container-fluid">
          //   <div className="mt-5">
          <div className="table-monitoring-container">
            <div className="table-monitoring">
              <MonitoringTab
                liftUpStateToApp={this.props.liftUpStateToApp}
                liftUpStateToAdminPage={this.liftUpStateToAdminPage}
                data={this.state.pastSearchData}
                key="monitoringTab"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
Admin.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(withRouter(withStyles(styles)(Admin)));
