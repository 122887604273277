import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import IconButton from '@material-ui/core/IconButton';
// import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';

import ProjectActionProtocolUpdatePopUpContainer from './ProjectActionProtocolUpdatePopUpContainer';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

const columns = [
  {
    name: 'ID',
    options: {
      display: 'excluded',
      filter: false
    }
  },

  { name: 'Beschreibung' },
  {
    name: 'Datum',
    options: {
      customBodyRender: value => UTILS.formatDateToDELocale(new Date(value))
    }
  },
  { name: 'Bearbeiter' },
  { name: 'Aktionen', options: { filter: false } }
];

// PAGE: Auftragssteuerung
class ProjectControlling extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntryDataForUpdate: {}
    };

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  generateName(firstName, lastName) {
    let name = '';

    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  deleteActionProtocol = entryId => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.deleteActionProtocolURL;

    const payload = {
      projectId: this.props.selectedProjectId,
      actionProtocolId: entryId
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        this.props.refreshProject();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleCloseProjectActionProtocolUpdatePopUp = () => {
    this.setState({ isProjectActionProtocolUpdatePopUpOpen: false });
  };

  handleOpenProjectActionProtocolUpdatePopUp(entryData) {
    this.setState({
      selectedEntryDataForUpdate: entryData,
      isProjectActionProtocolUpdatePopUpOpen: true
    });
  }

  renderProjectActionProtocolUpdatePopUp() {
    return (
      <ProjectActionProtocolUpdatePopUpContainer
        open={this.state.isProjectActionProtocolUpdatePopUpOpen}
        key={this.state.selectedEntryDataForUpdate.entryId}
        projectId={this.props.selectedProjectId}
        entryId={this.state.selectedEntryDataForUpdate.entryId}
        data={this.state.selectedEntryDataForUpdate}
        isUpdate
        handleClose={this.handleCloseProjectActionProtocolUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshProject={this.props.refreshProject}
        employees={this.props.employees}
      />
    );
  }

  renderButtons(entryData) {
    return (
      <div>
        <IconButton
          className=""
          aria-label="Edit"
          disabled={!this.props.isEditable}
          color="primary"
          onClick={() =>
            this.handleOpenProjectActionProtocolUpdatePopUp(entryData)
          }
        >
          <EditIcon />
        </IconButton>

        <IconButton
          className=""
          disabled={!this.props.isEditable}
          aria-label="Edit"
          color="default"
          onClick={() => this.deleteActionProtocol(entryData.entryId)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  prepareDataForTable(data, type) {
    const formatedData = [];
    if (data && data.length > 0)
      data.forEach(entry => {
        if (entry.type === type) {
          const entryData = {
            entryId: entry.id,

            description: entry.description,
            date: entry.date,
            doneByUser:
              entry.doneByUser &&
              this.generateName(
                entry.doneByUser.firstName,
                entry.doneByUser.lastName
              )
          };
          const mappedEntryData = JSON.parse(JSON.stringify(entryData));
          mappedEntryData.type = type;
          mappedEntryData.doneByUser = entry.doneByUser;
          formatedData.push([
            ...Object.values(entryData),
            this.renderButtons(mappedEntryData)
          ]);
        }
      });
    return formatedData;
  }

  render() {
    return (
      <div className="container-fluid">
        {this.props.data &&
          this.state.isProjectActionProtocolUpdatePopUpOpen &&
          this.renderProjectActionProtocolUpdatePopUp()}
        <div className="ProjectControlling row">
          <div className="col-6">
            <SafeMuiDatatable
              key="Anzeigenerstellung"
              title="Anzeigenerstellung"
              tableName="projectControllingJobPostings"
              data={this.prepareDataForTable(this.props.data, 'Anzeigen')}
              columns={columns}
            />
          </div>
          <div className="col-6">
            <SafeMuiDatatable
              key="Datenbanksuchen"
              title="Datenbanksuchen"
              tableName="projectControllingDatabases"
              data={this.prepareDataForTable(this.props.data, 'Datenbanksuche')}
              columns={columns}
            />
          </div>
        </div>
      </div>
    );
  }
}

ProjectControlling.propTypes = {
  data: PropTypes.array.isRequired,
  selectedProjectId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshProject: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  isEditable: PropTypes.bool.isRequired
};
export default ProjectControlling;
