import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';

import Paper from '@material-ui/core/Paper';
import './HelpBox.css';

class HelpBox extends PureComponent {
  constructor(props) {
    super(props);

    this.onHelpBoxLinkClick = this.onHelpBoxLinkClick.bind(this);
  }

  onHelpBoxLinkClick(e) {
    e.preventDefault();
    this.props.onHelpBoxLinkClick(e.currentTarget.textContent);
  }

  createSuggestionList() {
    return this.props.frequentCommonSearchTerms.map(searchTerm => (
      <div key={searchTerm}>
        <a
          onClick={this.onHelpBoxLinkClick}
          href="http://localhost:3000/search#"
        >
          {searchTerm}
        </a>
        <br />
      </div>
    ));
  }

  render() {
    return (
      <Paper className="HelpBoxInverted text-center">
        <div className="instructionText">{this.props.instructionText}</div>
        {this.createSuggestionList()}
      </Paper>
    );
  }
}
HelpBox.defaultProps = {
  frequentCommonSearchTerms: []
};
HelpBox.propTypes = {
  frequentCommonSearchTerms: PropTypes.arrayOf(PropTypes.string),
  onHelpBoxLinkClick: PropTypes.func.isRequired,
  instructionText: PropTypes.string.isRequired
};
export default HelpBox;
