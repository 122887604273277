import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import $ from 'jquery';

import CircularProgress from '@material-ui/core/CircularProgress';
import SingleCandidateResult from '../../candidateArchive/SingleCandidateResult';
import CandidateResults from '../../candidateArchive/CandidateResult';
import * as UTILS from '../../utils/utilFunctions';
import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import TEXT from './staticContent/text';

class IncomingCandidateDuplicateCheck extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      duplicateCandidateList: [],
      selectedCandidate: undefined,
      listLoaded: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    this.getDuplicateCandidateList();
  }

  handleDuplicateCheckCreateNew = () => {
    this.props.liftUpStateToIncomingCandidates({
      selectedDuplicateCandidate: {}
    });

    this.props.handleNext();
  };

  handleDuplicateCheckResumeWithDuplicate = selectedDuplicateCandidate => {
    console.log('duplicateVal:', selectedDuplicateCandidate);
    this.props.liftUpStateToIncomingCandidates({ selectedDuplicateCandidate });
    this.props.handleNext();
  };

  getDuplicateCandidateList() {
    const url = CONSTANTS.getCandidateListByNameURL;

    const request = {
      firstName: this.props.selectedIncomingCandidate.firstName,
      lastName: this.props.selectedIncomingCandidate.lastName
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(request)
    })
      .done(responseBody => {
        console.log('createdCandidate', responseBody);
        this.setState({
          duplicateCandidateList: responseBody,
          listLoaded: true
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getCandidateById(candidateId) {
    const requestBody = { candidateId };
    const url = CONSTANTS.getCandidateByIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(requestBody)
    })
      .done(responseBody => {
        this.setState({
          selectedCandidate: responseBody
        });
        this.getProjectListByCandidateIdAPICall(responseBody.id);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getProjectListByCandidateIdAPICall(candidateId) {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getProjectListByCandidateIdURL;

    const payload = {
      candidateId
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API ${url} response`, responseBody, status);
        this.setState(prevState => ({
          selectedCandidate: {
            ...prevState.selectedCandidate,
            projects: responseBody.projects
          }
        }));
        // this.props.refreshCandidate();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  renderDuplicateList() {
    return (
      <CandidateResults
        handleCandidateSelected={selectedRow => {
          this.getCandidateById(selectedRow[0]);
        }}
        candidateList={this.state.duplicateCandidateList}
      />
    );
  }

  renderSelectedCandiadte() {
    return (
      <SingleCandidateResult
        selectedCandidate={this.state.selectedCandidate}
        duplicateCheckMode
        incomingCandidate={this.props.selectedIncomingCandidate}
        liftUpStateToCandidateArchive={stateObject => {
          console.log(stateObject);
          if (stateObject && stateObject.currentTab !== undefined)
            this.setState({ currentTab: stateObject.currentTab });
        }}
        currentTab={this.state.currentTab}
        // handleDuplicateCheckMerge={this.handleDuplicateCheckMerge}
        handleDuplicateCheckCreateNew={this.handleDuplicateCheckCreateNew}
        handleDuplicateCheckResumeWithDuplicate={
          this.handleDuplicateCheckResumeWithDuplicate
        }
      />
    );
  }

  renderCreateNewCandidate() {
    // TODO make it beautiful
    return (
      <div>
        {TEXT.incomingCandidateDuplicateCheck.noDuplicates}
        <Button color="primary" onClick={this.handleDuplicateCheckCreateNew}>
          {TEXT.incomingCandidateDuplicateCheck.createNew}
        </Button>
      </div>
    );
  }

  renderDuplicateCheck() {
    return this.state.duplicateCandidateList.length > 0 ? (
      <div>
        {this.renderDuplicateList()}
        {this.state.selectedCandidate && this.renderSelectedCandiadte()}
      </div>
    ) : (
      this.renderCreateNewCandidate()
    );
  }

  render() {
    // if (this.state.redirect) {
    //   this.setState({ redirect: false });
    //   return (
    //     <Redirect to={`/candidates?id=${this.state.preSelectedCandidateId}`} />
    //   );
    // }
    return (
      <div>
        {this.state.listLoaded ? (
          this.renderDuplicateCheck()
        ) : (
          <CircularProgress className="mb-3" size={60} thickness={5} />
        )}
      </div>
    );
  }
}

IncomingCandidateDuplicateCheck.defaultProps = {};
IncomingCandidateDuplicateCheck.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  liftUpStateToIncomingCandidates: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};
export default IncomingCandidateDuplicateCheck;
