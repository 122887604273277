import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

export function deleteCounterPartAPICall(counterPartId, companyId) {
  const payload = {
    companyId,
    counterPartId
  };
  return post(CONSTANTS.deleteCompanyContactURL, payload);
}
export function deletePastContactAPICall(pastContactId, companyId) {
  const payload = {
    companyId,
    pastContactId
  };
  return post(CONSTANTS.deleteCompanyContactHistoryEntryURL, payload);
}
