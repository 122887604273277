import React, { PureComponent } from 'react';

// material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import $ from 'jquery';

import './RegisterTan.css';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

const QRCode = require('qrcode');

const TEXT = require('../text');

const systemPartner = CONSTANTS.partner;

class RegisterTan extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: {
        initialTan: ''
      },
      twoFASecret: '',
      tanIsValidated: false,
      errorWrongTan: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Registrierung - ${UTILS.getTitle()}`;

    this.get2FASecretAPIcall();
  }

  handleChange = event => {
    const key = event.target.id;
    const rawValue = event.target.value;
    let value = rawValue;
    if (key === 'initialTan') {
      value = rawValue.replace(' ', '').slice(0, 6);
      if (value.length === 6) this.verifyTanAPIcall(value);
    }
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [key]: value
      }
    }));
  };

  get2FASecretAPIcall() {
    const url = CONSTANTS.get2FASecretURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const { userStatus } = responseBody;
        if (userStatus === 'passwordSet') {
          this.setState({ twoFASecret: responseBody.twoFASecret });
        } else if (userStatus === 'initialLogin') window.location = '/register';
        else window.location = '/';
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  verifyTanAPIcall(tan) {
    this.setState({ errorWrongTan: false });
    const requestBody = {
      tan
    };
    const url = CONSTANTS.verifyTanURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    console.log('API /verifyTan request: ', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /verifyTan response body: ', responseBody);
        console.log('API /verifyTan response status: ', status);
        if (responseBody.userStatus === 'active' && responseBody.isValidTan)
          this.setState({ tanIsValidated: true });
        else if (responseBody.isValidTan === false)
          this.setState({
            errorWrongTan: true,
            inputFields: { initialTan: '' }
          });
        else window.location = '/err';
      })
      .fail(err => {
        const ignoreErrorCodes = [400];
        if (err.status)
          this.setState({
            errorWrongTan: true,
            inputFields: { initialTan: '' }
          });
        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  }

  handleButtonRedirect = () => {
    window.location = '/search';
  };

  handleButtonValidateTan = () => {
    this.verifyTanAPIcall(this.state.inputFields.initialTan);
  };

  render() {
    // prepare QRCodeImage
    let QRCodeImageUrl = '';
    const keyName =
      systemPartner === 'matchflix' ? 'Match-flix' : 'HR-Consult-Group';
    if (this.state.twoFASecret) {
      const rawUrl = `otpauth://totp/${keyName}?secret=${
        this.state.twoFASecret
      }`;
      QRCode.toDataURL(rawUrl, (err, dataUrl) => {
        QRCodeImageUrl = dataUrl;
      });
    }

    return (
      <div className="registerTanPage">
        <h2 className="headline">{TEXT.registerTanPage.headline}</h2>
        <div className="registerTanPage-content">
          <div className="registerTanPage-description">
            {TEXT.registerTanPage.description}
          </div>
          <div className="registerTanPage-steps">
            <div className="registerTanPage-step">
              <div className="stepNumber">
                {TEXT.registerTanPage.steps[0].headline}
              </div>
              <div className="stepDescription">
                {TEXT.registerTanPage.steps[0].description}
              </div>
            </div>

            <div className="registerTanPage-step">
              <div className="stepNumber">
                {' '}
                {TEXT.registerTanPage.steps[1].headline}
              </div>
              <div className="stepDescription">
                <div>{TEXT.registerTanPage.steps[1].description} </div>
              </div>
            </div>
          </div>
          {this.state.twoFASecret ? (
            <div className="QRCodeImage">
              <img src={QRCodeImageUrl} alt="" />
            </div>
          ) : (
            <div className="registerTanPage-loading-indicator-container">
              <div className="registerTanPage-QRImagePlaceholder">
                <CircularProgress className="mb-3" size={60} thickness={3} />
              </div>
            </div>
          )}
          <div className="registerTanPage-steps">
            <div className="registerTanPage-step">
              <div className="stepNumber">
                {' '}
                {TEXT.registerTanPage.steps[2].headline}
              </div>
              <div className="stepDescription ">
                <div>{TEXT.registerTanPage.steps[2].description}</div>
              </div>
            </div>
            {this.state.errorWrongTan && (
              <div className="registerTanPage-error-container">
                <div className="errorMessage-container">
                  {TEXT.registerTanPage.errorMessage}
                </div>
              </div>
            )}
            {this.state.tanIsValidated ? (
              <div className="registerTanPage-success-container">
                <div className="successMessage-container">
                  {TEXT.registerTanPage.successMessage}
                  <div className="registerTanPage-successButton-container">
                    <Button
                      onClick={this.handleButtonRedirect}
                      variant="contained"
                      color="primary"
                      className="buttonRedirect"
                    >
                      {TEXT.registerTanPage.buttonRedirect}
                      <i className="material-icons pl-1">arrow_forward_ios</i>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="registerTanPage-initialTan-container">
                <div className="registerTanPage-initialTan-form">
                  {!this.state.tanIsValidated && (
                    <div>
                      <TextField
                        id="initialTan"
                        fullWidth
                        onChange={this.handleChange}
                        value={this.state.inputFields.initialTan}
                        label={TEXT.registerTanPage.tanFieldLabel}
                      />
                    </div>
                  )}

                  <div className="registerTanPage-button-container">
                    {!this.state.tanIsValidated && (
                      <Button
                        onClick={this.handleButtonValidateTan}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="buttonValidateTan"
                      >
                        {TEXT.registerTanPage.buttonValidateTan}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

RegisterTan.propTypes = {};

export default RegisterTan;
