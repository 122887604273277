import TextField from '@material-ui/core/TextField';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as validators from '../utils/validators';
import TEXT from '../text';

class ValidatedTextField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      errorText: ''
    };
  }

  isInputValid = () => {
    let isValid = true;
    let errorText = '';
    // console.log('VALUE', this.props.value, this.props.value.length);
    if (
      this.props.required &&
      this.props.value !== undefined &&
      this.props.value.length === 0
    ) {
      errorText = TEXT.validationErrorMessages.isEmpty;
      isValid = false;
    }
    if (
      this.props.value &&
      this.props.value.length !== 0 &&
      validators[this.props.validator] &&
      !validators[this.props.validator].isValid(this.props.value)
    ) {
      errorText = validators[this.props.validator].errorText;
      isValid = false;
    }

    this.setState({ isValid, errorText });
    return isValid;
  };

  render() {
    const { required, customClass, label, ...rest } = this.props;
    const mappedLabel = required ? `${label}*` : label;
    return (
      <TextField
        {...rest}
        label={mappedLabel}
        error={!this.state.isValid}
        onBlur={this.isInputValid}
        helperText={this.state.errorText}
      />
    );
  }
}

ValidatedTextField.defaultProps = {
  required: false,
  validator: 'none',
  customClass: ''
};

ValidatedTextField.propTypes = {
  validator: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  label: PropTypes.string.isRequired
};
export default ValidatedTextField;
