import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import * as UTILS from '../utils/utilFunctions';

class ViewHtmlPopUp extends PureComponent {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          fullWidth
          onBackdropClick={this.props.onBackdropClick}
        >
          {/* <DialogTitle>{this.props.headlineText}</DialogTitle> */}
          <DialogContent>
            {UTILS.stringToShortenHTMLCode(this.props.html, 50000)}
          </DialogContent>

          <DialogActions>
            <div className="col-12 text-right pt-2">
              <span className="pl-2">
                <Button onClick={this.props.handleClose} color="primary">
                  {this.props.closeButtonText}
                </Button>
              </span>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
ViewHtmlPopUp.defaultProps = {
  closeButtonText: 'Schliessen',
  onBackdropClick: null
};

ViewHtmlPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  html: PropTypes.object.isRequired,
  closeButtonText: PropTypes.string,
  onBackdropClick: PropTypes.func
};

export default ViewHtmlPopUp;
