module.exports = {
  staticResources: {
    dropDowns: {
      contactType: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: ['telefonisch', 'E-Mail', 'Brief', 'persönlich']
      },
      companyStatus: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: [
          'AP nicht erreicht',
          'derzeit kein Bedarf',
          'in Kontakt bleiben',
          'kein Feedback',
          'kein Interesse',
          'neuen AP empfohlen',
          'Kundentermin',
          'Termin bestätigt',
          'Termin in Anbahnung',
          'Termin vereinbart',
          'Termin verschoben',
          'Unterlagen zugeschickt',
          'Unterlagen zuschicken',
          'Verbindliches Angebot geschickt',
          'Wiedervorlage vereinbart'
        ]
      },
      contactPerson: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl'
      }
    }
  }
};
