const PARTNER = require('../../constants').partner;

module.exports = {
  generateDropDownList(
    employees,
    status,
    statusDetails,
    serviceType,
    partners
  ) {
    const result = [
      {
        fieldId: 'jobPosting.isOnline',
        label: 'Anzeige Online',
        fieldType: 'dropDown',
        dropDownValues: [
          { label: 'Ja', value: true },
          { label: 'Nein', value: false }
        ]
      },
      {
        fieldId: 'editingUser',
        label: 'Bearbeitung durch',
        fieldType: 'dropDown',
        dropDownValues: employees
      },
      {
        fieldId: 'consultingUser',
        label: 'Berater',
        fieldType: 'dropDown',
        dropDownValues: employees
      },
      {
        fieldId: 'status',
        label: 'Status',
        fieldType: 'dropDown',
        dropDownValues: status
      },
      {
        fieldId: 'statusDetails',
        label: 'Statusgrund',
        fieldType: 'dropDown',
        dropDownValues: statusDetails
      },
      {
        fieldId: 'serviceType',
        label: 'Leistungsart',
        fieldType: 'dropDown',
        dropDownValues: serviceType
      },
      {
        fieldId: 'jobTitle1',
        label: 'Anzeigentitel intern',
        fieldType: 'textField'
      },
      {
        fieldId: 'projectNumber',
        label: 'Auftragsnummer',
        fieldType: 'textField'
      },
      {
        fieldId: 'potentialCandidates.applicationSendDate',
        label: 'Bew. hin am',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'creationDate',
        label: 'eingegeben am',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'lastUpdate.date',
        label: 'geändert am',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'jobPosting.title',
        label: 'Position / Berufsbezeichnung',
        fieldType: 'textField'
      },
      {
        fieldId: 'company.name',
        label: 'Unternehmen',
        fieldType: 'textField'
      },
      {
        fieldId: 'partner',
        label: 'Systempartner',
        fieldType: 'dropDown',
        dropDownValues: partners
      },
      {
        fieldId: 'partTime',
        label: 'Teilzeit',
        fieldType: 'dropDown',
        dropDownValues: ['50%', '80%', '100%']
      }
    ];
    if (PARTNER === 'lienert') {
      const lienertAdditions = [
        {
          fieldId: 'matching.isOnline',
          label: 'Matching Online',
          fieldType: 'dropDown',
          dropDownValues: [
            { label: 'Ja', value: true },
            { label: 'Nein', value: false }
          ]
        },
        {
          fieldId: 'potentialCandidates.isReferred',
          label: 'Vermittelt',
          fieldType: 'dropDown',
          dropDownValues: [
            { label: 'Ja', value: true },
            { label: 'Nein', value: false }
          ]
        },
        {
          fieldId: 'potentialCandidates.signedDate',
          label: 'Eintrittsdatum',
          fieldType: 'datePicker'
        }
      ];
      lienertAdditions.forEach(item => {
        result.push(item);
      });
    }
    return result;
  }
};
