import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from 'prop-types';

import $ from 'jquery';
import WillingnessToChangeCircle from './WillingnessToChangeCircle';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

import './SendOrderBox.css';

class SendOrderBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      markedResults: [],
      success: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  onClose = () => {
    this.props.liftUpStateToApp('sendOrderBoxOpen', false);
  };

  onEntering = () => {
    const markedResults = [];
    this.props.markedCandidateIDs.forEach(candidateID => {
      const indexOfFoundResult = this.props.results.findIndex(
        el => el.ID === candidateID
      );
      if (indexOfFoundResult >= 0) {
        markedResults.push(this.props.results[indexOfFoundResult]);
      }
    });
    this.setState({ markedResults });
  };

  onSendButtonClick = () => {
    const selectedCandidateIDs = [];
    this.state.markedResults.forEach(result => {
      selectedCandidateIDs.push(result.ID);
    });
    const request = {
      selectedCandidateIDs,
      searchID: this.props.searchID
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.sendOrderURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      // TODO remove previous version before more extensive testing
      // .done((responseBody, status) => {
      .done(() => {
        this.setState({ success: true });
      })
      .fail(err => {
        // TODO handle Error
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
  };

  renderListItems() {
    const listItems = [];
    this.state.markedResults.forEach(result => {
      let age = '';
      if (result.age && result.age > 1)
        age = `,  ${TEXT.resultPage.age} ${result.age}`;
      listItems.push(
        // <ListItem key={result.ID}>
        <div className="listItem">
          <div className="capitalize-words row headline">
            <div className="col-12">
              {`${
                result.jobs[0] ? result.jobs[0].jobText : TEXT.commonTerms.NA
              }${age}`}
            </div>
          </div>
          <div className="row details">
            <div className="col-6 matchPercentage">
              <div className="label">{`${
                TEXT.resultPage.matchPercentage
              }:`}</div>
              {UTILS.calcMatchStars(result.score)}
            </div>

            <div className="col-6 willingnessToChange">
              <WillingnessToChangeCircle
                willingnessToChange={result.willingnessToChange}
              />
            </div>
          </div>
        </div>
        // </ListItem>
      );
    });
    return listItems;
  }

  render() {
    return (
      <Dialog
        id="sendOrderBox-dialog"
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        onEntering={this.onEntering}
        onClose={this.onClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="sendOrderBox-dialog-title"
      >
        {this.state.success ? (
          <div>
            <DialogTitle id="sendOrderBox-dialog-title">
              <i className="material-icons" style={{ fontSize: 50 }}>
                check_circle_outline
              </i>
              {` ${TEXT.resultPage.sendOrderBox.success}`}
            </DialogTitle>
            <DialogContent id="sendOrderBox-dialog-content">
              <DialogContentText>
                {TEXT.resultPage.sendOrderBox.successText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={UTILS.logout} color="primary">
                {TEXT.commonTerms.logout}
              </Button>
              <Button
                onClick={() => {
                  window.location = '/search';
                }}
                color="primary"
              >
                {TEXT.resultPage.sendOrderBox.startNextSearch}
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div>
            <DialogTitle id="sendOrderBox-dialog-title">
              {TEXT.resultPage.sendOrderBox.yourOrder}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="sendOrderBox-dialog-subheadline">
                {TEXT.resultPage.sendOrderBox.yourCandidates}
              </DialogContentText>
              {/* <List style={{ maxHeight: '100%', overflow: 'auto' }}> */}
              {this.renderListItems()}
              {/* </List> */}
            </DialogContent>
            <DialogActions id="sendOrderBox-dialog-buttonContainer">
              <Button onClick={this.onClose} color="primary">
                {TEXT.commonTerms.cancel}
              </Button>
              <Button onClick={this.onSendButtonClick} color="primary">
                {TEXT.resultPage.sendOrderBox.sendOrder}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

SendOrderBox.defaultProps = {
  searchID: 'N/A'
};

SendOrderBox.propTypes = {
  open: PropTypes.bool.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  searchID: PropTypes.string,
  markedCandidateIDs: PropTypes.array.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      isAvailable: PropTypes.bool,
      currentResidence: PropTypes.string,
      currentCountry: PropTypes.string,
      age: PropTypes.string || PropTypes.number,
      lastChange: PropTypes.string,
      ID: PropTypes.string.isRequired,
      picture: PropTypes.string,
      jobs: PropTypes.arrayOf(
        PropTypes.shape({
          jobText: PropTypes.string,
          description: PropTypes.string,
          company: PropTypes.string,
          duration: PropTypes.number,
          branches: PropTypes.arrayOf(PropTypes.string),
          ID: PropTypes.string.isRequired
        })
      ),
      score: PropTypes.number.isRequired,
      isMarked: PropTypes.bool.isRequired,

      willingnessToChange: PropTypes.number.isRequired,
      distance: PropTypes.number.isRequired,
      willingnessToRelocate: PropTypes.string
    })
  ).isRequired
};
export default SendOrderBox;
